// SubjectSection.js
import React from 'react';
import ResourceType from './ResourceType';
import Ads from '../bodyFiles/AdPosts';

const SubjectSection = ({ subject }) => {
  return (
    <div className='subjects-and-resources-links-block'>
      <h2 className='oer-subject-name'>{subject.name}</h2>
      <div className='oer-resource-type-block'>
        {Object.keys(subject.resources).map((resourceType, index) => (
          <ResourceType
            key={index}
            type={resourceType}
            resources={subject.resources[resourceType]}
          />
        ))}<div style={{marginBottom:'10px'}}>
        <Ads />
      </div>
      </div>
      
      
    </div>
  );
};

export default SubjectSection;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MenuToggle from '../MenuToggle';  
import ReadMore from './ReadMore';  
import '../Communities.css'; // Import the CSS for styling

const ArticleTwo = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });
 
        if (response.ok) { 
          const data = await response.json();
          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        setIsLoggedIn(false);
      }
    };

    fetchUser();
  }, [navigate]);

  return (
    <div>
      <div className="communities-page">
        {isLoggedIn ? (
          <div>
            <div className='page-colour-manager'>

                <div className="my-study-container">  
                    <div className='unique-image-card-container-pad'>

                      <article className='aricle-block-div'>

                        <h1 className='article-name-tag-h1'>Subjects and Activities in Our Educational App</h1>

                        <p className='disclaimer-note-label'>
                          Read our
                          <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer </Link>
                          before proceeding with the article below
                        </p>

                        <label>by: Lernacy</label>

                        <p>
                          At our educational app, we believe in providing comprehensive resources tailored to 
                          the needs of South African learners. While our current offerings focus primarily on 
                          mathematics and social sciences for the foundation phase, we are excited to announce 
                          that we are expanding our services to cover a wide range of subjects and topics.
                        </p>

                        <h2>
                          Current Subjects Offered
                        </h2>

                        <p>
                          Our platform currently includes various activities designed for the foundation phase 
                          in the following subjects:
                        </p>

                        <ul>
                          <li>Mathematics</li>
                          <li>Social Sciences</li>
                        </ul>

                        <h2>Future Developments</h2>
                        <p>
                          In addition to our current offerings, we are in the process of developing additional 
                          subjects and topics that will cater to learners across all grades. These upcoming 
                          features will include:</p>
                        <ul>
                            <li>Natural Sciences</li>
                            <li>Life Skills</li>
                            <li>Language Arts</li>
                            <li>Technology</li>
                        </ul>

                        <h2>Mathematics Topics for Foundation and Intermediate Phases</h2>
                        <p>
                          Our educational app will provide detailed activities and resources covering 
                          essential mathematics topics for both the foundation and intermediate phases, such as:
                        </p>
                        <ul>
                            <li>Numbers and Operations</li>
                            <li>Measurement</li>
                            <li>Geometry</li>
                            <li>Data Handling</li>
                            <li>Patterns and Algebra</li>
                        </ul>

                        <h2>Notes and Resources in Development</h2>
                        <p>We are also working on developing notes and additional resources to support educators 
                          and learners in their educational journeys. These resources will be designed to 
                          enhance understanding and foster a love for learning.
                        </p>

                        <h2>A Platform for All Subjects</h2>
                        <p>While we currently focus on mathematics and social sciences, we are partnering with 
                          third-party services to offer resources across various subjects and topics. This 
                          collaboration will ensure that learners have access to a diverse range of educational 
                          materials, enhancing their learning experience.
                          </p>

                        <h2>Conclusion</h2>
                        <p>Our commitment to improving education in South Africa drives us to continually 
                          expand and enhance our app’s offerings. We are dedicated to providing valuable 
                          resources that meet the needs of all learners, ensuring that they can thrive 
                          academically.
                        </p>

                        <p>Published: 02 November, 2024</p>

                        <hr className='aricle-block-div-hr' />
        
                        <ReadMore />

                        <br />

                      </article>

                    </div>
                </div>

            </div>
          </div>
        ) : (
          <div>
            <div className='not-logged-in-alt'>
              <MenuToggle />
              <div className='not-logged-in-alt-aligner'>
                
              <div className='aricle-block-div-pad'>
                <article className='aricle-block-div'>

                <h1 className='article-name-tag-h1'>Subjects and Activities in Our Educational App</h1>

                <p className='disclaimer-note-label'>
                  Read our
                  <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer </Link>
                  before proceeding with the article below
                </p>

                <label>by: Lernacy</label>

                <p>
                  At our educational app, we believe in providing comprehensive resources tailored to 
                  the needs of South African learners. While our current offerings focus primarily on 
                  mathematics and social sciences for the foundation phase, we are excited to announce 
                  that we are expanding our services to cover a wide range of subjects and topics.
                </p>

                <h2>
                  Current Subjects Offered
                </h2>

                <p>
                  Our platform currently includes various activities designed for the foundation phase 
                  in the following subjects:
                </p>

                <ul>
                  <li>Mathematics</li>
                  <li>Social Sciences</li>
                </ul>

                <h2>Future Developments</h2>
                <p>
                  In addition to our current offerings, we are in the process of developing additional 
                  subjects and topics that will cater to learners across all grades. These upcoming 
                  features will include:</p>
                <ul>
                    <li>Natural Sciences</li>
                    <li>Life Skills</li>
                    <li>Language Arts</li>
                    <li>Technology</li>
                </ul>

                <h2>Mathematics Topics for Foundation and Intermediate Phases</h2>
                <p>
                  Our educational app will provide detailed activities and resources covering 
                  essential mathematics topics for both the foundation and intermediate phases, such as:
                </p>
                <ul>
                    <li>Numbers and Operations</li>
                    <li>Measurement</li>
                    <li>Geometry</li>
                    <li>Data Handling</li>
                    <li>Patterns and Algebra</li>
                </ul>

                <h2>Notes and Resources in Development</h2>
                <p>We are also working on developing notes and additional resources to support educators 
                  and learners in their educational journeys. These resources will be designed to 
                  enhance understanding and foster a love for learning.
                </p>

                <h2>A Platform for All Subjects</h2>
                <p>While we currently focus on mathematics and social sciences, we are partnering with 
                  third-party services to offer resources across various subjects and topics. This 
                  collaboration will ensure that learners have access to a diverse range of educational 
                  materials, enhancing their learning experience.
                  </p>

                <h2>Conclusion</h2>
                <p>Our commitment to improving education in South Africa drives us to continually 
                  expand and enhance our app’s offerings. We are dedicated to providing valuable 
                  resources that meet the needs of all learners, ensuring that they can thrive 
                  academically.
                </p>

                <p>Published: 02 November, 2024</p>

                <hr className='aricle-block-div-hr' />

                <ReadMore />

                <br />

                </article>
              </div>

              </div>
            </div>
          </div>
        )}
      </div>
      
    </div>
  ); 
};

export default ArticleTwo;

import React, { useState, useEffect, useRef } from 'react';
// import './NotificationPage.css'; // Import your styles

const NotificationPage = ({ userId, isOpen, toggleNav }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const panelRef = useRef(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/notifications', {
          credentials: 'include',
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch notifications');
        }
    
        const data = await response.json();
        setNotifications(data.notifications);
        setLoading(false);
    
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [userId]);

  const markNotificationsAsRead = async () => {
    try {
      const response = await fetch('https://lernacy.co.za/api/notifications/mark-read', {
        method: 'PUT',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to mark notifications as read');
      }

      // Update the notifications state to mark all as read
      setNotifications(notifications.map(notification => ({
        ...notification,
        read: true,
      })));

    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const handleClickOutside = (event) => {
    if (isOpen && panelRef.current && !panelRef.current.contains(event.target)) {
      toggleNav();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  if (loading) {
    return <div>Loading notifications...</div>;
  }

  return (
    <div className={`notification-panel ${isOpen ? 'open' : ''}`} ref={panelRef}>
      <button onClick={toggleNav} className="close-btn">Close</button>
      <h2>Notifications</h2>
      <button onClick={markNotificationsAsRead}>Mark all as read</button>
      <ul>
        {notifications.map(notification => (
          <li key={notification._id}>
            {notification.message} {!notification.read && <span>(unread)</span>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotificationPage;


import React, { useState, useEffect } from 'react';
import './bodyFiles-styling/supportProfile.css';
import './bodyFiles-styling/bodyFiles.css';
import AccessDenied from '../components/AccessDenied';
import { Link } from 'react-router-dom';

const UserLinks = () => {
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [trackingRequests, setTrackingRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentUserDetails, setCurrentUserDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetUserId, setTargetUserId] = useState(null);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [targetBlockUserId, setTargetBlockUserId] = useState(null);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [error, setError] = useState(null); // Add state for error handling

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/user-links', { credentials: 'include' });

        if (!response.ok) {
          if (response.status === 401) {
            throw new Error('Unauthorized');
          }
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();

        setFollowers(data.followers);
        setFollowing(data.following);
        setTrackingRequests(data.trackingRequests);
        setCurrentUserDetails(data.currentUser);
        setCurrentUserId(data.currentUser._id);
        setBlockedUsers(data.blockedUsers);

        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const proceedBlock = async () => {
    try {
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
      const { csrfToken } = await csrfResponse.json();
  
      const requestData = { currentUserId, userIdToBlock: targetBlockUserId };
  
      // Include CSRF token in headers
      const response = await fetch('https://lernacy.co.za/api/block-user', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,
        },
        body: JSON.stringify(requestData),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to block user');
      }
  
      // Update state and UI
      setFollowers(prevFollowers => prevFollowers.filter(user => user._id !== targetBlockUserId));
      setFollowing(prevFollowing => prevFollowing.filter(user => user._id !== targetBlockUserId));
      setTrackingRequests(prevTrackingRequests =>
        prevTrackingRequests.filter(request => request.sender._id !== targetBlockUserId)
      );
      setBlockedUsers(prevBlockedUsers => prevBlockedUsers.filter(user => user._id !== targetBlockUserId));
  
      // Reset modal and target user ID
      setShowBlockModal(false);
      setTargetBlockUserId(null);
  
      alert('User blocked successfully');
    } catch (error) {
      console.error('Error blocking user:', error);
      alert('Error blocking user: ' + error.message);
    }
  };
  /*
  const proceedBlock = async () => {
    try {
      console.log('Sending block request with:', { currentUserId, targetBlockUserId });
  
      const requestData = { currentUserId, userIdToBlock: targetBlockUserId };
  
      const response = await fetch('https://lernacy.co.za/api/block-user', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to block user');
      }
  
      // Update the followers state to remove the blocked user
      setFollowers((prevFollowers) => prevFollowers.filter((user) => user._id !== targetBlockUserId));
  
      // Remove the blocked user from the following list if exists
      setFollowing((prevFollowing) => prevFollowing.filter((user) => user._id !== targetBlockUserId));
  
      // Remove the blocked user from the tracking requests list if exists
      setTrackingRequests((prevTrackingRequests) =>
        prevTrackingRequests.filter((request) => request.sender._id !== targetBlockUserId)
      );
  
      // Remove the blocked user from the blocked users list if exists
      setBlockedUsers((prevBlockedUsers) =>
        prevBlockedUsers.filter((user) => user._id !== targetBlockUserId)
      );
  
      setShowBlockModal(false);
      setTargetBlockUserId(null);
    } catch (error) {
      console.error('Error blocking user:', error);
    }
  };  
  */
  
  const handleBlockModal = (userId) => {
    setTargetBlockUserId(userId);
    setShowBlockModal(true);
  };

  const cancelBlock = () => {
    setShowBlockModal(false);
    setTargetBlockUserId(null);
  };

  const handleUnfollow = (userId) => {
    setTargetUserId(userId);
    setShowModal(true);
  };
  
  const cancelUnfollow = () => {
    setShowModal(false);
    setTargetUserId(null);
  };

  const handleAcceptRequest = async (requestId) => {
    try {
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      const { csrfToken } = await csrfResponse.json();
  
      // Optimistically update the UI
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        updatedTrackingRequests[index].status = 'accepted';
        setTrackingRequests(updatedTrackingRequests);
      }
  
      const acceptResponse = await fetch('https://lernacy.co.za/api/users/accept-request', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include the CSRF token here
        },
        body: JSON.stringify({ requestId }),
      });
  
      if (!acceptResponse.ok) {
        throw new Error('Failed to accept request');
      }
  
      const updatedRequest = await acceptResponse.json();
  
      if (!updatedRequest.success) {
        throw new Error(updatedRequest.message || 'Failed to accept request');
      }
  
      // Fetch updated status with a GET request to 'https://lernacy.co.za/api/user-links'
      const linksResponse = await fetch('https://lernacy.co.za/api/user-links', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!linksResponse.ok) {
        throw new Error('Failed to fetch updated user links');
      }
  
      // Update UI with the fetched data
      const updatedData = await linksResponse.json();
      setTrackingRequests(updatedData); // Update the tracking requests with the latest data
  
    } catch (error) {
      console.error('Error accepting request:', error);
  
      // Revert the UI back to its original state if the request fails
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        updatedTrackingRequests[index].status = 'pending'; // Revert back to original status
        setTrackingRequests(updatedTrackingRequests);
      }
  
      alert('Error accepting request: ' + error.message);
    }
  };
  /*
  const handleAcceptRequest = async (requestId) => {
    try {
      //console.log('Sending accept request for requestId:', requestId);
  
      // Optimistically update the UI
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        updatedTrackingRequests[index].status = 'accepted';
        setTrackingRequests(updatedTrackingRequests);
        //console.log('Tracking requests updated optimistically');
      }
  
      const acceptResponse = await fetch('https://lernacy.co.za/api/users/accept-request', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ requestId }),
      });
  
      if (!acceptResponse.ok) {
        throw new Error('Failed to accept request');
      }
  
      //console.log('Accept request successful');
  
      // Fetch the updated request data directly from the response
      const updatedRequest = await acceptResponse.json();
      //console.log('Updated Request:', updatedRequest);
  
      // Fetch updated status with a GET request to 'https://lernacy.co.za/api/user-links'
      const linksResponse = await fetch('https://lernacy.co.za/api/user-links', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!linksResponse.ok) {
        throw new Error('Failed to fetch updated user links');
      }
  
      // Update UI with the fetched data
      const updatedData = await linksResponse.json();
      //console.log('Updated User Links:', updatedData);
    } catch (error) {
      //console.error('Error accepting request:', error);
      // Revert the UI back to its original state if the request fails
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        updatedTrackingRequests[index].status = 'pending'; // Revert back to original status
        setTrackingRequests(updatedTrackingRequests);
        //console.log('Tracking requests reverted');
      }
    }
  };
  */
  
  const handleDeclineRequest = async (requestId) => {
    try {
      // Validate requestId on the client side
      if (!requestId || typeof requestId !== 'string' || requestId.trim() === '') {
        throw new Error('Invalid requestId');
      }
  
      // Optimistically update the UI
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        updatedTrackingRequests[index].status = 'declined'; // Update the status to 'declined'
        setTrackingRequests(updatedTrackingRequests);
      }
  
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      const declineResponse = await fetch('https://lernacy.co.za/api/users/decline-request', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        body: JSON.stringify({ requestId: requestId.trim() }),
      });
  
      if (!declineResponse.ok) {
        const responseData = await declineResponse.json();
        throw new Error(responseData.message || 'Failed to decline request');
      }
  
      // Fetch the updated request data directly from the response
      const updatedRequest = await declineResponse.json();
      //console.log('Updated Request:', updatedRequest);
    } catch (error) {
      console.error('Error declining request:', error);
  
      // Revert the UI back to its original state if the request fails
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        // Revert the status change
        updatedTrackingRequests[index].status = 'pending'; // or 'accepted', depending on your logic
        setTrackingRequests(updatedTrackingRequests);
      }
  
      // Optionally show an error message to the user
      alert(`Error declining request: ${error.message}`);
    }
  };
  /*
  const handleDeclineRequest = async (requestId) => {
    try {
      // Optimistically update the UI
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        updatedTrackingRequests[index].status = 'declined'; // Update the status to 'declined'
        setTrackingRequests(updatedTrackingRequests);
        //console.log('Tracking requests updated optimistically');
      }
  
      const declineResponse = await fetch('https://lernacy.co.za/api/users/decline-request', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ requestId }),
      });
      
      if (!declineResponse.ok) {
        throw new Error('Failed to decline request');
      }
  
      // Fetch the updated request data directly from the response
      const updatedRequest = await declineResponse.json();
      //console.log('Updated Request:', updatedRequest);
    } catch (error) {
      console.error('Error declining request:', error);
      // Revert the UI back to its original state if the request fails
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        // Revert the status change
        updatedTrackingRequests[index].status = 'pending'; // or 'accepted', depending on your logic
        setTrackingRequests(updatedTrackingRequests);
        //console.log('Tracking requests reverted');
      }
    }
  }; 
  */ 
  
  const handleDeactivateConnection = async (requestId) => {
    try {
      // Optimistically update the UI
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        updatedTrackingRequests[index].status = 'error'; // Update the status to 'error'
        setTrackingRequests(updatedTrackingRequests);
        // console.log('Tracking requests updated optimistically');
      }
  
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      // Make request with CSRF token
      const response = await fetch('https://lernacy.co.za/api/users/deactivate-connection', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        body: JSON.stringify({ requestId }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to deactivate connection');
      }
  
      // Fetch updated user links data after deactivating the connection
      const linksResponse = await fetch('https://lernacy.co.za/api/user-links', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!linksResponse.ok) {
        throw new Error('Failed to fetch updated user links');
      }
  
      // Update UI or state with the updated user links data
      const updatedData = await linksResponse.json();
      // console.log('Updated User Links:', updatedData);
    } catch (error) {
      console.error('Error deactivating connection:', error);
      alert(`Error: ${error.message}`);
  
      // Revert the UI back to its original state if the request fails
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        // Revert the status change
        updatedTrackingRequests[index].status = 'accepted'; // or 'pending', depending on your logic
        setTrackingRequests(updatedTrackingRequests);
        // console.log('Tracking requests reverted');
      }
    }
  };
  /*
  const handleDeactivateConnection = async (requestId) => {
    try {
      // Optimistically update the UI
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        updatedTrackingRequests[index].status = 'error'; // Update the status to 'error'
        setTrackingRequests(updatedTrackingRequests);
        //console.log('Tracking requests updated optimistically');
      }
  
      const response = await fetch('https://lernacy.co.za/api/users/deactivate-connection', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ requestId }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to deactivate connection');
      }
  
      // Fetch updated user links data after deactivating the connection
      const linksResponse = await fetch('https://lernacy.co.za/api/user-links', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!linksResponse.ok) {
        throw new Error('Failed to fetch updated user links');
      }
  
      // Update UI or state with the updated user links data
      const updatedData = await linksResponse.json();
      //console.log('Updated User Links:', updatedData);
    } catch (error) {
      console.error('Error deactivating connection:', error);
      // Revert the UI back to its original state if the request fails
      const index = trackingRequests.findIndex((request) => request._id === requestId);
      if (index !== -1) {
        const updatedTrackingRequests = [...trackingRequests];
        // Revert the status change
        updatedTrackingRequests[index].status = 'accepted'; // or 'pending', depending on your logic
        setTrackingRequests(updatedTrackingRequests);
        //console.log('Tracking requests reverted');
      }
    }
  };
  */
  
  const proceedUnfollow = async () => {
    try {
      // Validate the user IDs on the client side
      if (!currentUserId || !targetUserId) {
        throw new Error('User IDs are required');
      }
  
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      const { csrfToken } = await csrfResponse.json();
  
      // Make the unfollow POST request with CSRF token
      const unfollowResponse = await fetch('https://lernacy.co.za/api/unfollow', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        body: JSON.stringify({ currentUserId, targetUserId }),
      });
  
      const unfollowData = await unfollowResponse.json();
  
      if (!unfollowResponse.ok) {
        throw new Error(unfollowData.message || 'Failed to unfollow user');
      }
  
      // Make the GET request to update the following list
      const linksResponse = await fetch('https://lernacy.co.za/api/user-links', {
        method: 'GET',
        credentials: 'include',
      });
  
      const linksData = await linksResponse.json();
  
      if (!linksResponse.ok) {
        throw new Error(linksData.message || 'Failed to fetch updated user links');
      }
  
      // Update the following state and log the updated count
      setFollowing(linksData.following);
  
      // Close the modal
      setShowModal(false);
      setTargetUserId(null);
  
    } catch (error) {
      console.error('Error unfollowing user:', error.message);
      alert(`Error: ${error.message}`);
    }
  };

  const unblockUser = async (userId) => {
    try {
      // Validate userId on client side
      if (!userId || typeof userId !== 'string' || userId.trim() === '') {
        throw new Error('Invalid userId');
      }

      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include'
      });

      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }

      const csrfData = await csrfResponse.json();

      // Use the CSRF token in the headers of the POST request
      const response = await fetch('https://lernacy.co.za/api/users/unblock-user', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfData.csrfToken // Include CSRF token in the headers
        },
        body: JSON.stringify({ userId: userId.trim() }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to unblock user');
      }

      // Update the UI to remove the unblocked user from the list of blocked users
      setBlockedUsers(prevBlockedUsers => prevBlockedUsers.filter(user => user._id !== userId));
      alert('User unblocked successfully');
    } catch (error) {
      console.error('Error unblocking user:', error.message);
      alert('Error unblocking user: ' + error.message);
    }
  };
  /*
  const unblockUser = async (userId) => {
    try {
      const response = await fetch('https://lernacy.co.za/api/users/unblock-user', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'Failed to unblock user');
      }
  
      // Update the UI to remove the unblocked user from the list of blocked users
      setBlockedUsers(prevBlockedUsers => prevBlockedUsers.filter(user => user._id !== userId));
    } catch (error) {
      console.error('Error unblocking user:', error);
    }
  };
  */

  if (error === 'Unauthorized') {
    return <AccessDenied />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const currentUser = currentUserDetails;

  return (
    <div className='userlinks-page'>
      <h1 className='activity-name-tag-h1'>User Links</h1>

      <div className='user-link-section-content-container'>
        <div className='user-link-section-content'>

          {currentUser && currentUser.status === 'Student' && (
            <section className='user-link-sections-blocks'>
              <h2 className='section-name-h2-tag'>Tracking Requests</h2>
              {trackingRequests.length ? (
                <ul className='tracking-requests-list-block'>
                  {trackingRequests.map(request => (
                    <li key={request._id} className='tracking-requests-li'>
                      <Link to={`/user/${request.sender.decoyId}`} className='user-link'>
                        <img
                          loading="lazy"
                          className='userlink-followers-profile'
                          src={request.sender.profilePicture}
                          alt="Profile"
                        /> 
                        <div>
                          <p><strong>{request.sender.name} {request.sender.surname}</strong></p>
                          <p>{request.sender.accountName}</p>
                          <p>{request.sender.status}</p> 
                          <p>Status: {request.status}</p>
                        </div>
                      </Link>
                      <div>
                        {(request.status !== 'accepted') && (
                          <button onClick={() => handleAcceptRequest(request._id)} className='accept-button'>Accept</button>
                        )}
                        {(request.status !== 'declined') && (
                          <button onClick={() => handleDeclineRequest(request._id)} className='decline-button'>Decline</button>
                        )}
                        {(request.status === 'accepted') && (
                          <button onClick={() => handleDeactivateConnection(request._id)} className='deactivate-button'>Deactivate</button>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className='tracking-requests-list-none'>
                  <p>No tracking requests found.</p>
                </div>
              )}
            </section>
          )}

          <section className='user-link-sections-blocks'>
            <h2 className='section-name-h2-tag'>Following</h2>
            {following.length ? (
              <ul className='tracking-requests-list-block' /*className='followers-section-list'*/>
                {following.map(user => (
                  <li key={user._id} className='followers-section-list-blocks'>
                    <Link to={`/user/${user.decoyId}`} className='user-link'>
                      <div>
                        <img
                          loading="lazy"
                          className='userlink-followers-profile'
                          src={user.profilePicture}
                          alt="Profile"
                        /> 
                      </div>
                      <div>
                        <p className='userlink-followers-name-surname'>{user.name} {user.surname}</p>
                        <p className='userlink-followers-accountName'>{user.accountName}</p>
                        <p className='userlink-followers-status'>Status: {user.status}</p>
                        <p className='userlink-followers-role'>Role: {user.role}</p>
                        {/*<p className='userlink-followers-role'>Id: {user._id}</p>*/}
                      </div>
                    </Link>
                    <button 
                      onClick={() => handleUnfollow(user._id)} 
                      className='unfollow-button'
                    >
                      Unfollow
                    </button>

                    {/* Modal for confirmation */}
                    {showModal && (
                      <div className="modal">
                        <div className="modal-content">
                          <p>Are you sure you want to <strong>unfollow {user.name} {user.surname}?</strong></p>
                          <button onClick={proceedUnfollow} className="proceed-button">Proceed</button>
                          <button onClick={cancelUnfollow} className="cancel-button">Cancel</button>
                        </div>
                      </div>
                    )}
                  </li> 
                ))}
              </ul>
            ) : (
              <div className='tracking-requests-list-none'>
                <p>No users followed.</p>
              </div>
            )}
          </section>

          <section className='user-link-sections-blocks'>
            <h2 className='section-name-h2-tag'>Followers</h2>
            {followers.length ? (
              <ul className='tracking-requests-list-block' /*className='followers-section-list'*/>
                {followers.map(user => (
                  <li key={user._id} className='followers-section-list-blocks'>
                    <Link to={`/user/${user.decoyId}`} className='user-link'>
                      <div>
                        <img
                          loading="lazy"
                          className='userlink-followers-profile'
                          src={user.profilePicture}
                          alt="Profile"
                        />
                      </div>
                      <div>
                        <p className='userlink-followers-name-surname'>{user.name} {user.surname}</p>
                        <p className='userlink-followers-accountName'>{user.accountName}</p>
                        <p className='userlink-followers-status'>Status: {user.status}</p>
                        <p className='userlink-followers-role'>Role: {user.role}</p>
                        {/*<p className='userlink-followers-role'>Id: {user._id}</p>*/}
                      </div>
                    </Link>
                    <button onClick={() => handleBlockModal(user._id)} className='block-button'>Block</button>
                  </li>
                ))}
              </ul>
            ) : (
              <div className='tracking-requests-list-none'>
                <p>No followers.</p>
              </div>
            )}
          </section> 

          <section className='user-link-sections-blocks'>
            <h2 className='section-name-h2-tag'>Blocked Users</h2>
            {blockedUsers.length ? (
              <ul className='tracking-requests-list-block' /*className='followers-section-list'*/>
                {blockedUsers.map(user => (
                  <li key={user._id} className='followers-section-list-blocks'>
                    <Link to={`/user/${user.decoyId}`} className='user-link'>
                      <div>
                        <img
                          loading="lazy"
                          className='userlink-followers-profile'
                          src={user.profilePicture}
                          alt="Profile"
                        />
                      </div>
                      <div>
                        <p className='userlink-followers-name-surname'>{user.name} {user.surname}</p>
                        <p className='userlink-followers-accountName'>{user.accountName}</p>
                        <p className='userlink-followers-status'>Status: {user.status}</p>
                        <p className='userlink-followers-role'>Role: {user.role}</p>
                        {/* 
                          <p className='userlink-followers-role'>Id: {user._id}</p>
                        */}
                      </div>
                    </Link>
                    <button onClick={() => unblockUser(user._id)} className='unblock-button'>Unblock</button>
                  </li>
                ))}
              </ul>
            ) : (
              <div className='tracking-requests-list-none'>
                <p>No users blocked.</p>
              </div>
            )}
          </section>

          <br />
          <br />
          <br />

        </div>
      </div>

      {/* Block Modal */}
      {showBlockModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to <strong>block this user?</strong></p>
            <button onClick={proceedBlock} className="proceed-button">Proceed</button>
            <button onClick={cancelBlock} className="cancel-button">Cancel</button>
          </div>
        </div>
      )}

      {/* Unfollow Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to <strong>unfollow {currentUserDetails.name} {currentUserDetails.surname}?</strong></p>
            <button onClick={proceedUnfollow} className="proceed-button">Proceed</button>
            <button onClick={cancelUnfollow} className="cancel-button">Cancel</button>
          </div>
        </div>
      )}

    </div>
  );
};

export default UserLinks;

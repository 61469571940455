import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AccessDenied from '../components/AccessDenied';
import SomeWentWrong from '../components/SomeWentWrong';

// Utility to get the number of days in a month
const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

// Utility to get the day of the week the month starts on
const getStartDayOfMonth = (year, month) => {
  return new Date(year, month, 1).getDay();
};

const CreateInstitutionSchedule = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [labeledDays, setLabeledDays] = useState({});
  const [colorLabel, setColorLabel] = useState({
    yellow: '',
    red: '',
    green: 'Formal Assessment',
    blue: '',
  });
  const [selectedColor, setSelectedColor] = useState('yellow');
  const [todayDate, setTodayDate] = useState('');

  // Fetch authentication status and existing schedule data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/create-schedule', {
          withCredentials: true,
        });

        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setErrorStatus(401);
          } else if (error.response.status === 403) {
            setErrorStatus(403);
          }
          setError(error.response.data.message);
        } else {
          setError('Failed to fetch schedule data');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Fetch the user's marked days
  useEffect(() => {
    const fetchMarkedDays = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/get-marked-days', {
          withCredentials: true,
        });

        if (response.status === 200 && response.data.markedDays) {
          const markedDaysData = response.data.markedDays.reduce((acc, dayObj) => {
            dayObj.markedDays.forEach(day => {
              acc[day.dayKey] = { color: day.color, label: day.label };
            });
            return acc;
          }, {});
          setLabeledDays(markedDaysData);
        }
      } catch (error) {
        console.error('Failed to fetch marked days:', error);
      }
    };

    fetchMarkedDays();
  }, []);

  // Set today's date
  useEffect(() => {
    const today = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    setTodayDate(today.toLocaleDateString(undefined, options));
  }, []);

  const handleDayClick = (month, day) => {
    const newLabeledDays = { ...labeledDays };
    const dayKey = `${month}-${day}`;

    if (newLabeledDays[dayKey] && newLabeledDays[dayKey].color === selectedColor) {
      delete newLabeledDays[dayKey];
    } else {
      newLabeledDays[dayKey] = {
        color: selectedColor,
        label: colorLabel[selectedColor],
      };
    }

    setLabeledDays(newLabeledDays);
  };

  const handleLabelChange = (color, label) => {
    setColorLabel({ ...colorLabel, [color]: label });
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Set loading to true when request starts
    try {
      // Fetch CSRF token
      const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
      if (!csrfResponse.data.csrfToken) {
        throw new Error('Failed to fetch CSRF token');
      }
      const csrfToken = csrfResponse.data.csrfToken;

      // Prepare labeledDays data to submit
      const markedDays = Object.entries(labeledDays).map(([dayKey, data]) => ({
        dayKey,
        color: data.color,
        label: data.label,
      }));

      // Make POST request with CSRF token in headers
      await axios.post(
        'https://lernacy.co.za/api/submit-marked-days',
        { markedDays },
        { withCredentials: true, headers: { 'CSRF-Token': csrfToken } }
      );

      // Display success message to the user
      alert('Schedule updated successfully');
      //console.log('Submitted Labeled Days:', markedDays); // Optional: Log the submitted data
    } catch (error) {
      console.error('Error submitting marked days:', error);
      // Handle error appropriately (e.g., show error message to user)
      alert('Failed to submit marked days. Please try again later.');
    } finally {
      setIsLoading(false); // Set loading to false when request completes
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errorStatus === 401) {
    return <AccessDenied />;
  }

  if (errorStatus === 403) {
    return <SomeWentWrong />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // List of months for the 2024 calendar
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Get today's date
  const today = new Date();

  return (
    <div className="user-profile-account-aligner-container">
      <div className="schedule-profile-navigation-container">
        <h1 className="schedule-page-title-name">Create Schedule</h1>
        <div className="schedule-profile-navigation">
          <Link to={'/profile'} className="schedule-profile-navigation-p-color-back">
            <div className="schedule-profile-navigation-div">Posts</div>
          </Link>
          <Link to={'/user-links'} className="schedule-profile-navigation-p-color-back-active">
            <div className="schedule-profile-navigation-div">Schedule</div>
          </Link>
        </div>
      </div>

      <br />

      <div className='calender-content-page'>
        <div className="calendar-container">
          <div className="color-label-input">

            <label>
              Yellow Label:
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.yellow}
              onChange={(e) => handleLabelChange('yellow', e.target.value)}
            /><br />

            <label>
              Red Label:
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.red}
              onChange={(e) => handleLabelChange('red', e.target.value)}
            /><br />

            <label>
              Green Label:
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.green}
              onChange={(e) => handleLabelChange('green', e.target.value)}
            /><br />

            <label>
              Blue Label:
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.blue}
              onChange={(e) => handleLabelChange('blue', e.target.value)}
            /><br />

            <label>
              Select Color:<br />
              <select className='color-label-input-select' value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)}>
                <option value="yellow">Yellow</option>
                <option value="red">Red</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
              </select>
            </label><br />
          </div>

          {months.map((month, index) => {
            const daysInMonth = getDaysInMonth(2024, index);
            let startDay = getStartDayOfMonth(2024, index);
            const weeks = [];
            let currentWeek = new Array(7).fill(null);

            // Fill the first week with leading nulls
            for (let i = 0; i < startDay; i++) {
              currentWeek[i] = null;
            }

            // Fill the days of the month
            for (let day = 1; day <= daysInMonth; day++) {
              currentWeek[startDay] = day;
              startDay++;
              if (startDay === 7) {
                weeks.push(currentWeek);
                currentWeek = new Array(7).fill(null);
                startDay = 0;
              }
            }

            // Push the last week
            if (startDay !== 0) {
              weeks.push(currentWeek);
            }

            return (
              <div key={index} className="month">
                <h2>
                  {month} 2024
                </h2>
                <div className="calendar-grid">
                  <div className="calendar-weekdays">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, i) => (
                      <div key={i} className="calendar-day">{day}</div>
                    ))}
                  </div>
                  {weeks.map((week, i) => (
                    <div key={i} className="calendar-week">
                      {week.map((day, j) => {
                        const dayKey = `${index}-${day}`;
                        const isToday = today.getDate() === day && today.getMonth() === index;
                        const backgroundColor = isToday ? '#666' : labeledDays[dayKey]?.color || 'transparent';
                        const label = labeledDays[dayKey]?.label || '';
                        const dayStyle = {
                          backgroundColor: backgroundColor,
                          position: 'relative', // Ensure the label is positioned relative to the day cell
                        };

                        return (
                          <div
                            key={j}
                            className="calendar-day"
                            onClick={() => day && handleDayClick(index, day)}
                            style={dayStyle}
                          >
                            {day !== null ? day : ''}
                            {label && (
                              <div className="day-label" >
                                {label}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              </div>
            ); 
          })}
        </div>
        <br />

        <div>
          {/* Other component code */}
          <button onClick={handleSubmit} className="submit-button">
            {isLoading ? 'Loading...' : 'Submit Data'}
          </button>
        </div>

      </div>
    </div>
  );
};

export default CreateInstitutionSchedule;


/* 
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AccessDenied from '../components/AccessDenied';
import SomeWentWrong from '../components/SomeWentWrong';

// Utility to get the number of days in a month
const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

// Utility to get the day of the week the month starts on
const getStartDayOfMonth = (year, month) => {
  return new Date(year, month, 1).getDay();
};

const CreateInstitutionSchedule = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [labeledDays, setLabeledDays] = useState({});
  const [colorLabel, setColorLabel] = useState({
    yellow: '',
    red: '',
    green: 'Formal Assessment',
    blue: '',
  });
  const [selectedColor, setSelectedColor] = useState('yellow');
  const [todayDate, setTodayDate] = useState('');

  // Fetch authentication status and existing schedule data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/create-schedule', {
          withCredentials: true,
        });

        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setErrorStatus(401);
          } else if (error.response.status === 403) {
            setErrorStatus(403);
          }
          setError(error.response.data.message);
        } else {
          setError('Failed to fetch schedule data');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Fetch the user's marked days
  useEffect(() => {
    const fetchMarkedDays = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/get-marked-days', {
          withCredentials: true,
        });

        if (response.status === 200 && response.data.markedDays) {
          const markedDaysData = response.data.markedDays.reduce((acc, dayObj) => {
            dayObj.markedDays.forEach(day => {
              acc[day.dayKey] = { color: day.color, label: day.label };
            });
            return acc;
          }, {});
          setLabeledDays(markedDaysData);
        }
      } catch (error) {
        console.error('Failed to fetch marked days:', error);
      }
    };

    fetchMarkedDays();
  }, []);

  // Set today's date
  useEffect(() => {
    const today = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    setTodayDate(today.toLocaleDateString(undefined, options));
  }, []);

  const handleDayClick = (month, day) => {
    const newLabeledDays = { ...labeledDays };
    const dayKey = `${month}-${day}`;

    if (newLabeledDays[dayKey] && newLabeledDays[dayKey].color === selectedColor) {
      delete newLabeledDays[dayKey];
    } else {
      newLabeledDays[dayKey] = {
        color: selectedColor,
        label: colorLabel[selectedColor],
      };
    }

    setLabeledDays(newLabeledDays);
  };

  const handleLabelChange = (color, label) => {
    setColorLabel({ ...colorLabel, [color]: label });
  };

  const handleSubmit = async () => {
    try {
      // Fetch CSRF token
      const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
      if (!csrfResponse.data.csrfToken) {
        throw new Error('Failed to fetch CSRF token');
      }
      const csrfToken = csrfResponse.data.csrfToken;
  
      // Prepare labeledDays data to submit
      const markedDays = Object.entries(labeledDays).map(([dayKey, data]) => ({
        dayKey,
        color: data.color,
        label: data.label
      }));
  
      // Make POST request with CSRF token in headers
      await axios.post(
        'https://lernacy.co.za/api/submit-marked-days',
        { markedDays },
        { withCredentials: true, headers: { 'CSRF-Token': csrfToken } }
      );

      // Display success message to the user
      alert('Marked days submitted successfully');
      
      //console.log('Submitted Labeled Days:', markedDays); // Optional: Log the submitted data
    } catch (error) {
      console.error('Error submitting marked days:', error);
      // Handle error appropriately (e.g., show error message to user)
      alert('Failed to submit marked days. Please try again later.');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errorStatus === 401) {
    return <AccessDenied />;
  }

  if (errorStatus === 403) {
    return <SomeWentWrong />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // List of months for the 2024 calendar
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Get today's date
  const today = new Date();

  return (
    <div className="user-profile-account-aligner-container">
      <div className="schedule-profile-navigation-container">
        <h1 className="schedule-page-title-name">Schedule Page</h1>
        <div className="schedule-profile-navigation">
          <Link to={'/profile'} className="schedule-profile-navigation-p-color-back">
            <div className="schedule-profile-navigation-div">Posts</div>
          </Link>
          <Link to={'/user-links'} className="schedule-profile-navigation-p-color-back-active">
            <div className="schedule-profile-navigation-div">Schedule</div>
          </Link>
        </div>
      </div>

      <div className='calender-content-page'>
        <div className="calendar-container">
          <div className="color-label-input">
            <h2>Create Schedule</h2>
            <label>
              Select Color:<br />
              <select className='color-label-input-select' value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)}>
                <option value="yellow">Yellow</option>
                <option value="red">Red</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
              </select>
            </label><br />

            <label>
              Yellow Label:
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.yellow}
              onChange={(e) => handleLabelChange('yellow', e.target.value)}
            /><br />

            <label>
              Red Label:
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.red}
              onChange={(e) => handleLabelChange('red', e.target.value)}
            /><br />

            <label>
              Green Label:
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.green}
              onChange={(e) => handleLabelChange('green', e.target.value)}
            /><br />

            <label>
              Blue Label:
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.blue}
              onChange={(e) => handleLabelChange('blue', e.target.value)}
            /><br />
          </div>

          {months.map((month, index) => {
            const daysInMonth = getDaysInMonth(2024, index);
            let startDay = getStartDayOfMonth(2024, index);
            const weeks = [];
            let currentWeek = new Array(7).fill(null);

            // Fill the first week with leading nulls
            for (let i = 0; i < startDay; i++) {
              currentWeek[i] = null;
            }

            // Fill the days of the month
            for (let day = 1; day <= daysInMonth; day++) {
              currentWeek[startDay] = day;
              startDay++;
              if (startDay === 7) {
                weeks.push(currentWeek);
                currentWeek = new Array(7).fill(null);
                startDay = 0;
              }
            }

            // Push the last week
            if (startDay !== 0) {
              weeks.push(currentWeek);
            }

            return (
              <div key={index} className="month">
                <h2>
                  {month} 2024
                </h2>
                <div className="calendar-grid">
                  <div className="calendar-weekdays">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, i) => (
                      <div key={i} className="calendar-day">{day}</div>
                    ))}
                  </div>
                  {weeks.map((week, i) => (
                    <div key={i} className="calendar-week">
                      {week.map((day, j) => {
                        const dayKey = `${index}-${day}`;
                        const isToday = today.getDate() === day && today.getMonth() === index;
                        const backgroundColor = isToday ? '#666' : labeledDays[dayKey]?.color || 'transparent';
                        const dayStyle = {
                          backgroundColor: backgroundColor,
                        };

                        return (
                          <div
                            key={j}
                            className="calendar-day"
                            onClick={() => day && handleDayClick(index, day)}
                            style={dayStyle}
                          >
                            {day !== null ? day : ''}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              </div>
            ); 
          })}
        </div>
        <button onClick={handleSubmit} className="submit-button">
          Submit Data
        </button>
      </div>
    </div>
  );
};

export default CreateInstitutionSchedule;
*/

/*
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AccessDenied from '../components/AccessDenied';
import SomeWentWrong from '../components/SomeWentWrong';

// Utility to get the number of days in a month
const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

// Utility to get the day of the week the month starts on
const getStartDayOfMonth = (year, month) => {
  return new Date(year, month, 1).getDay();
};

const CreateInstitutionSchedule = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [labeledDays, setLabeledDays] = useState({});
  const [colorLabel, setColorLabel] = useState({
    yellow: '',
    red: '',
    green: 'Formal Assessment',
    blue: '',
  });
  const [selectedColor, setSelectedColor] = useState('yellow');
  const [todayDate, setTodayDate] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/create-schedule', {
          withCredentials: true,
        });

        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setErrorStatus(401);
          } else if (error.response.status === 403) {
            setErrorStatus(403);
          }
          setError(error.response.data.message);
        } else {
          setError('Failed to fetch schedule data');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const today = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    setTodayDate(today.toLocaleDateString(undefined, options));
  }, []);

  const handleDayClick = (month, day) => {
    const newLabeledDays = { ...labeledDays };
    const dayKey = `${month}-${day}`;

    if (newLabeledDays[dayKey] && newLabeledDays[dayKey].color === selectedColor) {
      delete newLabeledDays[dayKey];
    } else {
      if (selectedColor === 'blue') {
        newLabeledDays[dayKey] = {
          color: selectedColor,
          label: colorLabel[selectedColor],
        };
      } else {
        newLabeledDays[dayKey] = {
          color: selectedColor,
          label: colorLabel[selectedColor],
        };
      }
    }

    setLabeledDays(newLabeledDays);
  };

  const handleLabelChange = (color, label) => {
    setColorLabel({ ...colorLabel, [color]: label });
  };

  const handleSubmit = async () => {
    try {
      // Fetch CSRF token
      const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
      if (!csrfResponse.data.csrfToken) {
        throw new Error('Failed to fetch CSRF token');
      }
      const csrfToken = csrfResponse.data.csrfToken;
  
      // Prepare labeledDays data to submit
      const markedDays = Object.entries(labeledDays).map(([dayKey, data]) => ({
        dayKey,
        color: data.color,
        label: data.label
      }));
  
      // Make POST request with CSRF token in headers
      await axios.post(
        'https://lernacy.co.za/api/submit-marked-days',
        { markedDays },
        { withCredentials: true, headers: { 'CSRF-Token': csrfToken } }
      );

      // Display success message to the user
      alert('Marked days submitted successfully');
      
      //console.log('Submitted Labeled Days:', markedDays); // Optional: Log the submitted data
    } catch (error) {
      console.error('Error submitting marked days:', error);
      // Handle error appropriately (e.g., show error message to user)
      alert('Failed to submit marked days. Please try again later.');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errorStatus === 401) {
    return <AccessDenied />;
  }

  if (errorStatus === 403) {
    return <SomeWentWrong />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // List of months for the 2024 calendar
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Get today's date
  const today = new Date();

  return (
    <div className="user-profile-account-aligner-container">
      <div className="schedule-profile-navigation-container">
        <h1 className="schedule-page-title-name">Schedule Page</h1>
        <div className="schedule-profile-navigation">
          <Link to={'/profile'} className="schedule-profile-navigation-p-color-back">
            <div className="schedule-profile-navigation-div">Posts</div>
          </Link>
          <Link to={'/user-links'} className="schedule-profile-navigation-p-color-back-active">
            <div className="schedule-profile-navigation-div">Schedule</div>
          </Link>
        </div>
      </div>
      

      <div className='calender-content-page'>
        
        
        <div className="calendar-container">
          
          <div className="color-label-input">
            <h2>Create Schedule</h2>

            <label>
              Select Color:<br />
              <select className='color-label-input-select' value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)}>
                <option value="yellow">Yellow</option>
                <option value="red">Red</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
              </select>
            </label><br />

            <label>
              Yellow Label:
              
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.yellow}
              onChange={(e) => handleLabelChange('yellow', e.target.value)}
            /><br />

            <label>
              Red Label:
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.red}
              onChange={(e) => handleLabelChange('red', e.target.value)}
            /><br />

            <label>
              Green Label:
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.green}
              onChange={(e) => handleLabelChange('green', e.target.value)}
            /><br />

            <label>
              Blue Label:
            </label><br />
            <input
              className='select-color-label-input'
              type="text"
              value={colorLabel.blue}
              onChange={(e) => handleLabelChange('blue', e.target.value)}
            /><br />

          </div>
          {months.map((month, index) => {
            const daysInMonth = getDaysInMonth(2024, index);
            let startDay = getStartDayOfMonth(2024, index);
            const weeks = [];
            let currentWeek = new Array(7).fill(null);

            // Fill the first week with leading nulls
            for (let i = 0; i < startDay; i++) {
              currentWeek[i] = null;
            }

            // Fill the days of the month
            for (let day = 1; day <= daysInMonth; day++) {
              currentWeek[startDay] = day;
              startDay++;
              if (startDay === 7) {
                weeks.push(currentWeek);
                currentWeek = new Array(7).fill(null);
                startDay = 0;
              }
            }

            // Push the last week
            if (startDay !== 0) {
              weeks.push(currentWeek);
            }

            return (
              <div key={index} className="month">
                <h2>
                  {month} 2024
                </h2>
                <div className="calendar-grid">
                  <div className="calendar-weekdays">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, i) => (
                      <div key={i} className="calendar-day">{day}</div>
                    ))}
                  </div>
                  {weeks.map((week, i) => (
                    <div key={i} className="calendar-week">
                      {week.map((day, j) => {
                        const dayKey = `${index}-${day}`;
                        const isToday = today.getDate() === day && today.getMonth() === index;
                        const backgroundColor = isToday ? '#666' : labeledDays[dayKey]?.color || 'transparent';
                        const dayStyle = {
                          backgroundColor: backgroundColor,
                        };

                        return (
                          <div
                            key={j}
                            className="calendar-day"
                            onClick={() => day && handleDayClick(index, day)}
                            style={dayStyle}
                          >
                            {day !== null ? day : ''}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              </div>
            ); 
          })}
        </div>
        <button onClick={handleSubmit} className="submit-button">
          Submit Data
        </button>
      </div>
      
    </div>
  );
};

export default CreateInstitutionSchedule;
*/
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';

const ModalBlock = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Function to close modal when clicking outside
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  // Effect to handle clicks outside the modal
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className='sidenav-div-container-block'>
        <Link onClick={toggleModal} className='sidenav-link-block'>
          <div className='sidenav-icons'>
            <FontAwesomeIcon icon={faMessage} size="lg" />
          </div>
          Contact Us
        </Link>
      </div>

      {isModalOpen && (
        <div className='custom-modal-overlay'>
          <div className='custom-modal-content' ref={modalRef}>
            <h2 className='custom-modal-title'>Want to get in contact with us?</h2>

            <h3 
                className='custom-modal-text'
            >
                <a 
                    className='reach-out-email-tab' 
                    href="mailto:admin@lernacy.co.za"
                >
                    Write to us at: admin@lernacy.co.za
                </a>
            </h3>

            <button className='custom-modal-close-button' onClick={toggleModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalBlock;


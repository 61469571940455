import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AssessmentsResults = () => {
    const [userData, setUserData] = useState(null);
    const [adminStats, setAdminStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const [enrollmentRecord, setEnrollmentRecord] = useState(null);
    const [enrolledUsers, setEnrolledUsers] = useState([]);
    const [activityScores, setActivityScores] = useState([]);
    const [expandedItem, setExpandedItem] = useState(null); // For expanded comments
    const [searchQuery, setSearchQuery] = useState(''); // For search box

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', { credentials: 'include' });
                const result = await response.json();
                if (result.success && result.user) {
                    setUserData(result.user);
                    if (result.user.status !== 'Admin' && result.user.status !== 'Teacher') {
                        navigate('/');
                    } else if (result.user.status === 'Admin') {
                        const statsResponse = await fetch('https://lernacy.co.za/api/admin/stats', { credentials: 'include' });
                        const statsResult = await statsResponse.json();
                        setAdminStats(statsResult);
                    }

                    const enrollmentResponse = await fetch('https://lernacy.co.za/api/enrollment-records-filing', { credentials: 'include' });
                    const enrollmentResult = await enrollmentResponse.json();
                    if (enrollmentResult.activityScores) {
                        setActivityScores(enrollmentResult.activityScores);
                    }
                    if (enrollmentResult.enrollmentRecord) {
                        setEnrollmentRecord(enrollmentResult.enrollmentRecord);
                        setEnrolledUsers(enrollmentResult.enrolledUsers);
                    }
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error:', error);
                navigate('/login');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [navigate]);

    const handleDownload = async () => {
        try {
            const response = await fetch('https://lernacy.co.za/api/download-activity-scores', { credentials: 'include' });
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'activity_scores.csv';
                document.body.appendChild(a);
                a.click();
                a.remove();
            } else {
                console.error('Failed to download file:', response.statusText);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const groupByActivityName = (scores) => {
        return scores.reduce((grouped, score) => {
            const activity = score.activityName;
            if (!grouped[activity]) {
                grouped[activity] = [];
            }
            grouped[activity].push(score);
            return grouped;
        }, {});
    };

    const toggleExpandItem = (id) => {
        setExpandedItem(expandedItem === id ? null : id);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter function based on search query
    const filterScores = () => {
        return activityScores.filter(score => {
            const fullName = `${score.name} ${score.surname} ${score.accountName} ${score.activityType} ${score.activityName}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
        });
    };

    const filteredScores = filterScores();

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className='activity-page-main-border'>
            <h1 className='activity-name-tag-h1'>Assessments Results</h1>

            <div className='filing-system-page-container'>

                {/* Search input box */}
                <input 
                    type="text" 
                    value={searchQuery} 
                    onChange={handleSearch} 
                    placeholder="Search by name, activity type, etc." 
                    className="search-box" 
                />

                <div className='assessments-results-keeping-block'>
                    {enrolledUsers.length > 0 && (
                        <div>
                            <h3>Enrolled Users</h3>
                            <ul>
                                {enrolledUsers.map(user => (
                                    <li key={user._id}>
                                        {user.accountName}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {filteredScores.length > 0 && (
                        <div className="activity-scores-container">
                            <h3 className="activity-scores-header">Activity Scores</h3>

                            {Object.entries(groupByActivityName(filteredScores)).map(([activityName, scores]) => (
                                <div key={activityName} className="activity-group">
                                    <h4 className="activity-name">{activityName}</h4>
                                    <ul className={`activity-scores-list ${scores.length === 1 ? 'single-item' : ''}`}>
                                        {scores.map(score => (
                                            <li
                                                className="activity-score-item"
                                                key={score._id}
                                                onClick={() => toggleExpandItem(score._id)}
                                            >
                                                <div className="activity-score-details">
                                                    <span className="activity-score-name">
                                                        {score.name} {score.surname} ({score.accountName})
                                                    </span>
                                                    <span className="activity-score-activity">
                                                        Activity Type: {score.activityType}
                                                    </span>
                                                    <span className="activity-score-score">
                                                        Score: {score.score}
                                                    </span>
                                                    <span className="activity-score-comments">
                                                        {expandedItem === score._id
                                                            ? score.comments
                                                            : `${score.comments.substring(0, 50)}...`}
                                                    </span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    )}

                    <button 
                        onClick={handleDownload}
                        className='selected-accounts-submit-button'
                    >
                        Download All Scores
                    </button>

                </div>
            </div>
        </div>
    );
};

export default AssessmentsResults;



import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './pages-styling/pages.css';
import useFetchUserData from '../../components/useFetchUserData';

const GradeFourLifeSkills = () => {
  const user = useFetchUserData();
  const [showMastermindModal, setShowMastermindModal] = useState(false);
  const [showAddSubModal, setShowAddSubModal] = useState(false);
  const [showBoxNumberModal, setShowBoxNumberModal] = useState(false);
  const [showRepeatedAdditionModal, setShowRepeatedAdditionModal] = useState(false);
  const [showBigNumberChallengeModal, setShowBigNumberChallengeModal] = useState(false);
  const [showNumericFusionModal, setShowNumericFusionModal] = useState(false);
  const [showRoundingOffModal, setShowRoundingOffModal] = useState(false);
  const navigate = useNavigate();

  const handleMastermindProceed = () => {
    setShowMastermindModal(false);
    navigate('/Grade-Four-Strength-Seeker');
  };

  const handleAddSubProceed = () => {
    setShowAddSubModal(false);
    navigate('/Grade-Four-AddSubGame');
  };

  const handleBoxNumberProceed = () => {
    setShowBoxNumberModal(false);
    navigate('/Grade-Four-Box-Game');
  };

  const handleRepeatedAdditionProceed = () => {
    setShowRepeatedAdditionModal(false);
    navigate('/Grade-Four-Repeated-addition');
  };

  const handleBigNumberChallengeProceed = () => {
    setShowBigNumberChallengeModal(false);
    navigate('/Grade-Four-Big-Number-Challenge');
  };

  const handleNumericFusionProceed = () => {
    setShowNumericFusionModal(false);
    navigate('/Grade-Four-Numeric-Fusion:Halve&Double');
  };

  const handleRoundingOffProceed = () => {
    setShowRoundingOffModal(false);
    navigate('/Grade-Four-Rounding-Off');
  };

  return (
    <div className='game-name-list-files'>
      <div className='game-name-list-files-box'>
        {user && user.accountName ? (
          <>
            <h1 className='grade-level-subject'>Grade 4 - Life Skills</h1>
            <p>This is the Grade 4 - Life Skills content.</p>
            <br />

            {/*<p>Welcome, {user.name} {user.surname}!</p>*/}

            {/* Number Mastermind Modal */}
            <button onClick={() => setShowMastermindModal(true)} className="my-game-name-text">Strength Seeker</button>
            {showMastermindModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowMastermindModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleMastermindProceed}>Proceed</button>
                  <button onClick={() => setShowMastermindModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            <br /><br />

            {/* AddSub Game Modal */}
            <button onClick={() => setShowAddSubModal(true)} className="my-game-name-text">AddSub</button>
            {showAddSubModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowAddSubModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleAddSubProceed}>Proceed</button>
                  <button onClick={() => setShowAddSubModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            <br /><br />

            {/* Box Number Modal */}
            <button onClick={() => setShowBoxNumberModal(true)} className="my-game-name-text">Box Number</button>
            {showBoxNumberModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowBoxNumberModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleBoxNumberProceed}>Proceed</button>
                  <button onClick={() => setShowBoxNumberModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            <br /><br />

            {/* Repeated Addition Modal */}
            <button onClick={() => setShowRepeatedAdditionModal(true)} className="my-game-name-text">Repeated Addition</button>
            {showRepeatedAdditionModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowRepeatedAdditionModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleRepeatedAdditionProceed}>Proceed</button>
                  <button onClick={() => setShowRepeatedAdditionModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* Big Number Challenge Modal */}
            <button onClick={() => setShowBigNumberChallengeModal(true)} className="my-game-name-text">Big Number Challenge</button>
            {showBigNumberChallengeModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowBigNumberChallengeModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleBigNumberChallengeProceed}>Proceed</button>
                  <button onClick={() => setShowBigNumberChallengeModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* Numeric Fusion Modal */}
            <button onClick={() => setShowNumericFusionModal(true)} className="my-game-name-text">Numeric Fusion</button>
            {showNumericFusionModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowNumericFusionModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleNumericFusionProceed}>Proceed</button>
                  <button onClick={() => setShowNumericFusionModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* Rounding Off Modal */}
            <button onClick={() => setShowRoundingOffModal(true)} className="my-game-name-text">Grand Round-Off</button>
            {showRoundingOffModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowRoundingOffModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleRoundingOffProceed}>Proceed</button>
                  <button onClick={() => setShowRoundingOffModal(false)}>Cancel</button>
                </div>
              </div>
            )}

          </>
        ) : null}
      </div>
    </div>
  );
};

export default GradeFourLifeSkills;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MenuToggle from './MenuToggle';
import AdPosts from '../bodyFiles/AdPosts';
import AccessDenied from './AccessDenied';
import Modal from './Modal'; // Import the Modal component

const Webinars = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [webinars, setWebinars] = useState([]);
  const [formData, setFormData] = useState({
    imgSrc: '',
    text: '',
    link: '',
    dateTime: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoSrc, setCurrentVideoSrc] = useState('');
  const navigate = useNavigate();

  // Fetch user to check if authenticated
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setIsLoggedIn(true);

          // Check if user has "Admin" status
          const checkAdminResponse = await fetch('https://lernacy.co.za/api/check/api/check/users', {
            method: 'GET',
            credentials: 'include',
          });
          const adminData = await checkAdminResponse.json();

          if (adminData.success && adminData.user.status === 'Admin') {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setIsLoggedIn(false);
      }
    };

    const fetchWebinars = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/webinars', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const webinarsData = await response.json();
          setWebinars(webinarsData.webinars); // Assuming the webinars are returned as an array
        } else {
          console.error('Failed to fetch webinars');
        }
      } catch (error) {
        console.error('Error fetching webinars:', error);
      }
    };

    fetchUser();
    fetchWebinars();
  }, [navigate]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });

      if (!csrfResponse.ok) {
        throw new Error('Failed to get CSRF token');
      }

      const { csrfToken } = await csrfResponse.json();

      const response = await fetch('https://lernacy.co.za/api/submit-webinar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,
        },
        credentials: 'include',
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        setFormData({ imgSrc: '', text: '', link: '', dateTime: '' });
      } else {
        console.error('Error submitting form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const openModal = (link) => {
    setCurrentVideoSrc(link);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentVideoSrc('');
  };

  return (
    <div className="oer-page">
      {isLoggedIn ? (
        <div className='page-colour-manager'>
          <h1 className='activity-name-tag-h1'>Webinars</h1>
          <p className='disclaimer-note-label'>
            Clicking any link below means you accept our <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer</Link>.
          </p>

          {isAdmin ? (
            <div className='webinars-admin-add'>
              <h2>Admin Access - Add New Webinar</h2>
              <form onSubmit={handleSubmit}>
                <label>
                  Image Source:
                  <input
                    type="text"
                    name="imgSrc"
                    value={formData.imgSrc}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <br />
                <label>
                  Text:
                  <input
                    type="text"
                    name="text"
                    value={formData.text}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <br />
                <label>
                  Link:
                  <input
                    type="text"
                    name="link"
                    value={formData.link}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <br />
                <label>
                  Date and Time:
                  <input
                    type="datetime-local"
                    name="dateTime"
                    value={formData.dateTime}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <br />
                <button type="submit">Submit Webinar</button>
              </form>
            </div>
          ) : (
            <p></p>
          )}

          {/* Displaying the fetched webinars */}
          <div className='unique-image-card-container-pad'>
            <div className="unique-image-card-container">
              {webinars.length > 0 ? (
                webinars.map((webinar, index) => {
                  const webinarDateTime = new Date(webinar.dateTime);
                  const now = new Date();
                  const isUpcoming = now < webinarDateTime;

                  return (
                    <React.Fragment key={index}>
                      <div className="unique-image-card">
                        <img loading="lazy" src={webinar.imgSrc} className="unique-image-card-image" alt={`Webinar ${index}`} />
                        <div className='unique-image-card-text-container'>
                          <p className="webinar-unique-image-card-text">{webinar.text}</p>
                        </div>
                        
                        <div className='unique-image-card-link-container'>
                          <p className='webinar-p-tag'>{webinarDateTime.toLocaleString()}</p>
                        </div>
                        
                        <div className='unique-image-card-link-container'>
                          <button className="webinar-image-card-button" onClick={() => openModal(webinar.link)}>
                            {isUpcoming ? "Upcoming" : "Join Webinar"}
                          </button>
                        </div>
                      </div>

                      {/* Show an ad after every 3rd webinar post */}
                      {(index + 1) % 3 === 0 && <AdPosts />}
                    </React.Fragment>
                  );
                })
              ) : (
                <p>No webinars available at the moment.</p>
              )}
            </div>
          </div>

          {/* Modal for displaying the video */}
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            videoSrc={currentVideoSrc}
          />
        </div>
      ) : (
        <div className='not-logged-in-alt'>
          <AccessDenied />
        </div> 
      )}
    </div>
  );
};

export default Webinars;






/*
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MenuToggle from './MenuToggle';
import AdPosts from '../bodyFiles/AdPosts';

const Webinars = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const navigate = useNavigate();

  const images = [
    { src: '/styling/communities/seminar2.png', text: 'Title 6', link: 'https://example.com', dateTime: '2080-08-15T21:09:00' },
    { src: '/styling/communities/seminar2.png', text: 'Title 5', link: 'https://example.com', dateTime: '2080-08-17T14:00:00' },
    { src: '/styling/communities/seminar2.png', text: 'Title 4', link: 'https://example.com', dateTime: '2080-08-18T16:00:00' },
    { src: '/styling/communities/seminar2.png', text: 'Title 3', link: 'https://example.com', dateTime: '2080-08-19T18:00:00' },
    { src: '/styling/communities/seminar2.png', text: 'Title 2', link: 'https://example.com', dateTime: '2080-08-20T20:00:00' },
    { src: '/styling/communities/seminar2.png', text: 'Title 1', link: 'https://example.com', dateTime: '2080-08-21T22:00:00' },
  ];

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });
 
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        setIsLoggedIn(false);
      }
    };

    fetchUser();
  }, [navigate]);

  useEffect(() => {
    // Update current date-time every second to enable the link when time passes
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const openModal = (src) => {
    setSelectedImage(src);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const checkLinkStatus = (dateTime) => {
    const targetDateTime = new Date(dateTime);

    if (currentDateTime >= targetDateTime) {
      return { clickable: true, text: 'Open Link' };
    } else {
      return { clickable: false, text: 'Upcoming' };
    }
  };

  return (
    <div className="oer-page">
      {isLoggedIn ? (
        <div className='page-colour-manager'>

          <h1 className='activity-name-tag-h1'>Webinars</h1>
          <div className="my-study-container">  

            <p className='disclaimer-note-label'>
              Clicking any link below means you accept our <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer</Link>.
            </p>
            <div className='unique-image-card-container-pad'>
              <div className="unique-image-card-container">
                {images.map((image, index) => {
                  const { clickable, text } = checkLinkStatus(image.dateTime);
                  return (
                    <div key={index} className="unique-image-card">
                      <img
                        src={image.src}
                        alt={image.text}
                        className="unique-image-card-image"
                        onClick={() => openModal(image.src)}
                      />
                      <div className='unique-image-card-text-container'>
                        <p className="unique-image-card-text">{image.text}</p>
                      </div>
                      <div className='unique-image-card-link-container'>
                        <a
                          href={clickable ? image.link : '#'}
                          className="unique-image-card-link"
                          onClick={e => !clickable && e.preventDefault()}
                          style={{
                            color: '#fff',
                            backgroundColor: clickable ? '#000099' : '#333',
                            borderColor: clickable ? '#000099' : '#333',
                            cursor: clickable ? 'pointer' : 'not-allowed',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            padding: '10px 20px', // Adjust padding as needed
                            display: 'inline-block', // Ensure the padding and border are visible
                            textDecoration: 'none', // Remove underline for links
                            borderRadius: '7px',
                          }}
                        >
                          {text}
                        </a>

                      </div>
                      
                    </div>
                  );
                })}

                {selectedImage && (
                  <div className="unique-image-modal-overlay" onClick={closeModal}>
                    <div className="unique-image-modal-content">
                      <img src={selectedImage} alt="Selected" className="unique-image-modal-image" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          
        </div>
      ) : (
        <div className='not-logged-in-alt'>
          <MenuToggle />
          <div className='not-logged-in-alt-aligner'>
            <h1 className='activity-name-tag-h1'>Webinars</h1>

        
              <p className='disclaimer-note-label'>
                Clicking any link below means you accept our <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer</Link>.
              </p>
            <div className="unique-image-card-container">
              {images.map((image, index) => {
                const { clickable, text } = checkLinkStatus(image.dateTime);
                return (
                  <div key={index} className="unique-image-card">
                    <img
                      src={image.src}
                      alt={image.text}
                      className="unique-image-card-image"
                      onClick={() => openModal(image.src)}
                    />
                    <div className='unique-image-card-text-container'>
                      <p className="unique-image-card-text">{image.text}</p>
                    </div>
                    <div className='unique-image-card-link-container'>
                      <a
                        href={clickable ? image.link : '#'}
                        className="unique-image-card-link"
                        onClick={e => !clickable && e.preventDefault()}
                        style={{
                          color: '#fff',
                          backgroundColor: clickable ? '#000099' : '#333',
                          borderColor: clickable ? '#000099' : '#333',
                          cursor: clickable ? 'pointer' : 'not-allowed',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          padding: '10px 20px', // Adjust padding as needed
                          display: 'inline-block', // Ensure the padding and border are visible
                          textDecoration: 'none', // Remove underline for links
                          borderRadius: '7px',
                        }}
                      >
                        {text}
                      </a>

                    </div>
                  </div>
                );
              })}

              {selectedImage && (
                <div className="unique-image-modal-overlay" onClick={closeModal}>
                  <div className="unique-image-modal-content">
                    <img src={selectedImage} alt="Selected" className="unique-image-modal-image" />
                  </div>
                </div>
              )}
            </div>
          </div>

          <br />
        </div>
      )}
    </div>
  );
};

export default Webinars;
*/
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './pages-styling/pages.css';
import useFetchUserData from '../../components/useFetchUserData';

const GradeRMathematics = () => {
  const user = useFetchUserData();
  const [showCountingModal, setShowCountingModal] = useState(false);
  const [showMastermindModal, setShowMastermindModal] = useState(false);
  const [showAddSubModal, setShowAddSubModal] = useState(false);
  const navigate = useNavigate();

  const handleCountingProceed = () => {
    setShowCountingModal(false);
    navigate('/counting-game');
  };

  const handleMastermindProceed = () => {
    setShowMastermindModal(false);
    navigate('/number-mastermind');
  };

  const handleAddSubProceed = () => {
    setShowAddSubModal(false);
    navigate('/Grade-r-AddSubGame');
  };

  return (
    <div className='game-name-list-files'>
      <div className='game-name-list-files-box'>
        {user && user.accountName ? (
          <>
            <h1 className='grade-level-subject'>Grade R - Mathematics</h1>

            {/*<p>Welcome, {user.name} {user.surname}!</p>*/}

            {/* Counting Game Modal */}
            <button onClick={() => setShowCountingModal(true)} className="my-game-name-text">Basic Counting Activity</button>
            {showCountingModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowCountingModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleCountingProceed}>Proceed</button>
                  <button onClick={() => setShowCountingModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* Number Mastermind Modal */}
            <button onClick={() => setShowMastermindModal(true)} className="my-game-name-text">Number Mastermind</button>
            {showMastermindModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowMastermindModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleMastermindProceed}>Proceed</button>
                  <button onClick={() => setShowMastermindModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* AddSub Game Modal */}
            <button onClick={() => setShowAddSubModal(true)} className="my-game-name-text">AddSub</button>
            {showAddSubModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowAddSubModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleAddSubProceed}>Proceed</button>
                  <button onClick={() => setShowAddSubModal(false)}>Cancel</button>
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default GradeRMathematics;

// FetPhase.js
import React from 'react';

const FetPhase = () => {
  return (
    <div>
      <h1>FET Phase</h1>
      <p>This is the content for the FET Phase.</p>
    </div>
  );
};

export default FetPhase;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ArticlesCard from './ArticlesCard';
import MenuToggle from './MenuToggle';  
import './Communities.css'; // Import the CSS for styling

const Articles = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const articles = [
    {
      name: 'Subjects and Activities in Our Educational App',
      link: '/articles-list/Subjects-and-Activities-in-Our-Educational-App',
      imageSrc: '/styling/communities/favi1.png',
      alt: 'Subjects and Activities in Our Educational App',
      date: ' 02 November, 2024',
    },
    {
      name: 'Shaping South Africa\'s Future: Our Educational App for a New Era',
      link: '/articles-list/article-one-shaping-the-future-of-south-africa-through-our-educational-app',
      imageSrc: '/styling/communities/community.png',
      alt: 'South African students using educational app',
      date: ' 02 November, 2024',
    },
  ];

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });
 
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        setIsLoggedIn(false);
      }
    };

    fetchUser();
  }, [navigate]);

  return (
    <div className="communities-page">
      {isLoggedIn ? (
        <div className='page-colour-manager'>

            <h1 className='activity-name-tag-h1'>Articles</h1>
            <div className="my-study-container">  
                <div className='unique-image-card-container-pad'>
                  
                  <p className='disclaimer-note-label'>
                    Clicking any link below means you accept our  
                    <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer</Link>.
                  </p>


                  <div className="communities-container">
                    {articles.map((community, index) => (
                      <ArticlesCard key={index} community={community} />
                    ))}
                  </div>

                </div>
            </div>

        </div>
      ) : (
        <div className='not-logged-in-alt'>
          <MenuToggle />
          <div className='not-logged-in-alt-aligner'>
            <h1 className='activity-name-tag-h1'>Articles</h1>

                <p className='disclaimer-note-label'>
                  Clicking any link below means you accept our  
                  <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer</Link>.
                </p>


                <div className="communities-container">
                  {articles.map((community, index) => (
                    <ArticlesCard key={index} community={community} />
                  ))}
                </div>

          </div>
        </div>
      )}
    </div>
    
  );
};

export default Articles;

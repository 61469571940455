import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import FilingSystemTour from '../components/TourGuide/FilingSystemTour';

const FilingSystem = () => {
    const [userData, setUserData] = useState(null); // For storing user info
    const [adminStats, setAdminStats] = useState(null); // For storing admin stats
    const [loading, setLoading] = useState(true); // To show loading spinner
    const [allAccounts, setAllAccounts] = useState([]); // For storing all accounts
    const [filteredAccounts, setFilteredAccounts] = useState([]); // For storing filtered accounts based on search
    const [searchInput, setSearchInput] = useState(''); // To capture search input
    const [selectedAccounts, setSelectedAccounts] = useState([]); // For storing selected accounts
    const navigate = useNavigate(); // For redirecting

    // Max number of accounts allowed for selection
    const MAX_SELECTION = 15;

    // Fetch user data and check access
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', { credentials: 'include' });
                const result = await response.json();

                if (result.success && result.user) {
                    setUserData(result.user);

                    if (result.user.status !== 'Admin' && result.user.status !== 'Teacher') {
                        navigate('/');
                    } else if (result.user.status === 'Admin') {
                        const statsResponse = await fetch('https://lernacy.co.za/api/admin/stats', { credentials: 'include' });
                        const statsResult = await statsResponse.json();
                        setAdminStats(statsResult);
                    }
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error:', error);
                navigate('/login');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [navigate]);

    // Fetch all user accounts on component mount
    useEffect(() => {
        const fetchAllAccounts = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/enrol-users', {
                    credentials: 'include',
                });
                const data = await response.json();
                const sortedAccounts = data.sort((a, b) => a.accountName.localeCompare(b.accountName));
                setAllAccounts(sortedAccounts);
            } catch (error) {
                console.error('Error fetching accounts:', error);
            }
        };

        fetchAllAccounts();
    }, []);

    // Fetch enrollment records on component mount
    useEffect(() => {
        const fetchEnrollmentRecords = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/my-enrollment-records', {
                    credentials: 'include',
                });
                const result = await response.json();

                if (result.success) {
                    // Set the selectedAccounts state with enrolled students
                    const enrolledStudents = result.enrollmentRecords[0]?.enrolledStudents || [];
                    setSelectedAccounts(enrolledStudents);
                }
            } catch (error) {
                console.error('Error fetching enrollment records:', error);
            }
        };

        fetchEnrollmentRecords();
    }, []);

    // Handle search input changes and filter accounts
    useEffect(() => {
        const filtered = allAccounts.filter(account =>
            account.accountName.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredAccounts(filtered);
    }, [searchInput, allAccounts]);

    // Handle account selection
    const handleAccountSelect = (accountName) => {
        if (selectedAccounts.includes(accountName)) {
            // If already selected, remove it (deselect)
            setSelectedAccounts(selectedAccounts.filter(acc => acc !== accountName));
        } else if (selectedAccounts.length < MAX_SELECTION) {
            // Add to selected accounts if the limit is not reached
            setSelectedAccounts([...selectedAccounts, accountName]);
        } else {
            alert(`You can only select up to ${MAX_SELECTION} accounts.`);
        }
    };

    // Handle bulk enrollment
    const handleEnrollUsers = async () => {
        try {
            // Step 1: Fetch the CSRF token from the server
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!csrfResponse.ok) {
                console.error('Error fetching CSRF token:', csrfResponse.statusText);
                return; // Exit if unable to get the CSRF token
            }
    
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken; // Assuming the token is sent in this field
    
            // Step 2: Prepare the data for the POST request
            const dataToSend = { 
                accountNames: selectedAccounts, 
                _csrf: csrfToken  // Include the CSRF token in the data
            };
    
            // Step 3: Console log the data being sent to the server
            // console.log('Data being sent to the server:', dataToSend);
    
            // Step 4: Make the POST request with the CSRF token
            const response = await fetch('https://lernacy.co.za/api/enrol-users', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend), // Send the selected accounts and CSRF token
            });
    
            if (response.ok) {
                console.log('Users enrolled successfully!');
                setSelectedAccounts([]); // Clear the selected accounts
            } else {
                console.error('Error enrolling users:', response.statusText);
            }
        } catch (error) {
            console.error('Error during enrollment:', error);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className='activity-page-main-border'>
            <FilingSystemTour />
            <h1 className='activity-name-tag-h1'>Filing System</h1>

            <div className='filing-system-page-container'>
                <div className='filing-system-page-container-two'>
                    <div className="filing-system-page">

                        {userData?.status === 'Admin' && adminStats && (
                            <div className="admin-stats">
                                <h2>Admin Statistics</h2>
                                <p>Total Users: {adminStats.totalUsers}</p>
                                <p>Teachers: {adminStats.statusCounts.teacher}</p>
                                <p>Admins: {adminStats.statusCounts.admin}</p>
                                <p>Students: {adminStats.statusCounts.student}</p>
                                <p>Parents: {adminStats.statusCounts.parent}</p>
                                <p>Accepted Tracking Requests: {adminStats.acceptedTrackingRequests}</p>
                                <p>Institutions: {adminStats.institutionCount}</p>
                                <p>Submissions: {adminStats.submissionCount}</p>
                            </div>
                        )}
                        
                        <div className='search-filing-names-container' id='search-filing-names-instruct'>

                            {/* Search Input */}
                            <div className='search-filing-names'>
                                <p className='filing-system-p-tag'>Add users to give activities to.</p>
                                <h3>Search for Accounts:</h3>
                                <input
                                    type="text"
                                    placeholder="Enter account name"
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)} // Update search input
                                />
                            </div>

                            {/* Matching Accounts List */}
                            {searchInput.length > 0 && filteredAccounts.length > 0 && (
                                <div className="filtered-accounts" id='filtered-accounts'>
                                    <h3>Matching Accounts:</h3>
                                    <ul>
                                        {filteredAccounts.map((account) => (
                                            <li
                                                key={account._id}
                                                onClick={() => handleAccountSelect(account.accountName)}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: selectedAccounts.includes(account.accountName) ? '#fff' : '#242526',
                                                    backgroundColor: selectedAccounts.includes(account.accountName) ? '#000099' : 'white',
                                                }}
                                            >
                                                {account.accountName}
                                            </li>
                                        ))}
                                    </ul>
                                    {selectedAccounts.length >= MAX_SELECTION && (
                                        <p style={{ color: 'red' }}>You can only select up to {MAX_SELECTION} accounts.</p>
                                    )}
                                </div>
                            )}

                        </div>

                        <div  className="selected-accounts">

                            {/* Selected Accounts for Enrollment */}
                            <div>
                                <h3>Selected Accounts for Enrollment:</h3>
                                {selectedAccounts.length > 0 ? (
                                    <ul>
                                        {selectedAccounts.map((account, index) => (
                                            <li key={index}>
                                                {account}
                                                <button 
                                                    onClick={() => handleAccountSelect(account)}
                                                    className='selected-accounts-remove-button'
                                                >
                                                    Remove
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No accounts selected. Please search and select accounts.</p>
                                )}
                            </div>
                            
                            {/* Button to enroll selected users */}
                            <button 
                                onClick={handleEnrollUsers} 
                                disabled={selectedAccounts.length === 0}
                                className='selected-accounts-submit-button'
                            >
                                Enroll Selected Users
                            </button>

                        </div>

                        <div className='filing-assessment-block'>
                            <h3>Assessments</h3>

                           
                            <Link to='/assessment-development' className='filing-assessment-link'>
                                <div className='filing-assessment-link-container'>
                                    Assessment Development
                                </div>
                            </Link>
                            

                           
                            <Link to='/assess-laq-submissions' className='filing-assessment-link'>
                                <div className='filing-assessment-link-container'>
                                    Assess LAQ Submissions
                                </div>
                            </Link>

                            <Link to='/assessments-results-keeping' className='filing-assessment-link'>
                                <div className='filing-assessment-link-container'>
                                    Assessments Results Keep
                                </div>
                            </Link> 
                            

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilingSystem; 





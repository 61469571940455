import React, { useState, useEffect } from 'react';
import AccessDenied from '../../../../components/AccessDenied';

const GrFourStrengthSeeker = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [highScore, setHighScore] = useState(null);
  const [gameScore, setGameScore] = useState(0);
  const [currentRound, setCurrentRound] = useState(1);
  const [userAnswers, setUserAnswers] = useState([]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          const user = data.user;

          if (!user || !user.accountName) {
            setIsAuthenticated(false);
            return;
          }

          setUserDetails(user);
          setIsAuthenticated(true);
        } else {
          console.error('Error fetching user details:', response.statusText);
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error fetching user details:', error.message);
        setIsAuthenticated(false);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchHighScore();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Insert logic for starting a new round or ending the game
  }, [currentRound]);

  const handleGameOver = async () => {
    // Insert logic for handling game over and recording score
  };

  const restartGame = async () => {
    // Insert logic for restarting the game
  };

  const fetchHighScore = async () => {
    // Insert logic for fetching high score
  };

  const handleSubmit = () => {
    // Insert logic for handling user submissions
  };

  return (
    <div>
      {isAuthenticated === null ? (
        <p>Loading authentication status...</p>
      ) : !isAuthenticated ? (
        <AccessDenied />
      ) : (
        <div>
          <h1 style={{display:'block'}}>Welcome, {userDetails ? `${userDetails.name} ${userDetails.surname}` : 'User'}!</h1>

          {/* Insert game UI and logic here */}
          
          {/* Insert high score display here */}
          
          <hr />

        </div>
      )}
    </div>
  );
};

export default GrFourStrengthSeeker;

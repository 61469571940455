import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import MenuToggle from './MenuToggle';
import './componentsCSS/auth.css';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginStatus, setLoginStatus] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/login', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.status === 403) {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error checking session:', error);
      }
    };

    checkSession();
  }, [navigate]); // Add navigate to the dependency array

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      // Fetch CSRF token from the server
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include', // Important for cookies
      });
  
      const { csrfToken } = await csrfResponse.json();
  
      // Log the CSRF token to ensure it's being fetched correctly
      //console.log('Fetched CSRF token:', csrfToken);
  
      const formData = {
        email,
        password,
      };
  
      // Log formData to ensure it's correct
      //console.log('Form data being sent:', formData);
  
      // Include CSRF token in the headers of the POST request
      const response = await fetch('https://lernacy.co.za/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in the headers
        },
        body: JSON.stringify(formData),
        credentials: 'include', // Important for cookies
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        // Redirect the user to "/"
        navigate('/');
        // Reload the page
        window.location.reload();
      } else {
        // Handle login failure scenarios
        if (response.status === 401 && responseData.message === 'Email does not exist in the database') {
          setLoginStatus('emailNotFound');
        } else {
          setLoginStatus('failure');
  
          // Check if the response indicates that the account is not verified
          if (response.status === 403) {
            setLoginStatus('emailNotVerified');
          }
        }
      }
    } catch (error) {
      console.error('Error during login:', error.message);
      setLoginStatus('error');
    } finally {
      setLoading(false);
    }
  };  
    
  return (
    <div className='auth-return-div'>
      <MenuToggle />
      <div className='auth-screen-main-div'>
        <div className='color-transition-block'>
          <div className='color-transition'>
            
            <img loading="lazy" src="/styling/logo.png" alt="Lernacy" className='logo-img' /> 
            <h1>Welcome!</h1>
            <p>Log in to your account</p>
          </div>
          <img loading="lazy" src="/styling/wave-mob.png" alt="Description" className='image-test-block' data-nosnippet/>
          <img loading="lazy" src="/styling/Cloudy-large-1.png" alt="Description" className='image-test-block-large' data-nosnippet/>
        </div>

        <div className='form-container'>
          <div className='form-div-box'>

            <div className='situation-controller-container'>
              <Link to="/login" className='situation-controller-active'>
                Login
              </Link>
              <Link to="/signup" className='situation-controller'>
                Sign Up
              </Link>
            </div>

            <form onSubmit={handleSubmit} className='login-form-block'>
              <label htmlFor="email">Email:</label>
              <input 
                type="text" 
                id="email" 
                value={email} 
                onChange={handleEmailChange} 
              />

              <label htmlFor="password">Password:</label>
              <div className='show-password-flex'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <button type="button" onClick={handleTogglePassword} className='show-password'>
                  {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                </button>
              </div>

              {loginStatus === 'emailNotFound' && (
                <p className='error-response'>
                  Email does not exist in the database. Please check your email and try again or{' '}
                  <Link to="/signup" className='links'>register</Link> for an account.
                </p>
              )}

              {loginStatus === 'notVerified' && (
                <p className='error-response'>
                  Account not verified. Please check your email for verification instructions.{' '}
                  <Link to="/request-verification" className='links'>Resend Verification Link</Link>
                </p>
              )}

              {loginStatus === 'emailNotVerified' && (
                <p className='error-response'>
                  Email not verified. Please check your email for verification instructions.{' '}
                  <Link to="/request-verification" className='links'>Resend Verification Link</Link>
                </p>
              )}

              <div>
                {loginStatus === 'success' && <p className='success-response'>You are authenticated. Redirecting...</p>}
                {loginStatus === 'failure' && <p className='error-response'>Invalid email or password. Please try again.</p>}
                {loginStatus === 'error' && <p className='error-response'>Internal Server Error. Please try again later.</p>}
              </div>

              <button type="submit" disabled={loading}>
                {loading ? 'Logging in...' : 'Login'}
              </button>
            </form>

            <p>
              {' '}
              <Link to="/reset-password" className='links'>Forgot Password?</Link>
            </p>

            <br />

            <p>
              Don't Have an account?{' '}
              <Link to="/signup" className='links'>Register here</Link>
            </p>

            <p>
              Account not verified?{' '}
              <Link to="/request-verification" className='links'>Request Verification Link</Link>
            </p>

          </div>

        </div>

      </div>
    </div>
  );
};

export default LoginForm;

import React, { useState, useEffect } from 'react';
import AccessDenied from '../components/AccessDenied';
import SomeWentWrong from '../components/SomeWentWrong';

const contributors = [
  {
    name: 'John Doe', 
    role: 'Developer',
    profilePicture: '/activityImages/contributors/placeholder.jpg'
  },
  {
    name: 'Jane Smith',
    role: 'Designer',
    profilePicture: '/activityImages/contributors/placeholder.jpg'
  },
  {
    name: 'Sam Wilson',
    role: 'Project Manager',
    profilePicture: '/activityImages/contributors/placeholder.jpg'
  },
  {
    name: 'Tshepo Diketso',
    role: 'Educator',
    profilePicture: '/activityImages/contributors/placeholder.jpg'
  },
  {
    name: 'Tshepo Diketso',
    role: 'Educator',
    profilePicture: '/activityImages/contributors/placeholder.jpg'
  }
];

const Contributors = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include', // Include credentials in request
        });

        if (!response.ok) {
          if (response.status === 401) {
            throw new Error('Unauthorized'); // Throw error for 401 status
          } else if (response.status === 403) {
            throw new Error('Forbidden'); // Throw error for 403 status
          } else {
            throw new Error('Network response was not ok');
          }
        }

        setIsLoading(false); // Set loading state to false
      } catch (error) {
        setError(error); // Set error state if request fails
        setIsLoading(false); // Set loading state to false
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures effect runs only once

  // Render loading spinner or error message if loading
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Render AccessDenied component if user is not authenticated (401)
  if (error && error.message === 'Unauthorized') {
    return <AccessDenied />;
  }

  // Render SomeWentWrong component for other errors (403 or network errors)
  if (error) {
    return <SomeWentWrong />;
  }

  // Render contributors list if no error
  return (
    <div className='activity-wall-container'>
      <h1 className='activity-name-tag-h1'>Contributors</h1>
      <div className="contributors-container">
        <div className="contributors-list">
          {contributors.map((contributor, index) => (
            <div key={index} className="contributor-card">
              <img
                src={contributor.profilePicture}
                loading="lazy"
                alt={`${contributor.name}'s profile`}
                className="contributor-image"
              />
              <div className="contributor-info">
                <h3>{contributor.name}</h3>
                <p>{contributor.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br />
    </div>
  );
};

export default Contributors;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Copyright from '../../../components/Copyright';
import '../../../pages/pages-styling/study.css';
import axios from 'axios';

const Grade8SocialSciencesUnitOne = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => { 
        // Make a request to the server to get user information
        const fetchUser = async () => {
        try {
            const response = await fetch('https://lernacy.co.za/api/study', {
            method: 'GET',
            credentials: 'include', // Include credentials for cross-origin requests
            });

            if (response.ok) {
            const data = await response.json();
            setUser(data.user);

            // Check if user has an account name
            if (!data.user.accountName) {
                navigate('/login-second-stage');
            }
            } else {
            const errorData = await response.json();
            console.error('Error fetching user data:', errorData.message);

            // Redirect to login if not authenticated
            navigate('/login');
            }
        } catch (error) {
            console.error('Error fetching user data:', error.message);

            // Redirect to login on error
            navigate('/login');
        }
        };

        fetchUser();
    }, [navigate]); // Include navigate in the dependency array

    return (
        <div className='page-colour-manager'>
            <div className='topic-notes-block'>

                <h1 className='page-title'>Africa Before European Colonization</h1> 

                <label className='general-delaration'>
                    Read the <Link to="/general-disclaimer" className='general-delaration-link'>General Declaration</Link> before using the notes.
                </label>

                <div>
                    <p>
                        Africa before colonization was a diverse continent with a rich history of advanced 
                        civilizations, complex societies, and dynamic cultures. Understanding pre-colonial 
                        Africa is essential to appreciate the continent's contributions to world history and 
                        its cultural heritage.
                    </p>

                    <h2>
                        Pre-Colonial Politics
                    </h2>
                    <h3>
                        Diverse Political Systems:
                    </h3>
                    <ul>
                        <li>
                            Africa was home to a variety of political systems ranging from small, stateless 
                            societies to large centralized kingdoms and empires.
                        </li>
                        <li>
                            <strong>Stateless Societies</strong> These were often organized around kinship or 
                            clan systems and relied on consensus-based decision-making. Examples include the 
                            Igbo of Nigeria and the Nuer of Sudan.
                        </li>
                        <li>
                            <strong>Centralized Kingdoms and Empires:</strong> Powerful kingdoms and empires 
                            with centralized authority and complex administrative systems. Notable examples 
                            include the Mali Empire, the Kingdom of Ghana, and the Kingdom of Zimbabwe.
                        </li>

                        <h3>
                            Notable Kingdoms and Empires:
                        </h3>
                        <li>
                            <strong>Mali Empire (13th to 16th Century):</strong> Known for its wealth, trade 
                            networks, and famous leaders like Mansa Musa, who was renowned for his pilgrimage 
                            to Mecca and his promotion of education and Islam.
                        </li>
                        <li>
                            <strong>Kingdom of Ghana (6th to 13th Century):</strong> Famous for its wealth 
                            derived from gold trade and its sophisticated political organization.
                        </li>
                        <li>
                            <strong>Great Zimbabwe (11th to 15th Century):</strong> Known for its impressive 
                            stone structures and as a center of trade.
                        </li>

                        <h3>
                            Leadership and Governance:
                        </h3>
                        <li>
                            Leadership varied from kings and emperors to chiefs and elders, each playing a 
                            crucial role in maintaining order, justice, and societal cohesion.
                        </li>
                        <li>
                            Some societies had councils of elders or assemblies that participated in governance, 
                            ensuring a form of democratic engagement.
                        </li>
                    </ul>

                    <h2>Pre-Colonial Economies</h2>

                    <h3>
                        Agriculture:
                    </h3>

                    <ul>
                        <li>
                            Agriculture was the backbone of most African economies, with people cultivating 
                            crops such as millet, sorghum, yams, and later, maize.
                        </li>
                        <li>
                            Animal husbandry was also significant, with cattle, goats, and sheep being 
                            important sources of food, labor, and wealth.
                        </li>

                        <h3>
                            Animal husbandry was also significant, with cattle, goats, and sheep being 
                            important sources of food, labor, and wealth.
                        </h3>
                        <li>
                            Africa had extensive trade networks both within the continent and with external 
                            regions such as Europe, the Middle East, and Asia.
                        </li>
                        <li>
                            <strong>Trans-Saharan Trade:</strong> 
                            Connected West Africa to North Africa and beyond, facilitating the exchange of gold, salt, cloth, and other goods.
                        </li>
                        <li>
                            <strong>Indian Ocean Trade:</strong> East African city-states like Kilwa and 
                            Mombasa were active in trade with Arab, Persian, Indian, and Chinese merchants, 
                            exchanging goods such as ivory, gold, and slaves.
                        </li>

                        <h3>
                            Crafts and Industries:
                        </h3>

                        <li>
                            Skilled artisans produced a variety of goods, including textiles, pottery, metalwork, and 
                            jewelry.
                        </li>
                        <li>
                            Mining was also important, with regions rich in minerals like gold, copper, and 
                            iron developing specialized mining techniques and industries.
                        </li>
                    </ul>

                    <h2>
                        Pre-Colonial Religion
                    </h2>

                    <h3>
                        Diverse Belief Systems:
                    </h3>

                    <ul>
                        <li>
                            Africa was home to a rich tapestry of religious beliefs and practices, often deeply 
                            intertwined with everyday life and community structures.
                        </li>
                        <li>
                            Indigenous African religions were predominantly animistic, with a belief in spirits, 
                            ancestors, and a supreme deity or creator.
                        </li>
                    </ul>

                    <h3>
                        Role of Religion in Society:
                    </h3>
                    <ul>
                        <li>
                            Religion played a crucial role in shaping cultural norms, social values, and governance.
                        </li>
                        <li>
                            Religious leaders, such as priests, diviners, and shamans, held significant 
                            influence, conducting rituals, offering guidance, and mediating between the 
                            spiritual and physical worlds.
                        </li>
                    </ul>

                    <h3>
                        Spread of Islam and Christianity:
                    </h3>
                    <ul>
                        <li>
                            <strong>Islam:</strong> Spread into North and West Africa from the 7th century 
                            onwards, primarily through trade and conquest. It significantly influenced local 
                            cultures, law, and education.
                        </li>
                        <li>
                            <strong>Christianity:</strong> Established in regions such as Ethiopia and Nubia 
                            from the early centuries of the Common Era, contributing to the development of 
                            unique Christian traditions and practices.
                        </li>

                        <h3>
                            Sacred Spaces and Practices:
                        </h3>
                        <li>
                            Religious practices often took place in sacred spaces such as groves, shrines, and temples.
                        </li>
                        <li>
                            Rituals, festivals, and ceremonies were common, celebrating various aspects of life, 
                            including harvests, births, deaths, and community events.
                        </li>
                    </ul>

                    <h2>
                        Conclusion
                    </h2>
                    <p>
                        Africa before colonization was a continent of immense diversity and complexity. Its 
                        political systems ranged from small communities to powerful empires, its economies thrived 
                        on agriculture and trade, and its religious practices were deeply embedded in the social 
                        fabric. Understanding this pre-colonial history provides a fuller picture of Africa's rich 
                        heritage and its contributions to global civilization.
                    </p>

                </div>
            
            </div>
        </div>
    );
};

export default Grade8SocialSciencesUnitOne;
  
// OERPage.js
import React, { useState, useEffect } from 'react';
import SubjectSection from './SubjectSection';
import { Link, useNavigate } from 'react-router-dom';
import MenuToggle from './MenuToggle';  
import AccessDenied from '../components/AccessDenied';
import './OERPage.css';
/*import Ads from '../bodyFiles/AdPosts'; */

const OERPage = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const subjects = [
    {
      name: "General",
      resources: { 
        assessments: [
          { title: "Art Education - Performing arts Visual art and Theater", link: "https://oercommons.org/courseware/lesson/102680" },
        ],
        lectures: [
          { title: "Classroom Management", link: "https://oercommons.org/courseware/lesson/110936" },
        ],
        Strategy: [ 
          { title: "Scanning your class: Classroom management skill", link: "https://oercommons.org/courses/scanning-your-class-classroom-management-skill" },
          { title: "ICT in the Classroom", link: "https://oercommons.org/courses/ict-in-the-classroom" },
          { title: "Classroom Furniture", link: "https://oercommons.org/authoring/48421" },
          { title: "Creating a Google Classroom", link: "https://oercommons.org/courseware/lesson/18681" },
          { title: "Inclusive Classrooms", link: "https://oercommons.org/courseware/lesson/74062" },
          { title: "Planning for classroom management: Classroom management practice", link: "https://oercommons.org/courses/planning-for-classroom-management-classroom-management-practice" },
          { title: "Focused classrooms: Managing the classroom to maximise learning", link: "https://oercommons.org/courses/focused-classrooms-managing-the-classroom-to-maximise-learning" },
        ],
        Source: [
          { title: "1.10 Classroom Culture: Routines & Procedures", link: "https://oercommons.org/authoring/46917" },
        ],
        Teaching_Learning: [
          { title: "100 Ideas for Active Learning", link: "https://oercommons.org/courses/100-ideas-for-active-learning" },
        ],
        textbooks: [
          { title: "100 Ideas for Active Learning", link: "https://oercommons.org/courses/100-ideas-for-active-learning" },
        ],

        
      }
    },
    {
      name: "Early Childhood Development",
      resources: { 
        course: [
          { title: "ASCCC ECE 130 Introduction to Curriculum Canvas Shell", link: "https://oercommons.org/courses/asccc-ece-130-introduction-to-curriculum-canvas-shell" },
          { title: "ASCCC CDEV 100 Child Growth and Development Canvas Shell", link: "https://oercommons.org/courses/asccc-cdev-100-child-growth-and-development-canvas-shell" },
          { title: "ASCCC CDEV 110 Child, Family, and Community", link: "https://oercommons.org/courses/asccc-cdev-110-child-family-and-community" },
          { title: "ASCCC ECE 120 Principles and Practices of Teaching Young Children Canvas Shell", link: "https://oercommons.org/courses/asccc-ece-120-principles-and-practices-of-teaching-young-children-canvas-shell" },
        ],
        diagram: [
          { title: "Alison Gopnik: What do babies think?", link: "https://oercommons.org/courses/alison-gopnik-what-do-babies-think" },
        ],
        lesson: [
          { title: "All about Time", link: "https://oercommons.org/courseware/lesson/38897" },
          { title: "Alphabet Knowledge/ Chicka Chicka Boom Boom", link: "https://oercommons.org/courseware/lesson/70494" },
          { title: "Bugs VS Insects", link: "https://oercommons.org/courseware/lesson/101589" },
        ],
        module: [
          { title: "Creative Expression", link: "https://oercommons.org/courses/creative-expression" },
        ],
        reading: [
          { title: "Am I A Bird?", link: "https://oercommons.org/courseware/lesson/118672" },
          { title: "Bobby's Lost Toy", link: "https://oercommons.org/courseware/lesson/93164" },
        ],
      }
    },
    {
      name: "Arts And Culture",
      resources: { 
        assessments: [
          { title: "Art Education - Performing arts Visual art and Theater", link: "https://oercommons.org/courseware/lesson/102680" },
        ],
        course: [
          { title: "Art Appreciation and Techniques", link: "https://oercommons.org/courses/art-appreciation-and-techniques-2" },
          { title: "Art Appreciation Open Educational Resource", link: "https://oercommons.org/courses/art-appreciation-open-educational-resource" }
        ],
        Diagrams: [
          { title: "Finding the Animals: A Visual Exploration of Art", link: "https://oercommons.org/courses/finding-the-animals-a-visual-exploration-of-art" },
          { title: "The Elements of Art", link: "https://oercommons.org/courses/the-elements-of-art" }
        ],
        lab: [
          { title: "An Environment and Cultural Heritage Workbook for Students and Teachers", link: "https://oercommons.org/courses/an-environment-and-cultural-heritage-workbook-for-students-and-teachers" } 
        ],
        lectures: [
          { title: "Introduction to Algebra", link: "https://example.com/algebra-lecture" } 
        ],
        lesson: [ 
          { title: "Art Club", link: "https://oercommons.org/authoring/56756" },
          { title: "Art Appreciation: Expressing Opinions", link: "https://oercommons.org/authoring/21464" },
          { title: "Arts Lessons in the Classroom: Visual Art Curriculum - Grade 5", link: "https://oercommons.org/courses/arts-lessons-in-the-classroom-visual-art-curriculum-grade-5" },
          { title: "Reading Art", link: "https://oercommons.org/courseware/lesson/103294" },
        ],
        textbooks: [
          { title: "Exploring the Arts: A Brief Introduction to Art, Theatre, Music, and Dance", link: "https://oercommons.org/courses/exploring-the-arts-a-brief-introduction-to-art-theatre-music-and-dance" },
          { title: "BSU Art 100:Introduction to Art and Visual Culture", link: "https://oercommons.org/authoring/29154" }
        ],
        
      }
    },
    {
      name: "Geography",
      resources: { 
        assessment: [
          { title: "Geography Grade 10 : Continental Drift", link: "https://oercommons.org/courseware/lesson/80384" },
          { title: "Physical Geography assessments", link: "https://oercommons.org/courses/physical-geography-assessments" },
          { title: "Geography Remote Sensing Class Activities", link: "https://oercommons.org/courses/geography-remote-sensing-class-activities" },
        ],
        course: [
          { title: "Physical Geography Lab Manual", link: "https://oercommons.org/courses/physical-geography-lab-manual-2" },
          { title: "Geography in Education: Exploring a Definition", link: "https://oercommons.org/courses/school-geography-exploring-a-definition" },
          { title: "Geography of Water Resources", link: "https://oercommons.org/courses/geography-of-water-resources-2" },
        ], 
        game: [
          { title: "Geohub", link: "https://www.geohub.gg/" },
          { title: "Geoguessr", link: "https://www.geoguessr.com/free" },
          { title: "Geography From Space", link: "https://oercommons.org/courses/geography-from-space-3" },
          { title: "Seterra", link: "https://www.seterra.com/" },
          { title: "World Geography-Games", link: "https://world-geography-games.com/" },
        ],
        lab: [
          { title: "Physical Geography Lab Manual", link: "https://oercommons.org/courses/physical-geography-lab-manual-3" },
          { title: "Geography Remote Sensing Class Activities", link: "https://oercommons.org/courses/geography-remote-sensing-class-activities" },
          { title: "WHY DO I HAVE TO LEARN ABOUT WORLD GEOGRAPHY?", link: "https://oercommons.org/courseware/lesson/66972" },
          { title: "Geography Grade 8", link: "https://oercommons.org/courseware/lesson/80309" },
        ],
        module: [
          { title: "Global Vegetation Types", link: "https://oercommons.org/courseware/lesson/35057" },
          { title: "Sustainability: the geography perspective", link: "https://oercommons.org/courses/sustainability-the-geography-perspective" },
          { title: "What is Geography and Landforms Within It", link: "https://oercommons.org/courseware/lesson/109431" },
        ],
        textbooks: [
          { title: "Fundamentals of Physical Geography", link: "https://oercommons.org/courses/fundamentals-of-physical-geography" },
          { title: "GEOGRAPHY", link: "https://oercommons.org/courseware/lesson/82232" },
          { title: "Human Geography", link: "https://oercommons.org/courses/human-geography" },
          { title: "Introduction to Human Geography", link: "https://oercommons.org/courses/introduction-to-human-geography" },
          { title: "Introduction to Geography", link: "https://oercommons.org/courses/introduction-to-geography" },
          { title: "Physical Geography", link: "https://oercommons.org/courses/introduction-to-physical-geography" },
          { title: "World Geography", link: "https://oercommons.org/courses/world-geography-2" },
        ],
      } 
    },
    {
      name: "History",
      resources: { 
        activity: [
          { title: "African Empires Gallery Walk Activity", link: "https://oercommons.org/courseware/lesson/115433" },
          { title: "The Berlin Conference, 1884", link: "https://oercommons.org/courseware/lesson/111950" },
          { title: "Colonization and Imperialism Primary Source Analysis", link: "https://oercommons.org/courseware/lesson/115439" },
        ],
        course: [
          { title: "Apartheid and South Africa", link: "https://oercommons.org/courses/apartheid-and-south-africa" },
        ],
        diagram: [
          { title: "African Ethnography Collection", link: "https://oercommons.org/courses/african-ethnography-collection" },

          { title: "Africa and the Atlantic World", link: "https://oercommons.org/courseware/lesson/111928" },
          { title: "Age of Exploration", link: "https://www.oerproject.com/topics/age-of-exploration" },
          { title: "Ancient Empires", link: "https://www.oerproject.com/topics/ancient-empires" },
          { title: "Apartheid", link: "https://www.oerproject.com/OER-Materials/OER-Media/PDFs/1750/Unit8/Apartheid" },
          { title: "Capitalism", link: "https://www.oerproject.com/topics/capitalism" },
          { title: "Colonialism and Resistance", link: "https://www.oerproject.com/topics/colonialism-and-resistance" },
          { title: "Decolonization", link: "https://www.oerproject.com/topics/decolonization" },
          { title: "Early Humans", link: "https://www.oerproject.com/topics/early-humans" },
          { title: "Evolution", link: "https://www.oerproject.com/topics/evolution" },
          { title: "Fascism", link: "https://www.oerproject.com/topics/fascism" },
          { title: "Genocide", link: "https://www.oerproject.com/topics/genocide" },
          { title: "Globalization", link: "https://www.oerproject.com/topics/globalization" },
          { title: "Imperialism", link: "https://www.oerproject.com/topics/imperialism" },
          { title: "Industrialization", link: "https://www.oerproject.com/topics/industrialization" },
          { title: "Human Rights", link: "https://www.oerproject.com/topics/human-rights" },
          { title: "Portable Belief Systems", link: "https://www.oerproject.com/topics/portable-belief-systems" },
          { title: "The Black Death", link: "https://www.oerproject.com/topics/the-black-death" },
          { title: "The Great Depression", link: "https://www.oerproject.com/topics/the-great-depression" },
          { title: "The Transatlantic Slave Trade", link: "https://www.oerproject.com/topics/transatlantic-slave-trade" },
          { title: "World War I", link: "https://www.oerproject.com/topics/wwi" },
          { title: "World War II", link: "https://www.oerproject.com/topics/wwii" },
        ],
        lesson: [
          { title: "Angolan Civil War", link: "https://oercommons.org/courses/angolan-civil-war" },
        ],
      }
    },
    {
      name: "Life Science",
      resources: { 
        assessments: [
          { title: "2nd Grade Science Habitats Vocabulary", link: "https://oercommons.org/courseware/lesson/73922" },
          { title: "Art and Ecology", link: "https://oercommons.org/courses/art-and-ecology" },
          { title: "Chemicals in the Environment", link: "https://oercommons.org/courses/chemicals-in-the-environment" },
          { title: "Ecosystem Change and Insect Visitation Rates", link: "https://oercommons.org/courses/ecosystem-change-and-insect-visitation-rates" },
          { title: "Environmental Education Teaching Materials", link: "https://oercommons.org/courses/environmental-education-teaching-materials-2" },
          { title: "Introductory Concepts in Soil Chemistry", link: "https://oercommons.org/courses/introductory-concepts-in-soil-chemistry" },
        ],
        course: [
          { title: "Animal Behavior", link: "https://oercommons.org/courses/animal-behavior" },
          { title: "Ecology I: The Earth System", link: "https://oercommons.org/courses/ecology-i-the-earth-system" },
          { title: "Introduction to Neuroanatomy", link: "https://oercommons.org/courses/introduction-to-neuroanatomy" },
          { title: "Molecular Biogeochemistry", link: "https://oercommons.org/courses/molecular-biogeochemistry" },
          { title: "Principles of Evolution, Ecology and Behavior", link: "https://oercommons.org/courses/principles-of-evolution-ecology-and-behavior" },
          { title: "Quantitative Physiology: Cells and Tissues", link: "https://oercommons.org/courses/quantitative-physiology-cells-and-tissues" },
        ],
        diagram: [
          { title: "6.5 Nerves, Hormones & Homeostasis", link: "https://oercommons.org/courses/6-5-nerves-hormones-homeostasis" },
          { title: "Accessible Genetic Code: RNA to Amino Acid", link: "https://oercommons.org/courses/accessible-genetic-code-rna-to-amino-acid" },
          { title: "ADAPTATION: Coral Reefs of Vanuatu", link: "https://oercommons.org/courses/adaptation-coral-reefs-of-vanuatu" },
          { title: "Adrenal Glands - Anatomy & Physiology", link: "https://oercommons.org/courses/adrenal-glands-anatomy-physiology-2" },
          { title: "Librande - Human Anatomy Laboratory Manual (First Edition, Rev. Fall 2022)", link: "https://oercommons.org/courseware/lesson/96816" },
          { title: "Photosynthesis - Light Reaction", link: "https://oercommons.org/courseware/lesson/93655" },
        ],
        game: [
          { title: "Escape Room for Body Tissues (Mini Version)", link: "https://oercommons.org/courseware/lesson/104549" },
          { title: "Lesson Plan 1 & 2: Plant Growing / Amazing Race", link: "https://oercommons.org/courseware/lesson/78693" },
          { title: "Medicine Games: Blood Typing", link: "https://oercommons.org/courses/medicine-games-blood-typing-2" },
          { title: "Web of Life Game", link: "https://oercommons.org/courses/web-of-life-game" },
        ],
        lesson: [
          { title: "A&P Identification PowerPoint Presentations", link: "https://oercommons.org/courseware/lesson/83393" },
          { title: "Biomagnification in the Food Web", link: "https://oercommons.org/courseware/lesson/117268" },
          { title: "Eukaryotic Cells", link: "https://oercommons.org/courseware/lesson/17217" },
          { title: "Introduction to cells", link: "https://oercommons.org/courseware/lesson/92717" },
          { title: "Ocean Habitat Changes", link: "https://oercommons.org/courses/ocean-habitat-changes" },
          { title: "Symbiotic Relationships Lesson", link: "https://oercommons.org/courseware/lesson/103324" },
          { title: "What Is Biodiversity?", link: "https://oercommons.org/courses/what-is-biodiversity-2" },
        ],
      }
    },
    {
      name: "Math",
      resources: {
        assessment: [
          { title: "Adding Subtracting Multiplying and Dividing", link: "https://oercommons.org/courses/adding-subtracting-multiplying-and-dividing-2" },
          { title: "Generalizing Patterns: The Difference of Two Squares", link: "https://oercommons.org/courses/generalizing-patterns-the-difference-of-two-squares" },
          { title: "Generalizing Patterns: Table Tiles", link: "https://oercommons.org/courses/generalizing-patterns-table-tiles" },
          { title: "PCC SLC Math Resources", link: "https://oercommons.org/courses/pcc-slc-math-resources" },
          { title: "Representing Data 1: Using Frequency Graphs", link: "https://oercommons.org/courses/representing-data-1-using-frequency-graphs" },
          { title: "Representing Data 2: Using Box Plots", link: "https://oercommons.org/courses/representing-data-2-using-box-plots" },
          { title: "Transformations in Algebra and Trigonometry", link: "https://oercommons.org/courseware/lesson/63859" },
        ], 
        diagram: [
          { title: "Area Maze", link: "https://oercommons.org/courses/area-maze" },
          { title: "Charts and Graphs", link: "https://oercommons.org/courses/charts-and-graphs" },
          { title: "Exploring Integers With the Number Line – GeoGebra", link: "https://oercommons.org/courses/exploring-integers-with-the-number-line-geogebra" },
          { title: "Online Algebra Study Guide", link: "https://oercommons.org/courses/online-algebra-study-guide" },
          { title: "Slope and Equations of Lines through Points", link: "https://oercommons.org/courses/slope-and-equations-of-lines-through-points" },
          { title: "Stats Stuff", link: "https://oercommons.org/courses/stats-stuff" },
          { title: "Trig. Cheat Sheet", link: "https://oercommons.org/courses/trig-cheat-sheet" },
        ],
        lab: [
          { title: "Features of Graphs - Notes", link: "https://oercommons.org/authoring/51677" },
          { title: "Measurement Conversion Chart", link: "https://oercommons.org/courseware/lesson/117477" },
          { title: "Percents -- Calculation Procedures", link: "https://oercommons.org/courses/percents-calculation-procedures" },
          { title: "Solving Systems of Linear Equations by Elimination with a TI-84", link: "https://oercommons.org/courses/solving-systems-of-linear-equations-by-elimination-with-a-ti-84" },
          { title: "Statistics CBSE", link: "https://oercommons.org/courseware/lesson/72218" },
          { title: "Teaching Numbers To Beginner ESL Students ( 21-100 ) - Off2Class Lesson Plan", link: "https://oercommons.org/courseware/lesson/90347" },
        ],
        lesson: [
          { title: "INTEGERS - Adding & Subtracting", link: "https://oercommons.org/courseware/lesson/89418" },
          { title: "Exponents and Radicals - Supplemental Learning Module", link: "https://oercommons.org/courseware/lesson/107932" },
          { title: "Fractions - Supplemental Learning Module", link: "https://oercommons.org/courseware/lesson/107930" },
          { title: "Remote Learning Plan: Vertex Form Quadratic Functions 8-11", link: "https://oercommons.org/courseware/lesson/70693" },
        ],
        module: [
          { title: "Factoring - Supplemental Learning Module", link: "https://oercommons.org/courseware/lesson/107933" },
          { title: "OER Curriculum guide: Adult Secondary level: Algebra and functions", link: "https://oercommons.org/courseware/lesson/23138" },
          { title: "Solving Rational Equations Videos and Worksheets", link: "https://oercommons.org/courseware/lesson/96764" },
        ],
      }
    },
    {
      name: "Physical Sciences",
      resources: {
        assessments: [
          { title: "Investigating Batteries: Building an Electrolytic Cell", link: "https://oercommons.org/courses/investigating-batteries-building-an-electrolytic-cell" },
          { title: "Investigating Heat and Temperature", link: "https://oercommons.org/courses/investigating-heat-and-temperature" },
          { title: "Investigating Isotopes: Using M&Ms as a Model for Calculating Average Atomic Mass", link: "https://oercommons.org/courses/investigating-isotopes-using-m-ms-as-a-model-for-calculating-average-atomic-mass" },
          { title: "Investigation of Heat Capacity and Specific Heat: Using Different Temperatures of Water and Solids", link: "https://oercommons.org/courses/investigation-of-heat-capacity-and-specific-heat-using-different-temperatures-of-water-and-solids" },
          { title: "Teaching Atomic Structure Using Cooperative Learning", link: "https://oercommons.org/courses/teaching-atomic-structure-using-cooperative-learning" },
          { title: "Transferring Heat Lab", link: "https://oercommons.org/courses/transferring-heat-lab" },
          
        ],
        lectures: [
          { title: "Concord Consortium: Excited States and Photons", link: "https://oercommons.org/courses/concord-consortium-excited-states-and-photons" },
          { title: "Electrons in Atoms and Molecules", link: "https://oercommons.org/courses/electrons-in-atoms-and-molecules" },
          { title: "Electrostatics", link: "https://oercommons.org/courses/electrostatics-3" },
          { title: "How Loud, How High?", link: "https://oercommons.org/courses/how-loud-how-high" },
          { title: "Melting Ice", link: "https://oercommons.org/courses/melting-ice-2" },
          { title: "Molecular Self-Assembly", link: "https://oercommons.org/courses/molecular-self-assembly" },
        ],
        textbooks: [
          { title: "Chemistry (Teacher's Edition)", link: "https://oercommons.org/courses/chemistry-teacher-s-edition" },
          { title: "Chemistry Fundamentals", link: "https://oercommons.org/courses/chemistry-fundamentals" },
          { title: "Exploring the Physical World: Introductory Chemistry and Physics", link: "https://oercommons.org/courses/exploring-the-physical-world-introductory-chemistry-and-physics" },
          { title: "Introduction to Chemistry", link: "https://oercommons.org/courses/introduction-to-chemistry-3" },
          { title: "Physics 132: What is an Electron? What is Light?", link: "https://oercommons.org/courses/physics-132-what-is-an-electron-what-is-light-2" },
          { title: "Teaching Physics and Chemistry for Elementary School Teachers", link: "https://oercommons.org/courses/x-mste5120-teaching-physics-and-chemistry-for-elementary-school-teachers" },
        ]
      }
    }
  ];

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });
 
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        setIsLoggedIn(false);
      }
    };

    fetchUser();
  }, [navigate]);

  return (
    <div className="communities-page">
      {isLoggedIn ? (
        <div className='set-page-colour-manager'>
            
          <div className="my-study-container">  
            <div className='unique-image-card-container-pad'>

              <div className='oer-main-material-page'>
                <h1 className='activity-name-tag-h1'>Open Educational Resources (OER)</h1>
                <br />
                {subjects.map((subject, index) => (
                  <div>
                  <SubjectSection key={index} subject={subject} />
                  {/*<Ads />*/}
                  </div>
                ))}
                
              </div>

            </div>
          </div> 

        </div>
      ) : (
        <div className='not-logged-in-alt'>
          <AccessDenied />
        </div>
      )}
    </div>
  );
};

export default OERPage;

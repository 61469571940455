import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AccessDenied from '../components/AccessDenied'; // Import the AccessDenied component
import './bodyFiles-styling/extra-style.css';
import AdPosts from './AdPosts';

const AdPostBlock = () => {
    const [userData, setUserData] = useState(null); // State to store user data
    const [adData, setAdData] = useState({
        title: '',
        adLink: '',
        media: null,
        priority: 1,
        expiryDate: '',
        notShowIn: [], // Holds selected page URLs
    });
    const [message, setMessage] = useState('');
    const [initialRequestMade, setInitialRequestMade] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Example list of page URLs
    const pageUrls = [
        { name: '/', url: '/' },
        { name: '/About', url: '/about' },
        { name: '/study', url: '/study' },
        { name: '/Blog', url: '/blog' },
        { name: '/topic-notes-block', url: '/topic-notes-block' },
        { name: '/foundation-phase', url: '/foundation-phase' },
        { name: '/intermediate-phase', url: '/intermediate-phase' },
        { name: '/accounts', url: '/accounts' },
        { name: '/institutions', url: '/institutions' },
        { name: '/profile', url: '/profile' },
        { name: '/Open-Educational-Resources', url: '/Open-Educational-Resources' },
        // Add more page URLs here
    ];

    // Fetch user data to check access
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/check/api/check/users', {
                    withCredentials: true,
                });
                setUserData(response.data.user);
                setInitialRequestMade(true);
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setError('Access forbidden');
                } else {
                    setError('Error fetching user data');
                }
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'media') {
            const file = files[0];
            // Validate media format (jpg, jpeg, png, gif)
            const validFormats = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
            if (!validFormats.includes(file.type)) {
                setMessage('Invalid file format. Please upload a jpg, jpeg, png, or gif.');
                return;
            }
            setAdData({ ...adData, media: file });
        } else {
            setAdData({ ...adData, [name]: value });
        }
    };

    // Handle checkbox selection
    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setAdData((prevData) => ({
                ...prevData,
                notShowIn: [...prevData.notShowIn, value], // Add the page URL
            }));
        } else {
            setAdData((prevData) => ({
                ...prevData,
                notShowIn: prevData.notShowIn.filter((url) => url !== value), // Remove the page URL
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if there's a media file
        if (adData.media) {
            const mediaType = adData.media.type; // Extract the file type (e.g., 'image/jpeg')
    
            // CSRF Token Fetch
            try {
                const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                    method: 'GET',
                    credentials: 'include',
                });
                if (!csrfResponse.ok) throw new Error('Failed to fetch CSRF token');
                const csrfData = await csrfResponse.json();
                const csrfToken = csrfData.csrfToken;
    
                // Prepare form data for media upload
                const formData = new FormData();
                formData.append('title', adData.title);
                formData.append('media', adData.media); // Media file (image or video)
                formData.append('mediaType', mediaType); // Send the media type
                formData.append('priority', adData.priority);
                formData.append('expiryDate', adData.expiryDate);
                formData.append('adLink', adData.adLink);
                formData.append('notShowIn', JSON.stringify(adData.notShowIn)); // Add the notShowIn field
    
                // Log the formData contents
                for (let [key, value] of formData.entries()) {
                    console.log(`${key}: ${value}`);
                }
    
                // Post request to submit ad
                const response = await axios.post('https://lernacy.co.za/api/ads/create', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'CSRF-Token': csrfToken,
                    },
                    withCredentials: true,
                });
    
                setMessage('Ad posted successfully!');
                // Clear the form
                setAdData({
                    title: '',
                    adLink: '',
                    media: null,
                    priority: 1,
                    expiryDate: '',
                    notShowIn: [],
                });
            } catch (error) {
                setMessage('Error posting ad');
                console.error('Error posting ad:', error);
            }
        } else {
            console.error('No media file selected');
            setMessage('Please select a media file.');
        }
    };    

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!userData) {
        return <AccessDenied />; // Render AccessDenied if the user is not authenticated
    }

    return (
        <div className='tested-activity-wall-container'>
            <h1 className='activity-name-tag-h1'>Ads Section</h1>
            <AdPosts /> 
            <div className="ad-post-container">
                <div className="ad-post-pad">
                    {(userData?.accountName === 'TerrenceLetsie2357') && (
                        <div>
                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                <div className="form-group">
                                    <label htmlFor="title">Ad Title</label><br />
                                    <input
                                        type="text"
                                        name="title"
                                        value={adData.title}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="media">Media (jpg, jpeg, png, gif):</label><br />
                                    <input
                                        type="file"
                                        name="media"
                                        accept=".jpg,.jpeg,.png,.gif"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="priority">Priority (1 = High, 10 = Low):</label><br />
                                    <input
                                        type="number"
                                        name="priority"
                                        min="1"
                                        max="10"
                                        value={adData.priority}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="expiryDate">Expiry Date:</label><br />
                                    <input
                                        type="date"
                                        name="expiryDate"
                                        value={adData.expiryDate}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="adLink">Advert Link:</label><br />
                                    <input
                                        type="text"
                                        name="adLink"
                                        value={adData.adLink}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                {/* Checkboxes for 'notShowIn' */}
                                <div className="form-group">
                                    <label>Don't show this ad on these pages:</label><br />
                                    {pageUrls.map((page, index) => (
                                        <div key={index}>
                                            <input
                                                type="checkbox"
                                                id={page.url}
                                                value={page.url}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor={page.url}>{page.name}</label>
                                        </div>
                                    ))}
                                </div>

                                <button type="submit" className="btn-submit">
                                    Post Ad
                                </button>
                            </form>

                            {message && <p>{message}</p>}
                        </div>
                    )}
                </div>
            </div>
            <br />
        </div>
    );
};

export default AdPostBlock;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './pages-styling/pages.css';
import useFetchUserData from '../../components/useFetchUserData';

const GradeSixMathematics = () => {
  const user = useFetchUserData();
  const [showGrGrSixRoundingOffGameModal, setShowGrGrSixRoundingOffGameModal] = useState(false);
  const [showGrSixLinearEquationModal, setShowGrSixLinearEquationModal] = useState(false);
  const [showGrSixEquationBuilderModal, setShowGrSixEquationBuilderModal] = useState(false);
  const [showGrSixArithmeticExpressionsModal, setShowGrSixArithmeticExpressionsModal] = useState(false);
  const [showGrSixMultiplicationModal, setShowGrSixMultiplicationModal] = useState(false);
  const navigate = useNavigate();

  const handleGrSixEquationBuilderProceed = () => {
    setShowGrSixEquationBuilderModal(false);
    navigate('/grade-six-equation-builder');
  };

  const handleGrSixArithmeticExpressionsProceed = () => {
    setShowGrSixArithmeticExpressionsModal(false);
    navigate('/grade-six-arithmetic-expressions');
  };

  const handleGrSixMultiplicationProceed = () => {
    setShowGrSixMultiplicationModal(false);
    navigate('/grade-six-three-part-multiplication');
  };

  const handleGrSixLinearEquationProceed = () => {
    setShowGrSixLinearEquationModal(false);
    navigate('/grade-six-linear-equation');
  };

  const handleGrGrSixRoundingOffGameProceed = () => {
    setShowGrGrSixRoundingOffGameModal(false);
    navigate('/grade-six-rounding-off');
  };

  return (
    <div className='game-name-list-files'>
      <div className='game-name-list-files-box'>
        {user && user.accountName ? (
          <>
            <h1 className='grade-level-subject'>Grade 6 - Mathematics</h1>

            <button onClick={() => setShowGrSixArithmeticExpressionsModal(true)} className="my-game-name-text">Arithmetic Expressions</button>
            {showGrSixArithmeticExpressionsModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowGrSixArithmeticExpressionsModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleGrSixArithmeticExpressionsProceed}>Proceed</button>
                  <button onClick={() => setShowGrSixArithmeticExpressionsModal(false)}>Cancel</button>
                </div>
              </div> 
            )}
            
            <button onClick={() => setShowGrSixEquationBuilderModal(true)} className="my-game-name-text">Equation Builder</button>
            {showGrSixEquationBuilderModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowGrSixEquationBuilderModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleGrSixEquationBuilderProceed}>Proceed</button>
                  <button onClick={() => setShowGrSixEquationBuilderModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            <button onClick={() => setShowGrSixLinearEquationModal(true)} className="my-game-name-text">Linear Equations</button>
            {showGrSixLinearEquationModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowGrSixLinearEquationModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleGrSixLinearEquationProceed}>Proceed</button>
                  <button onClick={() => setShowGrSixLinearEquationModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            <button onClick={() => setShowGrSixMultiplicationModal(true)} className="my-game-name-text">Three Part Multiplication</button>
            {showGrSixMultiplicationModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowGrSixMultiplicationModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleGrSixMultiplicationProceed}>Proceed</button>
                  <button onClick={() => setShowGrSixMultiplicationModal(false)}>Cancel</button>
                </div>
              </div> 
            )}

            <button onClick={() => setShowGrGrSixRoundingOffGameModal(true)} className="my-game-name-text">Rounding Off</button>
            {showGrGrSixRoundingOffGameModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowGrGrSixRoundingOffGameModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleGrGrSixRoundingOffGameProceed}>Proceed</button>
                  <button onClick={() => setShowGrGrSixRoundingOffGameModal(false)}>Cancel</button>
                </div>
              </div>
            )} 

            </>
        ) : null}
      </div>
    </div>
  );
};

export default GradeSixMathematics;

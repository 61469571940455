import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ActivityScoresComponent from './ActivityScoresComponent';
import ResultsPageTour from '../components/TourGuide/ResultsPageTour';

const ResultsPortal = () => {
  const [user, setUser] = useState(null);
  const [activityScores, setActivityScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState('activityScores'); // New state for section control
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          if (!data.user.accountName) {
            navigate('/login-second-stage');
          } else {
            fetchActivityScores(data.user._id);
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login');
      }
    };

    const fetchActivityScores = async (userId) => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/activity-scores', {
          withCredentials: true,
        });

        if (response.data.success) {
          setActivityScores(response.data.activityScores);
        } else {
          console.error('Error fetching activity scores:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching activity scores:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [navigate]);

  // Function to format submission time
  const formatSubmissionTime = (submissionTime) => {
    const date = new Date(submissionTime);
    const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };

    return `${date.toLocaleDateString(undefined, optionsDate)} at ${date.toLocaleTimeString(undefined, optionsTime)}`;
  };

  return (
    <div className='activity-page-main-border'>
      <ResultsPageTour />
      <h1 className='activity-name-tag-h1'>Results Portal</h1>

      <div className='filing-system-page-container'>
        <div className='filing-system-page'>
          {loading ? (
            <p>Loading...</p> 
          ) : (
            <>
              <div>
                <div className='section-buttons-container'>
                  <div className='section-buttons'>
                    <button
                      onClick={() => setActiveSection('activityScores')}
                      className={activeSection === 'activityScores' ? 'active-button' : 'inactive-button'}
                    >
                      Activity Scores
                    </button>
                    <button
                      onClick={() => setActiveSection('otherSection')}
                      className={activeSection === 'otherSection' ? 'active-button' : 'inactive-button'}
                    >
                      Other Section
                    </button>
                  </div>
                </div>

                {activeSection === 'activityScores' ? (
                  <>
                    {activityScores.length > 0 ? (
                      <div className="portal-activity-scores-container">
                        {activityScores.map(score => (
                          <div key={score._id} className="activity-score-card">
                            <h3 id='activity-name-plus-total-score'>Activity Name: {score.activityName}</h3>
                            <label>▲ The last number after "-" in the name is the activity's total</label>
                            <p><strong>Score:</strong> {score.score}</p>
                            <p><strong>Activity Type:</strong> {score.activityType}</p>
                            <p><strong>Comments:</strong> {score.comments || 'No comments provided.'}</p>
                            <p><strong>Submission Time:</strong> {formatSubmissionTime(score.submissionTime)}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>No activity scores found.</p>
                    )}
                  </>
                ) : ( 
                  <div className="other-section">
                    <h2>Data of users you are tracking</h2>
                    <ActivityScoresComponent />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultsPortal;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PinValidation from './PinValidation';

const StoryBlock1 = ({ user }) => {
    const [answers, setAnswers] = useState({});
    const [schoolNameInput, setSchoolNameInput] = useState('');
    const [schoolNameSuggestions, setSchoolNameSuggestions] = useState([]);
    const [pinValidated, setPinValidated] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchSchoolNameSuggestions = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/institutions/schools/names');
                const { success, schoolNames } = response.data;
                if (success) {
                    setSchoolNameSuggestions(schoolNames);
                } else {
                    console.error('Failed to fetch school names:', response.data);
                }
            } catch (error) {
                console.error('Error fetching school names:', error);
            }
        };

        const fetchUserId = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/study', { withCredentials: true });
                const { user } = response.data;
                setUserId(user._id);
            } catch (error) {
                console.error('Error fetching user ID:', error);
            }
        };

        fetchSchoolNameSuggestions();
        fetchUserId();
    }, []);

    const handleChange = (e) => {
        setAnswers({
            ...answers,
            [e.target.name]: e.target.value
        });
    };

    const handleSchoolNameInputChange = (e) => {
        const { value } = e.target;
        setSchoolNameInput(value);

        const filteredSuggestions = schoolNameSuggestions.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setSchoolNameSuggestions(filteredSuggestions);
    };

    const validatePin = () => {
        // Additional logic for validation if needed
        return pinValidated;
    };

    const onSubmit = async () => {
        if (!validatePin()) {
            alert('PIN validation failed. Submission blocked.');
            return;
        }
    
        try {
            // Fetch CSRF token
            const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
            if (!csrfResponse.data.csrfToken) {
                throw new Error('Failed to fetch CSRF token');
            }
            const csrfToken = csrfResponse.data.csrfToken;
    
            const correctAnswers = {
                question1: 'B',
                question2: 'C',
                question3: 'D',
                question4: 'D',
                question5: 'C',
                question6: 'C',
                question7: 'B',
                question8: 'B',
                question9: 'C',
                question10: 'C',
                
            };
    
            let score = 0;
            for (let key in correctAnswers) {
                if (answers[key] === correctAnswers[key]) {
                    score++;
                }
            }
    
            const submissionData = {
                score: score,
                taskBlock: 6,
                userId: userId,
                taskName: 'Comprehension-Levels',
                schoolName: schoolNameInput,
            };
    
            // console.log('Submission Data:', submissionData); // Log the data being passed to the route
    
            // POST request with CSRF token and credentials in headers
            const response = await axios.post('https://lernacy.co.za/api/ed-support/taskSubmit', submissionData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken // Include CSRF token in the header
                }
            });
    
            if (response.data.success) {
                alert('Task submitted successfully!');
                window.location.reload(); // Reload the page after successful submission
            } else {
                console.error('Failed to submit task:', response.data.error);
                alert('Failed to submit task. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting task:', error);
            alert('Error submitting task. Please check your network connection.');
        }
    };

    return (
        <div className='story-wall-block'>
            <h2>Block 6</h2>
            <h3><strong>New Horizons in the Classroom</strong></h3>
            <div className='story-div-image-text'>
                <div className='story-image-cover-block'>
                    <img loading="lazy" src='/activityImages/ed-support/new-horizons-in-the-classroom.jfif' alt='New Horizons in the Classroom' />
                </div>
                <div> 
                    <p className='story-p-tag'> 
                        In the bustling city of Johannesburg, a young boy named Thabo found himself standing before 
                        the gates of a new school. His family had recently moved to the city from a small town in 
                        the Northern Cape, and Thabo was struggling to adjust to his new environment. The towering 
                        buildings, crowded streets, and the constant hum of city life were a stark contrast to the 
                        quiet, open spaces of his hometown.
                    </p> 
                    <p className='story-p-tag'>
                        Thabo's new school, Marula High, was known for its academic excellence and diverse student 
                        body. Despite its reputation, Thabo felt out of place. He missed his old friends, familiar 
                        teachers, and the comforting routine he once knew. The first few weeks were particularly 
                        challenging as he navigated the unfamiliar hallways and tried to make sense of the 
                        different accents and languages spoken by his classmates.
                    </p>
                    <p className='story-p-tag'>
                        One day, during lunch, Thabo sat alone under a large marula tree in the schoolyard. He was 
                        approached by Aisha, a friendly girl with a warm smile. She had noticed Thabo's solitude 
                        and decided to reach out. "Hi, I'm Aisha. Mind if I sit with you?" she asked. Thabo nodded, 
                        grateful for the company.
                    </p>
                    <p className='story-p-tag'>
                        Aisha and Thabo quickly became friends. She introduced him to her group of friends, who 
                        came from various cultural backgrounds. There was Sipho, who loved playing soccer; Maria, 
                        who was passionate about painting; and Raj, who excelled in science. Thabo began to feel 
                        more at ease as he learned about their interests and experiences.
                    </p>
                    <p className='story-p-tag'>
                        Despite this, Thabo's academic performance began to slip. He found the pace of the lessons 
                        overwhelming and struggled to keep up with the homework. His teachers noticed the change 
                        and called a meeting with his parents. Thabo's mother, Mrs. Maseko, explained the 
                        difficulties he was facing adjusting to the new school. The teachers suggested extra 
                        tutoring and encouraged Thabo to join after-school clubs to help him integrate better.
                    </p>
                    <p className='story-p-tag'>
                        Thabo decided to join the school's drama club, hoping it would be a good outlet for his 
                        stress and a way to improve his English. The drama club was preparing for an end-of-year 
                        play, and Thabo was given a small role. Through rehearsals, he found joy in acting and 
                        began to build confidence. His English improved, and he started to make more friends.
                    </p>
                    <p className='story-p-tag'>
                        One afternoon, Thabo stayed behind after drama practice to work on his lines. Mrs. Dlamini, 
                        the drama teacher, noticed his dedication and took him aside. "Thabo, I see great potential 
                        in you. Remember, adjusting to a new environment takes time. Be patient with yourself and 
                        keep pushing forward," she advised.
                    </p>
                    <p className='story-p-tag'>
                        Thabo took her words to heart. He continued to work hard in his classes and participated 
                        actively in the drama club. Slowly but surely, his grades improved, and he felt more 
                        comfortable in his new surroundings. By the time the end-of-year play arrived, Thabo had 
                        transformed from the shy, uncertain boy into a confident and enthusiastic performer.
                    </p>
                    <p className='story-p-tag'>
                        On the night of the play, Thabo's parents sat proudly in the audience. As the curtain 
                        rose, Thabo took a deep breath and stepped onto the stage. The performance was a success, 
                        and Thabo received loud applause for his role. For the first time since moving to 
                        Johannesburg, Thabo felt a sense of accomplishment and belonging.
                    </p>
                    <p className='story-p-tag'>
                        After the play, Thabo's parents hugged him tightly. "We're so proud of you, Thabo," his 
                        mother said. "You've shown great courage and resilience."
                    </p>
                    <p className='story-p-tag'>
                        Thabo smiled, realizing that the journey of adapting to a new environment had taught him 
                        valuable lessons about perseverance and the importance of embracing new experiences. He 
                        looked forward to the future with renewed confidence, ready to face any challenges that 
                        lay ahead.
                    </p>
                </div>
            </div>

            <hr />

            <div className='comprehension-questions'>
                <h3>Comprehension Questions</h3>
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <p>1. What was the main reason Thabo struggled at his new school?</p>
                        <label>
                            <input type="radio" name="question1" value="A" onChange={handleChange} />
                            A) He didn't like the school subjects
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="B" onChange={handleChange} />
                            B) He missed his old friends and familiar environment
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="C" onChange={handleChange} />
                            C) He didn't have good teachers 
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="D" onChange={handleChange} />
                            D) He found the school building confusing
                        </label>
                    </div>

                    <div>
                        <p>2. Where did Thabo's family move from?</p>
                        <label>
                            <input type="radio" name="question2" value="A" onChange={handleChange} />
                            A) Durban
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="B" onChange={handleChange} />
                            B) Cape Town
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="C" onChange={handleChange} />
                            C) Northern Cape
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="D" onChange={handleChange} />
                            D) Pretoria
                        </label>
                    </div>

                    <div>
                        <p>3. Who was the first person to befriend Thabo at his new school?</p>
                        <label>
                            <input type="radio" name="question3" value="A" onChange={handleChange} />
                            A) Sipho
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="B" onChange={handleChange} />
                            B) Raj
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="C" onChange={handleChange} />
                            C) Maria
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="D" onChange={handleChange} />
                            D) Aisha
                        </label>
                    </div>

                    <div>
                        <p>4. What extracurricular activity did Thabo join to help him adjust?</p>
                        <label>
                            <input type="radio" name="question4" value="A" onChange={handleChange} />
                            A) Soccer club
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="B" onChange={handleChange} />
                            B) Art club
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="C" onChange={handleChange} />
                            C) Science club
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="D" onChange={handleChange} />
                            D) Drama club
                        </label>
                    </div>

                    <div>
                        <p>5. How did Thabo’s teachers first become aware of his struggles?</p>
                        <label>
                            <input type="radio" name="question5" value="A" onChange={handleChange} />
                            A) Thabo told them
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="B" onChange={handleChange} />
                            B) His friends informed the teachers
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="C" onChange={handleChange} />
                            C) His academic performance declined
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="D" onChange={handleChange} />
                            D) His parents contacted the school
                        </label>
                    </div>

                    <div>
                        <p>6. What advice did Mrs. Dlamini give Thabo?</p>
                        <label>
                            <input type="radio" name="question6" value="A" onChange={handleChange} />
                            A) To change schools
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="B" onChange={handleChange} />
                            B) To give up on the drama club
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="C" onChange={handleChange} />
                            C) To be patient with himself and keep pushing forward
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="D" onChange={handleChange} />
                            D) To focus only on academics
                        </label>
                    </div>

                    <div>
                        <p>7. What role did the drama club play in Thabo’s adjustment to the new school?</p>
                        <label>
                            <input type="radio" name="question7" value="A" onChange={handleChange} />
                            A) It provided him with extra tutoring
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="B" onChange={handleChange} />
                            B) It helped him improve his English and make friends
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="C" onChange={handleChange} />
                            C) It distracted him from his studies
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="D" onChange={handleChange} />
                            D) It increased his stress levels
                        </label>
                    </div>

                    <div>
                        <p>8. How did Thabo's performance in the end-of-year play affect him?</p>
                        <label>
                            <input type="radio" name="question8" value="A" onChange={handleChange} />
                            A) He decided to quit drama
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="B" onChange={handleChange} />
                            B) He felt a sense of accomplishment and belonging
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="C" onChange={handleChange} />
                            C) He became more nervous about school
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="D" onChange={handleChange} />
                            D) His parents were disappointed
                        </label>
                    </div>

                    <div>
                        <p>9. What lesson did Thabo learn from his experience at Marula High?</p>
                        <label>
                            <input type="radio" name="question9" value="A" onChange={handleChange} />
                            A) That moving to a new place is always easy
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="B" onChange={handleChange} />
                            B) The importance of giving up when things are tough
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="C" onChange={handleChange} />
                            C) Valuable lessons about perseverance and embracing new experiences
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="D" onChange={handleChange} />
                            D) That making friends isn't important
                        </label>
                    </div>

                    <div>
                        <p>10. How did Thabo’s parents react to his performance in the play?</p>
                        <label>
                            <input type="radio" name="question10" value="A" onChange={handleChange} />
                            A)  They were indifferent
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="B" onChange={handleChange} />
                            B) They were critical of his performance
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="C" onChange={handleChange} />
                            C) They were proud and supportive
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="D" onChange={handleChange} />
                            D) They wanted him to focus more on sports
                        </label>
                    </div>

                </form>
            </div>

            <hr />

            <div className='school-name-pin-block'>
                <h3>School Information</h3>
                <label htmlFor="schoolNameInput">School Name:</label><br />
                <input
                    type="text"
                    id="schoolNameInput"
                    name="schoolNameInput"
                    value={schoolNameInput}
                    onChange={handleSchoolNameInputChange}
                    list="schoolNameSuggestions"
                    required
                    className='tasks-school-name-input'
                /><br />
                <datalist id="schoolNameSuggestions">
                    {schoolNameSuggestions.map((schoolName, index) => (
                        <option key={index} value={schoolName} />
                    ))}
                </datalist>
                {!pinValidated && (
                    <PinValidation
                        schoolNameInput={schoolNameInput}
                        onSubmit={() => setPinValidated(true)}
                    />
                )}
                {pinValidated && (
                    <button className='activity-submit-button' type="button" onClick={onSubmit}>
                        Submit Task 
                    </button>
                )}
            </div>
        </div>
    );
};

export default StoryBlock1;
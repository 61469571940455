import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ActivityScoresComponent = () => {
  const [activityScores, setActivityScores] = useState([]); // Ensure it's initialized as an array
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  // Function to fetch the activity scores from the server
  const fetchActivityScores = async () => {
    try {
      const response = await axios.get('https://lernacy.co.za/api/laq-mcq-user-scores', { withCredentials: true });

      // Log the response to check if data is an array
      // console.log('API response data:', response.data);

      // Ensure that the response is an array before setting the state
      if (Array.isArray(response.data)) {
        setActivityScores(response.data); // Store the fetched data in state
      } else {
        console.error('Data is not an array', response.data);
        setActivityScores([]); // Set as empty array if response is not an array
      }
    } catch (error) {
      console.error('Error fetching activity scores:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivityScores();
  }, []);

  // Format submission time into a readable format
  const formatSubmissionTime = (submissionTime) => {
    const date = new Date(submissionTime);
    const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };

    return `${date.toLocaleDateString(undefined, optionsDate)} at ${date.toLocaleTimeString(undefined, optionsTime)}`;
  };

  // Filter the activity scores based on the search term
  const filteredScores = activityScores.filter(score =>
    score.userId.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    score.userId.surname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    score.activityName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="activity-scores-container">

      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by name, surname or activity name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className='search-input'
        />
      </div>
      <br />

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="activity-scores-list">
          {filteredScores.length > 0 ? (
            filteredScores.map((score) => (
              <div key={score._id} className="activity-score-card">
                <h3>{score.userId.name} {score.userId.surname}</h3>
                <p><strong>Account Name:</strong> {score.userId.accountName}</p>
                <p><strong>Activity Name:</strong> {score.activityName}</p>
                <p><strong>Score:</strong> {score.score}</p>
                <p><strong>Activity Type:</strong> {score.activityType}</p>
                <p><strong>Submission Time:</strong> {formatSubmissionTime(score.submissionTime)}</p>
                <p><strong>Comments:</strong> {score.comments || 'No comments provided'}</p>
              </div>
            ))
          ) : (
            <p>No activity scores found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ActivityScoresComponent;


import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const GradeFiveSocialSciencesSessionTwo = () => {
  const [user, setUser] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [mentions, setMentions] = useState([]);
  const [link, setLink] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [currentSection, setCurrentSection] = useState('task');
  const [submissions, setSubmissions] = useState([]);
  const [markedSubmissions, setMarkedSubmissions] = useState([]);
  const [scores, setScores] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [schoolNames, setSchoolNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const activityName = 'grade5_social-sciences_session-2';
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login');
      }
    };

    const fetchAccounts = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/multimodal-learning/accounts', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          const filteredAccounts = data.accounts.filter(
            (account) => account.status !== 'Admin' && !account.adminValidation
          );
          setAccounts(filteredAccounts);
        } else {
          console.error('Error fetching accounts');
        }
      } catch (error) {
        console.error('Error fetching accounts:', error.message);
      }
    };

    fetchUserData();
    fetchAccounts();
  }, [navigate]);
 
  useEffect(() => {
    if (currentSection === 'submissions') {
      const fetchSubmissions = async () => {
        try {
          const response = await fetch(`https://lernacy.co.za/api/submissions?activityName=${activityName}`, {
            method: 'GET',
            credentials: 'include',
          });

          if (response.ok) {
            const data = await response.json();
            setSubmissions(data.submissions);
            const initialScores = data.submissions.reduce((acc, submission, index) => {
              acc[index] = Array(20).fill(0);
              return acc;
            }, {});
            setScores(initialScores);
          } else {
            console.error('Error fetching submissions');
          }
        } catch (error) {
          console.error('Error fetching submissions:', error.message);
        }
      };

      fetchSubmissions();
    }
  }, [currentSection]);

  useEffect(() => {
    const fetchMarkedSubmissions = async () => {
      try {
        const response = await fetch(`https://lernacy.co.za/api/marked/submissions/${activityName}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setMarkedSubmissions(data.submissions);
        } else {
          console.error('Error fetching marked submissions');
        }
      } catch (error) {
        console.error('Error fetching marked submissions:', error.message);
      }
    };
  
    fetchMarkedSubmissions();
  }, []);

  useEffect(() => {
    const fetchSchoolNames = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/institutions/schools/names');
        setSchoolNames(response.data.schoolNames);
      } catch (error) {
        console.error('Error fetching school names:', error);
      }
    };

    fetchSchoolNames();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  
    const mentionsCount = value.split(' ').filter(word => word.startsWith('@')).length;
  
    // Limit amount of account names allowed
    if (mentionsCount > 2) {
      e.preventDefault();
      inputRef.current.disabled = true;
      alert('You can only mention up to 2 account names.');
      return;
    }
  
    const currentMentions = value.split(' ').filter(word => word.startsWith('@')).map(word => word.slice(1));
    setMentions(currentMentions);
  
    if (value.includes('@')) {
      const query = value.split('@').pop();
      if (query) {
        const filteredSuggestions = accounts.filter((account) => {
          return (
            account &&
            account.accountName &&
            account.accountName.toLowerCase().includes(query.toLowerCase()) &&
            !currentMentions.includes(account.accountName)
          );
        });
        setMentionSuggestions(filteredSuggestions);
      } else {
        setMentionSuggestions([]);
      }
    } else {
      setMentionSuggestions([]);
    }
  };

  const handleMentionClick = (accountName) => {
    setMentions([...mentions, accountName]);
    setSearchQuery(searchQuery.replace(/@\w*$/, `@${accountName} `));
    inputRef.current.focus();
    setMentionSuggestions([]);
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const handleSectionChange = (section) => {
    setCurrentSection(section); 
  };

  const filteredSubmissions = submissions.filter(submission => {
    // Logic to filter submissions based on searchValue
    return (
      submission.groupName.toLowerCase().includes(searchValue.toLowerCase()) ||
      submission.mentions.some(mention => mention.toLowerCase().includes(searchValue.toLowerCase()))
    );
  });

  const handleSubmit = async () => {
    if (!user || !isValidLink || groupName.trim() === '' || searchTerm.trim() === '') {
      return;
    }
  
    // Ensure mentions in input match mentions state
    const currentMentions = searchQuery.split(' ').filter(word => word.startsWith('@')).map(word => word.slice(1));
    if (!currentMentions.every(mention => mentions.includes(mention)) || !mentions.every(mention => currentMentions.includes(mention))) {
      console.log('Current Mentions:', currentMentions); // Log the current mentions to console
      // Remove the return statement here
    }
  
    try {
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include', // Ensure credentials are included
      });
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      const payload = {
        userId: user._id,
        activityName,
        mentions: currentMentions, // Use only the mentions from currentMentions
        link,
        groupName,
        schoolName: searchTerm, // Add searchTerm to the payload
      };
  
      // Log the payload for debugging
      // console.log('Payload being sent to the server:', payload);
  
      const response = await fetch('https://lernacy.co.za/api/submit-mentions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        credentials: 'include', // Ensure credentials are included
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        alert('Data submitted successfully!');
        // Clear all form fields after successful submission
        setSearchQuery('');
        setLink('');
        setGroupName(''); 
        setSearchTerm('');
      } else {
        const errorData = await response.json();
        console.error('Error submitting mentions:', errorData.message);
        alert('Error submitting mentions: ' + errorData.message);
      }
    } catch (error) {
      console.error('Error submitting mentions:', error.message);
      alert('Error submitting mentions');
    }
  };


  const validateLink = (link) => {
    const regex = /^https:\/\/(drive|docs)\.google\.com\/(file\/d\/[^/]+\/(view|edit)\?usp=sharing|document\/d\/[^/]+\/edit\?usp=sharing)$/;
    return regex.test(link);
  };

  const handleLinkChange = (e) => {
    const value = e.target.value;
    setLink(value);
    setIsValidLink(validateLink(value));
  };

  const handleSchoolInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    // Filter school names based on the input value
    const filteredSuggestions = schoolNames.filter((name) =>
      name.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (value) => {
    setSearchTerm(value); // Set the input value to the selected suggestion
    setSuggestions([]); // Clear the suggestions
  };


  //const isSubmitDisabled = searchQuery.length < 4 || !isValidLink || !link;
  
  // Update this useEffect to include the school input validation
  useEffect(() => {
    // Check if any of the required fields are empty
    if (searchQuery.trim() === '' || !isValidLink || groupName.trim() === '' || searchTerm.trim() === '') {
      setIsSubmitDisabled(true); // Disable the button if any required field is empty
    } else {
      setIsSubmitDisabled(false); // Enable the button if all required fields are filled
    }
  }, [searchQuery, isValidLink, groupName, searchTerm]);

  const handleScoreChange = (submissionIndex, scoreIndex, value) => {
    const newScores = { ...scores }; // Copying the scores object
    newScores[submissionIndex] = [...scores[submissionIndex]]; // Copying the array for the specific submission
    newScores[submissionIndex][scoreIndex] = value; // Updating the specific score
    setScores(newScores); // Updating the state
  };

  const calculateCategoryScores = (submissionScores) => {
    return {
      creativeOriginality: submissionScores[0] + submissionScores[1],
      contentGrasp: submissionScores[2] + submissionScores[3],
      clarity: submissionScores[4] + submissionScores[5],
      multiElements: submissionScores[6] + submissionScores[7],
      audienceEngage: submissionScores[8] + submissionScores[9],
      techUse: submissionScores[10] + submissionScores[11],
      presentation: submissionScores[12] + submissionScores[13],
      teamWork: submissionScores[14] + submissionScores[15],
      selfAssessment: submissionScores[16] + submissionScores[17],
      meanImpact: submissionScores[18] + submissionScores[19],
    };
  };

  const calculateTotalScore = (submissionIndex) => {
    const categoryScores = calculateCategoryScores(scores[submissionIndex]);
    return Object.values(categoryScores).reduce((a, b) => a + b, 0);
  };

  const handleUpdateScores = async (submissionIndex) => {
    if (!user || !user.accountName) {
      console.error('User account name not found');
      return;
    }
  
    const submission = submissions[submissionIndex];
    const categoryScores = calculateCategoryScores(scores[submissionIndex]);
    const totalScore = calculateTotalScore(submissionIndex);
  
    const payload = {
      creativeOriginality: categoryScores.creativeOriginality,
      contentGrasp: categoryScores.contentGrasp,
      clarity: categoryScores.clarity,
      multiElements: categoryScores.multiElements,
      audienceEngage: categoryScores.audienceEngage,
      techUse: categoryScores.techUse,
      presentation: categoryScores.presentation,
      teamWork: categoryScores.teamWork,
      selfAssessment: categoryScores.selfAssessment,
      meanImpact: categoryScores.meanImpact,
      total: totalScore,
      accountName: user.accountName,  // Add accountName here
    };
  
    try {
      // Get CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to retrieve CSRF token');
      }
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      // Update scores with CSRF token
      const updateResponse = await fetch(`https://lernacy.co.za/api/submissions/${submission._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,  // Include the CSRF token in the request headers
        },
        credentials: 'include',
        body: JSON.stringify(payload),
      });
  
      if (updateResponse.ok) {
        // Reset all range selectors to 0
        const initialScores = Array(submissions.length).fill().map(() => Array(20).fill(0));
        setScores(initialScores);
  
        // If PUT request is successful, make a GET request to update page data
        const getResponse = await fetch(`https://lernacy.co.za/api/submissions?activityName=${activityName}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken,  // Include the CSRF token in the request headers
          },
          credentials: 'include',
        });
  
        if (getResponse.ok) {
          const newData = await getResponse.json();
          // Update your state or page data with the new data
          setSubmissions(newData.submissions);
  
          alert('Scores updated successfully!');
        } else {
          console.error('Error updating page data after PUT request');
          alert('Error updating page data after PUT request');
        }
      } else {
        console.error('Error updating scores');
        alert('Error updating scores');
      }
    } catch (error) {
      console.error('Error updating scores:', error.message);
      alert('Error updating scores');
    }
  };

  return (
    <div className='multimodal-page-container'>
      <h1 className='page-title'>Grade 5 Social Sciences<br /> Session 2</h1>

      {/* {(user?.accountName === 'TerrenceLetsie6821' || user?.accountName === 'MeganLyon6223') && ( */}
      {(user?.status === 'Teacher' || user?.status === 'Admin') && (
        <div className='multimodal-education-teacher-menu'>
          <button
            className={`multimodal-education-teacher-menu-button ${currentSection === 'task' ? 'active' : ''}`}
            onClick={() => handleSectionChange('task')}
          >
            <div>
              Task
            </div>
          </button>
          <button
            className={`multimodal-education-teacher-menu-button ${currentSection === 'submissions' ? 'active' : ''}`}
            onClick={() => handleSectionChange('submissions')}
          >
            <div>
              Submissions
            </div>
          </button>
        </div>
      )}
      <br />

      
      {currentSection === 'task' && (
        <div className='multimodal-blocks-aligner'>
          <div className='multimodal-task-and-objectives'>
            <div>
              <h2 className='task-name-label'>Task Name:</h2>
              <h2 className='task-name-tag'>Natural Vegetation and Climate</h2>
              <hr />
              <h3>Objective</h3>
              <p>
                Students will explore the concept of natural vegetation, understand the relationship 
                between natural vegetation and climate, and examine the Savannah grasslands in South 
                Africa. They will create maps and presentations to demonstrate their understanding.
              </p>
            </div>
          </div>
          
          <div className='multimodal-activity-blocks'>
            <h2>Exploring Savannah Grasslands</h2>
            <label className='multimodal-activity-material-tag'>Materials:</label>
            <ul className='multimodal-activity-blocks-ul'>
              <li>
                <label>
                  <input type="checkbox" name="paper" value="sheets" />
                  Large paper sheets or poster boards
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" name="markers" value="crayons" />
                  Markers, crayons, colored pencils
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" name="stickers" value="images" />
                  Internet access or library resources for research
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" name="maps" value="access" />
                  Printed maps of South Africa (outline maps)
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" name="maps" value="access" />
                  Glue and scissors
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" name="maps" value="access" />
                  Presentation tools (computer, projector, or chart paper)
                </label>
              </li>
            </ul>
          </div>

          <div className='multimodal-activity-blocks'>
            <label className='multimodal-activity-material-tag'>Map Creation:</label>
            <p>
              Students should be divided into small groups and be in possession of necessary materials for the task.
            </p>
            <p className='ul-list-support-text'>
              <strong>Groups will:</strong>
            </p>
            <ul className='multimodal-activity-blocks-ul-bullet'>
              <li>Mark the location of the Savannah grasslands on their maps.</li>
              <li>Illustrate the types of vegetation found in this area with drawings or pictures.</li>
              <li>Indicate the climate characteristics using symbols or color codes.</li>
              <li>Add images or drawings of typical wildlife and explain their relationship with the vegetation.</li>
              <li>Discuss different types of natural vegetation and their importance.</li>
              <li>Explain how climate influences the types of vegetation found in different regions.</li>
              <li>Provide examples of plants and their adaptations to various climates in South Africa (e.g., fynbos in the Western Cape, aloes in the Karoo).</li>
              <li>Discuss the wildlife commonly found in savannah grasslands (e.g., lions, elephants, giraffes) and their reliance on the vegetation.</li>
              <li>Natural features (rivers, lakes, forests, etc.)</li>
            </ul>
          </div>

          <div className='multimodal-activity-blocks'>
            <label className='multimodal-activity-material-tag'>Presentation and Discussion:</label>
            <p>
              Each group presents their map and findings to the class.
            </p>
            <p><strong>Presentations should cover:</strong></p>
            <ul className='multimodal-activity-blocks-ul-bullet'>
              <li>The location and extent of the Savannah grasslands.</li>
              <li>Key climate characteristics and their impact on vegetation.</li>
              <li>Examples of plants and their adaptations.</li>
              <li>Wildlife found in the region and their dependency on the natural vegetation.</li>
            </ul>

            {(user?.status === 'Teacher' || user?.status === 'Admin') && (
              <div>
                <p style={{color:'red'}}>Teacher Access only!!!</p>

                <div>
                  <label>After presentations, facilitate a class discussion on:</label>
                  <ul className='multimodal-activity-blocks-ul-bullet'>
                    <li>How different climates across South Africa lead to diverse vegetation types.</li>
                    <li>The importance of natural vegetation in supporting wildlife.</li>
                    <li>The role of climate in shaping ecosystems.</li>
                    <li>Students can share their thoughts on how changes in climate might affect these ecosystems in the future.</li>
                  </ul>
                </div>

                <label style={{color:'red'}}>
                  ***
                </label>
              </div>
            )}

          </div>

          <div className='mention-input-container'>

            <div className='submit-multimodal-block'>
              <h2>Data Submission</h2>
              <div>
                <p>
                  Now that you have completed the task, it's time to submit proof of your work 
                  online and to your teacher. Please ensure all stages of the task are completed 
                  before submitting. Follow the steps below to submit correctly.
                </p>

                <ol className='submission-block-ol'>

                  <li>
                    <label className='submission-block-flex'>
                      <input type="checkbox" className='submission-block-checkboxes' name="step1" />
                      <p>
                        Scan your task parts in order, including the paragraphs you wrote. You can use 
                        apps like 'Adobe Scanner' to make this process easier.
                      </p>
                    </label>
                  </li>

                  <li>
                    <label className='submission-block-flex'>
                      <input type="checkbox" className='submission-block-checkboxes' name="step2" />
                      <p>
                        Combine all the scanned pages into a single PDF file.
                      </p>
                    </label>
                  </li>

                  <li>
                    <label className='submission-block-flex'>
                      <input type="checkbox" className='submission-block-checkboxes' name="step3" />
                      <p>
                        Upload the scanned PDF to your Google Drive.
                      </p>
                    </label>
                  </li>

                  <div className='submission-block-more-instructions-box'>
                    <label className='multimodal-activity-material-tag'>After uploading follow these important steps</label>
                    <p className='submission-block-more-instructions'>In your google drive, locate the file you uploaded and do the following:</p>
                  </div>

                  <li>
                    <label>
                      <div className='instructions-with-label-img'>
                        <input type="checkbox" className='submission-block-checkboxes' name="step3" />
                        <p>
                          Click the three dots to reveal a menu.
                        </p>
                      </div>
                      <img loading="lazy" src="/styling/step1.png" alt="Description of the image" className='image-test-block' />
                    </label>
                  </li>

                  <li>
                    <label>
                      <div className='instructions-with-label-img'>
                        <input type="checkbox" className='submission-block-checkboxes' name="step3" />
                        <p>
                          Click manage access to make your document accessible.
                        </p>
                      </div>
                      <img loading="lazy" src="/styling/step2.png" alt="Description of the image" className='image-test-block' />
                    </label>
                  </li>

                  <li>
                    <label>
                      <div className='instructions-with-label-img'>
                        <input type="checkbox" className='submission-block-checkboxes' name="step3" />
                        <p>
                          Change the setting.
                        </p>
                      </div>
                      <img loading="lazy" src="/styling/step3.png" alt="Description of the image" className='image-test-block' /><br />
                      <img loading="lazy" src="/styling/step4.png" alt="Description of the image" className='image-test-block' /><br />
                      <img loading="lazy" src="/styling/step5.png" alt="Description of the image" className='image-test-block' /><br />
                    </label>
                  </li>

                  <li>
                    <label>
                      <div className='instructions-with-label-img'>
                        <input type="checkbox" className='submission-block-checkboxes' name="step3" />
                        <p>
                          Click the button to copy the link, you will have to post that link in the section below.
                        </p>
                      </div>
                      <img loading="lazy" src="/styling/step6.png" alt="Description of the image" className='image-test-block' />
                    </label>
                  </li>

                </ol>

                <hr/>

              </div>
              
            </div>

            <div className='link-input-container'>
              <label>Select School:</label><br/>
              <input
                type="text"
                placeholder="Enter school name"
                value={searchTerm}
                onChange={handleSchoolInputChange}
              />
              {/* Render suggestions based on user input */}
              {suggestions.length > 0 && (
                <ul className='school-mention-list-ul'>
                  {suggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSuggestionClick(suggestion)}>{suggestion}</li>
                  ))}
                </ul>
              )}
            </div>

            <label>Group Name:</label><br/>
            <input
              type="text"
              placeholder="Enter Group Name"
              value={groupName}
              onChange={handleGroupNameChange}
            />
            {groupName.length > 50 && <p className="multimodal-error-message">Group Name cannot exceed 50 characters.</p>}

            <div className='link-input-container'>
              <label>Add Google Drive Link:</label><br/>
              <input
                type="text"
                placeholder="Enter Google Drive link"
                value={link}
                onChange={handleLinkChange}
              />
              {!isValidLink && <p className="multimodal-error-message">Please enter a valid Google Drive link.</p>}
            </div>

            <label>Add Group Members:</label><br/>
            <input
              type="text"
              placeholder="Type '@' to mention accounts"
              value={searchQuery}
              onChange={handleInputChange}
              ref={inputRef}
            />

            {mentionSuggestions.length > 0 && (
              <div className="mention-suggestions">
                <ul>
                  {mentionSuggestions.map((account, index) => (
                    <li key={index} onClick={() => handleMentionClick(account.accountName)}>
                      {account.accountName}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <button
              onClick={handleSubmit}
              className='multimodal-session-accounts-submit'
              disabled={isSubmitDisabled}
            >
              Submit Data
            </button>
          </div>
        </div>
      )}

      
      {/* {user?.accountName === 'SeanBaloyi5201' || user?.accountName === 'TerrenceLetsie6821' ? ( */}
      {(user?.status === 'Teacher' || user?.status === 'Admin') && (  
        <div>
          {currentSection === 'submissions' && (
            <div className='submissions-section'>
              <div className="search-bar-container">
                <input
                  type="text"
                  placeholder="Search by group name or member"
                  onChange={(e) => setSearchValue(e.target.value)}
                  className='submissions-searchbox'
                />
              </div>
              {submissions.length === 0 ? (
                <p>No submissions yet.</p>
              ) : (
                <div>
                  {submissions.map((submission, submissionIndex) => {
                    // Filter submissions based on search value
                    if (
                      submission.groupName.toLowerCase().includes(searchValue.toLowerCase()) ||
                      submission.mentions.some(mention => mention.toLowerCase().includes(searchValue.toLowerCase()))
                    ) {
                      const categoryScores = calculateCategoryScores(scores[submissionIndex] || []);
                      const totalScore = calculateTotalScore(submissionIndex);
                      
                      // Ensure scores[submissionIndex] is always an array with default values
                      const currentScores = scores[submissionIndex] || Array(20).fill(0);

                      return (
                        <React.Fragment key={submission._id}>
                          <div className='submission'>
                            <div>
                              <h3 className='submission-h3'>Submission by: {submission.groupName}</h3>
                              <div>
                                <p className='submission-mentions-tag'>Members:</p>
                                <p className='submission-mentions-list'>
                                  {submission.mentions[0].split(',').map((mention, index) => (
                                    <React.Fragment key={index}>
                                      {mention}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </p>
                              </div>
                              <a href={submission.link} target='_blank' rel='noopener noreferrer' className='soft-copy-link-tag'>
                                Access Soft Copy
                              </a>
                            </div>

                            <hr/>

                            <div className='range-inputs-container'>
                              <div className='category'>
                                <p><strong>Creativity and Originality:</strong> {categoryScores.creativeOriginality}</p>
                                <div className='range-input'>
                                  <label>Demonstrates originality and creativity in the use of multiple modes of communication.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[0]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 0, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[0]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Engages the audience effectively through innovative approaches.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[1]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 1, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[1]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Content Understanding:</strong> {categoryScores.contentGrasp}</p>
                                <div className='range-input'>
                                  <label>Shows a deep understanding of the content or topic being presented.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[2]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 2, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[2]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Presents information accurately and coherently across different modes.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[3]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 3, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[3]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Clarity and Organization:</strong> {categoryScores.clarity}</p>
                                <div className='range-input'>
                                  <label>Presents ideas in a clear and organized manner across different modes.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[4]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 4, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[4]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Uses transitions effectively to guide the audience through the presentation.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[5]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 5, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[5]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Use of Multimodal Elements:</strong> {categoryScores.multiElements}</p>
                                <div className='range-input'>
                                  <label>Integrates different modes of communication (text, images, audio, video) effectively to enhance understanding.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[6]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 6, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[6]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Demonstrates thoughtful selection and placement of multimodal elements to support the message.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[7]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 7, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[7]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Audience Engagement:</strong> {categoryScores.audienceEngage}</p>
                                <div className='range-input'>
                                  <label>Engages the audience effectively through the use of multimedia elements.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[8]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 8, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[8]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Captures and maintains the audience's interest throughout the presentation.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[9]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 9, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[9]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Technical Proficiency:</strong> {categoryScores.techUse}</p>
                                <div className='range-input'>
                                  <label>Demonstrates proficiency in the use of multimedia tools and software.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[10]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 10, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[10]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Shows attention to detail in the production of multimedia elements.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[11]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 11, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[11]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Creativity in Presentation:</strong> {categoryScores.presentation}</p>
                                <div className='range-input'>
                                  <label>Presents ideas in an engaging and creative manner.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[12]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 12, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[12]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Demonstrates originality in the presentation format and style.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[13]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 13, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[13]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Collaboration:</strong> {categoryScores.teamWork}</p>
                                <div className='range-input'>
                                  <label>Demonstrates effective collaboration and teamwork in the creation of multimodal projects.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[14]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 14, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[14]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Shows evidence of equitable contributions from all team members.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[15]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 15, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[15]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Reflection and Self-Assessment:</strong> {categoryScores.selfAssessment}</p>
                                <div className='range-input'>
                                  <label>Reflects on the strengths and weaknesses of the project.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[16]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 16, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[16]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Provides thoughtful self-assessment and identifies areas for improvement.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[17]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 17, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[17]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Overall Impact:</strong> {categoryScores.meanImpact}</p>
                                <div className='range-input'>
                                  <label>Evaluates the overall impact and effectiveness of the multimodal presentation.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[18]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 18, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[18]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Considers the extent to which the presentation achieves its intended purpose and communicates its message effectively.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[19]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 19, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[19]}</span>
                                </div>
                              </div>

                            </div>

                            <p><strong>Total Score: </strong>{totalScore}%</p>
                            <button 
                              onClick={() => handleUpdateScores(submissionIndex)} 
                              className='score-update-submit'
                            >
                              Update Scores
                            </button>

                          </div>
                        </React.Fragment>
                      );
                    } else {
                      // Return null if submission doesn't match search criteria
                      return null;
                    }
                  })}
                </div>
              )}

              <hr/>

              <div className='marked-list-container-ul-container'>
                <h1>Marked Submissions</h1>
                <ul className='marked-list-container-ul'>
                  {markedSubmissions.map((submission, index) => (
                    <li key={index} className='marked-list-blocks'>

                      <div className='marked-list-div-aligner'>
                        <label>
                          Group Name: 
                        </label>
                        <p className='marked-list-group-name'><strong>{submission.groupName}</strong></p>
                      </div>
                      
                      <div className='marked-list-div-aligner'>
                        <label>
                          Activity Name:
                        </label>
                        <p>{submission.activityName}</p>
                      </div>

                      <div className='marked-list-div-aligner'>
                        <label>
                          Members: 
                        </label>
                        <p className='submission-mentions-list'>
                          {submission.mentions[0].split(',').map((mention, index) => (
                            <React.Fragment key={index}>
                              {mention}
                              <br />
                            </React.Fragment>
                          ))}
                        </p>
                      </div>

                      <div className='marked-list-div-aligner'>
                        <label>
                          Link: 
                        </label><br/>
                        <a href={submission.link} target='_blank' rel='noopener noreferrer'>
                          Access Soft Copy
                        </a>
                      </div>

                      <hr/>

                      <div className='page-detailed-setup'>

                        <div className='marked-moda-left-div'>
                          <div>
                            <label>
                              Creativity and Originality
                            </label>
                          </div>

                          <div>
                            <label>
                              Content Understanding
                            </label>
                          </div>

                          <div>
                            <label>
                              Clarity and Organization
                            </label>
                          </div>

                          <div>
                            <label>
                              Use of Multimodal Elements
                            </label>
                          </div>

                          <div>
                            <label>
                              Audience Engagement
                            </label>
                          </div>

                          <div>
                            <label>
                              Technical Proficiency
                            </label>
                          </div>

                          <div>
                            <label>
                              Creativity in Presentation
                            </label>
                          </div>

                          <div>
                            <label>
                              Collaboration
                            </label>
                          </div>

                          <div>
                            <label>
                              Overall Impact
                            </label>
                          </div>

                          <div>
                            <label>
                              Reflection and Self-Assessment 
                            </label>
                          </div>

                        </div>

                        <div className='marked-moda-right-div'>
                          <p>{submission.creativeOriginality} /10</p>
                          <p>{submission.contentGrasp} /10</p>
                          <p>{submission.clarity} /10</p>
                          <p>{submission.multiElements} /10</p>
                          <p>{submission.audienceEngage} /10</p>
                          <p>{submission.techUse} /10</p>
                          <p>{submission.presentation} /10</p>
                          <p>{submission.teamWork} /10</p>
                          <p>{submission.meanImpact} /10</p>
                          <p>{submission.selfAssessment} /10</p>
                        </div>

                      </div>

                      <hr/>

                      <div className='marked-list-div-aligner-score-total'>
                        <label>
                          Total Score: 
                        </label>
                        <p>{submission.total}%</p>
                      </div>                  
                      
                    </li>
                  ))}
                </ul>
              </div>

            </div>
          )} 
        </div>
      )}

    </div>
  );
};

export default GradeFiveSocialSciencesSessionTwo;



/*
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const GradeFiveSocialSciencesSessionTwo = () => {
  const [user, setUser] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [mentions, setMentions] = useState([]);
  const [link, setLink] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [currentSection, setCurrentSection] = useState('task');
  const [submissions, setSubmissions] = useState([]);
  const [markedSubmissions, setMarkedSubmissions] = useState([]);
  const [scores, setScores] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [schoolNames, setSchoolNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const activityName = 'grade5_social-sciences_session-2';
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login');
      }
    };

    const fetchAccounts = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/multimodal-learning/accounts', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          const filteredAccounts = data.accounts.filter(
            (account) => account.status !== 'Admin' && !account.adminValidation
          );
          setAccounts(filteredAccounts);
        } else {
          console.error('Error fetching accounts');
        }
      } catch (error) {
        console.error('Error fetching accounts:', error.message);
      }
    };

    fetchUserData();
    fetchAccounts();
  }, [navigate]);
 
  useEffect(() => {
    if (currentSection === 'submissions') {
      const fetchSubmissions = async () => {
        try {
          const response = await fetch(`https://lernacy.co.za/api/submissions?activityName=${activityName}`, {
            method: 'GET',
            credentials: 'include',
          });

          if (response.ok) {
            const data = await response.json();
            setSubmissions(data.submissions);
            const initialScores = data.submissions.reduce((acc, submission, index) => {
              acc[index] = Array(20).fill(0);
              return acc;
            }, {});
            setScores(initialScores);
          } else {
            console.error('Error fetching submissions');
          }
        } catch (error) {
          console.error('Error fetching submissions:', error.message);
        }
      };

      fetchSubmissions();
    }
  }, [currentSection]);

  useEffect(() => {
    const fetchMarkedSubmissions = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/marked/submissions?marked=true', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setMarkedSubmissions(data.submissions);
        } else {
          console.error('Error fetching marked submissions');
        }
      } catch (error) {
        console.error('Error fetching marked submissions:', error.message);
      }
    };
  
    fetchMarkedSubmissions();
  }, []);

  useEffect(() => {
    const fetchSchoolNames = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/institutions/schools/names');
        setSchoolNames(response.data.schoolNames);
      } catch (error) {
        console.error('Error fetching school names:', error);
      }
    };

    fetchSchoolNames();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  
    const mentionsCount = value.split(' ').filter(word => word.startsWith('@')).length;
  
    if (mentionsCount > 2) {
      e.preventDefault();
      inputRef.current.disabled = true;
      alert('You can only mention up to 2 account names.');
      return;
    }
  
    if (value.includes('@')) {
      const query = value.split('@').pop();
      if (query) {
        const filteredSuggestions = accounts.filter((account) => {
          return (
            account &&
            account.accountName &&
            account.accountName.toLowerCase().includes(query.toLowerCase()) &&
            !mentions.includes(account.accountName)
          );
        });
        setMentionSuggestions(filteredSuggestions);
      } else {
        setMentionSuggestions([]);
      }
    } else {
      setMentionSuggestions([]);
    }
  };

  const handleMentionClick = (accountName) => {
    setMentions([...mentions, accountName]);
    setSearchQuery(searchQuery.replace(/@\w*$/, `@${accountName} `));
    inputRef.current.focus();
    setMentionSuggestions([]);
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const handleSectionChange = (section) => {
    setCurrentSection(section); 
  };

  const filteredSubmissions = submissions.filter(submission => {
    // Logic to filter submissions based on searchValue
    return (
      submission.groupName.toLowerCase().includes(searchValue.toLowerCase()) ||
      submission.mentions.some(mention => mention.toLowerCase().includes(searchValue.toLowerCase()))
    );
  });

  const handleSubmit = async () => {
    if (!user || !isValidLink || groupName.trim() === '' || searchTerm.trim() === '') {
      return;
    }
  
    try {
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include', // Ensure credentials are included
      });
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      const payload = {
        userId: user._id,
        activityName,
        mentions,
        link,
        groupName,
        schoolName: searchTerm, // Add searchTerm to the payload
      };
  
      // Log the payload for debugging
      //console.log('Payload being sent to the server:', payload);
  
      const response = await fetch('https://lernacy.co.za/api/submit-mentions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        credentials: 'include', // Ensure credentials are included
        body: JSON.stringify(payload),
      });
  
      const responseData = await response.json();
      if (response.ok) {
        alert('Mentions submitted successfully!');
      } else {
        console.error('Error submitting mentions:', responseData.message);
        alert('Error submitting mentions: ' + responseData.message);
      }
    } catch (error) {
      console.error('Error submitting mentions:', error.message);
      alert('Error submitting mentions');
    }
  };   

  const validateLink = (link) => {
    const regex = /^https:\/\/(drive|docs)\.google\.com\/(file\/d\/[^/]+\/(view|edit)\?usp=sharing|document\/d\/[^/]+\/edit\?usp=sharing)$/;
    return regex.test(link);
  };

  const handleLinkChange = (e) => {
    const value = e.target.value;
    setLink(value);
    setIsValidLink(validateLink(value));
  };

  const handleSchoolInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    // Filter school names based on the input value
    const filteredSuggestions = schoolNames.filter((name) =>
      name.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (value) => {
    setSearchTerm(value); // Set the input value to the selected suggestion
    setSuggestions([]); // Clear the suggestions
  };


  //const isSubmitDisabled = searchQuery.length < 4 || !isValidLink || !link;
  
  // Update this useEffect to include the school input validation
  useEffect(() => {
    // Check if any of the required fields are empty
    if (searchQuery.trim() === '' || !isValidLink || groupName.trim() === '' || searchTerm.trim() === '') {
      setIsSubmitDisabled(true); // Disable the button if any required field is empty
    } else {
      setIsSubmitDisabled(false); // Enable the button if all required fields are filled
    }
  }, [searchQuery, isValidLink, groupName, searchTerm]);

  const handleScoreChange = (submissionIndex, scoreIndex, value) => {
    const newScores = { ...scores }; // Copying the scores object
    newScores[submissionIndex] = [...scores[submissionIndex]]; // Copying the array for the specific submission
    newScores[submissionIndex][scoreIndex] = value; // Updating the specific score
    setScores(newScores); // Updating the state
  };

  const calculateCategoryScores = (submissionScores) => {
    return {
      creativeOriginality: submissionScores[0] + submissionScores[1],
      contentGrasp: submissionScores[2] + submissionScores[3],
      clarity: submissionScores[4] + submissionScores[5],
      multiElements: submissionScores[6] + submissionScores[7],
      audienceEngage: submissionScores[8] + submissionScores[9],
      techUse: submissionScores[10] + submissionScores[11],
      presentation: submissionScores[12] + submissionScores[13],
      teamWork: submissionScores[14] + submissionScores[15],
      selfAssessment: submissionScores[16] + submissionScores[17],
      meanImpact: submissionScores[18] + submissionScores[19],
    };
  };

  const calculateTotalScore = (submissionIndex) => {
    const categoryScores = calculateCategoryScores(scores[submissionIndex]);
    return Object.values(categoryScores).reduce((a, b) => a + b, 0);
  };

  const handleUpdateScores = async (submissionIndex) => {
    if (!user || !user.accountName) {
      console.error('User account name not found');
      return;
    }
  
    const submission = submissions[submissionIndex];
    const categoryScores = calculateCategoryScores(scores[submissionIndex]);
    const totalScore = calculateTotalScore(submissionIndex);
  
    const payload = {
      creativeOriginality: categoryScores.creativeOriginality,
      contentGrasp: categoryScores.contentGrasp,
      clarity: categoryScores.clarity,
      multiElements: categoryScores.multiElements,
      audienceEngage: categoryScores.audienceEngage,
      techUse: categoryScores.techUse,
      presentation: categoryScores.presentation,
      teamWork: categoryScores.teamWork,
      selfAssessment: categoryScores.selfAssessment,
      meanImpact: categoryScores.meanImpact,
      total: totalScore,
      accountName: user.accountName,  // Add accountName here
    };
  
    try {
      // Get CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to retrieve CSRF token');
      }
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      // Update scores with CSRF token
      const updateResponse = await fetch(`https://lernacy.co.za/api/submissions/${submission._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,  // Include the CSRF token in the request headers
        },
        credentials: 'include',
        body: JSON.stringify(payload),
      });
  
      if (updateResponse.ok) {
        // Reset all range selectors to 0
        const initialScores = Array(submissions.length).fill().map(() => Array(20).fill(0));
        setScores(initialScores);
  
        // If PUT request is successful, make a GET request to update page data
        const getResponse = await fetch(`https://lernacy.co.za/api/submissions?activityName=${activityName}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken,  // Include the CSRF token in the request headers
          },
          credentials: 'include',
        });
  
        if (getResponse.ok) {
          const newData = await getResponse.json();
          // Update your state or page data with the new data
          setSubmissions(newData.submissions);
  
          alert('Scores updated successfully!');
        } else {
          console.error('Error updating page data after PUT request');
          alert('Error updating page data after PUT request');
        }
      } else {
        console.error('Error updating scores');
        alert('Error updating scores');
      }
    } catch (error) {
      console.error('Error updating scores:', error.message);
      alert('Error updating scores');
    }
  };

  return (
    <div className='multimodal-page-container'>
      <h1 className='page-title'>Grade 5 Social Sciences<br /> Session 2</h1>

       {(user?.accountName === 'TerrenceLetsie6821' || user?.accountName === 'MeganLyon6223') && ( 
      {(user?.status === 'Teacher' || user?.status === 'Admin') && (
        <div className='multimodal-education-teacher-menu'>
          <button
            className={`multimodal-education-teacher-menu-button ${currentSection === 'task' ? 'active' : ''}`}
            onClick={() => handleSectionChange('task')}
          >
            <div>
              Task
            </div>
          </button>
          <button
            className={`multimodal-education-teacher-menu-button ${currentSection === 'submissions' ? 'active' : ''}`}
            onClick={() => handleSectionChange('submissions')}
          >
            <div>
              Submissions
            </div>
          </button>
        </div>
      )}
      <br />

      
      {currentSection === 'task' && (
        <div className='multimodal-blocks-aligner'>
          <div className='multimodal-task-and-objectives'>
            <div>
              <h2 className='task-name-label'>Task Name:</h2>
              <h2 className='task-name-tag'>Natural Vegetation and Climate</h2>
              <hr />
              <h3>Objective</h3>
              <p>
                Students will explore the concept of natural vegetation, understand the relationship 
                between natural vegetation and climate, and examine the Savannah grasslands in South 
                Africa. They will create maps and presentations to demonstrate their understanding.
              </p>
            </div>
          </div>
          
          <div className='multimodal-activity-blocks'>
            <h2>Exploring Savannah Grasslands</h2>
            <label className='multimodal-activity-material-tag'>Materials:</label>
            <ul className='multimodal-activity-blocks-ul'>
              <li>
                <label>
                  <input type="checkbox" name="paper" value="sheets" />
                  Large paper sheets or poster boards
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" name="markers" value="crayons" />
                  Markers, crayons, colored pencils
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" name="stickers" value="images" />
                  Internet access or library resources for research
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" name="maps" value="access" />
                  Printed maps of South Africa (outline maps)
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" name="maps" value="access" />
                  Glue and scissors
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" name="maps" value="access" />
                  Presentation tools (computer, projector, or chart paper)
                </label>
              </li>
            </ul>
          </div>

          <div className='multimodal-activity-blocks'>
            <label className='multimodal-activity-material-tag'>Map Creation:</label>
            <p>
              Students should be divided into small groups and be in possession of necessary materials for the task.
            </p>
            <p className='ul-list-support-text'>
              <strong>Groups will:</strong>
            </p>
            <ul className='multimodal-activity-blocks-ul-bullet'>
              <li>Mark the location of the Savannah grasslands on their maps.</li>
              <li>Illustrate the types of vegetation found in this area with drawings or pictures.</li>
              <li>Indicate the climate characteristics using symbols or color codes.</li>
              <li>Add images or drawings of typical wildlife and explain their relationship with the vegetation.</li>
              <li>Discuss different types of natural vegetation and their importance.</li>
              <li>Explain how climate influences the types of vegetation found in different regions.</li>
              <li>Provide examples of plants and their adaptations to various climates in South Africa (e.g., fynbos in the Western Cape, aloes in the Karoo).</li>
              <li>Discuss the wildlife commonly found in savannah grasslands (e.g., lions, elephants, giraffes) and their reliance on the vegetation.</li>
              <li>Natural features (rivers, lakes, forests, etc.)</li>
            </ul>
          </div>

          <div className='multimodal-activity-blocks'>
            <label className='multimodal-activity-material-tag'>Presentation and Discussion:</label>
            <p>
              Each group presents their map and findings to the class.
            </p>
            <p><strong>Presentations should cover:</strong></p>
            <ul className='multimodal-activity-blocks-ul-bullet'>
              <li>The location and extent of the Savannah grasslands.</li>
              <li>Key climate characteristics and their impact on vegetation.</li>
              <li>Examples of plants and their adaptations.</li>
              <li>Wildlife found in the region and their dependency on the natural vegetation.</li>
            </ul>

            {(user?.status === 'Teacher' || user?.status === 'Admin') && (
              <div>
                <p style={{color:'red'}}>Teacher Access only!!!</p>

                <div>
                  <label>After presentations, facilitate a class discussion on:</label>
                  <ul className='multimodal-activity-blocks-ul-bullet'>
                    <li>How different climates across South Africa lead to diverse vegetation types.</li>
                    <li>The importance of natural vegetation in supporting wildlife.</li>
                    <li>The role of climate in shaping ecosystems.</li>
                    <li>Students can share their thoughts on how changes in climate might affect these ecosystems in the future.</li>
                  </ul>
                </div>

                <label style={{color:'red'}}>
                  ***
                </label>
              </div>
            )}

          </div>

          <div className='mention-input-container'>

            <div className='submit-multimodal-block'>
              <h2>Data Submission</h2>
              <div>
                <p>
                  Now that you have completed the task, it's time to submit proof of your work 
                  online and to your teacher. Please ensure all stages of the task are completed 
                  before submitting. Follow the steps below to submit correctly.
                </p>

                <ol className='submission-block-ol'>

                  <li>
                    <label className='submission-block-flex'>
                      <input type="checkbox" className='submission-block-checkboxes' name="step1" />
                      <p>
                        Scan your task parts in order, including the paragraphs you wrote. You can use 
                        apps like 'Adobe Scanner' to make this process easier.
                      </p>
                    </label>
                  </li>

                  <li>
                    <label className='submission-block-flex'>
                      <input type="checkbox" className='submission-block-checkboxes' name="step2" />
                      <p>
                        Combine all the scanned pages into a single PDF file.
                      </p>
                    </label>
                  </li>

                  <li>
                    <label className='submission-block-flex'>
                      <input type="checkbox" className='submission-block-checkboxes' name="step3" />
                      <p>
                        Upload the scanned PDF to your Google Drive.
                      </p>
                    </label>
                  </li>

                  <div className='submission-block-more-instructions-box'>
                    <label className='multimodal-activity-material-tag'>After uploading follow these important steps</label>
                    <p className='submission-block-more-instructions'>In your google drive, locate the file you uploaded and do the following:</p>
                  </div>

                  <li>
                    <label>
                      <div className='instructions-with-label-img'>
                        <input type="checkbox" className='submission-block-checkboxes' name="step3" />
                        <p>
                          Click the three dots to reveal a menu.
                        </p>
                      </div>
                      <img src="/styling/step1.png" alt="Description of the image" className='image-test-block' />
                    </label>
                  </li>

                  <li>
                    <label>
                      <div className='instructions-with-label-img'>
                        <input type="checkbox" className='submission-block-checkboxes' name="step3" />
                        <p>
                          Click manage access to make your document accessible.
                        </p>
                      </div>
                      <img src="/styling/step2.png" alt="Description of the image" className='image-test-block' />
                    </label>
                  </li>

                  <li>
                    <label>
                      <div className='instructions-with-label-img'>
                        <input type="checkbox" className='submission-block-checkboxes' name="step3" />
                        <p>
                          Change the setting.
                        </p>
                      </div>
                      <img src="/styling/step3.png" alt="Description of the image" className='image-test-block' /><br />
                      <img src="/styling/step4.png" alt="Description of the image" className='image-test-block' /><br />
                      <img src="/styling/step5.png" alt="Description of the image" className='image-test-block' /><br />
                    </label>
                  </li>

                  <li>
                    <label>
                      <div className='instructions-with-label-img'>
                        <input type="checkbox" className='submission-block-checkboxes' name="step3" />
                        <p>
                          Click the button to copy the link, you will have to post that link in the section below.
                        </p>
                      </div>
                      <img src="/styling/step6.png" alt="Description of the image" className='image-test-block' />
                    </label>
                  </li>

                </ol>

                <hr/>

              </div>
              
            </div>

            <div className='link-input-container'>
              <label>Select School:</label><br/>
              <input
                type="text"
                placeholder="Enter school name"
                value={searchTerm}
                onChange={handleSchoolInputChange}
              />
              
              {suggestions.length > 0 && (
                <ul className='school-mention-list-ul'>
                  {suggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSuggestionClick(suggestion)}>{suggestion}</li>
                  ))}
                </ul>
              )}
            </div>

            <label>Group Name:</label><br/>
            <input
              type="text"
              placeholder="Enter Group Name"
              value={groupName}
              onChange={handleGroupNameChange}
            />
            {groupName.length > 50 && <p className="multimodal-error-message">Group Name cannot exceed 50 characters.</p>}

            <div className='link-input-container'>
              <label>Add Google Drive Link:</label><br/>
              <input
                type="text"
                placeholder="Enter Google Drive link"
                value={link}
                onChange={handleLinkChange}
              />
              {!isValidLink && <p className="multimodal-error-message">Please enter a valid Google Drive link.</p>}
            </div>

            <label>Add Group Members:</label><br/>
            <input
              type="text"
              placeholder="Type '@' to mention accounts"
              value={searchQuery}
              onChange={handleInputChange}
              ref={inputRef}
            />

            {mentionSuggestions.length > 0 && (
              <div className="mention-suggestions">
                <ul>
                  {mentionSuggestions.map((account, index) => (
                    <li key={index} onClick={() => handleMentionClick(account.accountName)}>
                      {account.accountName}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <button
              onClick={handleSubmit}
              className='multimodal-session-accounts-submit'
              disabled={isSubmitDisabled}
            >
              Submit Data
            </button>
          </div>
        </div>
      )}

      
       {user?.accountName === 'SeanBaloyi5201' || user?.accountName === 'TerrenceLetsie6821' ? ( 
      {(user?.status === 'Teacher' || user?.status === 'Admin') && (  
        <div>
          {currentSection === 'submissions' && (
            <div className='submissions-section'>
              <div className="search-bar-container">
                <input
                  type="text"
                  placeholder="Search by group name or member"
                  onChange={(e) => setSearchValue(e.target.value)}
                  className='submissions-searchbox'
                />
              </div>
              {submissions.length === 0 ? (
                <p>No submissions yet.</p>
              ) : (
                <div>
                  {submissions.map((submission, submissionIndex) => {
                    // Filter submissions based on search value
                    if (
                      submission.groupName.toLowerCase().includes(searchValue.toLowerCase()) ||
                      submission.mentions.some(mention => mention.toLowerCase().includes(searchValue.toLowerCase()))
                    ) {
                      const categoryScores = calculateCategoryScores(scores[submissionIndex] || []);
                      const totalScore = calculateTotalScore(submissionIndex);
                      
                      // Ensure scores[submissionIndex] is always an array with default values
                      const currentScores = scores[submissionIndex] || Array(20).fill(0);

                      return (
                        <React.Fragment key={submission._id}>
                          <div className='submission'>
                            <div>
                              <h3 className='submission-h3'>Submission by: {submission.groupName}</h3>
                              <div>
                                <p className='submission-mentions-tag'>Members:</p>
                                <p className='submission-mentions-list'>
                                  {submission.mentions[0].split(',').map((mention, index) => (
                                    <React.Fragment key={index}>
                                      {mention}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </p>
                              </div>
                              <a href={submission.link} target='_blank' rel='noopener noreferrer' className='soft-copy-link-tag'>
                                Access Soft Copy
                              </a>
                            </div>

                            <hr/>

                            <div className='range-inputs-container'>
                              <div className='category'>
                                <p><strong>Creativity and Originality:</strong> {categoryScores.creativeOriginality}</p>
                                <div className='range-input'>
                                  <label>Demonstrates originality and creativity in the use of multiple modes of communication.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[0]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 0, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[0]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Engages the audience effectively through innovative approaches.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[1]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 1, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[1]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Content Understanding:</strong> {categoryScores.contentGrasp}</p>
                                <div className='range-input'>
                                  <label>Shows a deep understanding of the content or topic being presented.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[2]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 2, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[2]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Presents information accurately and coherently across different modes.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[3]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 3, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[3]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Clarity and Organization:</strong> {categoryScores.clarity}</p>
                                <div className='range-input'>
                                  <label>Presents ideas in a clear and organized manner across different modes.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[4]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 4, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[4]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Uses transitions effectively to guide the audience through the presentation.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[5]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 5, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[5]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Use of Multimodal Elements:</strong> {categoryScores.multiElements}</p>
                                <div className='range-input'>
                                  <label>Integrates different modes of communication (text, images, audio, video) effectively to enhance understanding.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[6]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 6, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[6]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Demonstrates thoughtful selection and placement of multimodal elements to support the message.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[7]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 7, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[7]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Audience Engagement:</strong> {categoryScores.audienceEngage}</p>
                                <div className='range-input'>
                                  <label>Engages the audience effectively through the use of multimedia elements.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[8]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 8, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[8]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Captures and maintains the audience's interest throughout the presentation.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[9]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 9, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[9]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Technical Proficiency:</strong> {categoryScores.techUse}</p>
                                <div className='range-input'>
                                  <label>Demonstrates proficiency in the use of multimedia tools and software.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[10]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 10, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[10]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Shows attention to detail in the production of multimedia elements.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[11]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 11, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[11]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Creativity in Presentation:</strong> {categoryScores.presentation}</p>
                                <div className='range-input'>
                                  <label>Presents ideas in an engaging and creative manner.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[12]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 12, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[12]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Demonstrates originality in the presentation format and style.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[13]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 13, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[13]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Collaboration:</strong> {categoryScores.teamWork}</p>
                                <div className='range-input'>
                                  <label>Demonstrates effective collaboration and teamwork in the creation of multimodal projects.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[14]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 14, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[14]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Shows evidence of equitable contributions from all team members.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[15]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 15, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[15]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Reflection and Self-Assessment:</strong> {categoryScores.selfAssessment}</p>
                                <div className='range-input'>
                                  <label>Reflects on the strengths and weaknesses of the project.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[16]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 16, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[16]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Provides thoughtful self-assessment and identifies areas for improvement.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[17]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 17, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[17]}</span>
                                </div>
                              </div>

                              <div className='category'>
                                <p><strong>Overall Impact:</strong> {categoryScores.meanImpact}</p>
                                <div className='range-input'>
                                  <label>Evaluates the overall impact and effectiveness of the multimodal presentation.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[18]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 18, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[18]}</span>
                                </div>
                                <div className='range-input'>
                                  <label>Considers the extent to which the presentation achieves its intended purpose and communicates its message effectively.</label><br/>
                                  <input
                                    type='range'
                                    min='0'
                                    max='5'
                                    value={currentScores[19]}
                                    onChange={(e) =>
                                      handleScoreChange(submissionIndex, 19, parseInt(e.target.value, 10))
                                    }
                                  />
                                  <span>{currentScores[19]}</span>
                                </div>
                              </div>

                            </div>

                            <p><strong>Total Score: </strong>{totalScore}%</p>
                            <button 
                              onClick={() => handleUpdateScores(submissionIndex)} 
                              className='score-update-submit'
                            >
                              Update Scores
                            </button>

                          </div>
                        </React.Fragment>
                      );
                    } else {
                      // Return null if submission doesn't match search criteria
                      return null;
                    }
                  })}
                </div>
              )}

              <hr/>

              <div className='marked-list-container-ul-container'>
                <h1>Marked Submissions</h1>
                <ul className='marked-list-container-ul'>
                  {markedSubmissions.map((submission, index) => (
                    <li key={index} className='marked-list-blocks'>

                      <div className='marked-list-div-aligner'>
                        <label>
                          Group Name: 
                        </label>
                        <p className='marked-list-group-name'><strong>{submission.groupName}</strong></p>
                      </div>
                      
                      <div className='marked-list-div-aligner'>
                        <label>
                          Activity Name:
                        </label>
                        <p>{submission.activityName}</p>
                      </div>

                      <div className='marked-list-div-aligner'>
                        <label>
                          Members: 
                        </label>
                        <p className='submission-mentions-list'>
                          {submission.mentions[0].split(',').map((mention, index) => (
                            <React.Fragment key={index}>
                              {mention}
                              <br />
                            </React.Fragment>
                          ))}
                        </p>
                      </div>

                      <div className='marked-list-div-aligner'>
                        <label>
                          Link: 
                        </label><br/>
                        <a href={submission.link} target='_blank' rel='noopener noreferrer'>
                          Access Soft Copy
                        </a>
                      </div>

                      <hr/>

                      <div className='page-detailed-setup'>

                        <div className='marked-moda-left-div'>
                          <div>
                            <label>
                              Creativity and Originality
                            </label>
                          </div>

                          <div>
                            <label>
                              Content Understanding
                            </label>
                          </div>

                          <div>
                            <label>
                              Clarity and Organization
                            </label>
                          </div>

                          <div>
                            <label>
                              Use of Multimodal Elements
                            </label>
                          </div>

                          <div>
                            <label>
                              Audience Engagement
                            </label>
                          </div>

                          <div>
                            <label>
                              Technical Proficiency
                            </label>
                          </div>

                          <div>
                            <label>
                              Creativity in Presentation
                            </label>
                          </div>

                          <div>
                            <label>
                              Collaboration
                            </label>
                          </div>

                          <div>
                            <label>
                              Overall Impact
                            </label>
                          </div>

                          <div>
                            <label>
                              Reflection and Self-Assessment 
                            </label>
                          </div>

                        </div>

                        <div className='marked-moda-right-div'>
                          <p>{submission.creativeOriginality} /10</p>
                          <p>{submission.contentGrasp} /10</p>
                          <p>{submission.clarity} /10</p>
                          <p>{submission.multiElements} /10</p>
                          <p>{submission.audienceEngage} /10</p>
                          <p>{submission.techUse} /10</p>
                          <p>{submission.presentation} /10</p>
                          <p>{submission.teamWork} /10</p>
                          <p>{submission.meanImpact} /10</p>
                          <p>{submission.selfAssessment} /10</p>
                        </div>

                      </div>

                      <hr/>

                      <div className='marked-list-div-aligner-score-total'>
                        <label>
                          Total Score: 
                        </label>
                        <p>{submission.total}%</p>
                      </div>                  
                      
                    </li>
                  ))}
                </ul>
              </div>

            </div>
          )} 
        </div>
      )}

    </div>
  );
};

export default GradeFiveSocialSciencesSessionTwo;
*/

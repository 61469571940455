import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './pages-styling/pages.css';

const ApplicationPractice = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include', // Include credentials for cross-origin requests
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          // Check if user has an account name
          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);

          // Redirect to login if not authenticated
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);

        // Redirect to login on error
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  return (
    <div className='page-colour-manager'>
      <h1 className='activity-name-tag-h1'>Application Practice</h1>

      {/* 
      {user ? (
        <p>Welcome, {user.name} {user.surname}!</p>
      ) : (
        <p>Loading user data...</p>
      )}
      */}

      {/* Link to '/English' with a query parameter */}
      <div className='meant-to-align'>
        <div className='align-container'>
          <Link /*to="/pre-school?ref=application-practice"*/ className='links'>
            <div className='links-div-block'>
              <strong>Pre-School</strong>
              <br></br>
              Age 4 to 6 yrs.
            </div>
          </Link>

          <Link to="/foundation-phase?ref=application-practice" className='links'>
            <div className='links-div-block'>
              <strong>Foundation phase</strong>
              <br></br>
              Grade R to 3.
            </div>
          </Link>

          <Link to="/intermediate-phase?ref=application-practice" className='links'>
            <div className='links-div-block'>
              <strong>Intermediate phase</strong>
              <br></br>
              Grade 4 to 6.
            </div>
          </Link>

          <Link /*to="/senior-phase?ref=application-practice"*/ className='links'>
            <div className='links-div-block'>
              <strong>Senior phase</strong>
              <br></br>
              Grade 7 to 9.
            </div>
          </Link>

          <Link /*to="/fet-phase?ref=application-practice"*/ className='links'>
            <div className='links-div-block'>
              <strong>Further Education and Training phase</strong>
              <br></br>
              Grade 10 to 12.
            </div>
          </Link>
        </div>
      </div>

    </div>
  );
};

export default ApplicationPractice;

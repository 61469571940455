const socialSciencesQuestionsData = [

    {
        id: 1,
        question: 'What is the capital city of Gauteng?',
        options: ['Paris', 'London', 'Berlin', 'Rome'],
        correctAnswer: 'Paris',
    },
    {
        id: 2,
        question: 'What is the tallest mountain in the world?',
        options: ['Mount Everest', 'K2', 'Kangchenjunga', 'Lhotse'],
        correctAnswer: 'Mount Everest',
    },
    {
        id: 3,
        question: 'What type of infrastructure is shown in the picture below?',
        options: ['House', 'Road', 'Bridge', 'River'],
        correctAnswer: 'Bridge',
        imageUrl: '/activityImages/SocialSciences/bridge.png' 
    },
    {
        id: 4,
        question: 'Which of these places would have the most shops?',
        options: ['Village', 'Town', 'City', 'Farm'],
        correctAnswer: 'City',
    },
    {
        id: 5,
        question: 'What kind of building would you find in a town that helps people if they get sick?',
        options: ['School', 'Clinic', 'Factory', 'Fuel Garage'],
        correctAnswer: 'Clinic',
    },
    {
        id: 6,
        question: 'Table Mountain is a famous landmark in Cape Town.  What kind of landmark is it?',
        options: ['Man-made', 'Natural', 'Built for work', 'Used for travel'],
        correctAnswer: 'Natural',
    },
    {
        id: 7,
        question: "Which of these is NOT a basic need",
        options: ["Water", "Food", "Shelter", "Expensive Toys"],
        correctAnswer: "Expensive Toys"
    },
    {
        id: 8,
        question: "What is the main purpose of a footpath in a town?",
        options: ["Parking cars", "Walking", "Playing sports", "Storing rubbish"],
        correctAnswer: "Walking"
    },
    {
        id: 9,
        question: "Which of these buildings would you find in most South African cities that you would not find in a small village?",
        options: ["House", "Shop", "Bank", "School"],
        correctAnswer: "Bank"
    },
    {
        id: 10,
        question: "What is the difference between living in a city and a farm in South Africa?",
        options: ["Transportation", "Types of food available", "Weather", "Language spoken"],
        correctAnswer: "Transportation"
    },
    {
        id: 11,
        question: "How do people in South Africa use electricity in their homes?",
        options: ["For decoration only", "For cooking and heating", "To grow plants", "To transport goods"],
        correctAnswer: "For cooking and heating"
    },
    {
        id: 12,
        question: "Which of the following is NOT a type of settlement?",
        options: ["Farm", "River", "Town", "City"],
        correctAnswer: "River"
    },
    {
        id: 13,
        question: "What type of building is mostly found in a city and provides medical assistance to people?",
        options: ["School", "Factory", "Clinic", "Bank"],
        correctAnswer: "Clinic"
    },
    {
        id: 14,
        question: "Which of the following is a human-made landmark?",
        options: ["Mountain", "River", "Dam", "Statue of Nelson Mandela"],
        correctAnswer: "Statue of Nelson Mandela"
    },
    {
        id: 15,
        question: "Which of the following is a natural landmark?",
        options: ["School", "River", "Bank", "Library"],
        correctAnswer: "Statue of Nelson Mandela"
    },
    {
        id: 16,
        question: "What type of building is used for worship in different places?",
        options: ["Factory", "School", "Place of worship", "Train Station"],
        correctAnswer: "Place of worship"
    },
    {
        id: 17,
        question: "What is a basic need that people need to live?",
        options: ["Internet", "Television", "Water", "Video Games"],
        correctAnswer: "Water"
    },
    {
        id: 18,
        question: "What type of building is used for learning and education?",
        options: ["Hospital", "Factory", "School", "Supermarket"],
        correctAnswer: "School"
    },
    {
        id: 19,
        question: "Which of the following is a common type of settlement in rural areas?",
        options: ["Farm", "City", "Factory", "Airport"],
        correctAnswer: "Farm"
    },
    {
        id: 20,
        question: "What type of building is used for storing and selling goods in a town?",
        options: ["Bank", "School", "Library", "Shop"],
        correctAnswer: "Shop"
    },
    {
        id: 21,
        question: "What is the primary purpose of a bank?",
        options: ["Healthcare", "Education", "Money transactions", "Entertainment"],
        correctAnswer: "Money transactions"
    },
    {
        id: 22,
        question: "Which of the following is a source of renewable energy?",
        options: ["Coal", "Oil", "Solar power", "Natural gas"],
        correctAnswer: "Solar power"
    },
    {
        id: 23,
        question: "What is the main purpose of a factory?",
        options: ["Entertainment", "Manufacturing", "Education", "Healthcare"],
        correctAnswer: "Manufacturing"
    },
    {
        id: 24,
        question: "What is a common mode of transportation used in cities?",
        options: ["Spaceship", "Bicycle", "Car", "Horse"],
        correctAnswer: "Car"
    },
    {
        id: 25,
        question: "What is a common feature of rural landscapes?",
        options: ["Skyscrapers", "Fields", "Subways", "Traffic lights"],
        correctAnswer: "Fields"
    },
    {
        id: 26,
        question: "What is the primary function of a hospital?",
        options: ["Manufacturing", "Entertainment", "Healthcare", "Education"],
        correctAnswer: "Healthcare"
    },
    {
        id: 27,
        question: "What type of building is used for housing animals on a farm?",
        options: ["Factory", "School", "Barn", "Museum"],
        correctAnswer: "Barn"
    },
    {
        id: 28,
        question: "What is a common form of energy used for cooking in households?",
        options: ["Electricity", "Water", "Wind", "Firewood"],
        correctAnswer: "Electricity"
    },
    {
        id: 29,
        question: "What type of building provides financial services to individuals and businesses?",
        options: ["Mall", "Hospital", "Bank", "Zoo"],
        correctAnswer: "Bank"
    },    
    {
        id: 30,
        question: "Which of the following is a common mode of transportation in a town?",
        options: ["Subway", "Bus", "Car", "Taxi"],
        correctAnswer: "Car"
    },    
    {
        id: 31,
        question: "What is the main purpose of a library?",
        options: ["Selling groceries", "Borrowing books", "Repairing cars", "Performing surgeries"],
        correctAnswer: "Borrowing books"
    },    
    {
        id: 32,
        question: "What is a common feature of a city skyline?",
        options: ["Mountains", "Skyscrapers", "Trees", "Desert"],
        correctAnswer: "Skyscrapers"
    },    
    {
        id: 33,
        question: "What type of building provides education and learning opportunities to children?",
        options: ["Hospital", "Factory", "School", "Hotel"],
        correctAnswer: "School"
    },    
    {
        id: 34,
        question: "What is a common form of renewable energy coming from moving air?",
        options: ["Coal", "Oil", "Wind power", "Natural gas"],
        correctAnswer: "Wind power"
    },    
    {
        id: 35,
        question: "Which of the following is a type of man-made waterway used for transportation?",
        options: ["Bridge", "Tunnel", "Road", "Canal"],
        correctAnswer: "Canal"
    },    
    {
        id: 36,
        question: "What type of building provides medical care and treatment to patients?",
        options: ["Library", "Church", "Clinic", "Post office"],
        correctAnswer: "Clinic"
    },    
    {
        id: 37,
        question: "What type of building provides lodging accommodations for travelers?",
        options: ["Factory", "School", "Hotel", "Bank"],
        correctAnswer: "Hotel"
    },
    {
        id: 38,
        question: "What type of infrastructure is shown in the picture?",
        options: ["Bridge", "Tunnel", "Road", "Canal"],
        correctAnswer: "Bridge",
        imageUrl: "/activityImages/SocialSciences/bridge.png"
    },  
    {
        id: 39,
        question: "Which type of building is shown in the image?",
        options: ["Church", "School", "Hospital", "Library"],
        correctAnswer: "Church",
        imageUrl: "/activityImages/SocialSciences/building_church.png"
    }, 
    {
        id: 40,
        question: "What are the two types of buildings shown in the image?",
        options: ["Church/Mosque", "Hospital", "Factory", "Bank"],
        correctAnswer: "Church/Mosque",
        imageUrl: "/activityImages/SocialSciences/building_church_mosque.png"
    },
    {
        id: 41,
        question: "What type of building is displayed in the image?",
        options: ["Clinic", "Hospital", "School", "Library"],
        correctAnswer: "Clinic",
        imageUrl: "/activityImages/SocialSciences/building_clinic.png"
    },
    {
        id: 42,
        question: "Identify the type of building shown below.",
        options: ["Factory", "Office", "Warehouse", "Restaurant"],
        correctAnswer: "Factory",
        imageUrl: "/activityImages/SocialSciences/building_factory.png"
    },
    {
        id: 43,
        question: "Which type of building is shown   in the image?",
        options: ["Fuel Garage", "Supermarket", "Department Store", "Hotel"],
        correctAnswer: "Fuel Garage",
        imageUrl: "/activityImages/SocialSciences/building_fuel-garage.png"
    },
    {
        id: 44,
        question: "What type of building is shown in the image?",
        options: ["House", "Apartment", "Condo", "Cabin"],
        correctAnswer: "House",
        imageUrl: "/activityImages/SocialSciences/building_house.png"
    },
    {
        id: 45,
        question: "Identify the type of building shown below.",
        options: ["School", "Church", "Mall", "Parking lot"],
        correctAnswer: "School",
        imageUrl: "/activityImages/SocialSciences/building_school.png"
    },
    {
        id: 47,
        question: "What type of building is displayed in the image?",
        options: ["Train Station", "Airport", "Bus Terminal", "Metro Station"],
        correctAnswer: "Train Station",
        imageUrl: "/activityImages/SocialSciences/building_train.png"
    },
    {
        id: 48,
        question: "Which type of settlement is shown in the image?",
        options: ["City", "Town", "Village", "Suburb"],
        correctAnswer: "City",
        imageUrl: "/activityImages/SocialSciences/city_settlement.png"
    },
    {
        id: 49,
        question: "What type of settlement is shown in the picture?",
        options: ["Farm", "Township", "City", "House"],
        correctAnswer: "Farm",
        imageUrl: "/activityImages/SocialSciences/Farm_settlement.png"
    },
    {
        id: 50,
        question: "Identify the landmark shown below.",
        options: ["Mountain", "Hill", "Volcano", "Plateau"],
        correctAnswer: "Mountain",
        imageUrl: "/activityImages/SocialSciences/landmark_mountain.png"
    },
    {
        id: 51,
        question: "What type of landmark is shown in the image?",
        options: ["Pond", "Lake", "River", "Stream"],
        correctAnswer: "Pond",
        imageUrl: "/activityImages/SocialSciences/landmark_pond.png"
    },
    {
        id: 52,
        question: "Which landmark is visible in the picture?",
        options: ["River", "Creek", "Canal", "Brook"],
        correctAnswer: "River",
        imageUrl: "/activityImages/SocialSciences/landmark_river.png"
    },
    {
        id: 53,
        question: "What type of settlement is shown in the image?",
        options: ["Rural Settlement", "Suburban Area", "Urban Sprawl", "Industrial Estate"],
        correctAnswer: "Rural Settlement",
        imageUrl: "/activityImages/SocialSciences/rural_settlement.png"
    },
    {
        id: 54,
        question: "Which type of settlement is shown in the picture?",
        options: ["Town", "City", "Village", "Hamlet"],
        correctAnswer: "Town",
        imageUrl: "/activityImages/SocialSciences/town_settlement.png"
    },
    {
        id: 55,
        question: "What type of settlement is shown in the image?",
        options: ["Village", "Township", "Countryside", "Hamlet"],
        correctAnswer: "Village",
        imageUrl: "/activityImages/SocialSciences/village_farm.png"
    },
    {
        id: 56,
        question: "What type of building is typically used for religious worship?",
        options: ["School", "Clinic", "Church", "Hospital"],
        correctAnswer: "Church"
    }, 
    {
        id: 57,
        question: "Identify the type of building shown below.",
        options: ["Clinic", "Factory", "Warehouse", "Office"],
        correctAnswer: "Clinic",
        imageUrl: "/activityImages/SocialSciences/building_clinic.png"
    }, 
    {
        id: 58,
        question: "Which type of settlement is shown in the image?",
        options: ["City", "Town", "Village", "Suburb"],
        correctAnswer: "City",
        imageUrl: "/activityImages/SocialSciences/city_settlement1.png"
    }, 
    {
        id: 59,
        question: "What type of infrastructure is shown in the picture?",
        options: ["Bridge", "Tunnel", "Viaduct", "Aqueduct"],
        correctAnswer: "Bridge",
        imageUrl: "/activityImages/SocialSciences/bridge.png"
    }, 
    {
        id: 60,
        question: "What type of building is commonly used for manufacturing goods?",
        options: ["Clinic", "School", "Factory", "Supermarket"],
        correctAnswer: "Factory"
    }, 
    {
        id: 61,
        question: "Identify the type of building shown below.",
        options: ["Fuel Garage", "Workshop", "Car Wash", "Parking Lot"],
        correctAnswer: "Fuel Garage",
        imageUrl: "/activityImages/SocialSciences/building_fuel-garage.png"
    }, 
    {
        id: 62,
        question: "Which type of settlement is shown in the image?",
        options: ["Rural Settlement", "Suburban Area", "Urban Sprawl", "Industrial Estate"],
        correctAnswer: "Rural Settlement",
        imageUrl: "/activityImages/SocialSciences/rural_settlement2.png"
    }, 
    {
        id: 63,
        question: "What type of infrastructure is shown in the picture?",
        options: ["Road", "Street", "Avenue", "Boulevard"],
        correctAnswer: "Road",
        imageUrl: "/activityImages/SocialSciences/road.png"
    }, 
    {
        id: 64,
        question: "Which type of building is typically found in an educational institution?",
        options: ["Warehouse", "Factory", "Office", "School"],
        correctAnswer: "School"
    },
    {
        id: 65,
        question: "What type of building is primarily used for housing?",
        options: ["Hospital", "Museum", "Library", "House"],
        correctAnswer: "House"
    }, {
        id: 66,
        question: "Identify the type of building shown below.",
        options: ["Train Station", "Airport", "Bus Terminal", "Subway Station"],
        correctAnswer: "Train Station",
        imageUrl: "/activityImages/SocialSciences/building_train.png"
    }, {
        id: 67,
        question: "Which type of settlement is depicted in the image?",
        options: ["Town", "City", "Village", "Suburb"],
        correctAnswer: "Town",
        imageUrl: "/activityImages/SocialSciences/town_settlement1.png"
    }, {
        id: 68,
        question: "What type of infrastructure is shown in the picture?",
        options: ["Pavement", "Bridge", "Tunnel", "Trail"],
        correctAnswer: "Pavement",
        imageUrl: "/activityImages/SocialSciences/pavement.png"
    }, {
        id: 69,
        question: "What type of building is typically used for selling goods and services?",
        options: ["Clinic", "Bank", "Supermarket", "Warehouse"],
        correctAnswer: "Supermarket"
    }, {
        id: 70,
        question: "Identify the type of building shown below.",
        options: ["Church", "Mosque", "Temple", "Shrine"],
        correctAnswer: "Mosque",
        imageUrl: "/activityImages/SocialSciences/building_church_mosque.png"
    }, {
        id: 71,
        question: "Which type of settlement is depicted in the image?",
        options: ["Suburb", "City", "Town", "Village"],
        correctAnswer: "Suburb",
        imageUrl: "/activityImages/SocialSciences/city_settlement2.png"
    }, {
        id: 72,
        question: "What type of infrastructure is shown in the picture?",
        options: ["River", "Stream", "Canal", "Brook"],
        correctAnswer: "River",
        imageUrl: "/activityImages/SocialSciences/landmark_river.png"
    }, {
        id: 73,
        question: "What type of building is commonly used for medical treatment and care?",
        options: ["Library", "Theater", "Cinema", "Clinic"],
        correctAnswer: "Clinic"
    }, {
        id: 74,
        question: "Identify the type of building shown below.",
        options: ["Factory", "Mill", "Plant", "Workshop"],
        correctAnswer: "Factory",
        imageUrl: "/activityImages/SocialSciences/building_factory1.png"
    }, {
        id: 75,
        question: "Which type of settlement is depicted in the image?",
        options: ["Village", "Town", "City", "Hamlet"],
        correctAnswer: "Village",
        imageUrl: "/activityImages/SocialSciences/village_farm.png"
    }, {
        id: 76,
        question: "What type of infrastructure is shown in the picture?",
        options: ["Mountain", "Hill", "Valley", "Plateau"],
        correctAnswer: "Mountain",
        imageUrl: "/activityImages/SocialSciences/landmark_mountain.png"
    },
    {
        id: 77,
        question: "Which of the following is a primary source of energy for most homes?",
        options: ["Water", "Solar Power", "Electricity", "Wind Energy"],
        correctAnswer: "Electricity"
    }, 
    {
        id: 78,
        question: "Which type of settlement is typically characterized by vast agricultural land?",
        options: ["Town", "Village", "Suburb", "City"],
        correctAnswer: "Village"
    }, 
    {
        id: 79,
        question: "What is the primary function of a bank building?",
        options: ["Healthcare", "Education", "Financial Services", "Manufacturing"],
        correctAnswer: "Financial Services"
    }, 
    {
        id: 80,
        question: "Identify the landmark commonly associated with large bodies of water.",
        options: ["Mountain", "Pond", "Valley", "Desert"],
        correctAnswer: "Pond"
    }, 
    {
        id: 81,
        question: "Which of the following is a need for all living beings?",
        options: ["Internet Access", "Entertainment", "Shelter", "Mobile Phones"],
        correctAnswer: "Shelter"
    }, 
    {
        id: 82,
        question: "What is the primary mode of transportation in rural areas?",
        options: ["Cars", "Bicycles", "Trains", "Boats"],
        correctAnswer: "Bicycles"
    }, 
    {
        id: 83,
        question: "Which of the following is a common method of obtaining water for daily use?",
        options: ["Digging a Well", "Teleportation", "Cloud Seeding", "Magic"],
        correctAnswer: "Digging a Well"
    },
    {
        id: 84,
        question: "Which area has the most malls?",
        options: ["Town", "Village", "City", "Rural areas"],
        correctAnswer: "City"
    },
    {
        id: 85,
        question: "Which of the following is a characteristic of a city?",
        options: ["Large population density", "Predominantly agricultural economy", "Limited infrastructure", "Low-rise buildings"],
        correctAnswer: "Large population density"
    },
    {
        id: 86,
        question: "What is a common landmark found in many cities?",
        options: ["Tall Buildings", "Windmill", "Barn", "Lighthouse"],
        correctAnswer: "Tall Buildings"
    },
    {
        id: 87,
        question: "What type of infrastructure is important for cities?",
        options: ["Roads and bridges", "Hiking trails", "Ski resorts", "Fishing ports"],
        correctAnswer: "Roads and bridges"
    },
    {
        id: 88,
        question: "Which settlement is usually larger than a village but smaller than a city?",
        options: ["Town", "Metropolis", "Hamlet", "Borough"],
        correctAnswer: "Town"
    },
    {
        id: 89,
        question: "Which of the following is a reason why people settle in urban areas?",
        options: ["Access to agricultural land", "Closer proximity to nature", "More job opportunities", "Less population density"],
        correctAnswer: "More job opportunities"
    },
    {
        id: 90,
        question: "Which of the following is a basic human need?",
        options: ["Shopping malls", "High-speed internet", "Clean water", "Luxury cars"],
        correctAnswer: "Clean water"
    },
    {
        id: 91,
        question: "In which type of settlement can you find more agricilture fields?",
        options: ["Village", "Metropolis", "Suburb", "Condominium"],
        correctAnswer: "Village"
    },
    {
        id: 92,
        question: "What type of structure is typically found in a suburban area?",
        options: ["Single-family homes", "Skyscrapers", "Warehouses", "Tenements"],
        correctAnswer: "Single-family homes"
    },
    {
        id: 93,
        question: "Which of the following is a characteristic of a village?",
        options: ["High-rise buildings", "Large population", "Close proximity to nature", "Industrial factories"],
        correctAnswer: "Close proximity to nature"
    },

];
  
  export default socialSciencesQuestionsData;
  
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import AccessDenied from '../components/AccessDenied';
import SomeWentWrong from '../components/SomeWentWrong';

// Utility to get the number of days in a month
const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

// Utility to get the day of the week the month starts on
const getStartDayOfMonth = (year, month) => {
  return new Date(year, month, 1).getDay();
};

const ViewSchedulePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [todayDate, setTodayDate] = useState('');
  const [markedDaysData, setMarkedDaysData] = useState([]);
  const [authorDecoyId, setAuthorDecoyId] = useState('');
  const [authorSchoolName, setAuthorSchoolName] = useState('');
  const { markedDayId } = useParams(); // Get markedDayId from URL params

  // Fetch authentication status and existing schedule data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/study', {
          withCredentials: true,
        });

        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setErrorStatus(401);
          } else if (error.response.status === 403) {
            setErrorStatus(403);
          }
          setError(error.response.data.message);
        } else {
          setError('Failed to fetch schedule data');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Fetch marked day data based on markedDayId
  useEffect(() => {
    const fetchMarkedDayData = async () => {
      try {
        const response = await axios.get(`https://lernacy.co.za/api/institution/schedule/updated/${markedDayId}`, {
          withCredentials: true,
        });

        if (response.status === 200) {
          setMarkedDaysData(response.data.markedDays);
        }
      } catch (error) {
        console.error('Failed to fetch marked day data:', error);
        // Handle error appropriately
        setError('Failed to fetch marked day data');
      }
    };

    fetchMarkedDayData();
  }, [markedDayId]); // Fetch data whenever markedDayId changes

  // Fetch author's decoyId based on markedDayId
  useEffect(() => {
    const fetchAuthorData = async () => {
      try {
        const response = await axios.get(`https://lernacy.co.za/api/institution/schedule/user/${markedDayId}`, {
          withCredentials: true,
        });

        if (response.status === 200) {
          setAuthorDecoyId(response.data.authorDecoyId);
          setAuthorSchoolName(response.data.authorSchoolName);
        }
      } catch (error) {
        console.error('Failed to fetch author data:', error);
        // Handle error appropriately
        setError('Failed to fetch author data');
      }
    };

    fetchAuthorData();
  }, [markedDayId]); // Fetch authorDecoyId whenever markedDayId changes

  // Set today's date
  useEffect(() => {
    const today = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    setTodayDate(today.toLocaleDateString(undefined, options));
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errorStatus === 401) {
    return <AccessDenied />;
  }

  if (errorStatus === 403) {
    return <SomeWentWrong />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // List of months for the 2024 calendar
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  // Get today's date
  const today = new Date();

  return (
    <div className="user-profile-account-aligner-container">
      <div className="schedule-profile-navigation-container">
        <h1 className="schedule-page-title-name">Institution Calendar</h1>
        <div className="schedule-profile-navigation">
          <Link to={`/user/${authorDecoyId}`} className="schedule-profile-navigation-p-color-back">
            <div className="schedule-profile-navigation-div">Posts</div>
          </Link>
          <Link /*to={'/user-links'}*/ className="schedule-profile-navigation-p-color-back-active">
            <div className="schedule-profile-navigation-div">Schedule</div>
          </Link>
        </div>
      </div>

      <div className='calendar-content-page'>
        <br />
        {/*
        <h2>Create Schedule</h2>
        <p>Marked Day ID: {markedDayId}</p>
        <p>Author Decoy ID: {authorDecoyId}</p>
        */}
        <h2 className='calendar-content-page-h2'>{authorSchoolName}</h2>

        <div className="calendar-container">
          

          {months.map((month, index) => {
            const daysInMonth = getDaysInMonth(2024, index);
            let startDay = getStartDayOfMonth(2024, index);
            const weeks = [];
            let currentWeek = new Array(7).fill(null);

            // Fill the first week with leading nulls
            for (let i = 0; i < startDay; i++) {
              currentWeek[i] = null;
            }

            // Fill the days of the month
            for (let day = 1; day <= daysInMonth; day++) {
              currentWeek[startDay] = day;
              startDay++;
              if (startDay === 7) {
                weeks.push(currentWeek);
                currentWeek = new Array(7).fill(null);
                startDay = 0;
              }
            }

            // Push the last week
            if (startDay !== 0) {
              weeks.push(currentWeek);
            }

            return (
              <div key={index} className="month">
                <h2>
                  {month} 2024
                </h2>
                <div className="calendar-grid">
                  <div className="calendar-weekdays">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, i) => (
                      <div key={i} className="calendar-day">{day}</div>
                    ))}
                  </div>
                  {weeks.map((week, i) => (
                    <div key={i} className="calendar-week">
                      {week.map((day, j) => {
                        const isToday = today.getDate() === day && today.getMonth() === index;
                        const dayKey = `${index}-${day}`;
                        const markedDay = markedDaysData.find(item => item.dayKey === dayKey);
                        const backgroundColor = isToday ? '#666' : markedDay ? markedDay.color : 'transparent';
                        const label = markedDay ? markedDay.label : '';
                        const dayStyle = {
                          backgroundColor: backgroundColor,
                          position: 'relative', // Ensure the label is positioned relative to the day cell
                        };

                        return (
                          <div
                            key={j}
                            className="calendar-day"
                            style={dayStyle}
                          >
                            {day !== null ? day : ''}
                            {label && (
                              <div className="day-label">
                                {label}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <br />
    </div>
  );
};

export default ViewSchedulePage;
import React, { useState, useEffect } from 'react';

const PinValidation = ({ schoolNameInput, onSubmit }) => {
    const [pin, setPin] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const schoolsAndPins = {
        'Abc High School 12345678': '1234', 
        'Makanye Primary School 54685496': '9101'
    };

    useEffect(() => {
        //console.log('Component mounted');
        //console.log('School Name Input:', schoolNameInput);
    }, [schoolNameInput]);

    const handlePinChange = (e) => {
        setPin(e.target.value);
        //console.log('PIN changed:', e.target.value); // Log PIN changes
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // Debugging logs
        //console.log('Form submitted');
        //console.log('School Name Input:', schoolNameInput);
        //console.log('Entered PIN:', pin);
        //console.log('Expected PIN:', schoolsAndPins[schoolNameInput]);

        // Check if school name and pin are correct
        if (schoolsAndPins[schoolNameInput] === pin) {
            alert('PIN is correct!'); // Show alert for correct PIN
            onSubmit(); // Call parent component's onSubmit function
        } else {
            setErrorMessage('Incorrect school name or PIN. Submission blocked.');
        }
    };

    return (
        <div>
            <form onSubmit={handleFormSubmit}>
                <label htmlFor="pinInput">Enter PIN:</label><br />
                <input
                    type="password"
                    id="pinInput"
                    name="pinInput"
                    value={pin}
                    onChange={handlePinChange}
                    required
                    className='tasks-school-name-input'
                /><br />
                <button className='activity-submit-button' type="submit">Submit</button>
            </form>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
    );
};

export default PinValidation;

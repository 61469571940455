const socialSciencesWaterUsage = [

    {
        "id": 1,
        "question": "How do people access water from underground?",
        "options": ["By digging holes", "By drilling boreholes", "By climbing mountains", "By flying airplanes"],
        "correctAnswer": "By drilling boreholes"
    },
    {
        "id": 2,
        "question": "What is a well?",
        "options": ["A tool for digging holes", "A hole in the ground to reach water", "A type of bucket", "A type of pump"],
        "correctAnswer": "A hole in the ground to reach water"
    },
    {
        "id": 3,
        "question": "How do people reach water from a well?",
        "options": ["By using pumps", "By using buckets", "By using buckets and containers", "By using pipes"],
        "correctAnswer": "By using buckets"
    },
    {
        "id": 4,
        "question": "What is the main difference between boreholes and wells?",
        "options": ["Boreholes are shallower than wells", "Wells are deeper than boreholes", "Boreholes need pumps, but wells do not", "Wells need pumps, but boreholes do not"],
        "correctAnswer": "Boreholes need pumps, but wells do not"
    },
    {
        "id": 5,
        "question": "How do people in areas with no water supply get water?",
        "options": ["By using magic", "By truck delivery", "By swimming in rivers", "By waiting for rain"],
        "correctAnswer": "By truck delivery"
    },
    {
        "id": 6,
        "question": "What is the purpose of dams?",
        "options": ["To store water", "To clean water", "To deliver water", "To collect trash"],
        "correctAnswer": "To store water"
    },
    {
        "id": 7,
        "question": "What role do reservoirs play in the water supply process?",
        "options": ["To pump water", "To store cleaned water", "To deliver water", "To drill boreholes"],
        "correctAnswer": "To store cleaned water"
    },
    {
        "id": 8,
        "question": "How does water reach taps in homes?",
        "options": ["Through rivers", "Through airplanes", "Through underground pipes", "Through magic"],
        "correctAnswer": "Through underground pipes"
    },
    {
        "id": 9,
        "question": "What do water treatment centers do?",
        "options": ["They store water", "They deliver water to homes", "They clean dam water", "They drill boreholes"],
        "correctAnswer": "They clean dam water"
    },
    {
        "id": 10,
        "question": "What is the purpose of pumping stations?",
        "options": ["To collect rainwater", "To push water through pipes", "To clean water", "To store water in reservoirs"],
        "correctAnswer": "To push water through pipes"
    },
    {
        "id": 11,
        "question": "How do people collect water from trucks?",
        "options": ["By using pumps", "By using buckets and containers", "By using pipes", "By using magic"],
        "correctAnswer": "By using buckets and containers"
    },
    {
        "id": 12,
        "question": "What happens to water after it is cleaned at a water treatment center?",
        "options": ["It is stored in dams", "It is delivered to homes", "It is stored in reservoirs", "It is pushed through pipes"],
        "correctAnswer": "It is stored in reservoirs"
    },
    {
        "id": 13,
        "question": "What is the purpose of boreholes?",
        "options": ["To store water", "To collect rainwater", "To deliver water to homes", "To access water from underground"],
        "correctAnswer": "To access water from underground"
    },
    {
        "id": 14,
        "question": "How do people access water from boreholes?",
        "options": ["By using pumps", "By using buckets", "By using pipes", "By using magic"],
        "correctAnswer": "By using pumps"
    },
    {
        "id": 15,
        "question": "What is the main function of taps?",
        "options": ["To store water", "To deliver water to homes", "To clean water", "To collect rainwater"],
        "correctAnswer": "To deliver water to homes"
    },
    {
        "id": 16,
        "question": "What is the purpose of water trucks in areas with no other water supply?",
        "options": ["To collect rainwater", "To clean water", "To deliver water to homes", "To drill boreholes"],
        "correctAnswer": "To deliver water to homes"
    },
    {
        "id": 17,
        "question": "How do people access water from taps?",
        "options": ["By using pumps", "By using buckets", "By using pipes", "By using magic"],
        "correctAnswer": "By using pipes"
    },
    {
        "id": 18,
        "question": "Why do people need to store water in reservoirs?",
        "options": ["To collect rainwater", "To clean water", "To store cleaned water", "To deliver water to homes"],
        "correctAnswer": "To store cleaned water"
    },
    {
        "id": 19,
        "question": "What is the role of pumping stations in the water supply process?",
        "options": ["To collect rainwater", "To push water through pipes", "To clean water", "To drill boreholes"],
        "correctAnswer": "To push water through pipes"
    },
    {
        "id": 20,
        "question": "How do people access water from boreholes?",
        "options": ["By using pumps", "By using buckets", "By using pipes", "By using magic"],
        "correctAnswer": "By using pumps"
    },
    {
        "id": 21,
        "question": "What happens to water after it is cleaned at a water treatment center?",
        "options": ["It is stored in dams", "It is delivered to homes", "It is stored in reservoirs", "It is pushed through pipes"],
        "correctAnswer": "It is stored in reservoirs"
    },
    {
        "id": 22,
        "question": "What is the primary function of taps?",
        "options": ["To store water", "To deliver water to homes", "To clean water", "To collect rainwater"],
        "correctAnswer": "To deliver water to homes"
    },
    {
        "id": 23,
        "question": "What is the purpose of water treatment centers?",
        "options": ["To store water", "To deliver water to homes", "To clean water", "To collect rainwater"],
        "correctAnswer": "To clean water"
    },
    {
        "id": 24,
        "question": "How do people access water from water trucks?",
        "options": ["By using pumps", "By using buckets", "By using pipes", "By using magic"],
        "correctAnswer": "By using buckets and containers"
    },
    {
        "id": 25,
        "question": "What is the role of dams in the water supply process?",
        "options": ["To collect rainwater", "To clean water", "To store cleaned water", "To deliver water to homes"],
        "correctAnswer": "To store water"
    },
    {
        "id": 26,
        "question": "What do people use boreholes for?",
        "options": ["To collect rainwater", "To deliver water to homes", "To access water from underground", "To drill for oil"],
        "correctAnswer": "To access water from underground"
    },
    {
        "id": 27,
        "question": "How do people access water from reservoirs?",
        "options": ["By using pumps", "By using buckets", "By using pipes", "By using magic"],
        "correctAnswer": "By using pumps"
    },
    {
        "id": 28,
        "question": "What is the main function of taps?",
        "options": ["To store water", "To deliver water to homes", "To clean water", "To collect rainwater"],
        "correctAnswer": "To deliver water to homes"
    },
    {
        "id": 29,
        "question": "What are some personal, daily practices that contribute to water pollution?",
        "options": ["Throwing garbage in rivers", "Using soaps and cleaning products that wash into rivers", "Building dams", "Planting trees near rivers"],
        "correctAnswer": "Using soaps and cleaning products that wash into rivers"
    },
    {
        "id": 30,
        "question": "How do chemicals from cleaning substances affect water?",
        "options": ["They make water cleaner", "They make water safe to drink", "They pollute water and make it unsafe to drink", "They evaporate water"],
        "correctAnswer": "They pollute water and make it unsafe to drink"
    },
    {
        "id": 31,
        "question": "What happens when people burn fuels that release chemicals into the air?",
        "options": ["It makes the air cleaner", "It causes rain", "It pollutes water by washing chemicals into soil, rivers, and dams", "It cleans water"],
        "correctAnswer": "It pollutes water by washing chemicals into soil, rivers, and dams"
    },
    {
        "id": 32,
        "question": "What is a potential source of harmful bacteria in rivers?",
        "options": ["Factories", "Houses", "Forests", "Mountains"],
        "correctAnswer": "Toilets leaking into rivers"
    },
    {
        "id": 33,
        "question": "What kind of waste water contains harmful chemicals from factories and farms?",
        "options": ["Drinking water", "Clean water", "Waste water", "Sewage water"],
        "correctAnswer": "Waste water"
    },
    {
        "id": 34,
        "question": "How is sewage treated before being put back into rivers or the sea?",
        "options": ["It is left untreated", "It is cleaned at sewage treatment works", "It is poured directly into rivers", "It is filtered through sand"],
        "correctAnswer": "It is cleaned at sewage treatment works"
    },
    {
        "id": 35,
        "question": "What is the purpose of recycling waste water and sewage?",
        "options": ["To increase pollution", "To reduce water availability", "To clean water for drinking purposes", "To make water dirty"],
        "correctAnswer": "To clean water for drinking purposes"
    },
    {
        "id": 36,
        "question": "What are some harmful substances that may flow into rivers from factories and farms?",
        "options": ["Fruits and vegetables", "Fertilizers and pesticides", "Books and pencils", "Toys and games"],
        "correctAnswer": "Fertilizers and pesticides"
    },
    {
        "id": 37,
        "question": "What is the destination of sewage after passing through sewage treatment works?",
        "options": ["It is released into the atmosphere", "It is stored in reservoirs", "It is put back into rivers or the sea", "It is buried underground"],
        "correctAnswer": "It is put back into rivers or the sea"
    },
    {
        "id": 38,
        "question": "What happens to the rainwater that washes chemicals from the air into soil, rivers, and dams?",
        "options": ["It disappears", "It evaporates", "It becomes cleaner", "It pollutes water"],
        "correctAnswer": "It pollutes water"
    },
    {
        "id": 39,
        "question": "What is the main goal of sewage treatment works?",
        "options": ["To increase pollution", "To make water unsafe to drink", "To release untreated sewage", "To clean sewage"],
        "correctAnswer": "To clean sewage"
    },
    {
        "id": 40,
        "question": "How does waste water from factories and farms affect rivers?",
        "options": ["It makes them cleaner", "It makes them dry up", "It pollutes them with harmful chemicals", "It attracts more wildlife"],
        "correctAnswer": "It pollutes them with harmful chemicals"
    },
    {
        "id": 41,
        "question": "What is the final step in the process of recycling waste water and sewage?",
        "options": ["Pouring it into rivers", "Treating it at sewage treatment works", "Storing it in reservoirs", "Putting it back into rivers or the sea"],
        "correctAnswer": "Putting it back into rivers or the sea"
    },
    {
        "id": 42,
        "question": "How do cleaning substances from houses and villages contribute to water pollution?",
        "options": ["They make water cleaner", "They evaporate water", "They pollute water with chemicals", "They make water taste better"],
        "correctAnswer": "They pollute water with chemicals"
    },
    {
        "id": 43,
        "question": "What is a potential consequence of toilets leaking into rivers?",
        "options": ["Increased biodiversity", "Harmful bacteria in the water", "Cleaner water", "Enhanced soil fertility"],
        "correctAnswer": "Harmful bacteria in the water"
    },
    {
        "id": 44,
        "question": "How does sewage get cleaned before being released back into rivers or the sea?",
        "options": ["It undergoes no treatment", "It gets filtered through sand", "It is treated at sewage treatment works", "It is boiled"],
        "correctAnswer": "It is treated at sewage treatment works"
    },
    {
        "id": 45,
        "question": "What do fertilizers and pesticides from farms contribute to in rivers?",
        "options": ["Cleaner water", "Harmful chemicals", "Increased fish population", "Improved water quality"],
        "correctAnswer": "Harmful chemicals"
    },
    {
        "id": 46,
        "question": "What is the primary purpose of sewage treatment works?",
        "options": ["To increase pollution", "To release untreated sewage", "To clean sewage", "To collect rainwater"],
        "correctAnswer": "To clean sewage"
    },
    {
        "id": 47,
        "question": "How does waste water from factories and farms affect the quality of rivers?",
        "options": ["It improves water quality", "It causes rivers to dry up", "It pollutes them with harmful chemicals", "It attracts more wildlife"],
        "correctAnswer": "It pollutes them with harmful chemicals"
    },
    {
        "id": 48,
        "question": "What are some consequences of chemicals from cleaning substances entering rivers?",
        "options": ["Increased biodiversity", "Cleaner water", "Polluted water", "Enhanced soil fertility"],
        "correctAnswer": "Polluted water"
    },
    {
        "id": 49,
        "question": "How does waste water from factories and farms reach rivers?",
        "options": ["Through underground tunnels", "Through magical means", "Through drains", "Through pipelines"],
        "correctAnswer": "Through drains"
    },
    {
        "id": 50,
        "question": "What is the primary goal of recycling waste water and sewage?",
        "options": ["To increase pollution", "To make water unsafe to drink", "To clean water for drinking purposes", "To reduce water availability"],
        "correctAnswer": "To clean water for drinking purposes"
    },
    {
        "id": 51,
        "question": "How does sewage get treated before being released back into rivers or the sea?",
        "options": ["It is left untreated", "It is treated at sewage treatment works", "It is boiled", "It is buried underground"],
        "correctAnswer": "It is treated at sewage treatment works"
    },
    {
        "id": 52,
        "question": "What happens to chemicals from the air when rain washes them into soil, rivers, and dams?",
        "options": ["They disappear", "They evaporate", "They become cleaner", "They pollute water"],
        "correctAnswer": "They pollute water"
    },
    {
        "id": 53,
        "question": "What is the final step in the process of recycling waste water and sewage?",
        "options": ["Pouring it into rivers", "Treating it at sewage treatment works", "Storing it in reservoirs", "Putting it back into rivers or the sea"],
        "correctAnswer": "Putting it back into rivers or the sea"
    },
    {
        "id": 54,
        "question": "How do cleaning substances from houses and villages affect water quality in rivers?",
        "options": ["They improve water quality", "They evaporate water", "They pollute water with chemicals", "They make water taste better"],
        "correctAnswer": "They pollute water with chemicals"
    },
    {
        "id": 55,
        "question": "What is a potential consequence of toilets leaking into rivers?",
        "options": ["Increased biodiversity", "Harmful bacteria in the water", "Cleaner water", "Enhanced soil fertility"],
        "correctAnswer": "Harmful bacteria in the water"
    },
    {
        "id": 56,
        "question": "How does sewage get cleaned before being released back into rivers or the sea?",
        "options": ["It undergoes no treatment", "It gets filtered through sand", "It is treated at sewage treatment works", "It is boiled"],
        "correctAnswer": "It is treated at sewage treatment works"
    },
    {
        "id": 57,
        "question": "What do fertilizers and pesticides from farms contribute to in rivers?",
        "options": ["Cleaner water", "Harmful chemicals", "Increased fish population", "Improved water quality"],
        "correctAnswer": "Harmful chemicals"
    },
    {
        "id": 58,
        "question": "What is the primary purpose of sewage treatment works?",
        "options": ["To increase pollution", "To release untreated sewage", "To clean sewage", "To collect rainwater"],
        "correctAnswer": "To clean sewage"
    },
    {
        "id": 59,
        "question": "How does waste water from factories and farms affect the quality of rivers?",
        "options": ["It improves water quality", "It causes rivers to dry up", "It pollutes them with harmful chemicals", "It attracts more wildlife"],
        "correctAnswer": "It pollutes them with harmful chemicals"
    },
    {
        id: 60,
        question: 'What type of infrastructure is shown in the picture below?',
        options: ['Water Storage Tank', 'A house', 'A chicken hut', 'Swimming pool'],
        correctAnswer: 'Bridge',
        imageUrl: '/activityImages/SocialSciences/Water_Water-storage-tanks.jfif' 
    },
    {
        id: 61,
        question: 'What is the purpose of the item shown in the image?',
        options: ['Store water', 'Decoration', 'Store food', 'It has no use'],
        correctAnswer: 'Store water',
        imageUrl: '/activityImages/SocialSciences/Water_Water-storage-tanks.jfif' 
    },
    {
        "id": 62,
        "question": "In what ways do rivers, streams, and springs contribute to community water supply?",
        "options": ["They don't contribute", "They provide drinking water directly", "They provide water for irrigation", "They cause floods"],
        "correctAnswer": "They provide drinking water directly"
    },
    {
        "id": 63,
        "question": "What precautions should people take when collecting water from natural sources?",
        "options": ["None, it's always safe", "Boiling water before use", "Drinking it directly", "Using it without any treatment"],
        "correctAnswer": "Boiling water before use"
    },
    {
        "id": 64,
        "question": "Why is it important for people to be cautious when collecting water from rivers and streams?",
        "options": ["Because it's always safe", "Because it's free", "Because it can be contaminated", "Because it's always clean"],
        "correctAnswer": "Because it can be contaminated"
    },
    {
        "id": 65,
        "question": "What role do springs play in providing water to communities?",
        "options": ["None, they are not significant", "They provide clean drinking water", "They contribute to flooding", "They have no impact"],
        "correctAnswer": "They provide clean drinking water"
    }
    
];
  
  export default socialSciencesWaterUsage;
  
import React, { useState, useEffect, useRef } from 'react'; 
import { useParams, Link } from 'react-router-dom';
import './bodyFiles-styling/bodyFiles.css';
import './bodyFiles-styling/admin-check.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import AccessDenied from '../components/AccessDenied'; // Import AccessDenied component
import YouBlockedUser from '../components/YouBlockedUser'; 
import { useNavigate } from 'react-router-dom'; 

const SinglePostPage = () => {
  const [post, setPost] = useState(null);
  const [userId, setUserId] = useState(null); // State for userId
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [commentText, setCommentText] = useState(''); // State for comment text
  const { postId } = useParams();
  const MAX_COMMENT_LENGTH = 280;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const dropdownRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(post?.content || '');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditCommentModalOpen, setIsEditCommentModalOpen] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isAdmin, setIsAdmin] = useState(false); 

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`https://lernacy.co.za/api/open-post/${postId}`, {
          method: 'GET',
          credentials: 'include',
        });
  
        if (!response.ok) {
          if (response.status === 401) {
            setIsAuthenticated(false);
            setLoading(false);
            return;
          }
          throw new Error('Failed to fetch post');
        }
        const data = await response.json();
        setPost(data.post);
        setUserId(data.userId); // Set userId state
        setLoading(false);
        setIsAuthenticated(true);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
  
    fetchPost();
  }, [postId]);
  
  // Debugging logs 
  // console.log('Fetched userId:', userId);

  const handleSupportButton = async () => {
    try {
        // Update support count locally
        setPost(prevPost => ({
            ...prevPost,
            support: prevPost.isSupported ? prevPost.support - 1 : prevPost.support + 1,
            isSupported: !prevPost.isSupported
        }));

        // Fetch CSRF token
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
            method: 'GET',
            credentials: 'include',
        });

        if (!csrfResponse.ok) {
            throw new Error('Failed to fetch CSRF token');
        }

        const csrfData = await csrfResponse.json();
        const csrfToken = csrfData.csrfToken;

        // Send a POST request to update support count on the backend
        const response = await fetch(`https://lernacy.co.za/api/support/${postId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'CSRF-Token': csrfToken, // Include CSRF token in the headers
            },
            body: JSON.stringify({ postId }), // Send postId only
            credentials: 'include', // Include credentials
        });

        if (!response.ok) {
            throw new Error('Failed to update support count on the server');
        }
    } catch (error) {
        console.error('Error supporting post:', error);
        alert('Failed to support post. Please try again later.'); // Display error message to the user
        // Revert changes if the request fails
        setPost(prevPost => ({
            ...prevPost,
            support: prevPost.isSupported ? prevPost.support + 1 : prevPost.support - 1,
            isSupported: !prevPost.isSupported
        }));
    }
  };

  const toggleModal = (modalType) => {
    if (modalType === 'delete') {
      setIsDeleteModalOpen(!isDeleteModalOpen);
    } else if (modalType === 'edit') {
      setIsEditModalOpen(!isEditModalOpen);
    }
  };

  const isValidObjectId = (id) => {
    const checkForHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");
    return checkForHexRegExp.test(id);
  };

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/check/check-user-primary-status', {
          credentials: 'include'
        });
  
        if (response.ok) {
          setResponseMessage('Admin status check successful.');
          setIsAdmin(true); // Set isAdmin to true if the response is 'ok'
        } else {
          setResponseMessage('Admin status check failed.');
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        setResponseMessage('Internal server error.'); // Set response message for internal server error
      }
    };
  
    checkAdminStatus();
  }, []); 

  const handleBanPost = async (postId) => {
    try {
      // Validate postId on client side
      if (!postId || typeof postId !== 'string' || postId.trim() === '') {
        throw new Error('Invalid postId');
      }
  
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include', // Include credentials for the server to identify the user
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      const { csrfToken } = await csrfResponse.json();
  
      // Make POST request with CSRF token included in headers
      const response = await fetch('https://lernacy.co.za/api/check/ban-post', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in the headers
        },
        body: JSON.stringify({ postId: postId.trim() }),
        credentials: 'include',
      });
  
      if (!response.ok) {
        // Parse the error response to get the error message
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to ban post');
      }
  
      // Show success message
      alert('Post banned successfully');
  
      // Redirect to the /accounts page
      window.location.href = '/accounts';
    } catch (error) {
      // Display error message to the user
      alert(`Error banning post: ${error.message}`);
      console.error('Error banning post:', error);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      if (!isValidObjectId(postId)) {
        throw new Error('Invalid post ID format');
      }
  
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      const { csrfToken } = await csrfResponse.json();
  
      // Send a DELETE request to delete the post
      const response = await fetch(`https://lernacy.co.za/api/posts/${postId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        credentials: 'include', // Include credentials (cookies)
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete post');
      }
  
      // Show success message
      alert('Post deleted successfully');
  
      // Redirect to the profile page
      window.location.href = '/profile';
  
    } catch (error) {
      console.error('Error deleting post:', error);
      // Show error message
      alert(error.message || 'Failed to delete post. Please try again.');
    } finally {
      // Log the postId regardless of success or failure
      //console.log(`Attempting to delete post with ID: ${postId}`);
    }
  };  

  const handleSubmitComment = async (event) => {
    event.preventDefault();
  
    if (commentText.length > MAX_COMMENT_LENGTH) {
      setError('Comment exceeds character limit');
      return;
    }
  
    try {
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      const { csrfToken } = await csrfResponse.json();
  
      // Extract comment text from state or form input
      const commentData = {
        text: commentText,
        userId: userId,
      };
  
      // Send a POST request to add the comment with CSRF token included in headers
      const response = await fetch(`https://lernacy.co.za/api/posts/${postId}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,
        },
        body: JSON.stringify(commentData),
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit comment');
      }
  
      // Reset comment text input
      setCommentText('');
  
      // Fetch updated post data to get new comments
      const getResponse = await fetch(`https://lernacy.co.za/api/open-post/${postId}`, {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!getResponse.ok) {
        throw new Error('Failed to fetch updated post');
      }
  
      const postData = await getResponse.json();
      setPost(postData.post);
    } catch (error) {
      setError(error.message);
      console.error('Error submitting comment:', error);
    }
  };  

  const handleEditPost = async () => {
    try {
      const updatedPost = {
        content: editedContent,
        updatedAt: new Date(), // Update the updatedAt field
      };
  
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      const { csrfToken } = await csrfResponse.json();
  
      // Send PUT request to update the post with CSRF token included in headers
      const putResponse = await fetch(`https://lernacy.co.za/api/posts/${postId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        body: JSON.stringify(updatedPost),
        credentials: 'include',
      });
  
      if (!putResponse.ok) {
        throw new Error('Failed to edit post');
      }
  
      // Update the post state with the edited post
      const putData = await putResponse.json();
      setPost(putData.updatedPost);
      setIsEditing(false);
      // Close the modal
      setIsEditModalOpen(false);
  
      // Fetch updated post details including author details
      const getResponse = await fetch(`https://lernacy.co.za/api/open-post/${postId}`, {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!getResponse.ok) {
        throw new Error('Failed to fetch updated post details');
      }
  
      // Update the post state with the fetched post details
      const getData = await getResponse.json();
      setPost(getData.post);
      setError(null); // Clear any previous errors
  
    } catch (error) {
      console.error('Error editing post:', error);
      setError(error.message); // Set the error message in the state
    }
  };  
  
  // Add this where you want to display the error message
  {error && <p className="error-message">{error}</p>}

  useEffect(() => {
    // Check if post exists before setting the default value of editedContent
    if (post) {
      setEditedContent(post.content);
    }
  }, [post]);

  const handleEditButtonClick = () => {
    setIsEditing(true);
    setEditedContent(post.content);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  const handleEditedContentChange = (event) => {
    setEditedContent(event.target.value);
  };

  useEffect(() => {
    // Function to close the dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSelectedComment(null); // Close the dropdown
      }
    };

    // Add event listener to detect clicks on the document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup: remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownClick = (commentId) => {
    setSelectedComment(commentId);
  };

  const handleEditComment = (commentId) => {
    const selectedComment = post.comments.find(comment => comment._id === commentId);
    
    // Check if the comment text exceeds the character limit
    if (selectedComment.text.length > 280) {
      // Handle the error: Comment exceeds character limit
      console.error('Comment exceeds character limit');
      return;
    }
    
    setEditedCommentText(selectedComment.text);
    setSelectedCommentId(commentId); // Set the selected comment ID for updating
    setIsEditCommentModalOpen(true); 
  };

  const handleEditedCommentTextChange = (event) => {
    setEditedCommentText(event.target.value);
  };

  const handleUpdateComment = async () => {
    try {
      const updatedComment = {
        text: editedCommentText,
      };
  
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      const { csrfToken } = await csrfResponse.json();
  
      const response = await fetch(`https://lernacy.co.za/api/comments/${selectedCommentId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        body: JSON.stringify(updatedComment),
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error('Failed to update comment');
      }
  
      // Fetch updated post details after successfully updating the comment
      const getResponse = await fetch(`https://lernacy.co.za/api/open-post/${postId}`, {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!getResponse.ok) {
        throw new Error('Failed to fetch updated post details');
      }
  
      const postData = await getResponse.json();
      setPost(postData.post);
  
      setIsEditCommentModalOpen(false); // Close the modal
  
    } catch (error) {
      console.error('Error updating comment:', error);
      // Show an error message to the user
      alert(`Error updating comment: ${error.message}`);
    }
  };  
  
  const handleDeleteComment = async (postId, commentId) => {
    try {
        // Fetch CSRF token
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
            method: 'GET',
            credentials: 'include',
        });

        if (!csrfResponse.ok) {
            throw new Error('Failed to fetch CSRF token');
        }

        const { csrfToken } = await csrfResponse.json();

        // Proceed with deleting the comment
        const response = await fetch('https://lernacy.co.za/api/delete-comment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'CSRF-Token': csrfToken, // Include CSRF token in the headers
            },
            body: JSON.stringify({
                postId: postId,
                commentId: commentId,
            }),
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Failed to delete comment');
        }

        // Update the post state to remove the deleted comment
        const updatedComments = post.comments.filter(comment => comment._id !== commentId);
        setPost(prevPost => ({
            ...prevPost,
            comments: updatedComments,
        }));

    } catch (error) {
        console.error('Error deleting comment:', error);
        // Handle error (e.g., display an error message to the user)
    }
  };

  const handleReportComment = (commentId) => {
    // Implement the logic to report the comment
    //console.log(`Reporting comment with ID: ${commentId}`);
  };  

  const handleCommentTextChange = (event) => {
    setCommentText(event.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!isAuthenticated) return <AccessDenied />; // Render AccessDenied component instead of the text
  if (!post) return <div className="post-not-available">Post not available...</div>;

  const formatDate = (dateString) => { 
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ][date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day} ${month} ${year} - ${hours}:${minutes}`;
  };

  const copyPostLinkToClipboard = (postId) => {
    const postLink = `${window.location.origin}/open-post/${postId}`;
    navigator.clipboard.writeText(postLink)
      .then(() => alert('Post link copied to clipboard'))
      .catch((error) => console.error('Failed to copy post link: ', error));
  };

  if (post.isAuthorBlocked) {
    return <YouBlockedUser />;
  }

  return (
    <div className='open-post-size-controller'>
      
      <div>
        {/*
        <p style={{display:'none'}}>User ID: {userId}</p>
        <p style={{display:'none'}}>{post.isSupported ? 'You have added support for this post' : 'You have not added support for this post'}</p>
        <p style={{display:'none'}}>{post.isAuthor ? 'You are the author of this post' : 'You are not the author of this post'}</p>
        */}
          
          {isAdmin && ( // Show the following elements only if isAdmin is true
            <div className='admin-container-block'>
              <h2>Admin Status Check</h2>
              <p>Response from server: {responseMessage}</p> 

              <button 
                onClick={() => handleBanPost(postId)}
              >
                Ban Post
              </button>
            </div>
          )}

        <div className='main-post-details'>

          <div>
            
            {post.isAuthor ? (
              <Link to={`/profile`} className='user-account-link'> 
                <div className='single-post-main-block'>
                  <img
                    loading="lazy"
                    src={post.author.profilePicture}
                    alt="Profile"
                    className='posts-profileimage' 
                  />
                  <div className='author-details-in-posts'>
                    <p className='posts-author-name-surname'>{post.author.name} {post.author.surname}</p> 
                    <p className='posts-accountName'>| {post.author.accountName}</p>
                  </div> 
                </div>
              </Link>
            ) : (
              <Link to={`/user/${post.author.decoyId}`} className='user-account-link'> 
                <div className='single-post-main-block'>
                  <img
                    loading="lazy"
                    src={post.author.profilePicture}
                    alt="Profile"
                    className='posts-profileimage' 
                  />
                  <div className='author-details-in-posts'>
                    <p className='posts-author-name-surname'>{post.author.name} {post.author.surname}</p> 
                    <p className='posts-accountName'>| {post.author.accountName}</p>
                  </div> 
                </div>
              </Link>
            )}

            <hr />
            
            <p className='post-content-text'>{post.content}</p> 
            {/* <p style={{display:'none'}}>{post._id}</p> */}

            {/* Delete Post Modal */}
            {isDeleteModalOpen && (
              <div className="modal">
                <div className="modal-content">
                  <h2>Delete Post</h2>
                  <p>Are you sure you want to delete this post?</p>
                  <p>This action can not be undone.</p>
                  <button onClick={() => handleDeletePost(post._id)}>Delete</button>
                  <button onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* Edit Post Modal */}
            {isEditModalOpen && (
              <div className="modal">
                <div className="modal-content">
                  <h2>Edit Post</h2>
                  <textarea
                    value={editedContent}
                    onChange={handleEditedContentChange}
                    placeholder="Edit your post here..." 
                    maxLength={700}
                  /><br />
                  <button onClick={handleEditPost}>Save</button>
                  <button onClick={() => setIsEditModalOpen(false)}>Cancel</button>
                </div>
              </div>
            )}

            <div className='change-post-button'>

              <div className='mainly-made-for-delete-container'>
                <div className='delete-trash-icon'>
                  {post.isAuthor && (
                    <div className='fontawesome-icon-author-pad' onClick={() => toggleModal('delete')}>
                      <FontAwesomeIcon icon={faTrash} className='fontawesome-icon' size='lg' />
                    </div>
                  )}
                </div>
              </div>

              <div className='mainly-made-for-edit-container'>
                <div className='edit-post-icon'>
                  {post.isAuthor && (
                    <div className='fontawesome-post-edit-author-pad' onClick={() => { toggleModal('edit'); setIsEditing(true); }}>
                      <FontAwesomeIcon icon={faEdit} className='fontawesome-icon' size='lg' />
                    </div>
                  )}
                </div>
              </div>
              <p>.</p>
            </div>

            {/* Edit Post Modal */}
            {isEditModalOpen && (
              <div className="modal">
                <div className="modal-content">
                  <h2>Edit Post</h2>
                  <textarea
                    value={editedContent}
                    onChange={handleEditedContentChange}
                    placeholder="Edit your post here..." 
                    maxLength={700}
                    className='edit-post-modal-open'
                  /><br />
                  <button onClick={handleEditPost}>Save</button>
                  <button onClick={() => setIsEditModalOpen(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* Delete Post Modal */}
            {isModalOpen && !isEditing && (
              <div className="modal">
                <div className="modal-content">
                  <h2>Delete Post</h2>
                  <p>Are you sure you want to delete this post?</p>
                  <p>This action can not be undone.</p>
                  <button onClick={() => { toggleModal('delete'); handleDeletePost(post._id); }}>Delete</button>
                  <button onClick={() => { toggleModal('delete'); }}>Cancel</button>
                </div>
              </div>
            )}
            
            <p className='created-at'>{formatDate(new Date(post.createdAt))}</p>
              
          </div>

        </div>

        <div className='open-posts-interactions'>
          
          <Link className="open-post-interaction-link-button">
            Comments
            <FontAwesomeIcon icon={faComment} className='fontawesome-icon' />
          </Link>
          
          {/*
          <button 
            onClick={() => handleSupportButton(userId, post._id)} 
            className="interaction-link-button">
            <div className='interaction-link-support-div'>
              {post.support} 
              <p>Support</p>
            </div>
          </button>
          */}

          <button 
            onClick={() => handleSupportButton(userId, post._id)} 
            className={`interaction-link-button ${post.isSupported ? 'supported-button' : 'unsupported-button'}`}
          >
            <div className="interaction-link-support-div">
              {post.support}
              <p>Support</p>
            </div>
          </button>

          <button onClick={() => copyPostLinkToClipboard(post._id)} className="interaction-link-button">
            Share
            <FontAwesomeIcon icon={faShareNodes} className='fontawesome-icon' />
          </button>

        </div>

        <div className='comments-f-box'>
          <p>
            Comments
          </p>
          <form onSubmit={handleSubmitComment}>
            <textarea
              className='comment-textbox-field'
              value={commentText}
              onChange={handleCommentTextChange}
              placeholder="Add your comment here..."
              maxLength={MAX_COMMENT_LENGTH} // Set the maxLength
            /><br />
            <p>Character count: {commentText.length}/{MAX_COMMENT_LENGTH}</p> {/* Display character count */}
            {/*<button type="submit" className='submit-comment-button'>Post Comment</button>*/}
            <button
              type="submit"
              className='submit-comment-button'
              disabled={!commentText.trim()} // Disable button if commentText is empty or contains only whitespace
            >
              Post Comment  
            </button>

          </form>
        </div>

      </div>

      <div>
        {/* Comments Section */}
        <div className='comments-list'>
          {post.comments && post.comments.length > 0 ? (
            post.comments.map((comment, index) => (
              <div key={index} className='comments'>
                <div className='post-author-access-control'>
                  
                  <Link 
                    to={comment.commentAuthor.isAuthor ? `/profile` : `/user/${comment.commentAuthor.decoyId}`} 
                    className='comment-author-link'
                  >
                    <img
                      loading="lazy"
                      src={comment.commentAuthor.profilePicture}
                      alt="Profile"
                      className='comment-author-profile-image'
                    />
                    <p className='comment-author-details'>{comment.commentAuthor.name} {comment.commentAuthor.surname} | </p>
                    <p className='comment-author-details-account-name'> {comment.commentAuthor.accountName}</p>
                  </Link>

                  {isEditCommentModalOpen && (
                    <div className="modal">
                      <div className="modal-content">
                        <h2>Edit Comment</h2>
                        <textarea
                          value={editedCommentText}
                          onChange={handleEditedCommentTextChange}
                          placeholder="Edit your comment here..."
                          maxLength={280} // Set the maximum character limit
                        /><br />
                        <button onClick={handleUpdateComment}>Save</button>
                        <button onClick={() => setIsEditCommentModalOpen(false)}>Cancel</button>
                      </div>
                    </div>
                  )}

                  <div className='comment-control-button' ref={dropdownRef}>
                    <FontAwesomeIcon icon={faEllipsis} size='lg' onClick={() => handleDropdownClick(comment._id)} />
                    {selectedComment === comment._id && (
                      <div className="dropdown-menu">
                        {comment.commentAuthor._id === userId && (
                          <>
                            <button onClick={() => handleEditComment(comment._id)}>Edit comment</button>
                            <button onClick={() => handleDeleteComment(post._id, comment._id)}>Delete comment</button>
                          </>
                        )}
                        {comment.commentAuthor._id !== userId && (
                          <button onClick={() => handleReportComment(comment._id)}>Report Comment</button>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <hr />
                <p className='comment-body'>{comment.text}</p> 
                {/*
                <p className='comment-body' style={{display:'block'}}>{comment._id}</p>
                {comment.commentAuthor._id === userId && <p style={{display:'none'}}>This comment was authored by you.</p>}
                */}
                <p className='comment-time-stamp'>{formatDate(new Date(comment.createdAt))}</p>
              </div>
            ))
          ) : (   
            <p className='no-comments-notification'>No comments yet</p>
          )}
        </div>
      </div>

    </div>

  );
};

export default SinglePostPage;

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './auth.css';
import LandingPage from '../components/LandingPage';
import SomeWentWrong from '../components/SomeWentWrong';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import AdPosts from '../bodyFiles/AdPosts';
import WelcomeTour from './TourGuide/WelcomeTour';

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [displayedPosts, setDisplayedPosts] = useState([]); // State for posts to display
  const [endOfList, setEndOfList] = useState(false); // State to check if end of list is reached
  const postsPerPage = 20; // Number of posts to display at once
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDataAndPosts = async () => {
      try {
        // Fetch user data
        const userDataResponse = await fetch('https://lernacy.co.za/api/dashboard', {
          method: 'GET',
          credentials: 'include',
        });

        if (userDataResponse.status === 404 || userDataResponse.status === 403) {
          setError('Server returned 404 or 403');
          return;
        }

        if (!userDataResponse.ok) {
          throw new Error('Failed to fetch user data');
        } 

        const userData = await userDataResponse.json();
        if (!userData.success) {
          throw new Error(userData.message || 'Unknown error while fetching user data');
        }

        setUserData(userData.user);
        setIsAuthenticated(true);

        // Check if accNameFix is false and redirect if so
        if (userData.user.accNameFix === false) {
          navigate('/login-second-stage');
          return;
        }

        // Fetch posts if accNameFix is not false
        const postsResponse = await fetch('https://lernacy.co.za/api/dashboard-posts', {
          method: 'GET',
          credentials: 'include',
        });

        if (postsResponse.status === 404 || postsResponse.status === 403) {
          setError('Server returned 404 or 403');
          return;
        }

        if (!postsResponse.ok) {
          throw new Error('Failed to fetch posts');
        }

        const postData = await postsResponse.json();
        setPosts(postData.posts);
        setFilteredPosts(postData.posts);
        setDisplayedPosts(postData.posts.slice(0, postsPerPage)); // Set initial displayed posts
      } catch (error) {
        console.error('Error fetching data:', error.message);
        setError('An error occurred while fetching data. Please try again later.');
        setIsAuthenticated(false);
      }
    };

    fetchUserDataAndPosts();
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated === false) {
      setError('Access Denied');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredPosts(posts); // Reset to all posts if the search is empty
    } else {
      const filtered = posts.filter(post =>
        post.content.toLowerCase().includes(searchQuery.toLowerCase()) // Check if post content includes the search query
      );
      setFilteredPosts(filtered);
    }
  }, [searchQuery, posts]);

  // Function to load more posts
  const loadMorePosts = () => {
    if (displayedPosts.length >= filteredPosts.length) {
      setEndOfList(true);
      return;
    }
    const newPosts = filteredPosts.slice(displayedPosts.length, displayedPosts.length + postsPerPage);
    setDisplayedPosts([...displayedPosts, ...newPosts]);
  };

  useEffect(() => {
    // Load more posts on scroll
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
        loadMorePosts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [displayedPosts, filteredPosts]);

  const handleSupportButton = async (userId, postId) => {
    try {
      const postIndex = posts.findIndex(post => post._id === postId);
      if (postIndex === -1) {
        console.error('Post not found');
        return;
      }

      const updatedPosts = [...posts];
      const post = updatedPosts[postIndex];

      if (post.isSupported) {
        post.supportCount--;
      } else {
        post.supportCount++;
      }
      post.isSupported = !post.isSupported;

      setPosts(updatedPosts);
      setFilteredPosts(updatedPosts);

      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });

      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;

      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }

      const response = await fetch(`https://lernacy.co.za/api/support/${postId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ userId, postId }),
        credentials: 'include',
      });

      if (!response.ok) {
        console.error('Failed to update support count on the server');
        setPosts(posts);
        setFilteredPosts(posts);
        return;
      }

    } catch (error) {
      console.error('Error supporting post:', error);
      setPosts(posts);
      setFilteredPosts(posts);
    }
  };

  const formatDateWithTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ][date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day} ${month} ${year} - ${hours}:${minutes}`;
  };

  const copyPostLinkToClipboard = (postId) => {
    const postLink = `${window.location.origin}/open-post/${postId}`;
    navigator.clipboard.writeText(postLink)
      .then(() => alert('Post link copied to clipboard'))
      .catch((error) => console.error('Failed to copy post link: ', error));
  };

  const renderAuthorRole = (role) => {
    switch (role) {
      case 'User':
        return 'User Account';
      case 'School':
        return 'Official School Account ▼';
      case 'Admin':
        return 'Admin Communiqué!';
      case 'Scheme':
        return 'Official Scheme Account';
      default:
        return role;
    }
  };

  const filterPostsByRole = (role) => {
    if (role === 'All') {
      setFilteredPosts(posts);
      setDisplayedPosts(posts.slice(0, postsPerPage)); // Reset displayed posts
      setEndOfList(false); // Reset end of list state
    } else {
      const filtered = posts.filter(post => post.authorDetails.role === role);
      setFilteredPosts(filtered);
      setDisplayedPosts(filtered.slice(0, postsPerPage)); // Reset displayed posts based on role
      setEndOfList(false); // Reset end of list state
    }
  };

  const toggleFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div>
      {isAuthenticated === null ? (
        <p>Loading...</p>
      ) : isAuthenticated === false ? (
        <LandingPage />
      ) : error === 'Server returned 404 or 403' ? (
        <SomeWentWrong />
      ) : error ? (
        <p>{error}</p>
      ) : userData && (
        <>
          <div className='user-profile-account-aligner-container'>
            <h1 className='activity-name-tag-h1'>Dashboard</h1>


            <div>
              {/* Include the WelcomeTour component */}
              <WelcomeTour />
            </div>


            <div className='user-profile-account-posts'>

            <div className='filter-and-search-box'>

              <div className="filter-dropdown">
                <button className="filter-toggle-button" onClick={toggleFilterOptions}>
                  Filter by <span className="arrow-down">{showFilterOptions ? '▲' : '▼'}</span>
                </button>
                <div className={`filter-options ${showFilterOptions ? 'show' : ''}`}>
                  <button onClick={() => filterPostsByRole('All')}>All</button>
                  <button onClick={() => filterPostsByRole('User')}>User</button>
                  <button onClick={() => filterPostsByRole('Admin')}>Admin</button>
                  <button onClick={() => filterPostsByRole('School')}>School</button>
                  <button onClick={() => filterPostsByRole('Scheme')}>Scheme</button>
                </div>
              </div>

              <div>
                <input
                  type="text"
                  placeholder="Search posts..."
                  value={searchQuery}
                  onChange={handleSearchInputChange} // This function is called to update the search query
                  className="dashboard-search-input"
                />
              </div>

              </div> 

              <ol className="vertical-list">
                {filteredPosts
                  .slice() // or you can use displayedPosts if you want to paginate
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((post, index) => (
                    <React.Fragment key={post._id}>
                      {/* Render the blog post */}
                      <li key={post._id}>
                        <div className='main-post-details'>
                          <Link 
                            to={post.authorDetails._id === userData.userId ? '/profile' : `/user/${post.authorDetails.decoyId}`} 
                            className='user-account-link'
                          >
                            <div className='single-post-main-block'>
                              <img
                                src={post.authorDetails.profilePicture}
                                loading="lazy"
                                alt="Profile-picture" className='posts-profileimage'
                              />
                              <div className='author-details-in-posts'>
                                <p className='posts-author-name-surname'>{post.authorDetails.name} {post.authorDetails.surname}</p>
                                <p className='posts-accountName'>| {post.authorDetails.accountName}</p>
                              </div> 
                            </div>
                          </Link> 

                          <hr />

                          <Link to={`https://lernacy.co.za/open-post/${post._id}`} className="post-link">
                            <p className='dashboard-role-statement'>{renderAuthorRole(post.authorDetails.role)}</p>
                            <p className='dashboard-post-content-text'>{post.content}</p>
                            <p className='created-at'>{formatDateWithTime(new Date(post.createdAt))}</p>
                          </Link>

                          <div className='user-dashboard-posts-interactions'>
                            <Link to={`/open-post/${post._id}`} className="interaction-link-button">
                              {post.commentCount} Comment(s)
                            </Link>
                            <button 
                              onClick={() => handleSupportButton(userData.userId, post._id)}
                              className={`interaction-link-button ${post.isSupported ? 'supported-button' : 'unsupported-button'}`}
                            >
                              <div className="interaction-link-support-div">
                                {post.supportCount} 
                                <p>Support</p>
                              </div>
                            </button>
                            <button onClick={() => copyPostLinkToClipboard(post._id)} className="interaction-link-button">
                              Share
                              <FontAwesomeIcon icon={faShareNodes} className='fontawesome-icon' />
                            </button>
                          </div>
                        </div>
                      </li>
                      {/* Insert the Ad component after every 2 posts */}
                      <div className='ap-post-block-dash'>
                        {index % 3 === 1 && <AdPosts refreshAd={true} />}
                      </div>
                    </React.Fragment>
                  ))}
                {endOfList && <p style={{textAlign:'center'}}>No more posts to load.</p>}
              </ol>


            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;




/*
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './auth.css';
import LandingPage from '../components/LandingPage';
import SomeWentWrong from '../components/SomeWentWrong';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDataAndPosts = async () => {
      try {
        // Fetch user data
        const userDataResponse = await fetch('https://lernacy.co.za/api/dashboard', {
          method: 'GET',
          credentials: 'include',
        });

        if (userDataResponse.status === 404 || userDataResponse.status === 403) {
          setError('Server returned 404 or 403');
          return;
        }

        if (!userDataResponse.ok) {
          throw new Error('Failed to fetch user data');
        } 

        const userData = await userDataResponse.json();
        if (!userData.success) {
          throw new Error(userData.message || 'Unknown error while fetching user data');
        }

        setUserData(userData.user);
        setIsAuthenticated(true);

        // Check if accNameFix is false and redirect if so
        if (userData.user.accNameFix === false) {
          navigate('/login-second-stage');
          return;
        }

        // Fetch posts if accNameFix is not false
        const postsResponse = await fetch('https://lernacy.co.za/api/dashboard-posts', {
          method: 'GET',
          credentials: 'include',
        });

        if (postsResponse.status === 404 || postsResponse.status === 403) {
          setError('Server returned 404 or 403');
          return;
        }

        if (!postsResponse.ok) {
          throw new Error('Failed to fetch posts');
        }

        const postData = await postsResponse.json();
        setPosts(postData.posts);
        setFilteredPosts(postData.posts);
      } catch (error) {
        console.error('Error fetching data:', error.message);
        setError('An error occurred while fetching data. Please try again later.');
        setIsAuthenticated(false);
      }
    };

    fetchUserDataAndPosts();
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated === false) {
      setError('Access Denied');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredPosts(posts);
    } else {
      const filtered = posts.filter(post =>
        post.content.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredPosts(filtered);
    }
  }, [searchQuery, posts]);

  const handleSupportButton = async (userId, postId) => {
    try {
      const postIndex = posts.findIndex(post => post._id === postId);
      if (postIndex === -1) {
        console.error('Post not found');
        return;
      }

      const updatedPosts = [...posts];
      const post = updatedPosts[postIndex];

      if (post.isSupported) {
        post.supportCount--;
      } else {
        post.supportCount++;
      }
      post.isSupported = !post.isSupported;

      setPosts(updatedPosts);
      setFilteredPosts(updatedPosts);

      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });

      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;

      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }

      const response = await fetch(`https://lernacy.co.za/api/support/${postId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ userId, postId }),
        credentials: 'include',
      });

      if (!response.ok) {
        console.error('Failed to update support count on the server');
        setPosts(posts);
        setFilteredPosts(posts);
        return;
      }

    } catch (error) {
      console.error('Error supporting post:', error);
      setPosts(posts);
      setFilteredPosts(posts);
    }
  };

  const formatDateWithTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ][date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day} ${month} ${year} - ${hours}:${minutes}`;
  };

  const copyPostLinkToClipboard = (postId) => {
    const postLink = `${window.location.origin}/open-post/${postId}`;
    navigator.clipboard.writeText(postLink)
      .then(() => alert('Post link copied to clipboard'))
      .catch((error) => console.error('Failed to copy post link: ', error));
  };

  const renderAuthorRole = (role) => {
    switch (role) {
      case 'User':
        return 'User Account';
      case 'School':
        return 'Official School Account ▼';
      case 'Admin':
        return 'Admin Communiqué!';
      case 'Scheme':
        return 'Official Scheme Account';
      default:
        return role;
    }
  };

  const filterPostsByRole = (role) => {
    if (role === 'All') {
      setFilteredPosts(posts);
    } else {
      const filtered = posts.filter(post => post.authorDetails.role === role);
      setFilteredPosts(filtered);
    }
  };

  const toggleFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div >
      {isAuthenticated === null ? (
        <p>Loading...</p>
      ) : isAuthenticated === false ? (
        <LandingPage />
      ) : error === 'Server returned 404 or 403' ? (
        <SomeWentWrong />
      ) : error ? (
        <p>{error}</p>
      ) : userData && (
        <>
          <div className='user-profile-account-aligner-container'>
            <h1 className='activity-name-tag-h1'>Dashboard</h1>

            <div className='user-profile-account-posts'>

              <div className='filter-and-search-box'>

                <div className="filter-dropdown">
                  <button className="filter-toggle-button" onClick={toggleFilterOptions}>
                    Filter by <span className="arrow-down">{showFilterOptions ? '▲' : '▼'}</span>
                  </button>
                  <div className={`filter-options ${showFilterOptions ? 'show' : ''}`}>
                    <button onClick={() => filterPostsByRole('All')}>All</button>
                    <button onClick={() => filterPostsByRole('User')}>User</button>
                    <button onClick={() => filterPostsByRole('Admin')}>Admin</button>
                    <button onClick={() => filterPostsByRole('School')}>School</button>
                    <button onClick={() => filterPostsByRole('Scheme')}>Scheme</button>
                  </div>
                </div>

                <div>
                  <input
                    type="text"
                    placeholder="Search posts..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    className="dashboard-search-input"
                  />
                </div>

              </div>

              <ol className="vertical-list">
                {filteredPosts
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map(post => (
                    <li key={post._id}>
                      <div className='main-post-details'>
                        <Link 
                          to={post.authorDetails._id === userData.userId ? '/profile' : `/user/${post.authorDetails.decoyId}`} 
                          className='user-account-link'
                        >
                          <div className='single-post-main-block'>
                            
                            <img
                              src={post.authorDetails.profilePicture}
                              alt="Profile-picture" className='posts-profileimage'
                            />

                            <div className='author-details-in-posts'>
                              <p className='posts-author-name-surname'>{post.authorDetails.name} {post.authorDetails.surname}</p> <p className='posts-accountName'>| {post.authorDetails.accountName}</p>
                            </div> 

                          </div>
                        </Link> 

                        <hr />

                        <Link to={`https://lernacy.co.za/open-post/${post._id}`} className="post-link">
                          
                          <p className='dashboard-role-statement'>{renderAuthorRole(post.authorDetails.role)}</p>

                          <p className='dashboard-post-content-text'>{post.content}</p>
                          
                          <p className='created-at'>{formatDateWithTime(new Date(post.createdAt))}</p>

                        </Link>

                        <div className='user-dashboard-posts-interactions'>

                          <Link to={`/open-post/${post._id}`} className="interaction-link-button">
                            {post.commentCount} Comment(s)
                          </Link>

                          <button 
                            onClick={() => handleSupportButton(userData.userId, post._id)}
                            className={`interaction-link-button ${post.isSupported ? 'supported-button' : 'unsupported-button'}`}
                          >
                            <div className="interaction-link-support-div">
                              {post.supportCount} 
                              <p>Support</p>
                            </div>
                          </button>

                          <button onClick={() => copyPostLinkToClipboard(post._id)} className="interaction-link-button">
                            Share
                            <FontAwesomeIcon icon={faShareNodes} className='fontawesome-icon' />
                          </button>
                          
                        </div>
                      </div>
                    </li>
                  ))}
              </ol>
              <br />
            </div>
          </div>
        </>
      )}
      
    </div>
  );
};

export default Dashboard;
*/

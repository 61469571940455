import React, { useState } from 'react';

const FAQ = () => {
    const [visibleIndex, setVisibleIndex] = useState(null); // State to track the active question

    const faqs = [
        {
            question: "What is Lernacy and how does it work?",
            answer: "Lernacy is a free educational platform that offers personalized learning paths, interactive activities, and progress tracking to enhance the learning experience for students, parents, and educators."
        },
        {
            question: "Are the learning resources on Lernacy really free?",
            answer: "Yes, all learning resources on Lernacy are completely free, ensuring that high-quality educational tools are accessible to everyone."
        },
        {
            question: "What types of educational tools does Lernacy offer?",
            answer: "Lernacy provides a variety of educational tools, including personalized learning paths, interactive practice activities, and detailed progress tracking features."
        },
        {
            question: "How can Lernacy support my child’s learning journey?",
            answer: "Lernacy supports your child’s learning journey by offering tailored resources that cater to their unique needs, helping them build skills and track progress effectively."
        },
        {
            question: "Is Lernacy suitable for all grade levels?",
            answer: "Yes, Lernacy is designed to be suitable for learners of all grade levels, providing resources that adapt to different ages and skill levels."
        },
        {
            question: "Can parents track their child's progress on Lernacy?",
            answer: "Absolutely! Parents can easily monitor their child's progress through detailed reports, allowing them to support learning effectively."
        },
        {
            question: "How does Lernacy personalize learning paths for students?",
            answer: "Lernacy personalizes learning paths using data-driven insights, allowing students to engage with resources that match their individual learning styles and needs."
        },
        {
            question: "What subjects and topics are covered in Lernacy's resources?",
            answer: "Lernacy covers a wide range of subjects, including math, science, language arts, and more, ensuring comprehensive support for learners."
        },
        {
            question: "How do educators benefit from using Lernacy in their classrooms?",
            answer: "Educators benefit from Lernacy by accessing engaging resources that enhance their teaching methods and help track student progress, fostering a collaborative learning environment."
        },
        {
            question: "Is there a mobile app available for Lernacy?",
            answer: "Currently, Lernacy is accessible via web browsers, but we are exploring the development of a mobile app for enhanced user convenience."
        },
        {
            question: "How can I get started with Lernacy?",
            answer: "Getting started with Lernacy is easy! Simply visit our website, sign up for a free account, and explore our range of educational resources."
        },
        {
            question: "Where can I find support or help using Lernacy?",
            answer: "For support, users can visit our Help Center on the website, where we provide guides, FAQs, and contact options for further assistance."
        }
    ];

    const toggleAnswer = (index) => {
        setVisibleIndex(visibleIndex === index ? null : index); // Toggle the visible answer
    };

    return (
        <section id="faq">
            <dl>
                {faqs.map((faq, index) => (
                    <div key={index}>
                        <dt onClick={() => toggleAnswer(index)}>{faq.question}</dt>
                        <dd className={visibleIndex === index ? "show" : ""}>{faq.answer}</dd>
                    </div>
                ))}
            </dl>
        </section>
    );
};

export default FAQ;
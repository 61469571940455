// src/pages/EarlyLearning.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const EarlyLearning = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Make a request to the server to get user information
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include', // Include credentials for cross-origin requests
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchUser();
  }, []); // Run the effect once on component mount

  return (
    <div>
        <h1>Early Learning Page</h1>

        {user ? (
            <p>Welcome, {user.name} {user.surname}!</p>
        ) : (
            <p>Loading user data...</p>
        )}

        <div>

            <div>

                <h3>Suitable for Toddlers (1-3 years old)</h3>

                <Link className="my-text" to="/sensory-play">Sensory Play</Link><br></br>

                <Link className="my-text" to="/mock-exam">Books and Reading</Link><br></br>

                <Link className="my-text" to="/mock-exam">Movement and Music</Link><br></br>

                <Link className="my-text" to="/mock-exam">Art and Creativity</Link>

            </div>

            <div>

                <h3>Suitable for Preschoolers (4-5 years old)</h3>

                <Link className="my-text" to="/mock-exam">Building and Construction</Link><br></br>

                <Link className="my-text" to="/mock-exam">Pretend Play</Link><br></br>

                <Link className="my-text" to="/mock-exam">Counting and Sorting</Link><br></br>

                <Link className="my-text" to="/mock-exam">Puzzles and Games</Link><br></br>

                <Link className="my-text" to="/mock-exam">Art and Creativity</Link>

            </div>

            <div>
            
                <h3>Suitable for Grade RR (5-6 years old)</h3>

                <Link className="my-text" to="/mock-exam">Pre-writing and Writing Tools</Link><br></br>

                <Link className="my-text" to="/mock-exam">Reading Practice</Link><br></br>

                <Link className="my-text" to="/mock-exam">Number and Addition Games</Link><br></br>

                <Link className="my-text" to="/mock-exam">Science and Nature Exploration</Link><br></br>

                <Link className="my-text" to="/mock-exam">Creative Activities</Link>
            
            </div>

        </div>

    </div>
  );
};

export default EarlyLearning;

// ResourceType.js
import React from 'react';
import ResourceLink from './ResourceLink';

const ResourceType = ({ type, resources }) => {
  return (
    <div style={{ margin: '10px 0' }}>
      <h3 className='oer-resource-type-name'>{type.charAt(0).toUpperCase() + type.slice(1)}</h3>
      <ul className='oer-resource-type-links'>
        {resources.map((resource, index) => (
          <ResourceLink key={index} resource={resource} />
        ))}
      </ul>
    </div>
  );
};

export default ResourceType;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PinValidation from './PinValidation';

const StoryBlock1 = ({ user }) => {
    const [answers, setAnswers] = useState({});
    const [schoolNameInput, setSchoolNameInput] = useState('');
    const [schoolNameSuggestions, setSchoolNameSuggestions] = useState([]);
    const [pinValidated, setPinValidated] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchSchoolNameSuggestions = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/institutions/schools/names');
                const { success, schoolNames } = response.data;
                if (success) {
                    setSchoolNameSuggestions(schoolNames);
                } else {
                    console.error('Failed to fetch school names:', response.data);
                }
            } catch (error) {
                console.error('Error fetching school names:', error);
            }
        };

        const fetchUserId = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/study', { withCredentials: true });
                const { user } = response.data;
                setUserId(user._id);
            } catch (error) {
                console.error('Error fetching user ID:', error);
            }
        };

        fetchSchoolNameSuggestions();
        fetchUserId();
    }, []);

    const handleChange = (e) => {
        setAnswers({
            ...answers,
            [e.target.name]: e.target.value
        });
    };

    const handleSchoolNameInputChange = (e) => {
        const { value } = e.target;
        setSchoolNameInput(value);

        const filteredSuggestions = schoolNameSuggestions.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setSchoolNameSuggestions(filteredSuggestions);
    };

    const validatePin = () => {
        // Additional logic for validation if needed
        return pinValidated;
    };

    const onSubmit = async () => {
        if (!validatePin()) {
            alert('PIN validation failed. Submission blocked.');
            return;
        }
    
        try {
            // Fetch CSRF token
            const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
            if (!csrfResponse.data.csrfToken) {
                throw new Error('Failed to fetch CSRF token');
            }
            const csrfToken = csrfResponse.data.csrfToken;
    
            const correctAnswers = {
                question1: 'B',
                question2: 'C',
                question3: 'B',
                question4: 'B',
                question5: 'B'
            };
    
            let score = 0;
            for (let key in correctAnswers) {
                if (answers[key] === correctAnswers[key]) {
                    score++;
                }
            }
    
            const submissionData = {
                score: score,
                taskBlock: 1,
                userId: userId,
                taskName: 'Comprehension-Levels',
                schoolName: schoolNameInput,
            };
    
            // console.log('Submission Data:', submissionData); // Log the data being passed to the route
    
            // POST request with CSRF token and credentials in headers
            const response = await axios.post('https://lernacy.co.za/api/ed-support/taskSubmit', submissionData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken // Include CSRF token in the header
                }
            });
    
            if (response.data.success) {
                alert('Task submitted successfully!');
                window.location.reload(); // Reload the page after successful submission
            } else {
                console.error('Failed to submit task:', response.data.error);
                alert('Failed to submit task. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting task:', error);
            alert('Error submitting task. Please check your network connection.');
        }
    };

    return (
        <div className='story-wall-block'>
            <h2>Block 1</h2>
            <h3><strong>The Golden Baobab</strong></h3>
            <div className='story-div-image-text'>
                <div className='story-image-cover-block'>
                    <img loading="lazy" src='/activityImages/ed-support/golden-baobab-tree.webp' alt='Golden Baobab Tree' />
                </div>
                <div>
                    <p className='story-p-tag'>
                        In a small village nestled deep within the rolling hills of Limpopo, South Africa,
                        there lived a young girl named Thandi. Thandi was known for her adventurous spirit and
                        her love for exploring the wilderness that surrounded her home.
                    </p>
                    <p className='story-p-tag'>
                        One sunny afternoon, while wandering through the dense bushveld, Thandi stumbled upon a
                        majestic baobab tree unlike any she had ever seen. Its trunk was as wide as a small
                        house, and its branches reached out like welcoming arms to the sky above. But what
                        caught Thandi's eye the most was its bark – it shimmered with a golden hue that
                        glistened in the sunlight.
                    </p>
                    <p className='story-p-tag'>
                        Curious and excited, Thandi approached the tree cautiously. As she touched its smooth,
                        golden bark, a voice echoed softly in her mind. "Seek the wisdom within," it whispered.
                    </p>
                    <p className='story-p-tag'>
                        Intrigued, Thandi closed her eyes and placed her hand on the trunk. Suddenly, a rush of
                        images flooded her mind – visions of her ancestors, tales of courage and resilience,
                        and the wisdom of the land. She felt a deep connection to her heritage and the natural
                        world around her.
                    </p>
                    <p className='story-p-tag'>
                        From that day on, Thandi visited the golden baobab often, sitting beneath its branches
                        and listening to its silent teachings. She learned to appreciate the beauty of her
                        homeland and the wisdom passed down through generations.
                    </p>
                    <p className='story-p-tag'>
                        Years later, when Thandi had grown into a wise young woman, she became known as the
                        storyteller of her village. She shared the tales of the golden baobab with children and
                        adults alike, reminding them of the importance of respecting nature and embracing their
                        cultural roots.
                    </p>
                    <p className='story-p-tag'>
                        And so, the golden baobab remained a symbol of strength, wisdom, and the enduring spirit
                        of the people in the Limpopo village.
                    </p>
                </div>
            </div>

            <hr />

            <div className='comprehension-questions'>
                <h3>Comprehension Questions</h3>
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <p>1. What caught Thandi's attention about the baobab tree?</p>
                        <label>
                            <input type="radio" name="question1" value="A" onChange={handleChange} />
                            A) Its towering height
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="B" onChange={handleChange} />
                            B) Its golden shimmering bark
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="C" onChange={handleChange} />
                            C) Its colorful flowers
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="D" onChange={handleChange} />
                            D) Its fruit hanging low
                        </label>
                    </div>

                    <div>
                        <p>2. How did Thandi feel when she touched the baobab tree?</p>
                        <label>
                            <input type="radio" name="question2" value="A" onChange={handleChange} />
                            A) Fearful and scared
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="B" onChange={handleChange} />
                            B) Indifferent and bored
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="C" onChange={handleChange} />
                            C) Connected and enlightened
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="D" onChange={handleChange} />
                            D) Angry and upset
                        </label>
                    </div>

                    <div>
                        <p>3. What role did Thandi take on in her village after growing up?</p>
                        <label>
                            <input type="radio" name="question3" value="A" onChange={handleChange} />
                            A) The village healer
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="B" onChange={handleChange} />
                            B) The storyteller
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="C" onChange={handleChange} />
                            C) The chief
                        </label><br />
                        <label>
                            D) The farmer
                        </label>
                    </div>

                    <div>
                        <p>4. What did the golden baobab symbolize for the people in the Limpopo village?</p>
                        <label>
                            <input type="radio" name="question4" value="A" onChange={handleChange} />
                            A) Wealth and prosperity
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="B" onChange={handleChange} />
                            B) Strength, wisdom, and enduring spirit
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="C" onChange={handleChange} />
                            C) Mystery and magic
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="D" onChange={handleChange} />
                            D) Adventure and exploration
                        </label>
                    </div>

                    <div>
                        <p>5. What lesson did Thandi learn from the golden baobab?</p>
                        <label>
                            <input type="radio" name="question5" value="A" onChange={handleChange} />
                            A) The importance of wealth
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="B" onChange={handleChange} />
                            B) The importance of respecting nature and cultural roots
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="C" onChange={handleChange} />
                            C) The value of technology
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="D" onChange={handleChange} />
                            D) The power of solitude
                        </label>
                    </div>
                </form>
            </div>

            <hr />

            <div className='school-name-pin-block'>
                <h3>School Information</h3>
                <label htmlFor="schoolNameInput">School Name:</label><br />
                <input
                    type="text"
                    id="schoolNameInput"
                    name="schoolNameInput"
                    value={schoolNameInput}
                    onChange={handleSchoolNameInputChange}
                    list="schoolNameSuggestions"
                    required
                    className='tasks-school-name-input'
                /><br />
                <datalist id="schoolNameSuggestions">
                    {schoolNameSuggestions.map((schoolName, index) => (
                        <option key={index} value={schoolName} />
                    ))}
                </datalist>
                {!pinValidated && (
                    <PinValidation
                        schoolNameInput={schoolNameInput}
                        onSubmit={() => setPinValidated(true)}
                    />
                )}
                {pinValidated && (
                    <button className='activity-submit-button' type="button" onClick={onSubmit}>
                        Submit Task 
                    </button>
                )}
            </div> 
        </div>
    );
};

export default StoryBlock1;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './pages-styling/pages.css';
import useFetchUserData from '../../components/useFetchUserData';

const GradeFiveMathematics = () => {
  const user = useFetchUserData();
  const [showRepeatedAdditionModal, setShowRepeatedAdditionModal] = useState(false);
  const [showMultiplyAndDivideModal, setShowMultiplyAndDivideModal] = useState(false);
  const [showGrFiveBreakDownModal, setShowGrFiveBreakDownModal] = useState(false);
  const [showLinearEquationModal, setShowLinearEquationModal] = useState(false);
  const [showEquationBuilderModal, setShowEquationBuilderModal] = useState(false);
  const navigate = useNavigate();


  const handleRepeatedAdditionProceed = () => {
    setShowRepeatedAdditionModal(false);
    navigate('/grade-five-repeated-addition');
  };

  const handleEquationBuilderProceed = () => {
    setShowEquationBuilderModal(false);
    navigate('/grade-five-equation-builder');
  };

  const handleMultiplyAndDivideProceed = () => {
    setShowMultiplyAndDivideModal(false);
    navigate('/grade-five-multiply-and-divide');
  };

  const handleLinearEquationProceed = () => {
    setShowLinearEquationModal(false);
    navigate('/grade-five-linear-equation');
  };

  const handleGrFiveBreakDownProceed = () => {
    setShowGrFiveBreakDownModal(false);
    navigate('/grade-five-break-it-down');
  };

  return (
    <div className='game-name-list-files'>
      <div className='game-name-list-files-box'>
        {user && user.accountName ? (
          <>
            <h1 className='grade-level-subject'>Grade 5 - Mathematics</h1>

            <button onClick={() => setShowGrFiveBreakDownModal(true)} className="my-game-name-text">Break Down</button>
            {showGrFiveBreakDownModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowGrFiveBreakDownModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleGrFiveBreakDownProceed}>Proceed</button>
                  <button onClick={() => setShowGrFiveBreakDownModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            <button onClick={() => setShowEquationBuilderModal(true)} className="my-game-name-text">Equation Builder</button>
            {showEquationBuilderModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowEquationBuilderModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleEquationBuilderProceed}>Proceed</button>
                  <button onClick={() => setShowEquationBuilderModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            <button onClick={() => setShowLinearEquationModal(true)} className="my-game-name-text">Linear Equations</button>
            {showLinearEquationModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowLinearEquationModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleLinearEquationProceed}>Proceed</button>
                  <button onClick={() => setShowLinearEquationModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            <button onClick={() => setShowMultiplyAndDivideModal(true)} className="my-game-name-text">Multiply And Divide</button>
            {showMultiplyAndDivideModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowMultiplyAndDivideModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleMultiplyAndDivideProceed}>Proceed</button>
                  <button onClick={() => setShowMultiplyAndDivideModal(false)}>Cancel</button>
                </div>
              </div>
            )}
            
            {/* Repeated Addition Modal */}
            <button onClick={() => setShowRepeatedAdditionModal(true)} className="my-game-name-text">Repeated Addition</button>
            {showRepeatedAdditionModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowRepeatedAdditionModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleRepeatedAdditionProceed}>Proceed</button>
                  <button onClick={() => setShowRepeatedAdditionModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            </>
        ) : null}
      </div>
    </div>
  );
};

export default GradeFiveMathematics;

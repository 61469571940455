import React, { useState } from 'react';
import { processText } from '../utils/processText';

const TextProcessor = () => {
  const [inputText, setInputText] = useState('');
  const [processedText, setProcessedText] = useState(null);

  const handleTextChange = (e) => {
    setInputText(e.target.value);
  };

  const handleProcessText = () => {
    const result = processText(inputText);
    setProcessedText(result);
  };

  return (
    <div>
      <h1>Text Processor</h1>
      <textarea
        value={inputText}
        onChange={handleTextChange}
        placeholder="Enter text here..."
        rows="10"
        cols="50"
      />
      <button onClick={handleProcessText}>Process Text</button>
      {processedText && (
        <div>
          <h2>Processed Text</h2>
          <pre>Original Text: {JSON.stringify(processedText.correctedText, null, 2)}</pre>
          <h3>Details</h3>
          <pre>{JSON.stringify(processedText, null, 2)}</pre>
          <h3>Misspelled Words and Suggestions</h3>
          <ul>
            {processedText.misspelledWords.map((word, index) => (
              <li key={index}>
                {word}: {processedText.suggestions[word].join(', ')}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TextProcessor;

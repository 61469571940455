import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PinValidation from './tasks/PinValidation';

const EducationSupport = () => {
    const [user, setUser] = useState(null);
    const [schoolNameInput, setSchoolNameInput] = useState('');
    const [schoolNameSuggestions, setSchoolNameSuggestions] = useState([]);
    const [pinValidated, setPinValidated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch user information
        const fetchUser = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setUser(data.user);

                    // Check if user has an account name
                    if (!data.user.accountName) {
                        navigate('/login-second-stage');
                    }
                } else {
                    const errorData = await response.json();
                    console.error('Error fetching user data:', errorData.message);
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching user data:', error.message);
                navigate('/login');
            }
        };

        // Fetch school names
        const fetchSchoolNameSuggestions = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/institutions/schools/names');
                const { success, schoolNames } = response.data;
                if (success) {
                    setSchoolNameSuggestions(schoolNames);
                } else {
                    console.error('Failed to fetch school names:', response.data);
                }
            } catch (error) {
                console.error('Error fetching school names:', error);
            }
        };

        fetchUser();
        fetchSchoolNameSuggestions();
    }, [navigate]);

    const handleSchoolNameInputChange = (e) => {
        const { value } = e.target;
        setSchoolNameInput(value);

        const filteredSuggestions = schoolNameSuggestions.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setSchoolNameSuggestions(filteredSuggestions);
    };

    return (
        <div className='page-colour-manager'>
            <h1 className='page-title'>Education Support</h1>

            {!pinValidated && (
                <div className='school-name-pin-block'>
                    <h3>School Information</h3>

                    <label style={{color:'red'}}>*The assessments must take place under supervision in a controlled environment.*</label>
                    <hr />
                    <label htmlFor="schoolNameInput">School Name:</label><br />
                    <input
                        type="text"
                        id="schoolNameInput"
                        name="schoolNameInput"
                        value={schoolNameInput}
                        onChange={handleSchoolNameInputChange}
                        list="schoolNameSuggestions"
                        required
                        className='tasks-school-name-input'
                    /><br />
                    <datalist id="schoolNameSuggestions">
                        {schoolNameSuggestions.map((schoolName, index) => (
                            <option key={index} value={schoolName} />
                        ))}
                    </datalist>
                    <PinValidation
                        schoolNameInput={schoolNameInput}
                        onSubmit={() => setPinValidated(true)}
                    />
                </div>
            )}

            {pinValidated && (
                <div className="my-study-container">  
                    <div className='meant-to-align'>
                        <div className='align-container'>
                            <Link style={{ backgroundColor: '#fff' }} className="my-text-container" to="/comprehension-levels">
                                <div className="my-text">
                                    <p>Comprehension Levels</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EducationSupport;


/*
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
//import '../pages-styling/study.css';

const EducationSupport = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Make a request to the server to get user information
        const fetchUser = async () => {
        try {
            const response = await fetch('https://lernacy.co.za/api/study', {
            method: 'GET',
            credentials: 'include', // Include credentials for cross-origin requests
            });

            if (response.ok) {
            const data = await response.json();
            setUser(data.user);

            // Check if user has an account name
            if (!data.user.accountName) {
                navigate('/login-second-stage');
            }
            } else {
            const errorData = await response.json();
            console.error('Error fetching user data:', errorData.message);

            // Redirect to login if not authenticated
            navigate('/login');
            }
        } catch (error) {
            console.error('Error fetching user data:', error.message);

            // Redirect to login on error
            navigate('/login');
        }
        };

        fetchUser();
    }, [navigate]); // Include navigate in the dependency array

    return (
        <div className='page-colour-manager'>

            <h1 className='page-title'>Education Support</h1>

            {user ? (
                <p>Welcome, {user.name} {user.surname}!</p>
                // Add other user information as needed
            ) : (
                <p>Loading user data...</p>
            )}

            <div className="my-study-container">  

                <div className='meant-to-align'>
                    <div className='align-container'>

                        <Link style={{ backgroundColor: '#fff' }} className="my-text-container" to="/comprehension-levels">
                            <div className="my-text">
                                <p>
                                    Comprehension Levels
                                </p>
                            </div>
                        </Link>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EducationSupport;
*/
import React, { useState, useEffect, Suspense } from 'react';
import { Link } from 'react-router-dom';
import Equation from './Equation';
import AccessDenied from '../../components/AccessDenied';

const GrTwoBoxGame = () => {
  const [equations, setEquations] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const [currentRound, setCurrentRound] = useState(1);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [highScore, setHighScore] = useState(null);
  const [gameScore, setGameScore] = useState(0);
  const [scoreRating, setScoreRating] = useState('');
  const [percentage, setPercentage] = useState(0); 
  const [gameName] = useState('Grade_2:_Box-Activity_Maths_10');
  const [isGameOver, setIsGameOver] = useState(false); 

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          const user = data.user;

          if (!user || !user.accountName) {
            setIsAuthenticated(false);
            window.location.href = "/login-second-stage";
            return;
          }

          setUserDetails(user);
          setIsAuthenticated(true);
        } else {
          throw new Error('Error fetching user details: ' + response.statusText);
        }
      } catch (error) {
        console.error(error.message);
        setIsAuthenticated(false);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    const fetchHighScore = async () => {
      try {
        const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setHighScore(data.highScore);
        } else {
          console.error('Error fetching high score:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching high score:', error.message);
      }
    };

    fetchHighScore();
  }, []);

  useEffect(() => {
    const calculatedPercentage = (gameScore / 10) * 100;
    setPercentage(calculatedPercentage);

    if (calculatedPercentage >= 90) {
      setScoreRating('Outstanding');
    } else if (calculatedPercentage >= 75) {
      setScoreRating('Passed');
    } else if (calculatedPercentage >= 50) {
      setScoreRating('Almost');
    } else {
      setScoreRating('Failed');
    }
  }, [gameScore]);

  useEffect(() => {
    const newEquations = [];
    for (let i = 0; i < 10; i++) {
      const isAddition = Math.random() < 0.5;
      const operator = isAddition ? '+' : '-';
      let operand1 = Math.floor(Math.random() * 100);
      let operand2 = Math.floor(Math.random() * 100);
      if (!isAddition && operand1 < operand2) {
        const temp = operand1;
        operand1 = operand2;
        operand2 = temp;
      }
      const result = isAddition ? operand1 + operand2 : operand1 - operand2;
      newEquations.push({
        equation1: `${operand1} ${operator} ${operand2} = ${result}`,
        equation2: `${operand1} ${operator} ☐ = ${result}`,
        userAnswer: '',
        isCorrect: null,
      });
    }
    setEquations(newEquations);
  }, []);

  const handleAnswerChange = (index, e) => {
    const newEquations = [...equations];
    newEquations[index].userAnswer = e.target.value;
    setEquations(newEquations);
  };

  const handleSubmit = async () => {
    let score = 0;
    const newAnswers = equations.map((equation, index) => {
      const userAnswer = parseInt(equation.userAnswer, 10);
      const isCorrectAnswer = evaluateAnswer(equation.equation1, userAnswer);
      if (isCorrectAnswer) {
        score += 1;
      }
      return {
        round: index + 1,
        equation: equation,
        userAnswer: userAnswer,
        isCorrect: isCorrectAnswer,
      };
    });
  
    setUserAnswers(newAnswers);
    setGameScore(score);
  
    if (newAnswers.length === 10) {
      setIsGameOver(true);
      try {
        // Step 1: Fetch CSRF token
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
          method: 'GET',
          credentials: 'include',
        });
  
        const csrfData = await csrfResponse.json();
        const csrfToken = csrfData.csrfToken;
  
        if (!csrfResponse.ok) {
          throw new Error('Failed to fetch CSRF token');
        }
  
        // Step 2: Send a POST request with CSRF token
        const response = await fetch('https://lernacy.co.za/api/record-score', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken, // Include CSRF token in headers
          },
          body: JSON.stringify({
            userId: userDetails?._id, // Assuming userDetails contains the user ID
            score: score,
            timeOfDay: new Date().getHours(), // Current hour of the day
            gameName: gameName,
          }),
        });
  
        if (response.ok) {
          //console.log('Score recorded successfully.');
        } else {
          console.error('Error recording score:', response.statusText);
        }
      } catch (error) {
        console.error('Error recording score:', error.message);
      }
    }
  };

  const evaluateAnswer = (equation, userAnswer) => {
    const parts = equation.split(' ');
    const operand2 = parseInt(parts[2], 10);
    return userAnswer === operand2;
  };

  const restartGame = async () => {
    try {
      setCurrentRound(1);
      setGameScore(0);
      setUserAnswers([]);
      setIsGameOver(false);
      // Generate new equations
      const newEquations = [];
      for (let i = 0; i < 10; i++) {
        const isAddition = Math.random() < 0.5;
        const operator = isAddition ? '+' : '-';
        let operand1 = Math.floor(Math.random() * 100);
        let operand2 = Math.floor(Math.random() * 100);
        if (!isAddition && operand1 < operand2) {
          const temp = operand1;
          operand1 = operand2;
          operand2 = temp;
        }
        const result = isAddition ? operand1 + operand2 : operand1 - operand2;
        newEquations.push({
          equation1: `${operand1} ${operator} ${operand2} = ${result}`,
          equation2: `${operand1} ${operator} ☐ = ${result}`,
          userAnswer: '',
          isCorrect: null,
        });
      }
      setEquations(newEquations);
      // Fetch high score
      const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        console.error('Error fetching high score:', response.statusText);
      }
    } catch (error) {
      console.error('Error restarting game:', error.message);
    }
  }; 

  const gameOverMessage = () => {
    if (gameScore === 10) {
      return "Congratulations! You got a perfect score!";
    } else if (gameScore >= 7) {
      return "Well done! You did a great job!";
    } else if (gameScore >= 5) {
      return "Good effort! Keep practicing!";
    } else {
      return "Keep trying! Practice makes perfect!";
    }
  };

  return (
    <div className='tested-activity-wall-container'>
      {!isAuthenticated ? (
        <AccessDenied />
      ) : (
        <>
          {equations.length > 0 && !isGameOver && (
            <div>
              <h1 className='activity-name-tag-h1'>G2 - Box Operation</h1>
              <div className='round-high-score'>
                <h2 className='activity-rounds-tag-h2'>10 Questions</h2>
                <p>
                  <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/10</strong>
                </p>
              </div>
              <div className='equations-aligner-block-cover'>
                {equations.map((equation, index) => (
                  <div key={index} className='equations-block-box'> 
                    <p><strong>Question {index + 1}</strong></p>
                    <p>Complete the equation:</p>
                    <strong>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Equation equation1={equation.equation1} equation2={equation.equation2} />
                      </Suspense>
                    </strong>
                    <label>Your Answer:</label>
                    <br />
                    <input className='equations-block-box-input' value={equation.userAnswer} onChange={(e) => handleAnswerChange(index, e)} />
                  </div>
                ))}
               
              </div> 
              <br/>
              <button className='activity-submit-button' onClick={handleSubmit}>Submit Answers</button>
              <br/> 
            </div>
          )}
          {isGameOver && (
            <div>
              <h1 className='activity-name-tag-h1'>Activity Over<br />Your Score: {gameScore}/10</h1>

              <div className='activity-game-over-data'>
                <h2 className='activity-rounds-tag-h2-left'>Score Rating: {scoreRating}</h2>
                <h3>{gameOverMessage()}</h3>
                <p>Percentage: <strong>{percentage}%</strong></p>
              </div>

              <div>
                <h3 className='activity-rounds-tag-h2'>Question Records:</h3>
                <div className='activity-over-rounds-evidence-ul'> 
                  {userAnswers.map((answer, index) => (
                    <div key={index} className='activity-over-rounds-evidence'>
                      <p className='activity-over-rounds-evidence-p-container'>
                        <strong>Question {index + 1}</strong> <br />
                        Equation: {equations[index].equation1} <br />
                        Your Answer: {answer.userAnswer}  
                        {answer.isCorrect ? <span style={{ color: 'green' }}>✔</span> : <span style={{ color: 'red' }}>✘</span>} <br />
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <hr />
              <p>
                <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/10</strong>
              </p>

              <div className='activity-restart-or-more'>
                <button className='activity-restart-button' onClick={restartGame}>Play Again</button>
                <button className='more-activity-button' onClick={() => window.location.href = "/Grade:2_Mathematics"}>Do More Activities</button>
              </div>
              <br/>
            </div>
          )}
        </>
      )}
      <br />
    </div>
  );
};

export default GrTwoBoxGame;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../pages-styling/pages.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import AdsComponent from '../../bodyFiles/AdPosts'; // Import AdsComponent

const FoundationPhase = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSubjects, setFilteredSubjects] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include', 
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          if (!data.user) {
            navigate('/login');
          }

          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const filterSubjects = () => {
      const filtered = [
        { grade: 'Grade R', subject: 'Mathematics', to: 'Grade:R_Mathematics' },
        { grade: 'Grade 1', subject: 'Mathematics', to: 'Grade:1_Mathematics' },
        { grade: 'Grade 2', subject: 'Mathematics', to: 'Grade:2_Mathematics' },
        { grade: 'Grade 3', subject: 'Mathematics', to: 'Grade:3_Mathematics' },
      ].filter(subject =>
        subject.grade.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subject.subject.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredSubjects(filtered);
    };

    filterSubjects();
  }, [searchQuery]);

  const handleSearchChange = e => {
    setSearchQuery(e.target.value);
  };

  const renderSubjectsWithAds = (subjects) => {
    let elements = [];
    subjects.forEach((subject, index) => {
      elements.push(renderSubjectCard(subject.grade, subject.subject, subject.to));

      if ((index + 1) % 3 === 0) {
        elements.push(<AdsComponent key={`ad-${index}`} />);
      }
    });
    return elements;
  };

  return (
    <div className='subjects-by-Grade'>
      <div className='subjects-by-Grad-inside'>
        {user && user.accountName ? (
          <>
            <h1 className='page-title'>Subjects by Grade</h1>
            <div className='subject-search-input-container'>
              <input
                type="text"
                placeholder="Search by grade or subject"
                value={searchQuery}
                onChange={handleSearchChange}
                className='subject-search-input'
              />
              <FontAwesomeIcon icon={faMagnifyingGlass} className='subject-search-input-icon' size='lg'/>
            </div>

            <div className="flex-container">
              {renderSubjectsWithAds(filteredSubjects)}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const renderSubjectCard = (grade, subject, to) => (
  <div className='subjects-container' key={to}>
    <div className="subject-card">
      <Link className="my-subjects-text" to={`/${to}`}>
        <div className='my-subjects-text-inner'>
          <p className='subject-grade-title-tile'>{`${grade}`}</p>
          <p className='subject-name'>{`${subject}`}</p>
        </div>
      </Link>
    </div>
  </div>
);

export default FoundationPhase;

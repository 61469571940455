// FoundationPhase.js
import React from 'react';

const PreSchool = () => {
  return (
    <div>

      <h1>Pre School</h1>

      <di>
        <p></p>
      </di>

    </div>
  );
};

export default PreSchool;

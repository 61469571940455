import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StoryBlock1 from './tasks/StoryBlock1';
import StoryBlock2 from './tasks/StoryBlock2';
import StoryBlock3 from './tasks/StoryBlock3';
import StoryBlock4 from './tasks/StoryBlock4';
import StoryBlock5 from './tasks/StoryBlock5';
import StoryBlock6 from './tasks/StoryBlock6';
import StoryBlock7 from './tasks/StoryBlock7';
import StoryBlock8 from './tasks/StoryBlock8';

const ComprehensionLevels = () => {
    const [user, setUser] = useState(null);
    const [currentTaskBlock, setCurrentTaskBlock] = useState(1); // Default to 1 initially
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setUser(data.user);

                    if (!data.user.accountName) {
                        navigate('/login-second-stage');
                    } else {
                        fetchHighestTaskBlock(data.user._id);
                    }
                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching user:', error);
                navigate('/login');
            }
        };

        const fetchHighestTaskBlock = async (userId) => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/ed-support/highest-task-block?userId=${userId}`, {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    //console.log('Highest Task Block Data:', data);
                    if (data.success) {
                        setCurrentTaskBlock(data.highestTaskBlock + 1); // Set the next task block
                    } else {
                        //console.log('No Comprehension-Levels submissions found for this user');
                        setCurrentTaskBlock(1); // Default to 1 if no submissions found
                    }
                } else {
                    console.error('Failed to fetch highest task block');
                }
            } catch (error) {
                console.error('Error fetching highest task block:', error);
            }
        };

        fetchUser();
    }, [navigate]);

    const handleSubmit = async (submissionData) => {
        // console.log(submissionData);
        // Handle submission to the database here
        // Example: await fetch('https://lernacy.co.za/api/submit', { method: 'POST', body: JSON.stringify(submissionData) });
    };

    return (
        <div className='tested-activity-wall-container'>
            <h1 className='page-title'>Comprehension Levels</h1>
            {currentTaskBlock === 1 && <StoryBlock1 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 2 && <StoryBlock2 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 3 && <StoryBlock3 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 4 && <StoryBlock4 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 5 && <StoryBlock5 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 6 && <StoryBlock6 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 7 && <StoryBlock7 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 8 && <StoryBlock8 user={user} handleSubmit={handleSubmit} />}
            <br />
        </div>
    );
};

export default ComprehensionLevels;


/*
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StoryBlock1 from './tasks/StoryBlock1';
import StoryBlock2 from './tasks/StoryBlock2';
import StoryBlock3 from './tasks/StoryBlock3';
import StoryBlock4 from './tasks/StoryBlock4';
import StoryBlock5 from './tasks/StoryBlock5';
import StoryBlock6 from './tasks/StoryBlock6';
import StoryBlock7 from './tasks/StoryBlock7';
import StoryBlock8 from './tasks/StoryBlock8';

const ComprehensionLevels = () => {
    const [user, setUser] = useState(null);
    const [currentTaskBlock, setCurrentTaskBlock] = useState(1); // Default to 1 initially
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setUser(data.user);

                    if (!data.user.accountName) {
                        navigate('/login-second-stage');
                    } else {
                        fetchHighestTaskBlock(data.user._id);
                    }
                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching user:', error);
                navigate('/login');
            }
        };

        const fetchHighestTaskBlock = async (userId) => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/ed-support/highest-task-block?userId=${userId}`, {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    //console.log('Highest Task Block Data:', data);
                    if (data.success) {
                        setCurrentTaskBlock(data.highestTaskBlock + 1); // Set the next task block
                    } else {
                        // console.log('No Comprehension-Levels submissions found for this user');
                        setCurrentTaskBlock(1); // Default to 1 if no submissions found
                    }
                } else {
                    console.error('Failed to fetch highest task block');
                }
            } catch (error) {
                console.error('Error fetching highest task block:', error);
            }
        };

        fetchUser();
    }, [navigate]);

    const handleSubmit = async (submissionData) => {
        // console.log(submissionData);
        // Handle submission to the database here
        // Example: await fetch('https://lernacy.co.za/api/submit', { method: 'POST', body: JSON.stringify(submissionData) });
    };

    return (
        <div className='tested-activity-wall-container'>
            <h1 className='page-title'>Comprehension Levels</h1>
            {currentTaskBlock === 1 && <StoryBlock1 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 2 && <StoryBlock2 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 3 && <StoryBlock3 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 4 && <StoryBlock4 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 5 && <StoryBlock5 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 6 && <StoryBlock6 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 7 && <StoryBlock7 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 8 && <StoryBlock8 user={user} handleSubmit={handleSubmit} />}
            <br />
        </div>
    );
};

export default ComprehensionLevels;
*/
/*
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StoryBlock1 from './tasks/StoryBlock1';
import StoryBlock2 from './tasks/StoryBlock2';
import StoryBlock3 from './tasks/StoryBlock3';

const ComprehensionLevels = () => {
    const [user, setUser] = useState(null);
    const [currentTaskBlock, setCurrentTaskBlock] = useState(1); // Default to 1 initially
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setUser(data.user);

                    if (!data.user.accountName) {
                        navigate('/login-second-stage');
                    } else {
                        fetchHighestTaskBlock(data.user._id);
                    }
                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching user:', error);
                navigate('/login');
            }
        };

        const fetchHighestTaskBlock = async (userId) => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/ed-support/highest-task-block?userId=${userId}`, {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    // console.log('Highest Task Block Data:', data);
                    if (data.success) {
                        if (data.highestTaskBlock === 1) {
                            setCurrentTaskBlock(2); // Set to 2 to render StoryBlock2 after StoryBlock1
                        } else if (data.highestTaskBlock === 2) {
                            setCurrentTaskBlock(2); // Render StoryBlock2
                        } else {
                            setCurrentTaskBlock(1); // Default to 1 if the block is not 1 or 2
                        }
                    } else {
                        console.log('No Comprehension-Levels submissions found for this user');
                        setCurrentTaskBlock(1); // Default to 1 if no submissions found
                    }
                } else {
                    console.error('Failed to fetch highest task block');
                }
            } catch (error) {
                console.error('Error fetching highest task block:', error);
            }
        };

        fetchUser();
    }, [navigate]);

    const handleSubmit = async (submissionData) => {
        console.log(submissionData);
        // Handle submission to the database here
        // Example: await fetch('https://lernacy.co.za/api/submit', { method: 'POST', body: JSON.stringify(submissionData) });
    };

    return (
        <div className='tested-activity-wall-container'>
            <h1 className='page-title'>Comprehension Levels</h1>
            {currentTaskBlock === 1 && <StoryBlock1 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 2 && <StoryBlock2 user={user} handleSubmit={handleSubmit} />}
            <br />
        </div>
    );
};

export default ComprehensionLevels;
*/
/*
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StoryBlock1 from './tasks/StoryBlock1';
import StoryBlock2 from './tasks/StoryBlock2';

const ComprehensionLevels = () => {
    const [user, setUser] = useState(null);
    const [currentTaskBlock, setCurrentTaskBlock] = useState(1); // Default to 1 initially
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setUser(data.user);

                    if (!data.user.accountName) {
                        navigate('/login-second-stage');
                    } else {
                        fetchHighestTaskBlock(data.user._id);
                    }
                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching user:', error);
                navigate('/login');
            }
        };

        const fetchHighestTaskBlock = async (userId) => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/ed-support/highest-task-block?userId=${userId}`, {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Highest Task Block Data:', data);
                    if (data.success) {
                        if (data.highestTaskBlock === 1 || data.highestTaskBlock === 2) {
                            setCurrentTaskBlock(data.highestTaskBlock);
                        } else {
                            setCurrentTaskBlock(1); // Default to 1 if the block is not 1 or 2
                        }
                    } else {
                        console.log('No Comprehension-Levels submissions found for this user');
                        setCurrentTaskBlock(1); // Default to 1 if no submissions found
                    }
                } else {
                    console.error('Failed to fetch highest task block');
                }
            } catch (error) {
                console.error('Error fetching highest task block:', error);
            }
        };

        fetchUser();
    }, [navigate]);

    const handleSubmit = async (submissionData) => {
        console.log(submissionData);
        // Handle submission to the database here
        // Example: await fetch('https://lernacy.co.za/api/submit', { method: 'POST', body: JSON.stringify(submissionData) });
    };

    return (
        <div className='tested-activity-wall-container'>
            <h1 className='page-title'>Comprehension Levels</h1>
            {currentTaskBlock === 1 && <StoryBlock1 user={user} handleSubmit={handleSubmit} />}
            {currentTaskBlock === 2 && <StoryBlock2 user={user} handleSubmit={handleSubmit} />}
            <br />
        </div>
    );
};

export default ComprehensionLevels;
*/
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../pages/pages-styling/study.css';
import axios from 'axios';

const Disclaimer = () => {

  return (
    <div >  

      <div className="my-study-container">  

        <div className='meant-to-align'>
          <div className='align-container'>

            <div className="my-text-container">
              <div className="topic-notes-block">

              <h1 className='page-title'>General Disclaimer for AI-Generated Notes and Images</h1>

              <h2>
                  Disclaimer
              </h2>

                  <div>
                        <p>
                            Welcome to our educational platform. The following disclaimer outlines important information 
                            regarding the AI-generated notes and images provided on this site, as well as submissions from 
                            teachers or individuals brainstorming ideas for the app. Please read this disclaimer carefully 
                            before using the content or submitting any ideas.
                        </p>

                        <h2>
                            AI-Generated Content
                        </h2>
                        <ul>
                            <h3>Accuracy and Reliability:</h3>
                            <li>
                                Some of the notes and images provided on this platform are generated using artificial 
                                intelligence (AI) technology. While we strive to ensure the accuracy and relevance of 
                                the content, AI-generated material may not always be completely accurate or up-to-date.
                            </li>
                            <li>
                                We recommend that users independently verify the information provided and consult 
                                additional resources or professionals for confirmation, especially for educational 
                                purposes.
                            </li>

                            <h3>Educational Use:</h3>
                            <li>
                                The AI-generated notes and images are intended to support learning and should be used 
                                as supplementary material. They are not a substitute for professional educational 
                                advice or instruction.
                            </li>
                            <li>
                                Users are encouraged to cross-reference the content with other educational materials to 
                                ensure a comprehensive understanding of the subject matter.
                            </li>

                            <h2>User Submissions and Brainstorming Ideas</h2>
                            <h3>Idea Submissions:</h3>

                            <li>
                                We welcome ideas and suggestions from teachers and other individuals to enhance our 
                                platform. However, please be aware that submitting an idea does not imply any agreement 
                                or obligation on our part to compensate you for the idea.
                            </li>
                            <li>
                                By submitting an idea, you acknowledge that you are doing so voluntarily and without 
                                any expectation of compensation.
                            </li>

                            <h2>No Compensation Without Agreement:</h2>
                            <li>
                                If you believe your idea warrants compensation, it is crucial that we reach a formal 
                                agreement before you submit your idea. We must mutually agree on the terms of 
                                compensation and document them appropriately.
                            </li>
                            <li>
                                Without such an agreement, you waive any right to claim compensation for the submitted 
                                idea, regardless of whether it is implemented or not.
                            </li>

                            <h3>Ownership and Use:</h3>
                            <li>
                                By submitting ideas or content, you grant us a non-exclusive, royalty-free, worldwide 
                                license to use, modify, and distribute the content as we see fit.
                            </li>
                            <li>
                                You retain ownership of your original content, but we reserve the right to use the 
                                submitted ideas without any obligation to provide attribution or compensation unless 
                                explicitly agreed upon in writing.
                            </li>

                        </ul>

                        <hr />

                        <div>
                            <h2>OER Material Disclaimer</h2>
                            <p>
                                The materials and resources provided on this website, including Open Educational 
                                Resources (OER) and links to external websites, are offered for informational 
                                and educational purposes only.
                            </p>
                            <h3>
                                OER Materials:
                            </h3>

                            <p>
                                The OER content available on this site is provided as-is, without any guarantees 
                                or warranties regarding accuracy, completeness, or fitness for a particular 
                                purpose. While we strive to provide high-quality educational resources, the 
                                content is subject to change, and we do not assume responsibility for any errors, 
                                omissions, or results obtained from the use of this material.
                            </p>

                            <h3>
                                External Links:
                            </h3>

                            <p>
                                Links to external websites are provided for convenience and informational 
                                purposes only. We do not endorse, approve, or control the content on these 
                                external sites, nor do we assume any responsibility for their availability, 
                                accuracy, or legality. Users are encouraged to review the terms and conditions 
                                of any third-party websites they visit through links provided on our site.
                            </p>

                            <h3>
                                Use of Information:
                            </h3>
                            <p>
                                Users of this site are advised to use the information provided at their own 
                                risk. It is recommended that users independently verify any information before 
                                relying on it. We disclaim any liability for any direct, indirect, or 
                                consequential loss or damage incurred by users in connection with the use of 
                                this website, its content, or any linked external websites.
                            </p>

                            <h3>
                                No Legal Advice:
                            </h3>
                            <p>
                                The information provided on this site is not intended to be, nor should it be 
                                construed as, legal advice. If you require legal or other professional advice, 
                                please consult a qualified professional.
                            </p>

                            <p>
                                By using this website, you agree to this disclaimer and our terms of use. If 
                                you do not agree, please refrain from using the website.
                            </p> 

                        </div>

                        <hr />

                        <div>
                            <h2>
                                Webinars and Conferences Disclaimer
                            </h2>
                            <p>
                                The webinars and conferences listed on this website are provided for 
                                informational purposes only. By posting these events, we do not endorse or 
                                assume any responsibility for the security, safety, or overall experience of 
                                attendees.
                            </p>

                            <h3>
                                In-Person Conferences:
                            </h3>
                            <p>
                                Attendance at any conference or event listed on this site is at your own risk. 
                                We do not control the organization, security measures, or conduct at these 
                                events. Attendees are encouraged to exercise caution, make informed decisions, 
                                and ensure their personal safety when attending conferences or events. We 
                                disclaim any liability for any injury, loss, or damage that may occur to you or 
                                your property in connection with attending these events.
                            </p>

                            <h3>
                                Webinars and Online Events:
                            </h3>
                            <p>
                                Participation in webinars or online events listed on this site is also at your 
                                own risk. We are not responsible for the cyber security, data protection, or 
                                privacy practices of the webinar hosts or platforms used. Participants should 
                                take appropriate steps to protect their personal information and devices while 
                                accessing online content. We disclaim any liability for any cyber-related 
                                incidents, including data breaches, unauthorized access, or loss of information 
                                that may occur during or after your participation in webinars.
                            </p>

                            <p>
                                By choosing to attend any of the conferences or webinars listed on this site, 
                                you agree to this disclaimer and acknowledge that you do so at your own risk. 
                                If you do not agree, please refrain from attending these events.
                            </p>
                        </div>

                        <hr />

                        <div>
                            <h2>
                                Community Links Disclaimer
                            </h2>
                            <p>
                                The community groups linked on this website are intended for educational 
                                conversations, networking, and the sharing of knowledge. While we make efforts 
                                to control and monitor the communities posted, we cannot guarantee the behavior 
                                or intentions of all participants.
                            </p>

                            <h3>
                                Community Conduct:
                            </h3>
                            <p>
                                We expect all members of the linked communities to engage respectfully and 
                                contribute positively to discussions. However, we are not responsible for the 
                                actions, behavior, or content shared by individuals within these communities. 
                                Inappropriate behavior, offensive content, or any form of harassment will not 
                                be tolerated, and we encourage users to report such activities to the community 
                                administrators.
                            </p>

                            <h3>
                                Disclaimer of Liability:
                            </h3>
                            <p>
                                By joining or participating in any community group linked on this site, you 
                                acknowledge that you do so at your own risk. We disclaim any liability for any 
                                negative experiences, including but not limited to harassment, misinformation, 
                                or other harmful conduct that may occur within these groups. The responsibility 
                                for maintaining a safe and respectful environment rests with the community 
                                members and administrators.
                            </p>

                            <h3>
                                Educational Purpose Only:
                            </h3>
                            <p>
                                The communities linked here are meant solely for educational and professional 
                                development purposes. Any discussions, content, or actions outside of this 
                                scope are not endorsed or supported by us. We reserve the right to remove links 
                                to any community that does not adhere to these guidelines.
                            </p>
                            <p>
                                By participating in these community groups, you agree to this disclaimer and 
                                understand that you are responsible for your conduct and experience within the 
                                community.
                            </p>
                        </div>

                        <hr />

                        <div>
                            <h3>
                                Article Disclaimer
                            </h3>
                            <p>
                                The articles published on our website are intended to provide insights, 
                                updates, and information related to our ongoing projects, company progress, 
                                and industry-related topics. While we strive to ensure that the content is 
                                accurate and up-to-date, the information presented in these articles 
                                reflects the views and opinions of the authors at the time of writing and 
                                may not necessarily represent the latest developments or comprehensive 
                                coverage of the subjects discussed.
                            </p>
                            <p>
                                Please note that the articles are meant for informational purposes only and 
                                should not be construed as professional advice. We encourage readers to 
                                independently verify any information presented and to consider the content 
                                within the context of their own knowledge and experiences.
                            </p>
                            <p>
                                Furthermore, while we aim to foster a positive and informed community, we 
                                reserve the right to manage and moderate the content we publish, including 
                                the narrative and tone of the articles. We are not responsible for any 
                                actions taken based on the information provided in these articles, and 
                                readers should use their own judgment when interpreting and applying the 
                                content to their own situations.
                            </p>
                        </div>

                        <hr />

                        <h2>
                            General Use
                        </h2>
                        <h3>
                            No Warranties:
                        </h3>

                        <ul>

                            <li>
                                The content provided on this platform is provided "as is" without any warranties, 
                                express or implied. We do not guarantee the accuracy, completeness, or usefulness of 
                                the information.
                            </li>
                            <li>
                                We disclaim all liability for any errors or omissions in the content and for any loss 
                                or damage incurred as a result of using the information.
                            </li>

                            <h3>
                                Changes to the Disclaimer:
                            </h3>
                            <li>
                                We reserve the right to update or modify this disclaimer at any time. Changes will be 
                                effective immediately upon posting on the platform. Continued use of the platform after 
                                any changes constitutes acceptance of the new terms.
                            </li>

                            <h3>
                                Contact Information:
                            </h3>
                            <li>
                                If you have any questions or concerns regarding this disclaimer, please contact us 
                                at <strong>admin@lernacy.co.za</strong>.
                            </li>

                        </ul>   
                        <br />  
                        <hr />

                        <label><strong>Effective Date:</strong> August 18, 2024</label><br />
                        <label><strong>Last Updated:</strong> August 18, 2024</label>

                  </div>

              </div>

            </div>

          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default Disclaimer;
  
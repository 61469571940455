const socialSciencesQuestionsData = [

    {
        id: 1,
        question: 'What are the different kinds of food?',
        options: ['Fresh and cooked', 'Sweet and sour', 'Red and green', 'Hot and cold'],
        correctAnswer: 'Fresh and cooked'
    },
    {
        id: 2,
        question: 'Where does food come from?',
        options: ['From the sky', 'From the ocean', 'From plants and animals', 'From the mountains'],
        correctAnswer: 'From plants and animals'
    },
    {
        id: 3,
        question: 'How can food be packaged?',
        options: ['In paper bags', 'In glass jars', 'In plastic buckets', 'All of the above'],
        correctAnswer: 'All of the above'
    },
    {
        id: 4,
        question: 'Where can we get fresh food?',
        options: ['From the supermarket', 'From the refrigerator', 'Picked from a tree or dug up from the soil', 'Imported from other countries'],
        correctAnswer: 'Picked from a tree or dug up from the soil'
    },
    {
        id: 5,
        question: 'Where does milk come from?',
        options: ['From a tree', 'From a cow', 'From a chicken', 'From a fish'],
        correctAnswer: 'From a cow'
    },
    {
        id: 6,
        question: 'Which food is picked from a tree?',
        options: ['Potato', 'Banana', 'Carrot', 'Lettuce'],
        correctAnswer: 'Banana'
    },
    {
        id: 8,
        question: 'Where does most of our food come from?',
        options: ['From wild forests', 'From supermarkets', 'From farms', 'From factories'],
        correctAnswer: 'From farms'
    },
    {
        id: 9,
        question: 'Who works to produce the food we eat?',
        options: ['Chefs in restaurants', 'Teachers at schools', 'Farmers on farms', 'Doctors in hospitals'],
        correctAnswer: 'Farmers on farms'
    },
    {
        id: 11,
        question: 'Where do some people in towns and cities grow their own food?',
        options: ['In their basements', 'In community gardens', 'In shopping malls', 'In parking lots'],
        correctAnswer: 'In their own vegetable gardens or fruit trees'
    },
    {
        id: 12,
        question: 'Where do freshwater fish for consumption come from?',
        options: ['From the sea', 'From dams and lakes', 'From rivers', 'From aquariums'],
        correctAnswer: 'From dams and lakes'
    },
    {
        id: 13,
        question: 'What do some people do if they live on farms?',
        options: ['They buy food from supermarkets', 'They grow their own food', 'They fish in rivers', 'They hunt wild animals'],
        correctAnswer: 'They grow their own food'
    },
    {
        id: 14,
        question: 'Where do people living in towns and cities sometimes grow their own food?',
        options: ['In shopping malls', 'On rooftops', 'In underground bunkers', 'In their own vegetable gardens or fruit trees'],
        correctAnswer: 'In their own vegetable gardens or fruit trees'
    },
    {
        id: 15,
        question: 'What is the primary work of farmers?',
        options: ['Fishing', 'Teaching', 'Manufacturing', 'Producing food'],
        correctAnswer: 'Producing food'
    },
    {
        id: 16,
        question: 'Where do most people buy their food from?',
        options: ['Pet stores', 'Farmers markets', 'Supermarkets', 'Online retailers'],
        correctAnswer: 'Supermarkets'
    },
    {
        id: 17,
        question: 'What is a common source of freshwater fish?',
        options: ['Ponds', 'Swimming pools', 'Dams and lakes', 'Glaciers'],
        correctAnswer: 'Dams and lakes'
    },
    {
        id: 18,
        question: 'What do some people do with extra food from their gardens?',
        options: ['Throw it away', 'Donate it to food banks', 'Feed it to animals', 'Sell it at the market'],
        correctAnswer: 'Donate it to food banks'
    },
    {
        id: 19,
        question: 'What do farmers use to help plants grow?',
        options: ['Water and sunlight', 'Sand and rocks', 'Chocolate and candies', 'Computers and robots'],
        correctAnswer: 'Water and sunlight'
    },
    {
        id: 20,
        question: 'What is a common method used by farmers to protect crops from pests?',
        options: ['Using pesticides', 'Singing to the plants', 'Praying for protection', 'Building scarecrows'],
        correctAnswer: 'Using pesticides'
    },
    {
        id: 21,
        question: 'Which of the following methods is used to preserve food for long periods?',
        options: ['Freezing', 'Burying it underground', 'Leaving it in the sun', 'Throwing salt on it'],
        correctAnswer: 'Freezing'
    },
    {
        id: 22,
        question: 'What is the purpose of canning food?',
        options: ['To make it taste better', 'To keep it fresh longer', 'To change its color', 'To reduce its size'],
        correctAnswer: 'To keep it fresh longer'
    },
    {
        id: 23,
        question: 'What do farmers raise on livestock farms?',
        options: ['Plants', 'Animals', 'Robots', 'Machines'],
        correctAnswer: 'Animals'
    },
    {
        id: 24,
        question: 'What is the main purpose of dairy farming?',
        options: ['Producing wool', 'Raising chickens', 'Growing vegetables', 'Producing milk'],
        correctAnswer: 'Producing milk'
    },
    {
        id: 25,
        question: 'Which of the following is an example of aquaculture?',
        options: ['Hunting deer in the forest', 'Catching fish in a lake', 'Raising salmon in a fish farm', 'Picking apples in an orchard'],
        correctAnswer: 'Raising salmon in a fish farm'
    },
    {
        id: 28,
        question: 'How is most of the work done on subsistence farms?',
        options: ['Using advanced machinery', 'By hand', 'By hired laborers', 'By animals'],
        correctAnswer: 'By hand'
    },
    {
        id: 29,
        question: 'What do subsistence farmers do with the food they grow?',
        options: ['Donate it to charity', 'Sell it all for profit', 'Eat most of it and sell some', 'Throw it away'],
        correctAnswer: 'Eat most of it and sell some'
    },
    {
        id: 30,
        question: 'What is the primary purpose of the money made from selling food on subsistence farms?',
        options: ['Buying luxury items', 'Buying things they need like soap and pots', 'Investing in the stock market', 'Saving for retirement'],
        correctAnswer: 'Buying things they need like soap and pots'
    },
    {
        id: 31,
        question: 'What are some challenges faced by subsistence farmers?',
        options: ['Lack of access to markets', 'Abundance of modern machinery', 'High levels of automation', 'Reliance on hired laborers'],
        correctAnswer: 'Lack of access to markets'
    },
    {
        id: 32,
        question: 'What is one reason why subsistence farmers might struggle to sell their surplus produce?',
        options: ['They have too much produce', 'There is no demand for their produce', 'They do not know how to grow crops', 'They are too far from the market'],
        correctAnswer: 'There is no demand for their produce'
    },
    {
        id: 33,
        question: 'Why is subsistence farming important?',
        options: ['To maximize profits', 'To feed families and communities', 'To promote industrialization', 'To compete in global markets'],
        correctAnswer: 'To feed families and communities'
    },
    {
        id: 34,
        question: 'What is one benefit of subsistence farming for families?',
        options: ['Increased income', 'Improved nutrition', 'Reduced workload', 'Access to modern amenities'],
        correctAnswer: 'Improved nutrition'
    },
    {
        id: 35,
        question: 'What types of crops are commonly grown on subsistence farms?',
        options: ['Cash crops only', 'Fruit trees only', 'A variety of crops for consumption', 'No crops'],
        correctAnswer: 'A variety of crops for consumption'
    },
    {
        id: 34,
        question: 'Why is sustainability important in subsistence farming?',
        options: ['To maximize profits', 'To ensure long-term food security', 'To attract investors', 'To minimize workload'],
        correctAnswer: 'To ensure long-term food security'
    },
    {
        id: 35,
        question: 'How does subsistence farming impact local economies?',
        options: ['It leads to overproduction', 'It boosts employment and local markets', 'It causes environmental degradation', 'It decreases food availability'],
        correctAnswer: 'It boosts employment and local markets'
    },
    {
        id: 36,
        question: 'What is urban agriculture?',
        options: ['Farming in rural areas', 'Growing food in towns and cities', 'Fishing in urban areas', 'Importing food from other countries'],
        correctAnswer: 'Growing food in towns and cities'
    },
    {
        id: 37,
        question: 'What are some common methods of urban agriculture?',
        options: ['Hydroponics and aquaponics', 'Large-scale monoculture', 'Traditional farming techniques', 'No agriculture is practiced in urban areas'],
        correctAnswer: 'Hydroponics and aquaponics'
    },
    {
        id: 38,
        question: 'What are some benefits of urban agriculture?',
        options: ['Reduced food availability', 'Increased access to fresh produce', 'Environmental degradation', 'Reliance on imported food'],
        correctAnswer: 'Increased access to fresh produce'
    },
    {
        id: 39,
        question: 'How does urban agriculture contribute to food security?',
        options: ['It decreases food availability', 'It reduces reliance on imported food', 'It increases food waste', 'It disrupts local economies'],
        correctAnswer: 'It reduces reliance on imported food'
    },
    {
        id: 40,
        question: 'What are some challenges faced by urban farmers?',
        options: ['Limited space and land availability', 'Abundance of agricultural land', 'Minimal access to water', 'Lack of demand for fresh produce'],
        correctAnswer: 'Limited space and land availability'
    },
    {
        id: 41,
        question: 'What is hydroponics?',
        options: ['Growing plants in soil', 'Growing plants without soil, using water and nutrients', 'Using animals for farming', 'Importing food from other countries'],
        correctAnswer: 'Growing plants without soil, using water and nutrients'
    },
    {
        id: 42,
        question: 'What is rooftop gardening?',
        options: ['Growing crops in underground tunnels', 'Growing crops on rooftops of buildings', 'Growing crops in vertical gardens', 'Growing crops indoors under artificial light'],
        correctAnswer: 'Growing crops on rooftops of buildings'
    },
    {
        id: 43,
        question: 'What are community gardens?',
        options: ['Private gardens for individual households', 'Gardens maintained by a single individual', 'Shared spaces where people grow food together', 'Commercial farms in urban areas'],
        correctAnswer: 'Shared spaces where people grow food together'
    },
    {
        id: 44,
        question: 'What are the benefits of community gardens?',
        options: ['Isolation and social disconnection', 'Improved community unity and food security', 'Increased reliance on store-bought food', 'Environmental degradation'],
        correctAnswer: 'Improved community unity and food security'
    },
    {
        id: 45,
        question: 'What are the benefits of community gardens?',
        options: ['Isolation and social disconnection', 'Improved community working together and food security', 'Increased reliance on store-bought food', 'Environmental degradation'],
        correctAnswer: 'Improved community working together and food security'
    },
    {
        id: 46,
        question: 'What is commercial farming?',
        options: ['Farming for subsistence', 'Farming for personal consumption', 'Farming for profit and selling produce', 'Farming for environmental conservation'],
        correctAnswer: 'Farming for profit and selling produce'
    },
    {
        id: 47,
        question: 'What are cash crops?',
        options: ['Crops grown for personal consumption', 'Crops grown for animal feed', 'Crops grown to sell for profit', 'Crops grown for environmental conservation'],
        correctAnswer: 'Crops grown to sell for profit'
    },
    {
        id: 48,
        question: 'What is monoculture?',
        options: ['Growing multiple crops on a farm', 'Growing a single crop on a farm', 'Growing crops without soil', 'Growing crops in urban areas'],
        correctAnswer: 'Growing a single crop on a farm'
    },
    {
        id: 49,
        question: 'Why do most commercial farmers grow only one crop?',
        options: ['To diversify their income', 'To improve soil fertility', 'To focus on high-yield crops', 'To promote biodiversity'],
        correctAnswer: 'To focus on high-yield crops'
    },
    {
        id: 50,
        question: 'What types of livestock do commercial farmers keep?',
        options: ['Exotic animals', 'Endangered species', 'Sheep, cattle, and chickens', 'Wild animals'],
        correctAnswer: 'Sheep, cattle, and chickens'
    },
    {
        id: 51,
        question: 'How do commercial farms differ from subsistence farms in terms of size?',
        options: ['Commercial farms are smaller', 'Commercial farms are larger', 'Both types of farms are similar in size', 'Farm size does not matter in commercial farming'],
        correctAnswer: 'Commercial farms are larger'
    },
    {
        id: 52,
        question: 'What role do machines like tractors play in commercial farming?',
        options: ['They decrease productivity', 'They increase labor costs', 'They help with planting and harvesting', 'They have no impact on farming operations'],
        correctAnswer: 'They help with planting and harvesting'
    },
    {
        id: 53,
        question: 'How are farm workers compensated for their work on commercial farms?',
        options: ['They are paid with food', 'They are paid with livestock', 'They receive a salary', 'They work voluntarily'],
        correctAnswer: 'They receive a salary'
    },
    {
        id: 54,
        question: 'How can commercial farming contribute to rural development?',
        options: ['By displacing rural communities', 'By creating job opportunities', 'By degrading natural resources', 'By increasing poverty levels'],
        correctAnswer: 'By creating job opportunities'
    },
    {
        id: 55,
        question: 'What are some common crops grown on commercial farms?',
        options: ['Toys and books', 'Vegetables and fruits', 'Rocks and sand', 'Computers and phones'],
        correctAnswer: 'Vegetables and fruits'
    },
    {
        id: 56,
        question: 'What do farmers grow on commercial farms to sell for profit?',
        options: ['Toys and clothes', 'Plants and flowers', 'Crops and livestock', 'Books and stationery'],
        correctAnswer: 'Crops and livestock'
    },
    {
        id: 57,
        question: 'What kinds of animals are typically raised on commercial farms?',
        options: ['Dinosaurs and dragons', 'Cats and dogs', 'Sheep, cows, and chickens', 'Lions and tigers'],
        correctAnswer: 'Sheep, cows, and chickens'
    },
    {
        id: 58,
        question: 'What do commercial farmers do with the animals they raise?',
        options: ['Keep them as pets', 'Sell them for profit', 'Release them into the wild', 'Give them away for free'],
        correctAnswer: 'Sell them for profit'
    },
    {
        id: 59,
        question: 'What tools do farmers use to work on commercial farms?',
        options: ['Fishing rods and nets', 'Tractors and plows', 'Paintbrushes and canvases', 'Screwdrivers and hammers'],
        correctAnswer: 'Tractors and plows'
    },
    {
        id: 60,
        question: 'How do machines like tractors help farmers on commercial farms?',
        options: ['By making farming easier and faster', 'By flying in the sky', 'By digging tunnels underground', 'By driving to the moon'],
        correctAnswer: 'By making farming easier and faster'
    },
    {
        id: 61,
        question: 'What do farmers do with the crops they grow on commercial farms?',
        options: ['Eat them all', 'Sell them to make money', 'Bury them in the ground', 'Throw them away'],
        correctAnswer: 'Sell them to make money'
    },
    {
        id: 62,
        question: 'Why do farmers sell their crops?',
        options: ['To give them away for free', 'To help their neighbors', 'To make a profit', 'To keep them fresh'],
        correctAnswer: 'To make a profit'
    },
    {
        id: 63,
        question: 'Why is water important on commercial farms?',
        options: ['To make the soil muddy', 'To water the flowers', 'To grow healthy crops', 'To clean the animals'],
        correctAnswer: 'To grow healthy crops'
    },
    {
        id: 64,
        question: 'What do farmers use water for on commercial farms?',
        options: ['To wash their cars', 'To fill up swimming pools', 'To irrigate crops and hydrate animals', 'To make ice cream'],
        correctAnswer: 'To water crops and hydrate animals'
    },
    {
        id: 65,
        question: 'When do farmers harvest their crops on commercial farms?',
        options: ['During the night', 'During the rainy season', 'At random times', 'When the crops are ripe'],
        correctAnswer: 'When the crops are ripe'
    },
    {
        id: 66,
        question: 'Why is it important for farmers to harvest crops at the right time?',
        options: ['To keep the birds away', 'To avoid getting dirty', 'To prevent the crops from growing', 'To ensure good quality produce'],
        correctAnswer: 'To ensure good quality food'
    },
    {
        id: 67,
        question: 'What are the different seasons for farming?',
        options: ['Spring, Summer, Autumn, Winter', 'Morning, Afternoon, Evening, Night', 'Hot, Warm, Cool, Cold', 'Fast, Slow, Quick, Speedy'],
        correctAnswer: 'Spring, Summer, Autumn, Winter'
    },
    {
        id: 68,
        question: 'What are crops or animals grown on commercial farms primarily used for?',
        options: ['Personal consumption', 'Decoration purposes', 'Selling in shops', 'Building materials'],
        correctAnswer: 'Selling in shops'
    },
    {
        id: 69,
        question: 'Do most commercial farmers grow only one crop?',
        options: ['Yes, they grow only one crop', 'No, they grow multiple crops', 'Only in winter', 'Only in summer'],
        correctAnswer: 'No, they grow multiple crops'
    },
    {
        id: 70,
        question: 'Why do commercial farmers grow lots of one crop?',
        options: ['To make the farm look pretty', 'To attract more customers', 'To sell it in bulk and make more profit', 'To keep it as a backup'],
        correctAnswer: 'To sell it in bulk and make more profit'
    },
    {
        id: 71,
        question: 'What types of livestock do commercial farmers typically keep?',
        options: ['Pet dogs and cats', 'Exotic birds', 'Sheep, cattle, and chickens', 'Wild animals'],
        correctAnswer: 'Sheep, cattle, and chickens'
    },
    {
        id: 72,
        question: 'Why do commercial farmers keep livestock?',
        options: ['For entertainment', 'As pets', 'To sell their products like meat and eggs', 'To scare away pests'],
        correctAnswer: 'To sell their products like meat and eggs'
    },
    {
        id: 73,
        question: 'Are commercial farms usually big or small?',
        options: ['Big', 'Small', 'Medium', 'Tiny'],
        correctAnswer: 'Big'
    },
    {
        id: 74,
        question: 'Why are commercial farms usually big?',
        options: ['To fit more animals', 'To make them easier to manage', 'To grow more crops and make more profit', 'To have more space for decoration'],
        correctAnswer: 'To grow more crops and make more profit'
    },
    {
        id: 75,
        question: 'Do commercial farmers work alone?',
        options: ['Yes, they work alone', 'No, they have farm workers to help them', 'Only on weekends', 'Only during the day'],
        correctAnswer: 'No, they have farm workers to help them'
    },
    {
        id: 76,
        question: 'Why do commercial farmers have farm workers?',
        options: ['To chat with', 'To keep them company', 'To help with the work and get things done faster', 'To play games'],
        correctAnswer: 'To help with the work and get things done faster'
    },
    {
        id: 77,
        question: 'What do commercial farmers do with the money they make from selling their produce?',
        options: ['Spend it all on toys', 'Save it for a holiday', 'Buy more farm equipment and supplies', 'Give it away for free'],
        correctAnswer: 'Buy more farm equipment and supplies'
    },
    {
        id: 78,
        question: 'How do commercial farmers benefit from selling their produce?',
        options: ['They become famous', 'They make a profit and can reinvest in their farm', 'They lose money', 'They get a vacation'],
        correctAnswer: 'They make a profit and can reinvest in their farm'
    },
    {
        id: 79,
        question: 'Where does sugar cane grow best in South Africa?',
        options: ['Where its cold and dry', 'Where its warm and rains in summer', 'Where it snows', 'In the desert'],
        correctAnswer: 'Where its warm and rains in summer'
    },
    {
        id: 80,
        question: 'Which province in South Africa has fields of sunflowers?',
        options: ['Gauteng', 'Western Cape', 'Free State', 'Eastern Cape'],
        correctAnswer: 'Free State'
    },
    {
        id: 81,
        question: 'Which type of fruit grows in hot, wet places in South Africa?',
        options: ['Citrus fruit', 'Tropical fruit', 'Deciduous fruit', 'Berries'],
        correctAnswer: 'Tropical fruit'
    },
    {
        id: 82,
        question: 'When does citrus fruit become ripe in South Africa?',
        options: ['In the summer', 'In the winter', 'During spring', 'During autumn'],
        correctAnswer: 'In the winter'
    },
    {
        id: 83,
        question: 'Which fruit variety grows well in places with cold winters and dry summers in South Africa?',
        options: ['Bananas', 'Mangoes', 'Grapes', 'Pineapples'],
        correctAnswer: 'Grapes'
    }, 
    {
        id: 84,
        question: 'Where do tropical fruits like mangoes and bananas grow best in South Africa?',
        options: ['In regions with cold winters and dry summers', 'In hot and wet places', 'In deserts', 'In areas with heavy snowfall'],
        correctAnswer: 'In hot and wet places'
    },
    {
        id: 85,
        question: 'Which province in South Africa is known for growing citrus fruits such as oranges and lemons?',
        options: ['Limpopo', 'KwaZulu-Natal', 'Western Cape', 'Northern Cape'],
        correctAnswer: 'Limpopo'
    },
    {
        id: 86,
        question: 'Why is agriculture important in South Africa?',
        options: ['To provide food only', 'To create jobs and boost the economy', 'To cause environmental degradation', 'To increase pollution'],
        correctAnswer: 'To create jobs and boost the economy'
    },
    {
        id: 87,
        question: 'Why do different crops grow in different parts of South Africa?',
        options: ['Due to government regulations', 'Due to cultural preferences', 'Due to differences in climate and soil', 'Due to historical reasons'],
        correctAnswer: 'Due to differences in climate and soil'
    },
    {
        id: 88,
        question: 'What are some common farming techniques used in South Africa?',
        options: ['Using modern technology and machinery', 'Only manual labor', 'No specific techniques', 'Relying solely on natural processes'],
        correctAnswer: 'Using modern technology and machinery'
    },
    {
        id: 89,
        question: 'Why do farmers in South Africa use machinery?',
        options: ['To make farming more difficult', 'To reduce efficiency', 'To increase productivity and reduce labor costs', 'To harm the environment'],
        correctAnswer: 'To increase productivity and reduce labor costs'
    },
    {
        id: 90,
        question: 'What are the benefits of growing a variety of crops on a farm?',
        options: ['Increased risk of crop failure', 'Decreased biodiversity', 'Improved soil health and reduced pest problems', 'Decreased food availability'],
        correctAnswer: 'Improved soil health and reduced pest problems'
    },
    {
        id: 91,
        question: 'Why is sustainable agriculture important?',
        options: ['To deplete natural resources', 'To harm ecosystems', 'To ensure food production for future generations', 'To promote soil erosion'],
        correctAnswer: 'To ensure food production for future generations'
    },
    {
        id: 92,
        question: 'What are some challenges faced by farmers in South Africa?',
        options: ['Abundant natural resources', 'Unpredictable weather patterns and climate change', 'Lack of market demand', 'High availability of labor'],
        correctAnswer: 'Unpredictable weather patterns and climate change'
    },
    {
        id:93,
        question: 'Which of the following animals is considered large stock?',
        options: ['Chickens', 'Sheep', 'Pigs', 'Cattle'],
        correctAnswer: 'Cattle'
    },
    {
        id: 94,
        question: 'Which animals are classified as small stock?',
        options: ['Cattle and pigs', 'Sheep and pigs', 'Chickens and ostriches', 'Cattle and sheep'],
        correctAnswer: 'Sheep and pigs'
    },
    {
        id: 95,
        question: 'What do chickens primarily provide to farmers?',
        options: ['Wool and meat', 'Eggs and meat', 'Feathers and leather', 'Milk and meat'],
        correctAnswer: 'Eggs and meat'
    },
    {
        id: 96,
        question: 'What are the primary products obtained from cattle?',
        options: ['Wool and meat', 'Eggs and meat', 'Dairy products and leather', 'Feathers and leather'],
        correctAnswer: 'Dairy products and leather'
    },
    {
        id: 97,
        question: 'Which of the following animals is NOT considered small stock?',
        options: ['Sheep', 'Chickens', 'Pigs', 'Ostriches'],
        correctAnswer: 'Ostriches'
    },
    {
        id: 98,
        question: 'What category do cattle fall into on a farm?',
        options: ['Small stock', 'Large stock', 'Poultry', 'None of the above'],
        correctAnswer: 'Large stock'
    },
    {
        id: 99,
        question: 'What do pigs primarily provide to farmers?',
        options: ['Wool and meat', 'Eggs and meat', 'Feathers and leather', 'Meat and leather'],
        correctAnswer: 'Meat and leather'
    },
    {
        id: 100,
        question: 'Which farm animal primarily gives us wool?',
        options: ['Pigs', 'Chickens', 'Cattle', 'Sheep'],
        correctAnswer: 'Sheep'
    },
    {
        id: 101,
        question: 'Why do farmers need to make sure there is enough water for their animals?',
        options: ['To promote their health', 'To increase their weight', 'To save water', 'To reduce the farm\'s expenses'],
        correctAnswer: 'To promote their health'
    },
    {
        id: 102,
        question: 'What do chickens primarily provide to farmers?',
        options: ['Eggs and meat', 'Feathers and leather', 'Wool and meat', 'Dairy products and leather'],
        correctAnswer: 'Eggs and meat'
    },
    {
        id: 103,
        question: 'What do cattle primarily provide to farmers?',
        options: ['Feathers', 'Wool', 'Meat', 'Eggs'],
        correctAnswer: 'Meat'
    },
    {
        id: 104,
        question: 'Why do farmers need to provide shade for their animals?',
        options: ['To keep the animals warm', 'To protect them from predators', 'To prevent them from wandering off', 'To protect them from extreme heat'],
        correctAnswer: 'To protect them from extreme heat'
    },
    {
        id: 105,
        question: 'Why are some farms located near cities and towns?',
        options: ['For better access to water', 'To avoid extreme weather conditions', 'To get food sold quick', 'For better access to grazing land'],
        correctAnswer: 'To get food sold quick'
    },
    {
        id: 106,
        question: 'Which type of farming is commonly found in areas more available water resources?',
        options: ['Stock farming', 'Commercial farming', 'Subsistence farming', 'Mixed farming'],
        correctAnswer: 'Commercial farming'
    },
    {
        id: 107,
        question: 'In which regions of South Africa are dairy and vegetable farms commonly found?',
        options: ['Coastal regions', 'Mountainous regions', 'Desert regions', 'Inland regions'],
        correctAnswer: 'Coastal regions'
    },
    {
        id: 108,
        question: 'Why do different types of farming occur in different parts of South Africa?',
        options: ['Due to government regulations', 'Because of varying climate and soil conditions', 'To promote biodiversity', 'To reduce transportation costs'],
        correctAnswer: 'Because of different climate and soil conditions'
    },
    {
        id: 109,
        question: 'Which farming type is commonly practiced in areas with little access to water resources?',
        options: ['Commercial farming', 'Subsistence farming', 'Mixed farming', 'Intensive farming'],
        correctAnswer: 'Subsistence farming'
    },
    {
        id: 110,
        question: 'What factor primarily influences the choice of farming activities in different parts of South Africa?',
        options: ['Historical traditions', 'Proximity to transportation hubs', 'Government subsidies', 'Climate and soil conditions'],
        correctAnswer: 'Climate and soil conditions'
    },
    {
        id: 111,
        question: 'What is an example of unprocessed food?',
        options: ['Bread', 'Orange juice', 'Canned soup', 'Chocolate bar'],
        correctAnswer: 'Orange juice'
    },
    {
        id: 112,
        question: 'Which term best describes uncooked fruits and vegetables?',
        options: ['Processed food', 'Raw food', 'Canned food', 'Preserved food'],
        correctAnswer: 'Raw food'
    },
    {
        id: 113,
        question: 'What is an example of food processing at home?',
        options: ['Eating raw carrots', 'Drinking bottled water', 'Mixing milk and banana to make a milkshake', 'Eating a frozen pizza'],
        correctAnswer: 'Mixing milk and banana to make a milkshake'
    },
    {
        id: 114,
        question: 'Which of the following is a processed food?',
        options: ['Freshly squeezed orange juice', 'Canned tomatoes', 'Raw almonds', 'Whole-grain bread'],
        correctAnswer: 'Canned tomatoes'
    },
    {
        id: 115,
        question: 'What is an example of food processed in factories?',
        options: ['Home-cooked pasta', 'Homemade apple pie', 'Sugar cane to make sugar', 'Freshly picked strawberries'],
        correctAnswer: 'Sugar cane to make sugar'
    },
    {
        id: 116,
        question: 'Which ingredient is commonly processed in factories to make flour?',
        options: ['Bananas', 'Wheat', 'Apples', 'Rice'],
        correctAnswer: 'Wheat'
    },
    {
        id: 117,
        question: 'Which of the following is an example of processed food?',
        options: ['Freshly squeezed orange juice', 'Whole carrots', 'Plain yogurt', 'Canned soup'],
        correctAnswer: 'Canned soup'
    },
    {
        id: 118,
        question: 'Which of the following is an example of home food processing?',
        options: ['Eating raw spinach leaves', 'Making a smoothie with blended fruits', 'Eating a store-bought sandwich', 'Consuming canned tuna'],
        correctAnswer: 'Making a smoothie with blended fruits'
    },
    {
        id: 119,
        question: 'Which of the following is a product of factory food processing?',
        options: ['Freshly picked strawberries', 'Canned peaches', 'Boiled potatoes', 'Raw almonds'],
        correctAnswer: 'Canned peaches'
    },
    {
        id: 120,
        question: 'Which of the following is an example of unprocessed food?',
        options: ['Frozen pizza', 'Instant noodles', 'Fresh apples', 'Canned soup'],
        correctAnswer: 'Fresh apples'
    },
    {
        id: 121,
        question: 'Which of the following is a product of factory food processing?',
        options: ['Freshly squeezed orange juice', 'Packaged potato chips', 'Whole carrots', 'Raw chicken breast'],
        correctAnswer: 'Packaged potato chips'
    },
    {
        id: 122,
        question: 'Where does most of our food come from?',
        options: ['Factories', 'Supermarkets', 'Farms', 'Restaurants'],
        correctAnswer: 'Farms',
        imageUrl: '/activityImages/SocialSciences/food_food-farm.jfif'
    },
    {
        id: 123,
        question: 'What type of food is shown in the image?',
        options: ['Fruits', 'Vegetables', 'Dairy', 'Meat'],
        correctAnswer: 'Vegetables',
        imageUrl: '/activityImages/SocialSciences/food_food-from-the-ground.jfif'
    },
    {
        id: 124,
        question: 'What do people typically grow in a food garden?',
        options: ['Flowers', 'Vegetables', 'Trees', 'Grass'],
        correctAnswer: 'Vegetables',
        imageUrl: '/activityImages/SocialSciences/food_food-garden.jfif'
    },
    {
        id: 125,
        question: 'What is the person in the image doing?',
        options: ['Reading a book', 'Fishing', 'Harvesting fruits', 'Cooking dinner'],
        correctAnswer: 'Harvesting fruits',
        imageUrl: '/activityImages/SocialSciences/Food_fruit-farmer.jfif'
    },
    {
        id: 126,
        question: 'What kind of business might own this stall?',
        options: ['Butcher', 'Bakery', 'Fishmonger', 'Fruit vendor'],
        correctAnswer: 'Fruit vendor',
        imageUrl: '/activityImages/SocialSciences/food_fruits-stall.png'
    },
    {
        id: 127,
        question: 'What activity is commonly done in a garden?',
        options: ['Reading', 'Swimming', 'Playing sports', 'Planting'],
        correctAnswer: 'Planting',
        imageUrl: '/activityImages/SocialSciences/food_garden.jfif'
    },
    {
        id: 128,
        question: 'What activity is the person in the image doing?',
        options: ['Cooking', 'Fishing', 'Gardening', 'Driving'],
        correctAnswer: 'Fishing',
        imageUrl: '/activityImages/SocialSciences/food_man-fishing.jfif'
    },
    {
        id: 129,
        question: 'What crop is being cultivated in the image?',
        options: ['Wheat', 'Rice', 'Potatoes', 'Corn'],
        correctAnswer: 'Potatoes',
        imageUrl: '/activityImages/SocialSciences/food_potatoes-being-farmed.jfif'
    },
    {
        id: 130,
        question: 'Which type of meat is commonly referred to as "red meat"?',
        options: ['Chicken', 'Beef', 'Fish', 'Pork'],
        correctAnswer: 'Beef',
        imageUrl: '/activityImages/SocialSciences/food_red-meat.jfif'
    },
    {
        id: 131,
        question: 'What place is depicted in the image?',
        options: ['School', 'Hospital', 'Market', 'Library'],
        correctAnswer: 'Market',
        imageUrl: '/activityImages/SocialSciences/food_fruits-stall1.png'
    },

];
  
  export default socialSciencesQuestionsData;
  
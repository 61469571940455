const socialSciencesCardinalPoints = [
    {
        id: 1,
        question: 'Determining the cardinal direction from point A to point B.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 2,
        question: 'Determining the cardinal direction from point A to point C.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 3,
        question: 'Determining the cardinal direction from point A to point D.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 4,
        question: 'Determining the cardinal direction from point A to point E.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 5,
        question: 'Determining the cardinal direction from point A to point I.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 6,
        question: 'Determining the cardinal direction from point A to point M.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 7,
        question: 'Determining the cardinal direction from point B to point A.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 8,
        question: 'Determining the cardinal direction from point B to point F.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 9,
        question: 'Determining the cardinal direction from point B to point J.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 10,
        question: 'Determining the cardinal direction from point B to point N.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 11,
        question: 'Determining the cardinal direction from point B to point C.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 12,
        question: 'Determining the cardinal direction from point B to point D.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 13,
        question: 'Determining the cardinal direction from point C to point A.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 14,
        question: 'Determining the cardinal direction from point C to point B.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 15,
        question: 'Determining the cardinal direction from point C to point G.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 16,
        question: 'Determining the cardinal direction from point C to point K.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 17,
        question: 'Determining the cardinal direction from point C to point O.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 18,
        question: 'Determining the cardinal direction from point C to point D.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 19,
        question: 'Determining the cardinal direction from point D to point A.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 20,
        question: 'Determining the cardinal direction from point D to point B.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 21,
        question: 'Determining the cardinal direction from point D to point C.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 22,
        question: 'Determining the cardinal direction from point D to point H.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 23,
        question: 'Determining the cardinal direction from point D to point L.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 24,
        question: 'Determining the cardinal direction from point D to point P.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 25,
        question: 'Determining the cardinal direction from point E to point I.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 26,
        question: 'Determining the cardinal direction from point E to point F.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 27,
        question: 'Determining the cardinal direction from point E to point G.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 28,
        question: 'Determining the cardinal direction from point E to point H.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 29,
        question: 'Determining the cardinal direction from point E to point I.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 30,
        question: 'Determining the cardinal direction from point E to point M.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 31,
        question: 'Determining the cardinal direction from point F to point E.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 32,
        question: 'Determining the cardinal direction from point F to point J.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 33,
        question: 'Determining the cardinal direction from point F to point N.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 34,
        question: 'Determining the cardinal direction from point F to point G.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 35,
        question: 'Determining the cardinal direction from point F to point H.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 36,
        question: 'Determining the cardinal direction from point F to point B.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 37,
        question: 'Determining the cardinal direction from point G to point F.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 38,
        question: 'Determining the cardinal direction from point G to point E.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 39,
        question: 'Determining the cardinal direction from point G to point C.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 40,
        question: 'Determining the cardinal direction from point G to point H.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 41,
        question: 'Determining the cardinal direction from point G to point K.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 42,
        question: 'Determining the cardinal direction from point G to point O.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 43,
        question: 'Determining the cardinal direction from point H to point D.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 44,
        question: 'Determining the cardinal direction from point H to point E.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 45,
        question: 'Determining the cardinal direction from point H to point F.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 46,
        question: 'Determining the cardinal direction from point H to point G.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 47,
        question: 'Determining the cardinal direction from point H to point L.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 48,
        question: 'Determining the cardinal direction from point H to point P.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 49,
        question: 'Determining the cardinal direction from point I to point M.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 50,
        question: 'Determining the cardinal direction from point I to point A.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 51,
        question: 'Determining the cardinal direction from point I to point E.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 52,
        question: 'Determining the cardinal direction from point I to point J.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 53,
        question: 'Determining the cardinal direction from point I to point K.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 54,
        question: 'Determining the cardinal direction from point I to point L.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 55,
        question: 'Determining the cardinal direction from point J to point I.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 56,
        question: 'Determining the cardinal direction from point J to point F.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 57,
        question: 'Determining the cardinal direction from point J to point B.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 58,
        question: 'Determining the cardinal direction from point J to point N.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 59,
        question: 'Determining the cardinal direction from point J to point K.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 60,
        question: 'Determining the cardinal direction from point J to point L.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 61,
        question: 'Determining the cardinal direction from point K to point G.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 62,
        question: 'Determining the cardinal direction from point K to point C.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 63,
        question: 'Determining the cardinal direction from point K to point O.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 64,
        question: 'Determining the cardinal direction from point K to point J.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 65,
        question: 'Determining the cardinal direction from point K to point I.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 66,
        question: 'Determining the cardinal direction from point K to point L.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 67,
        question: 'Determining the cardinal direction from point L to point K.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 68,
        question: 'Determining the cardinal direction from point L to point J.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 69,
        question: 'Determining the cardinal direction from point L to point I.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 70,
        question: 'Determining the cardinal direction from point L to point P.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'South',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 71,
        question: 'Determining the cardinal direction from point L to point H.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 72,
        question: 'Determining the cardinal direction from point L to point D.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    }, 
    {
        id: 73,
        question: 'Determining the cardinal direction from point M to point I.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 74,
        question: 'Determining the cardinal direction from point M to point E.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 75,
        question: 'Determining the cardinal direction from point M to point A.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 76,
        question: 'Determining the cardinal direction from point M to point N.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 77,
        question: 'Determining the cardinal direction from point M to point O.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 78,
        question: 'Determining the cardinal direction from point M to point P.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 79,
        question: 'Determining the cardinal direction from point N to point M.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 80,
        question: 'Determining the cardinal direction from point N to point J.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 81,
        question: 'Determining the cardinal direction from point N to point F.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 82,
        question: 'Determining the cardinal direction from point N to point B.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 83,
        question: 'Determining the cardinal direction from point N to point O.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 84,
        question: 'Determining the cardinal direction from point N to point P.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 85,
        question: 'Determining the cardinal direction from point O to point N.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 86,
        question: 'Determining the cardinal direction from point O to point M.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 87,
        question: 'Determining the cardinal direction from point O to point K.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 88,
        question: 'Determining the cardinal direction from point O to point G.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 89,
        question: 'Determining the cardinal direction from point O to point C.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 90,
        question: 'Determining the cardinal direction from point O to point P.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'East',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    }, 
    {
        id: 91,
        question: 'Determining the cardinal direction from point P to point M.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 92,
        question: 'Determining the cardinal direction from point P to point N.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 93,
        question: 'Determining the cardinal direction from point P to point O.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'West',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 94,
        question: 'Determining the cardinal direction from point P to point L.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 95,
        question: 'Determining the cardinal direction from point P to point H.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    },
    {
        id: 96,
        question: 'Determining the cardinal direction from point P to point D.',
        options: ['North', 'South', 'West', 'East'],
        correctAnswer: 'North',
        imageUrl: '/activityImages/SocialSciences/Cardinal_points.png' 
    }

];
  
export default socialSciencesCardinalPoints;
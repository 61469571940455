// AccessDenied.js
import React from 'react';

const AccessDenied = () => {
  return (
    <div style={{margin:'10px'}}>
      <h1>Access Denied</h1>
      <p>You are not authenticated. Please <a style={{color:'blue', textDecoration:'underline'}} href="/login">login</a> to access this page.</p>
    </div>
  );
};

export default AccessDenied;

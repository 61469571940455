const socialSciencesMapSkillsQuestions = [
    {
        id: 1,
        question: 'Which continent is the largest by land area?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Asia',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 2,
        question: 'Which continent is home to the Amazon Rainforest?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'South America',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 3,
        question: 'Which continent is known for its vast deserts such as the Sahara and Kalahari?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Africa',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 4,
        question: 'Which continent is the only one to span all four hemispheres?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Africa',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 5,
        question: 'Which continent is the coldest, driest, and windiest continent on Earth?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'Antarctica', 'South America'],
        correctAnswer: 'Antarctica',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 6,
        question: 'Which continent is the most populated?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Asia',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 7,
        question: 'Which continent is the smallest by land area?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Australia',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 8,
        question: 'Which continent is home to the Himalayas, the highest mountain range in the world?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Asia',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 9,
        question: 'Which continent is known for its diverse wildlife, including the "Big Five"?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Africa',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 10,
        question: 'Which continent is the only one that lies entirely within the Southern Hemisphere?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'South America', 'Antarctica'],
        correctAnswer: 'Australia',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 11,
        question: 'Which continent is the only one that lies entirely within the Southern Hemisphere?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'South America', 'Antarctica'],
        correctAnswer: 'Australia',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 12,
        question: 'Which continent is home to the Great Barrier Reef, the largest coral reef system in the world?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Australia',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 13,
        question: 'Which continent is often referred to as the "Old World"?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Europe',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 14,
        question: 'Which continent is the northernmost and westernmost part of the Americas?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'North America',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 15,
        question: 'Which continent is known as the "Land of the Rising Sun"?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Asia',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 16,
        question: 'Which continent is entirely located within the Eastern Hemisphere?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Europe',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 17,
        question: 'Which continent is the only one to have no permanent human inhabitants?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'Antarctica', 'South America'],
        correctAnswer: 'Antarctica',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 18,
        question: 'Which continent is known for its tectonic activity, including frequent earthquakes and volcanic eruptions?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Asia',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 19,
        question: 'Which continent is home to the largest population of indigenous peoples?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Asia',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 20,
        question: 'Which continent is the only one to have land in all four hemispheres?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'North America', 'South America'],
        correctAnswer: 'Africa',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 21,
        question: 'Which continent is labeled as "A"?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'Antarctica', 'South America'],
        correctAnswer: 'Africa',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 22,
        question: 'Which continent is labeled as "C"?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'Antarctica', 'South America'],
        correctAnswer: 'Europe',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 23,
        question: 'Which continent is labeled as "D"?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'Antarctica', 'South America'],
        correctAnswer: 'North America',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 24,
        question: 'Which continent is labeled as "F"?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'Antarctica', 'South America'],
        correctAnswer: 'Antarctica',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 23,
        question: 'Which continent is labeled as "G"?',
        options: ['Africa', 'Asia', 'Europe', 'Australia', 'Antarctica', 'South America'],
        correctAnswer: 'South America',
        imageUrl: '/activityImages/SocialSciences/World-Map_Countries.png' 
    },
    {
        id: 24,
        question: 'Which province is marked as "A" on the map?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Limpopo',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 25,
        question: 'Which province is marked as "F" on the map?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'KwaZulu-Natal',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 26,
        question: 'Which province is marked as "G" on the map?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Eastern Cape',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 27,
        question: 'Which province is marked as "I" on the map?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Western Cape',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 28,
        question: 'Which province is marked as "H" on the map?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Northern Cape',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 29,
        question: 'Which South African province is known for being the economic hub of the country, housing the city of Johannesburg?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Gauteng',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 30,
        question: 'Which province in South Africa is famous for its beaches along the Indian Ocean coastline?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'KwaZulu-Natal',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 31,
        question: 'Which South African province is known for its diverse landscapes, including the famous Garden Route and Addo Elephant National Park?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Eastern Cape',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 31,
        question: 'Which province in South Africa is known for its scenic beauty, including landmarks like Table Mountain and the Cape Winelands?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Western Cape',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 32,
        question: 'Which South African province is characterized by its vast wildlife reserves, including the Kruger National Park?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Limpopo',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 33,
        question: 'Which South African province is known for its gold and diamond mining industries?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Gauteng',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },{
        id: 34,
        question: 'Which province in South Africa is renowned for its cultural heritage, including the Zulu Kingdom and the Battlefields Route?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'KwaZulu-Natal',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 35,
        question: 'Which South African province is known for its agricultural productivity and is often referred to as the "Breadbasket of South Africa"?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Free State',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 36,
        question: 'Which province in South Africa is famous for its platinum mines and the breathtaking Blyde River Canyon?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Mpumalanga',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 37,
        question: 'Which South African province is the largest by land area?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Northern Cape',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 38,
        question: 'Which province in South Africa is home to the Drakensberg Mountains and the Golden Gate Highlands National Park?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Free State',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 39,
        question: 'Which South African province is known for its rich history, including the city of Bloemfontein, the judicial capital of South Africa?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Free State',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 40,
        question: 'Which province in South Africa is famous for its wildlife reserves, including the Pilanesberg National Park and the Madikwe Game Reserve?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'North West',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 41,
        question: 'Which South African province is known for its citrus fruit farming and the famous Hogsback Mountains?',
        options: ['Gauteng', 'KwaZulu-Natal', 'Eastern Cape', 'Western Cape', 'Limpopo', 'Mpumalanga', 'North West', 'Free State', 'Northern Cape'],
        correctAnswer: 'Eastern Cape',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 42,
        question: 'Which South African province is known for its wine production and vineyards?',
        options: ['Gauteng', 'Western Cape', 'Mpumalanga', 'Northern Cape'],
        correctAnswer: 'Western Cape',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 43,
        question: 'What are the names of the oceans along South Africa’s coastline?',
        options: ['Atlantic Ocean and Indian Ocean', 'Pacific Ocean and Arctic Ocean', 'Southern Ocean and Pacific Ocean', 'Indian Ocean and Southern Ocean'],
        correctAnswer: 'Atlantic Ocean and Indian Ocean',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 44,
        question: 'Which province in South Africa is known for the city of Johannesburg?',
        options: ['Western Cape', 'Gauteng', 'KwaZulu-Natal', 'Eastern Cape'],
        correctAnswer: 'Gauteng',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 45,
        question: 'Which two oceans meet along the coast of South Africa?',
        options: ['Pacific Ocean and Arctic Ocean', 'Southern Ocean and Pacific Ocean', 'Indian Ocean and Southern Ocean', 'Atlantic Ocean and Indian Ocean'],
        correctAnswer: 'Atlantic Ocean and Indian Ocean',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 46,
        question: 'Which province in South Africa is known for the city of Durban?',
        options: ['Western Cape', 'Gauteng', 'KwaZulu-Natal', 'Eastern Cape'],
        correctAnswer: 'KwaZulu-Natal',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 47,
        question: 'Which ocean lies to the east of South Africa?',
        options: ['Atlantic Ocean', 'Pacific Ocean', 'Indian Ocean', 'Southern Ocean'],
        correctAnswer: 'Indian Ocean',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 48,
        question: 'Which province in South Africa is known for the city of Cape Town?',
        options: ['Western Cape', 'Gauteng', 'KwaZulu-Natal', 'Eastern Cape'],
        correctAnswer: 'Western Cape',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 49,
        question: 'Which ocean lies to the west of South Africa?',
        options: ['Atlantic Ocean', 'Pacific Ocean', 'Indian Ocean', 'Southern Ocean'],
        correctAnswer: 'Atlantic Ocean',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    },
    {
        id: 50,
        question: 'Which province in South Africa is known for the city of Port Elizabeth?',
        options: ['Western Cape', 'Gauteng', 'KwaZulu-Natal', 'Eastern Cape'],
        correctAnswer: 'Eastern Cape',
        imageUrl: '/activityImages/SocialSciences/World-Map_South-Africa-marked.png' 
    }


];
  
export default socialSciencesMapSkillsQuestions;
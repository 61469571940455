import React from 'react';

const YouBlockedUser = () => {
    return (
        <div>
            <p>You have blocked the user who authored this post.</p>
        </div>
    );
};

export default YouBlockedUser;

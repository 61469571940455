import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './auth.css';

const Verify = () => {
  const { token } = useParams();
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await fetch(`https://lernacy.co.za/api/verify?token=${token}`);
        const responseData = await response.json();

        //console.log('Verification response:', responseData); // Log the response data for debugging

        if (response.ok) {
          setVerificationStatus('Verification Successful!');
        } else {
          setVerificationStatus(responseData.message || 'Verification Failed. Please try again.');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setVerificationStatus('Verification Failed. Please try again.');
      }
    };

    verifyToken();
  }, [token]);

  return (
    <div className='unauthenticated-page-block-container'>
      <div className='unauthenticated-page-aligner-block'>
        <div>
          <h1>{verificationStatus}</h1>
          <p>
            <Link to="/login" className='links'>Proceed to login</Link>
          </p>
          <hr/>
          <p>
            Verification unsuccessful?  
            <Link to="/request-verification" className='links'> Request Verification Link</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Verify;

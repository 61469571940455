import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MenuToggle from '../MenuToggle';  
import ReadMore from './ReadMore';  
import '../Communities.css'; // Import the CSS for styling

const ArticleOne = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });
 
        if (response.ok) { 
          const data = await response.json();
          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        setIsLoggedIn(false);
      }
    };

    fetchUser();
  }, [navigate]);

  return (
    <div>
      <div className="communities-page">
        {isLoggedIn ? (
          <div>
            <div className='page-colour-manager'>

                <div className="my-study-container">  
                    <div className='unique-image-card-container-pad'>

                      <article className='aricle-block-div'>

                        <h1 className='article-name-tag-h1'>Shaping South Africa's Future: Our Educational App for a New Era</h1>

                        <p className='disclaimer-note-label'>
                          Read our
                          <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer </Link>
                          before proceeding with the article below
                        </p>

                        <label>by: Lernacy</label>

                        <p>
                          Education is a powerful tool, capable of shaping individuals and communities. Our app, 
                          designed with the specific needs of South African learners in mind, is more than just a 
                          tool for academic learning; it’s a platform that encourages growth, engagement, and 
                          success. Built with a vision to make education accessible and engaging, this app empowers 
                          young learners to take control of their academic journey and develop skills that go 
                          beyond the classroom.
                        </p>

                        <h2>What Does Our App Offer?</h2>

                        <p>
                          The app is a comprehensive educational platform that brings together students, teachers, 
                          and institutions to support academic achievement and personal development. With 
                          interactive exercises, real-time feedback, and customizable learning plans, it caters to 
                          the unique needs of each learner. Schools benefit from a centralized system that provides 
                          tracking capabilities, making it easy to monitor progress, assess learning outcomes, and 
                          adapt teaching strategies accordingly.
                        </p>

                        <p>
                          Our app also includes diagnostic tools to help educators and learners identify potential 
                          areas for improvement. By providing tailored activities and resources, the app supports 
                          every learner's journey, enhancing their understanding of subjects and encouraging 
                          critical thinking. For learners struggling in specific areas, the app offers targeted 
                          activities and exercises to address these challenges effectively.
                        </p>

                        <h2>Our Mission and Vision</h2>

                        <p>At the core of our app is a commitment to quality education and lifelong learning. We 
                          stand for empowerment, inclusivity, and access. Every feature in the app is designed with 
                          these values in mind. Our mission is to close the educational gaps faced by students, 
                          regardless of background or resources, and give them a platform that encourages active 
                          participation and academic confidence.
                        </p>

                        <p>Looking to the future, we envision an education system where every learner has access to 
                          quality resources, where education is personalized, and where success is attainable for 
                          all. Our app is just the beginning of this journey, a tool to bridge the gap between 
                          current educational challenges and the future we believe is possible. Through continuous 
                          innovation and dedication, we aim to become an integral part of the educational landscape 
                          in South Africa.
                        </p>

                        <h2>The Benefits for South African Youth</h2>

                        <p>The youth of South Africa have much to gain from this educational app. By providing an 
                          interactive, adaptable, and supportive learning environment, the app promotes a growth 
                          mindset and equips students with skills that will benefit them throughout their lives. 
                          Learners can access resources anytime, anywhere, making it easier for them to keep up 
                          with their studies and excel academically. Furthermore, our app encourages 
                          self-assessment and reflection, empowering students to take charge of their learning 
                          journey.
                        </p>

                        <p>For South African youth, the app is more than an academic tool; it’s a stepping stone 
                          to success. The app encourages learners to reach their full potential, helping them build 
                          confidence and develop critical skills needed in the workplace. By supporting the next 
                          generation, we’re not only strengthening their educational foundation but also 
                          contributing to a stronger and more educated workforce in the future.
                        </p>

                        <h2>The Future of Education in South Africa</h2>

                        <p>Education in South Africa is at a pivotal point. The need for accessible, high-quality 
                          learning resources is more pressing than ever, especially in underserved communities. 
                          Our app is part of a broader movement to modernize and uplift the education system in 
                          the country. By leveraging technology, we can break down the barriers that prevent 
                          learners from reaching their potential.
                        </p>

                        <p>In the future, we envision an education system where learning is personalized, 
                          adaptable, and inclusive. By using data-driven insights, we hope to provide teachers 
                          with the tools they need to support each learner, irrespective of their learning style 
                          or pace. Our app is built with these principles in mind, striving to set a new standard 
                          in educational technology.
                        </p>

                        <p>As we look forward, we are committed to evolving our app to meet the changing needs of 
                          South African students, educators, and institutions. We believe that every student has 
                          the right to a meaningful education and that technology can be the catalyst to bring 
                          about this change. With the continued support of our users and the education community, 
                          we aim to make a lasting impact on the future of learning in South Africa.
                        </p>

                        <h2>Join Us on This Journey</h2>

                        <p>We invite students, educators, and institutions to join us in revolutionizing 
                          education. With our app, we’re not only reshaping how learners engage with content 
                          but also preparing them for a bright future. Together, we can build a generation of 
                          confident, informed, and skilled individuals who are ready to contribute to society 
                          and make a difference.
                        </p>

                        <p>Explore the possibilities with us, and let’s create a brighter future for the youth of 
                          South Africa. Our app is more than an educational tool – it’s a commitment to positive 
                          change, growth, and success for every learner.
                        </p>

                        <p>Published: 02 November, 2024</p>

                        <hr className='aricle-block-div-hr' />
        
                        <ReadMore />

                        <br />

                      </article>

                    </div>
                </div>

            </div>
          </div>
        ) : (
          <div>
            <div className='not-logged-in-alt'>
              <MenuToggle />
              <div className='not-logged-in-alt-aligner'>
                
              <div className='aricle-block-div-pad'>
                <article className='aricle-block-div'>

                  <h1 className='article-name-tag-h1'>Shaping South Africa's Future: Our Educational App for a New Era</h1>

                  <p className='disclaimer-note-label'>
                    Read our
                    <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer </Link>
                    before proceeding with the article below
                  </p>
  
                  <label>by: Lernacy</label>

                  <p>
                    Education is a powerful tool, capable of shaping individuals and communities. Our app, 
                    designed with the specific needs of South African learners in mind, is more than just a 
                    tool for academic learning; it’s a platform that encourages growth, engagement, and 
                    success. Built with a vision to make education accessible and engaging, this app empowers 
                    young learners to take control of their academic journey and develop skills that go 
                    beyond the classroom.
                  </p>

                  <h2>What Does Our App Offer?</h2>

                  <p>
                    The app is a comprehensive educational platform that brings together students, teachers, 
                    and institutions to support academic achievement and personal development. With 
                    interactive exercises, real-time feedback, and customizable learning plans, it caters to 
                    the unique needs of each learner. Schools benefit from a centralized system that provides 
                    tracking capabilities, making it easy to monitor progress, assess learning outcomes, and 
                    adapt teaching strategies accordingly.
                  </p>

                  <p>
                    Our app also includes diagnostic tools to help educators and learners identify potential 
                    areas for improvement. By providing tailored activities and resources, the app supports 
                    every learner's journey, enhancing their understanding of subjects and encouraging 
                    critical thinking. For learners struggling in specific areas, the app offers targeted 
                    activities and exercises to address these challenges effectively.
                  </p>

                  <h2>Our Mission and Vision</h2>

                  <p>At the core of our app is a commitment to quality education and lifelong learning. We 
                    stand for empowerment, inclusivity, and access. Every feature in the app is designed with 
                    these values in mind. Our mission is to close the educational gaps faced by students, 
                    regardless of background or resources, and give them a platform that encourages active 
                    participation and academic confidence.
                  </p>

                  <p>Looking to the future, we envision an education system where every learner has access to 
                    quality resources, where education is personalized, and where success is attainable for 
                    all. Our app is just the beginning of this journey, a tool to bridge the gap between 
                    current educational challenges and the future we believe is possible. Through continuous 
                    innovation and dedication, we aim to become an integral part of the educational landscape 
                    in South Africa.
                  </p>

                  <h2>The Benefits for South African Youth</h2>

                  <p>The youth of South Africa have much to gain from this educational app. By providing an 
                    interactive, adaptable, and supportive learning environment, the app promotes a growth 
                    mindset and equips students with skills that will benefit them throughout their lives. 
                    Learners can access resources anytime, anywhere, making it easier for them to keep up 
                    with their studies and excel academically. Furthermore, our app encourages 
                    self-assessment and reflection, empowering students to take charge of their learning 
                    journey.
                  </p>

                  <p>For South African youth, the app is more than an academic tool; it’s a stepping stone 
                    to success. The app encourages learners to reach their full potential, helping them build 
                    confidence and develop critical skills needed in the workplace. By supporting the next 
                    generation, we’re not only strengthening their educational foundation but also 
                    contributing to a stronger and more educated workforce in the future.
                  </p>

                  <h2>The Future of Education in South Africa</h2>

                  <p>Education in South Africa is at a pivotal point. The need for accessible, high-quality 
                    learning resources is more pressing than ever, especially in underserved communities. 
                    Our app is part of a broader movement to modernize and uplift the education system in 
                    the country. By leveraging technology, we can break down the barriers that prevent 
                    learners from reaching their potential.
                  </p>

                  <p>In the future, we envision an education system where learning is personalized, 
                    adaptable, and inclusive. By using data-driven insights, we hope to provide teachers 
                    with the tools they need to support each learner, irrespective of their learning style 
                    or pace. Our app is built with these principles in mind, striving to set a new standard 
                    in educational technology.
                  </p>

                  <p>As we look forward, we are committed to evolving our app to meet the changing needs of 
                    South African students, educators, and institutions. We believe that every student has 
                    the right to a meaningful education and that technology can be the catalyst to bring 
                    about this change. With the continued support of our users and the education community, 
                    we aim to make a lasting impact on the future of learning in South Africa.
                  </p>

                  <h2>Join Us on This Journey</h2>

                  <p>We invite students, educators, and institutions to join us in revolutionizing 
                    education. With our app, we’re not only reshaping how learners engage with content 
                    but also preparing them for a bright future. Together, we can build a generation of 
                    confident, informed, and skilled individuals who are ready to contribute to society 
                    and make a difference.
                  </p>

                  <p>Explore the possibilities with us, and let’s create a brighter future for the youth of 
                    South Africa. Our app is more than an educational tool – it’s a commitment to positive 
                    change, growth, and success for every learner.
                  </p>

                  <p className='publishing-date'>
                    Published: 02 November, 2024
                  </p>

                  <hr className='aricle-block-div-hr' />

                  <ReadMore />

                  <br />

                </article>
              </div>

              </div>
            </div>
          </div>
        )}
      </div>
      
    </div>
  ); 
};

export default ArticleOne;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetchUserData from '../../components/useFetchUserData';
import './pages-styling/pages.css';

const GradeTwoMathematics = () => {
  const user = useFetchUserData();
  const [showCountingModal, setShowCountingModal] = useState(false);
  const [showMastermindModal, setShowMastermindModal] = useState(false);
  const [showAddSubModal, setShowAddSubModal] = useState(false);
  const [showBoxNumberModal, setShowBoxNumberModal] = useState(false);
  const [showRepeatedAdditionModal, setShowRepeatedAdditionModal] = useState(false);
  const [showBigNumberChallengeModal, setShowBigNumberChallengeModal] = useState(false);
  const [showNumericFusionModal, setShowNumericFusionModal] = useState(false);
  const navigate = useNavigate();

  const handleCountingProceed = () => {
    setShowCountingModal(false);
    navigate('/Grade-Two-counting-game');
  };

  const handleMastermindProceed = () => {
    setShowMastermindModal(false);
    navigate('/Grade-Two-number-mastermind');
  };

  const handleAddSubProceed = () => {
    setShowAddSubModal(false);
    navigate('/Grade-Two-AddSubGame');
  };

  const handleBoxNumberProceed = () => {
    setShowBoxNumberModal(false);
    navigate('/Grade-Two-Box-Game');
  };

  const handleRepeatedAdditionProceed = () => {
    setShowRepeatedAdditionModal(false);
    navigate('/Grade-Two-Repeated-addition');
  };

  const handleBigNumberChallengeProceed = () => {
    setShowBigNumberChallengeModal(false);
    navigate('/Grade-Two-Big-Number-Challenge');
  };

  const handleNumericFusionProceed = () => {
    setShowNumericFusionModal(false);
    navigate('/Grade-Two-Numeric-Fusion:Halve&Double');
  };

  return (
    <div className='game-name-list-files'>
      <div className='game-name-list-files-box'>
        {user && user.accountName ? (
          <>
            <h1 className='grade-level-subject'>Grade 2 - Mathematics</h1>

            {/*<p>Welcome, {user.name} {user.surname}!</p>*/}

            {/* Counting Game Modal */}
            <button onClick={() => setShowCountingModal(true)} className="my-game-name-text">Basic Counting Activity</button>
            {showCountingModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowCountingModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleCountingProceed}>Proceed</button>
                  <button onClick={() => setShowCountingModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* Number Mastermind Modal */}
            <button onClick={() => setShowMastermindModal(true)} className="my-game-name-text">Number Mastermind</button>
            {showMastermindModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowMastermindModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleMastermindProceed}>Proceed</button>
                  <button onClick={() => setShowMastermindModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* AddSub Game Modal */}
            <button onClick={() => setShowAddSubModal(true)} className="my-game-name-text">AddSub</button>
            {showAddSubModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowAddSubModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleAddSubProceed}>Proceed</button>
                  <button onClick={() => setShowAddSubModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* Box Number Modal */}
            <button onClick={() => setShowBoxNumberModal(true)} className="my-game-name-text">Box Number</button>
            {showBoxNumberModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowBoxNumberModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleBoxNumberProceed}>Proceed</button>
                  <button onClick={() => setShowBoxNumberModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* Repeated Addition Modal */}
            <button onClick={() => setShowRepeatedAdditionModal(true)} className="my-game-name-text">Repeated Addition</button>
            {showRepeatedAdditionModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowRepeatedAdditionModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleRepeatedAdditionProceed}>Proceed</button>
                  <button onClick={() => setShowRepeatedAdditionModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* Big Number Challenge Modal */}
            <button onClick={() => setShowBigNumberChallengeModal(true)} className="my-game-name-text">Big Number</button>
            {showBigNumberChallengeModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowBigNumberChallengeModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleBigNumberChallengeProceed}>Proceed</button>
                  <button onClick={() => setShowBigNumberChallengeModal(false)}>Cancel</button>
                </div>
              </div>
            )}

            {/* Numeric Fusion Modal */}
            <button onClick={() => setShowNumericFusionModal(true)} className="my-game-name-text">Numeric Fusion</button>
            {showNumericFusionModal && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setShowNumericFusionModal(false)}>&times;</span>
                  <p>Are you sure you want to proceed?</p>
                  <button className='proceed-modal-button' onClick={handleNumericFusionProceed}>Proceed</button>
                  <button onClick={() => setShowNumericFusionModal(false)}>Cancel</button>
                </div>
              </div>
            )}

          </>
        ) : null}
      </div>
    </div>
  );
};

export default GradeTwoMathematics;

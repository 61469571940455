import React, { useState, useEffect } from 'react';
import AccessDenied from '../../components/AccessDenied';

const GrrAddSubGame = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [highScore, setHighScore] = useState(null);
  const [equations, setEquations] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const [gameScore, setGameScore] = useState(0);
  const [showGameOver, setShowGameOver] = useState(false);
  const [scoreRating, setScoreRating] = useState('');
  const [scoreComment, setScoreComment] = useState('');
  const [gameName] = useState('Grade_R:_AddSub_Maths_10');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          const user = data.user;

          if (!user || !user.accountName) {
            setIsAuthenticated(false);
            window.location.href = "/login-second-stage";
            return;
          }

          setUserDetails(user);
          setIsAuthenticated(true);
        } else {
          throw new Error('Error fetching user details: ' + response.statusText);
        }
      } catch (error) {
        console.error(error.message);
        setIsAuthenticated(false);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchHighScore();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      generateEquations();
    }
  }, [isAuthenticated]);

  const fetchHighScore = async () => {
    try {
      const url = `https://lernacy.co.za/api/user-score/${gameName}`;
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        throw new Error('Error fetching high score: ' + response.statusText);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const generateEquations = () => {
    const newEquations = [];
    for (let i = 0; i < 10; i++) {
      const num1 = Math.floor(Math.random() * 11);
      const num2 = Math.floor(Math.random() * (num1 + 1)); // Avoid negative numbers in subtraction
      const operator = Math.random() < 0.5 ? '+' : '-';
      const result = operator === '+' ? num1 + num2 : num1 - num2;
      newEquations.push({ equation: `${num1} ${operator} ${num2}`, result });
    }
    setEquations(newEquations);
    setUserAnswers(Array(10).fill(''));
  };

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...userAnswers];
    newAnswers[index] = value;
    setUserAnswers(newAnswers);
  };

  const handleSubmitAll = () => {
    let score = 0;
    const answers = equations.map((eq, index) => {
      const userAnswer = parseInt(userAnswers[index], 10);
      const isCorrect = userAnswer === eq.result;
      if (isCorrect) {
        score += 1;
      }
      return { ...eq, userAnswer, isCorrect };
    });

    setGameScore(score);
    setUserAnswers(answers);
    setShowGameOver(true);
    recordGameScore(score);
  };

  const recordGameScore = async (finalScore) => {
    const percentage = (finalScore / 10) * 100;
    const roundedPercentage = Math.round(percentage);
  
    let scoreRating = '';
    let scoreComment = '';
  
    if (roundedPercentage >= 90) {
      scoreRating = 'Outstanding';
      scoreComment = 'Congratulations! You performed outstandingly.';
    } else if (roundedPercentage >= 75) {
      scoreRating = 'Passed';
      scoreComment = 'Well done! You passed the counting game.';
    } else if (roundedPercentage >= 50) {
      scoreRating = 'Almost';
      scoreComment = 'You are close to passing. Keep practicing!';
    } else {
      scoreRating = 'Failed';
      scoreComment = 'Keep practicing. You can do better!';
    }
  
    setScoreRating(scoreRating);
    setScoreComment(scoreComment);
  
    const data = {
      userId: userDetails?._id,
      score: finalScore,
      timeOfDay: new Date().getHours(),
      gameName: gameName,
    };
  
    try {
      // Step 1: Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      // Step 2: Send a POST request with CSRF token
      const response = await fetch('https://lernacy.co.za/api/record-score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        body: JSON.stringify(data),
        credentials: 'include', // Include credentials
      });
  
      if (response.ok) {
        const responseData = await response.json();
        //console.log(responseData);
      } else {
        throw new Error('Error recording game score: ' + response.statusText);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const restartGame = async () => {
    try {
      setEquations([]);
      setUserAnswers([]);
      setGameScore(0);
      setShowGameOver(false);

      const url = `https://lernacy.co.za/api/user-score/${gameName}`;
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        throw new Error('Error fetching high score: ' + response.statusText);
      }

      generateEquations();
    } catch (error) {
      console.error('Error restarting game:', error.message);
    }
  };

  return (
    <div className='tested-activity-wall-container'>
      {isAuthenticated === null ? (
        <p>Loading authentication status...</p>
      ) : !isAuthenticated ? (
        <AccessDenied />
      ) : (
        <div>
          {!showGameOver ? (
            <div>
              <h1 className='activity-name-tag-h1'>GR - AddSub Activity</h1>
              <div className='round-high-score'>
                <h2 className='activity-rounds-tag-h2'>10 Questions</h2>
                <p>
                  <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/10</strong>
                </p>
              </div>
              <div className='equations-aligner-block-cover'>
                {equations.map((eq, index) => (
                  <div key={index} className='equations-block-box'>
                    <p><strong>Question {index + 1}</strong></p>
                    <p >Solve the equation:<br/> <strong className='equation-p-tag'>{eq.equation} =</strong></p>
                    <label>Enter Your Answer:</label><br />
                    <input
                      type="text"
                      value={userAnswers[index]}
                      onChange={(e) => handleAnswerChange(index, e.target.value)}
                      className='equations-block-box-input'
                    /><br />
                  </div>
                ))}
              </div>
              <br/>
              <button className='activity-submit-button' onClick={handleSubmitAll}>Submit All Answers</button>
            </div>
          ) : (
            <div>
              <h1 className='activity-name-tag-h1'>Activity Over<br/>Your Score: {gameScore}/10</h1>

              <div className='activity-game-over-data'>
                <h2 className='activity-rounds-tag-h2-left'>Score Rating: {scoreRating}</h2>
                <h3>{scoreComment}</h3>
                <p>Percentage: <strong>{(gameScore / 10) * 100}%</strong></p>
              </div>

              <div>
                <h3 className='activity-rounds-tag-h2'>Question Records:</h3>
                <div className='activity-over-rounds-evidence-ul'> 
                  {userAnswers.map((answer, index) => (
                    <div key={index} className='activity-over-rounds-evidence'>
                      <p className='activity-over-rounds-evidence-p-container'>
                        <strong>Question {index + 1}</strong> <br />
                        Equation: {answer.equation} <br />
                        Your Answer: {answer.userAnswer}  
                        {answer.isCorrect ? <span style={{ color: 'green' }}>✔</span> : <span style={{ color: 'red' }}>✘</span>} <br />
                      </p>
                    </div>
                  ))}
                </div>  
              </div>

              <hr />
              <p>
                <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/10</strong>
              </p>

              <div className='activity-restart-or-more'>
                <button className='activity-restart-button' onClick={restartGame}>Play Again</button>
                <button className='more-activity-button' onClick={() => window.location.href = "/Grade:R_Mathematics"}>Do More Activities</button>
              </div>
              <br/>
              
            </div>
          )}
          <br />
        </div>
      )}
    </div>
  );
};

export default GrrAddSubGame;

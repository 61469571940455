const socialSciencesPathfinderosInstructions = [

    {
        id: 1,
        question: 'Coming out of the A letter circle, turn left, move forward, take the first right, turn right, move forward, turn left, and finally, turn left into the letter circle of:',
        options: ['J', 'F', 'G', 'B'],
        correctAnswer: 'F',
        imageUrl: '/activityImages/SocialSciences/Pathfinderos.png' 
    },
    {
        id: 2,
        question: 'Coming out of the A letter circle, turn left, move forward, take the first right, turn right, move forward, take the second left, move forward, cross two intersections, and then turn left into the letter circle of:.',
        options: ['J', 'O', 'H', 'L'],
        correctAnswer: 'L',
        imageUrl: '/activityImages/SocialSciences/Pathfinderos.png' 
    },
    {
        id: 3,
        question: 'Coming out of the A letter circle, turn left, move forward, cross one intersection and then turn left into the letter circle of:',
        options: ['B', 'D', 'F', 'G'],
        correctAnswer: 'B',
        imageUrl: '/activityImages/SocialSciences/Pathfinderos.png' 
    },
    {
        id: 4,
        question: 'Coming out of the B letter circle, turn left, move forward, take the first right, move forward, cross one intersection, turn right, move forward, and then turn left into the letter circle of:',
        options: ['O', 'I', 'N', 'G'],
        correctAnswer: 'N',
        imageUrl: '/activityImages/SocialSciences/Pathfinderos.png' 
    },
    {
        id: 5,
        question: 'Coming out of the C letter circle, turn left, move forward, and then turn right into the letter circle of:',
        options: ['O', 'I', 'N', 'G'],
        correctAnswer: 'N',
        imageUrl: '/activityImages/SocialSciences/Pathfinderos.png' 
    },
    {
        id: 6,
        question: 'Coming out of the D letter circle, turn right, move forward, take the first left, move forward, cross one intersection, turn left, move forward, and then turn left into the letter circle of:',
        options: ['L', 'C', 'J', 'K'],
        correctAnswer: 'L',
        imageUrl: '/activityImages/SocialSciences/Pathfinderos.png' 
    },
    {
        id: 7,
        question: 'Coming out of the F letter circle, turn right, move forward, take the first left, move forward, cross one intersection, turn right into the letter circle of:',
        options: ['I', 'M', 'N', 'A'],
        correctAnswer: 'M',
        imageUrl: '/activityImages/SocialSciences/Pathfinderos.png' 
    },
    {
        id: 8,
        question: 'Coming out of the F letter circle, turn right, move forward, take the first left, move forward, cross one intersection, turn right into the letter circle of:',
        options: ['I', 'M', 'N', 'A'],
        correctAnswer: 'M',
        imageUrl: '/activityImages/SocialSciences/Pathfinderos.png' 
    },
    {
        id: 9,
        "question": "Coming out of the A letter circle, turn left, move forward, cross two intersections, then turn first left into the letter circle of",
        "options": ["C", "B", "F", "G"],
        "correctAnswer": "C",
        imageUrl: '/activityImages/SocialSciences/Pathfinderos.png' 
    },
    {
        id: 9,
        "question": "Coming out of the A letter circle, turn left, move forward, cross two intersections, then turn first left into the letter circle of:",
        "options": ["C", "D", "J", "G"],
        "correctAnswer": "C",
        imageUrl: '/activityImages/SocialSciences/Pathfinderos.png' 
    },
    {
        id: 10,
        "question": "Coming out of the D letter circle, turn right, move forward, take the first left, move forward, turn left, take the first left into the letter circle of:.        ",
        "options": ["C", "D", "C", "G"],
        "correctAnswer": "G",
        imageUrl: '/activityImages/SocialSciences/Pathfinderos.png' 
    },
    {
        "id": 11,
        "question": "Coming out of the H letter circle, turn right, move forward, cross 2 intersections, turn left on the third intersection, turn right, move forward, take the first left, then turn right into the letter circle of:",
        "options": ["K", "A", "F", "G"],
        "correctAnswer": "A",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    }, 
    {
        "id": 12,
        "question": "Coming out of the P letter circle, turn right, move forward, take the first right, move forward, take the first left into the letter circle of:",
        "options": ["C", "L", "J", "P"],
        "correctAnswer": "L",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 13,
        "question": "Coming out of the N letter circle, move forward into the letter circle of:",
        "options": ["B", "A", "J", "G"],
        "correctAnswer": "J",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 14,
        "question": "Coming out of the N letter circle, turn right, move forward, take the first left, cross one intersection, take the first left, then turn right into the letter circle of:",
        "options": ["C", "D", "B", "G"],
        "correctAnswer": "B",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 15,
        "question": "Coming out of the L letter circle, turn right, move forward, cross two intersections, then take the first left into the letter circle of:",
        "options": ["B", "J", "N", "G"],
        "correctAnswer": "N",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 16,
        "question": "Coming out of the F letter circle, turn left, move forward, cross one intersection, then take the first left into the letter circle of:",
        "options": ["O", "J", "A", "G"],
        "correctAnswer": "G",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 17,
        "question": "Coming out of the K letter circle, turn right, move forward, take the first right, move forward, turn left, then take the first right into the letter circle of:",
        "options": ["A", "N", "F", "P"],
        "correctAnswer": "F",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 18,
        "question": "Coming out of the O letter circle, turn left, move forward, turn right, move forward, turn right, then take the first left into the letter circle of:",
        "options": ["C", "G", "F", "G"],
        "correctAnswer": "G",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 19,
        "question": "Coming out of the B letter circle, turn left, move forward, take the first right, move forward, turn right, move forward to intersection, turn right, move forward, turn right, then take the first left into the letter circle of:",
        "options": ["B", "J", "F", "O"],
        "correctAnswer": "B",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 20,
        "question": "Coming out of the C letter circle, turn left, move forward, take the first right, move forward, turn right, move forward to intersection, turn right, move forward, turn right, then take the first left into the letter circle of:",
        "options": ["A", "C", "F", "G"],
        "correctAnswer": "C",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 21,
        "question": "Coming out of the F letter circle, turn left, move forward, take the first right, move forward, turn right, move forward to intersection, turn right, move forward, turn right, then take the first left into the letter circle of:",
        "options": ["B", "F", "C", "G"],
        "correctAnswer": "F",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 22,
        "question": "Coming out of the G letter circle, turn left, move forward, take the first right, move forward, turn right, move forward to intersection, turn right, move forward, turn right, then take the first left into the letter circle of:",
        "options": ["B", "C", "G", "A"],
        "correctAnswer": "G",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 23,
        "question": "Coming out of the J letter circle, turn left, move forward, take the first right, move forward, turn right, move forward to intersection, turn right, move forward, turn right, then take the first left into the letter circle of:",
        "options": ["D", "C", "F", "J"],
        "correctAnswer": "J",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 24,
        "question": "Coming out of the K letter circle, turn left, move forward, take the first right, move forward, turn right, move forward to intersection, turn right, move forward, turn right, then take the first left into the letter circle of:",
        "options": ["K", "I", "N", "O"],
        "correctAnswer": "K",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 25,
        "question": "Coming out of the A letter circle, turn left, move forward, then take the second left into the letter circle of:",
        "options": ["A", "C", "B", "D"],
        "correctAnswer": "B",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 26,
        "question": "Coming out of the B letter circle, turn right, move forward, then take the second right into the letter circle of:",
        "options": ["B", "A", "C", "J"],
        "correctAnswer": "A",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 27,
        "question": "Coming out of the B letter circle, turn left, move forward, then take the second left into the letter circle of:",
        "options": ["I", "K", "C", "J"],
        "correctAnswer": "C",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 28,
        "question": "Coming out of the C letter circle, turn right, move forward, then take the second right into the letter circle of:",
        "options": ["K", "N", "L", "B"],
        "correctAnswer": "B",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 29,
        "question": "Coming out of the C letter circle, turn left, move forward, then take the second left into the letter circle of:",
        "options": ["B", "J", "O", "D"],
        "correctAnswer": "D",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 30,
        "question": "Coming out of the D letter circle, turn right, move forward, then take the second right into the letter circle of:",
        "options": ["A", "B", "C", "D"],
        "correctAnswer": "C",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
    },
    {
        "id": 31,
        "question": "Coming out of the E letter circle, turn left, move forward, then take the second left into the letter circle of:",
        "options": ["E", "F", "G", "H"],
        "correctAnswer": "F",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 32,
        "question": "Coming out of the F letter circle, turn right, move forward, then take the second right into the letter circle of:",
        "options": ["E", "F", "G", "H"],
        "correctAnswer": "E",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 33,
        "question": "Coming out of the F letter circle, turn left, move forward, then take the second left into the letter circle of:",
        "options": ["A", "F", "G", "B"],
        "correctAnswer": "G",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 34,
        "question": "Coming out of the G letter circle, turn right, move forward, then take the second right into the letter circle of:",
        "options": ["C", "F", "G", "P"],
        "correctAnswer": "F",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 35,
        "question": "Coming out of the G letter circle, turn left, move forward, then take the second left into the letter circle of:",
        "options": ["F", "E", "H", "G"],
        "correctAnswer": "H",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 36,
        "question": "Coming out of the H letter circle, turn right, move forward, then take the second right into the letter circle of:",
        "options": ["J", "K", "G", "A"],
        "correctAnswer": "G",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 37,
        "question": "Coming out of the I letter circle, turn left, move forward, then take the second left into the letter circle of:",
        "options": ["I", "J", "K", "L"],
        "correctAnswer": "J",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 38,
        "question": "Coming out of the J letter circle, turn right, move forward, then take the second right into the letter circle of:",
        "options": ["O", "B", "K", "I"],
        "correctAnswer": "I",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 39,
        "question": "Coming out of the J letter circle, turn left, move forward, then take the second left into the letter circle of:",
        "options": ["P", "O", "K", "L"],
        "correctAnswer": "K",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 40,
        "question": "Coming out of the K letter circle, turn right, move forward, then take the second right into the letter circle of:",
        "options": ["L", "M", "J", "O"],
        "correctAnswer": "J",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 41,
        "question": "Coming out of the K letter circle, turn left, move forward, then take the second left into the letter circle of:",
        "options": ["H", "J", "O", "L"],
        "correctAnswer": "L",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 42,
        "question": "Coming out of the L letter circle, turn right, move forward, then take the second right into the letter circle of:",
        "options": ["I", "P", "B", "K"],
        "correctAnswer": "K",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 43,
        "question": "Coming out of the D letter circle, turn right, move forward, then take the first left, move forward, then take the second left into the letter circle of:",
        "options": ["F", "G", "H", "L"],
        "correctAnswer": "H",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 44,
        "question": "Coming out of the C letter circle, turn right, move forward, then take the first left, move forward, then take the second left into the letter circle of:",
        "options": ["C", "D", "F", "G"],
        "correctAnswer": "G",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 45,
        "question": "Coming out of the C letter circle, turn left, move forward, then take the first right, move forward, then take the first right into the letter circle of:",
        "options": ["C", "D", "F", "G"],
        "correctAnswer": "G",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 46,
        "question": "Coming out of the B letter circle, turn right, move forward, then take the first left, move forward, then take the second left into the letter circle of:",
        "options": ["F", "G", "H", "L"],
        "correctAnswer": "F",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 47,
        "question": "Coming out of the B letter circle, turn left, move forward, then take the first right, move forward, then take the first right into the letter circle of:",
        "options": ["L", "F", "D", "L"],
        "correctAnswer": "F",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 48,
        "question": "Coming out of the H letter circle, turn right, move forward, then take the first left, move forward, then take the second left into the letter circle of:",
        "options": ["F", "G", "H", "L"],
        "correctAnswer": "L",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 49,
        "question": "Coming out of the H letter circle, turn left, move forward, then take the first right, move forward, then take the first right into the letter circle of:",
        "options": ["A", "L", "M", "P"],
        "correctAnswer": "L",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 50,
        "question": "Coming out of the G letter circle, turn right, move forward, then take the first left, move forward, then take the second left into the letter circle of:",
        "options": ["E", "H", "K", "N"],
        "correctAnswer": "K",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 51,
        "question": "Coming out of the G letter circle, turn left, move forward, then take the first right, move forward, then take the first right into the letter circle of:",
        "options": ["K", "O", "P", "N"],
        "correctAnswer": "K",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 52,
        "question": "Coming out of the F letter circle, turn right, move forward, then take the first left, move forward, then take the second left into the letter circle of:",
        "options": ["B", "D", "J", "M"],
        "correctAnswer": "J",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 53,
        "question": "Coming out of the F letter circle, turn left, move forward, then take the first right, move forward, then take the first right into the letter circle of:",
        "options": ["J", "D", "B", "N"],
        "correctAnswer": "J",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 54,
        "question": "Coming out of the A letter circle, turn left, move forward, cross 2 intersections, then turn left into the letter circle of:",
        "options": ["B", "C", "E", "G"],
        "correctAnswer": "C",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 55,
        "question": "Coming out of the C letter circle, turn right, move forward, cross 2 intersections, then turn right into the letter circle of:",
        "options": ["A", "B", "E", "G"],
        "correctAnswer": "A",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 56,
        "question": "Coming out of the B letter circle, turn left, move forward, cross 2 intersections, then turn left into the letter circle of:",
        "options": ["D", "G", "A", "A"],
        "correctAnswer": "D",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 57,
        "question": "Coming out of the D letter circle, turn right, move forward, cross 2 intersections, then turn right into the letter circle of:",
        "options": ["A", "C", "B", "G"],
        "correctAnswer": "B",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 58,
        "question": "Coming out of the E letter circle, turn left, move forward, cross 2 intersections, then turn left into the letter circle of:",
        "options": ["B", "C", "D", "G"],
        "correctAnswer": "G",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 59,
        "question": "Coming out of the G letter circle, turn right, move forward, cross 2 intersections, then turn right into the letter circle of:",
        "options": ["D", "C", "E", "J"],
        "correctAnswer": "E",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 60,
        "question": "Coming out of the F letter circle, turn left, move forward, cross 2 intersections, then turn left into the letter circle of:",
        "options": ["F", "G", "H", "I"],
        "correctAnswer": "H",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 61,
        "question": "Coming out of the H letter circle, turn right, move forward, cross 2 intersections, then turn right into the letter circle of:",
        "options": ["J", "G", "F", "H"],
        "correctAnswer": "F",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 62,
        "question": "Coming out of the I letter circle, turn left, move forward, cross 2 intersections, then turn left into the letter circle of:",
        "options": ["I", "J", "K", "L"],
        "correctAnswer": "K",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 63,
        "question": "Coming out of the K letter circle, turn right, move forward, cross 2 intersections, then turn right into the letter circle of:",
        "options": ["K", "J", "I", "O"],
        "correctAnswer": "I",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 64,
        "question": "Coming out of the J letter circle, turn left, move forward, cross 2 intersections, then turn left into the letter circle of:",
        "options": ["O", "J", "P", "L"],
        "correctAnswer": "L",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 65,
        "question": "Coming out of the L letter circle, turn right, move forward, cross 2 intersections, then turn right into the letter circle of:",
        "options": ["H", "O", "K", "J"],
        "correctAnswer": "J",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 66,
        "question": "Coming out of the D letter circle, turn right, move forward, turn left, cross 1 intersection, then turn left, and finally, turn left into the letter circle of:",
        "options": ["L", "J", "D", "K"],
        "correctAnswer": "L",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 67,
        "question": "Coming out of the L letter circle, turn right, move forward, turn right, cross 1 intersection, then turn right, and finally, turn left into the letter circle of:",
        "options": ["D", "L", "J", "K"],
        "correctAnswer": "D",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 68,
        "question": "Coming out of the C letter circle, turn right, move forward, turn left, cross 1 intersection, then turn left, and finally, turn left into the letter circle of:",
        "options": ["C", "D", "K", "L"],
        "correctAnswer": "K",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 69,
        "question": "Coming out of the K letter circle, turn right, move forward, turn right, cross 1 intersection, then turn right, and finally, turn left into the letter circle of:",
        "options": ["K", "D", "C", "L"],
        "correctAnswer": "C",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 70,
        "question": "Coming out of the B letter circle, turn right, move forward, turn left, cross 1 intersection, then turn left, and finally, turn left into the letter circle of:",
        "options": ["J", "B", "C", "D"],
        "correctAnswer": "J",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      },
      {
        "id": 71,
        "question": "Coming out of the J letter circle, turn right, move forward, turn right, cross 1 intersection, then turn right, and finally, turn left into the letter circle of:",
        "options": ["B", "A", "D", "C"],
        "correctAnswer": "B",
        "imageUrl": "/activityImages/SocialSciences/Pathfinderos.png"
      }
      
];
  
export default socialSciencesPathfinderosInstructions;

  
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const AdPosts = ({ refreshAd }) => {
  const [ads, setAds] = useState([]); // Store fetched ad posts
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [sequence, setSequence] = useState([]); // Store final ad sequence
  const [currentAd, setCurrentAd] = useState(null); // Store current ad to display
  const location = useLocation(); // Get the current location

  // Fetch ad posts when the component mounts
  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/ads', {
          withCredentials: true,
        });

        if (response.data.success) {
          // Filter ads based on the location
          const filteredAds = response.data.ads.filter((ad) => {
            const notShowLocations = JSON.parse(ad.notShowIn[0] || '[]');
            return !notShowLocations.includes(location.pathname);
          });

          // Sort ads by priority (higher numbers first)
          const sortedAds = filteredAds.sort((a, b) => b.priority - a.priority);

          // Set the ads
          setAds(sortedAds); 

          // Generate the solid sequence once based on frequency and priority
          const generatedSequence = generateAdSequence(sortedAds);
          setSequence(generatedSequence); // Set the fixed sequence

          // Set a weighted random ad from the generated sequence to display
          if (generatedSequence.length > 0) {
            setCurrentAd(selectWeightedRandomAd(generatedSequence));
          }
        } else {
          setError('No ads found.');
        }
      } catch (err) {
        setError('Error fetching ads: ' + err.message); // Handle any errors
      } finally {
        setLoading(false); // Set loading to false once request is complete
      }
    };

    fetchAds();
  }, [location.pathname]);

  // Function to generate a solid, fixed sequence of ads based on frequency and priority
  const generateAdSequence = (ads) => {
    let generatedSequence = [];
    const maxAds = Math.min(15, ads.length); // Limit to a maximum of 15 ads

    // Generate the solid sequence of ads based on their frequency
    for (let i = 0; i < maxAds; i++) {
      generatedSequence.push(ads[i]); // Push the whole ad object to the sequence
    }

    return generatedSequence; // Return the fixed sequence
  };

  // Function to select a weighted random ad based on priority
  const selectWeightedRandomAd = (ads) => {
    // Create a cumulative weight array
    const totalWeight = ads.reduce((sum, ad) => sum + ad.priority, 0);
    const randomValue = Math.random() * totalWeight;

    let cumulativeWeight = 0;
    for (let ad of ads) {
      cumulativeWeight += ad.priority;
      if (randomValue <= cumulativeWeight) {
        return ad; // Return the selected ad
      }
    }
    return ads[ads.length - 1]; // Fallback in case of rounding errors
  };

  // If refreshAd is triggered, select a new random ad
  useEffect(() => {
    if (sequence.length > 0) {
      setCurrentAd(selectWeightedRandomAd(sequence));
    }
  }, [refreshAd, sequence]);

  // Render loading, error, or the current ad
  return (
    <div>
      {loading && <p>Loading ads...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {!loading && !error && currentAd && (
        <div className="ad-container">
          {(currentAd.mediaType.startsWith('image/') || currentAd.mediaType === 'image/gif') && (
            <a href={currentAd.adLink} target="_blank" rel="noopener noreferrer">
              <img
                loading="lazy"
                className='ad-media-file'
                src={currentAd.mediaUrl}
                alt={currentAd.title}
                style={{ maxWidth: '100%', height: 'auto' }} // Adjust styles as needed
              />
            </a>
          )}
          <div style={{display:'none'}}>
            <p className='ad-learn-button'>
              <a href={currentAd.adLink} target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </p> 
          </div>
        </div>
      )}
    </div>
  );
};

export default AdPosts;






/*
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const AdPosts = () => {
  const [ads, setAds] = useState([]); // Store fetched ad posts
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [sequence, setSequence] = useState([]); // Store final ad sequence
  const location = useLocation(); // Get the current location

  // Fetch ad posts when the component mounts
  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/ads', {
          withCredentials: true,
        });

        if (response.data.success) {
          // Sort ads by priority (higher numbers mean higher priority)
          const sortedAds = response.data.ads.sort((a, b) => b.priority - a.priority);
          const filteredAds = sortedAds.filter((ad) => {
            const notShowLocations = JSON.parse(ad.notShowIn[0] || '[]');
            return !notShowLocations.includes(location.pathname);
          });

          setAds(filteredAds); // Set the filtered and sorted ads

          // Generate the solid sequence once based on priority
          const generatedSequence = generateAdSequence(filteredAds);
          setSequence(generatedSequence); // Set the fixed sequence
        } else {
          setError('No ads found.');
        }
      } catch (err) {
        setError('Error fetching ads: ' + err.message); // Handle any errors
      } finally {
        setLoading(false); // Set loading to false once request is complete
      }
    };

    fetchAds();
  }, [location.pathname]);

  // Function to generate a solid, fixed sequence of ads based on priority
  const generateAdSequence = (ads) => {
    let generatedSequence = [];
    const maxAds = 15; // Limit to a maximum of 15 ads

    // Calculate the total priority sum
    const totalPriority = ads.reduce((sum, ad) => sum + ad.priority, 0);

    // Generate the solid sequence of ads based on their priority
    for (let i = 0; i < maxAds; i++) {
      const randomValue = Math.random() * totalPriority; // Random value for weighted choice
      let cumulativePriority = 0;

      for (let ad of ads) {
        cumulativePriority += ad.priority;
        if (randomValue < cumulativePriority) {
          generatedSequence.push(ad.title); // Add ad title to the sequence
          break;
        }
      }
    }

    return generatedSequence; // Return the fixed sequence
  };

  // Render loading, error, or the fixed ad sequence
  return (
    <div>
      <h2>Ad Posts</h2>

      {loading && <p>Loading ads...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {!loading && !error && sequence.length > 0 && (
        <div>
          <h3>Fixed Ad Sequence</h3>
          <ol>
            {sequence.map((adTitle, index) => (
              <li key={index}>{adTitle}</li> // Display solid sequence with ad titles
            ))}
          </ol>
        </div>
      )}

      {!loading && !error && ads.length === 0 && <p>No ads to display.</p>}
    </div>
  );
};

export default AdPosts;
*/
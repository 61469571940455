import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './bodyFiles-styling/extra-style.css';

const ApproveEnrollment = () => {
  const [users, setUsers] = useState([]); // Ensure users is initialized as an array
  const [pendingEnrollments, setPendingEnrollments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [blockRequests, setBlockRequests] = useState(false);
  const [initialRequestMade, setInitialRequestMade] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all users
        const userResponse = await axios.get('https://lernacy.co.za/api/check/api/check/users', {
          withCredentials: true
        });
        // console.log('User response:', userResponse.data); // Log the response to inspect its structure

        // Assuming the response has a 'users' key holding an array of users
        setUsers(Array.isArray(userResponse.data.user) ? userResponse.data.user : []); // Safeguard to ensure it's an array
        setInitialRequestMade(true);

        // Fetch pending enrollments
        const pendingResponse = await axios.get('https://lernacy.co.za/api/pending-enrollment', {
          withCredentials: true
        });
        // console.log('Pending enrollments response:', pendingResponse.data); // Log to inspect structure
        setPendingEnrollments(pendingResponse.data.pendingEnrollments || []);

        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setError('Access forbidden');
          setBlockRequests(true); // Block all requests on 403 response
        } else {
          setError('Error fetching data');
        }
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to get the user details based on userId from the users array
  const getUserDetails = (userId) => {
    const user = users.find((user) => user._id === userId);
    return user ? `${user.name} ${user.surname} (${user.accountName}) - ${user.email}` : 'User not found';
  };

  // Function to approve the enrollment and submit the data
  const handleApproveEnrollment = async (record) => {
    try {
        // Step 1: Fetch the CSRF token from the server
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
            method: 'GET',
            credentials: 'include',
        });

        if (!csrfResponse.ok) {
            console.error('Error fetching CSRF token:', csrfResponse.statusText);
            return; // Exit if unable to get the CSRF token
        }

        const csrfData = await csrfResponse.json();
        const csrfToken = csrfData.csrfToken; // Assuming the token is sent in this field

        // Step 2: Prepare the data for the POST request
        const dataToSend = {
            adminOrTeacherId: record.adminOrTeacherId, // Ensure to include the ID of the approving user
            enrolledStudents: record.enrolledStudents,
            _csrf: csrfToken // Include the CSRF token in the data
        };

        // Step 3: Make the POST request with the CSRF token
        const response = await axios.post('https://lernacy.co.za/api/approved-enrollment-record', dataToSend, {
            withCredentials: true
        });

        if (response.data.success) {
            // Optionally, refresh the list of pending enrollments after approval
            setPendingEnrollments((prev) => prev.filter((item) => item._id !== record._id));
            alert('Enrollment approved successfully!');
        }
    } catch (error) {
        console.error('Error approving enrollment:', error);
        alert('Error approving enrollment');
    }
  };


  // Block all requests if initial request has not been made
  if (!initialRequestMade) {
    return <p>Loading...</p>;
  }

  return (
    <div className='activity-page-main-border'>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          <div>
            <h1 className='activity-name-tag-h1'>Approve Enrollment</h1>

            <div className='filing-system-page-container'>
              <div className='filing-system-page-container-two'>
                <div className="filing-system-page">
                  {pendingEnrollments.length > 0 ? (
                    <ul className='enrollment-record-to-be-processed'>
                      {pendingEnrollments.map((record) => (
                        <li key={record._id} className='enrollment-record'>
                          <p><strong>Admin/Teacher:</strong> {record.adminOrTeacher.name} {record.adminOrTeacher.surname}</p>
                          <p><strong>Account Name:</strong> {record.adminOrTeacher.accountName}</p>
                          <p><strong>Email:</strong> {record.adminOrTeacher.email}</p>
                          <p><strong>Enrolled Students:</strong> ({record.enrolledStudents.length} total)</p>
                          {record.enrolledStudents.map((student, index) => (
                            <span key={index}>
                              {student}
                              <br />
                            </span>
                          ))}
                          <p><strong>Enrollment Date:</strong> {new Date(record.enrollmentDate).toLocaleDateString()}</p>
                          {/* Fetch and display user details using the userId */}
                          <p><strong>User Details:</strong> {getUserDetails(record.adminOrTeacherId)}</p>
                          {/* Button to approve the enrollment */}
                          <button onClick={() => handleApproveEnrollment(record)}>Approve Enrollment</button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No pending enrollments found.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApproveEnrollment;

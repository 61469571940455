import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './bodyFiles-styling/extra-style.css';

const AdminUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [blockRequests, setBlockRequests] = useState(false);
  const [initialRequestMade, setInitialRequestMade] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Fetch all users
        const response = await axios.get('https://lernacy.co.za/api/check/api/check/users', {
          withCredentials: true
        });
        setUsers(response.data.user);
        setInitialRequestMade(true);

        // Fetch filtered users if initial request succeeds
        const filteredResponse = await axios.get('https://lernacy.co.za/api/check/api/check/users/filtered-users', {
          withCredentials: true
        });
        setFilteredUsers(filteredResponse.data.users);

        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setError('Access forbidden');
          setBlockRequests(true); // Block all requests on 403 response
        } else {
          setError('Error fetching users');
        }
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Block all requests if initial request has not been made
  if (!initialRequestMade) {
    return <p>Loading...</p>;
  }

  // Handle search input changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter users based on search query
  const filteredResults = filteredUsers.filter((user) => {
    return (
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.surname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.decoyId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (user.adminValidation ? 'yes' : 'no').includes(searchQuery.toLowerCase()) ||
      user.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.accountName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleStatusChange = async (userId, newStatus) => {
    try {
      // Validate input
      if (!userId || typeof userId !== 'string' || userId.trim() === '') {
        throw new Error('Invalid userId');
      }
      if (!newStatus || typeof newStatus !== 'string' || newStatus.trim() === '') {
        throw new Error('Invalid newStatus');
      }
  
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
      const { csrfToken } = await csrfResponse.json();
  
      // Make PUT request with CSRF token in header
      const requestData = { userId: userId.trim(), newStatus: newStatus.trim() };
      const response = await fetch('https://lernacy.co.za/api/check/api/check/users/update-status', {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,
        },
        body: JSON.stringify(requestData),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to update status');
      }
  
      // Update local state after successful status update
      setFilteredUsers(filteredUsers.map(user => (
        user._id === userId ? { ...user, status: newStatus } : user
      )));
      alert('Status updated successfully');
    } catch (error) {
      console.error('Error updating status:', error);
      alert(`Failed to update status: ${error.message}`);
    }
  };

  return (
    <div className='tested-activity-wall-container'>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          <h1 className='activity-name-tag-h1'>All Users</h1>
          <input
            type="text"
            placeholder="Search by name, surname, decoy ID, admin validation, status or account name"
            value={searchQuery}
            onChange={handleSearchChange}
            className='search-field-for-outside'
          />
          {filteredResults.map((user) => (
            <div key={user._id} className='admin-user-list'>
              <p className='name-and-surname'>{user.name} {user.surname}</p>
              <p>Decoy ID: {user.decoyId}</p>
              <p>Admin Validation: {user.adminValidation ? 'Yes' : 'No'}</p>
              <p>Account Name: {user.accountName}</p>
              <div className='status-change-button'>
                <p>Status: </p>
                <select
                  value={user.status}
                  onChange={(e) => handleStatusChange(user._id, e.target.value)}
                >
                  <option value="Student">Student</option>
                  <option value="Parent">Parent</option>
                  <option value="Admin">Admin</option>
                  <option value="Teacher">Teacher</option>
                </select>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminUsersPage;

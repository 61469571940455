const socialSciencesWaterUsage = [

    {
        "id": 1,
        "question": "What is essential for life on Earth?",
        "options": ["Money", "Toys", "Water", "Chairs"],
        "correctAnswer": "Water"
    },
    {
        "id": 2,
        "question": "What is the primary source of water on Earth?",
        "options": ["Oceans", "Lakes", "Rain", "Rivers"],
        "correctAnswer": "Rain"
    },
    {
        "id": 3,
        "question": "How do people obtain water for various uses?",
        "options": ["By digging wells", "By purchasing from stores", "By collecting and storing rainwater", "By using desalination plants"],
        "correctAnswer": "By collecting and storing rainwater"
    },
    {
        "id": 4,
        "question": "What role does rainwater play in human life?",
        "options": ["It's a source of drinking water", "It helps in agriculture", "It provides water for sanitation", "All of the above"],
        "correctAnswer": "All of the above"
    },
    {
        "id": 5,
        "question": "How can we make sure that we have water even in dry seasons?",
        "options": ["Constructing dams", "Drilling boreholes", "Collecting and storing rainwater", "Using water from rivers"],
        "correctAnswer": "Collecting and storing rainwater"
    },
    {
        "id": 6,
        "question": "Why is clean water important for human health?",
        "options": ["To prevent dehydration", "To maintain hygiene", "To ensure proper digestion", "All of the above"],
        "correctAnswer": "All of the above"
    },
    {
        "id": 7,
        "question": "What risk is associated with dirty water?",
        "options": ["It causes soil erosion", "It attracts pests", "It spreads diseases", "It damages infrastructure"],
        "correctAnswer": "It spreads diseases"
    },
    {
        "id": 8,
        "question": "How can people save the quality of water?",
        "options": ["By using water filters", "By minimizing water usage", "By disposing of waste properly", "All of the above"],
        "correctAnswer": "All of the above"
    },
    {
        "id": 9,
        "question": "How should we treat water resources?",
        "options": ["By polluting them intentionally", "By protecting them", "By using them excessively", "By neglecting their importance"],
        "correctAnswer": "By protecting them"
    },
    {
        "id": 10,
        "question": "What is the consequence of wasting water?",
        "options": ["Increase in water quality", "Decrease in water availability", "Improvement in sanitation", "Reduction in diseases"],
        "correctAnswer": "Decrease in water availability"
    },
    {
        "id": 11,
        "question": "Why is it important to prevent water pollution?",
        "options": ["To save money on water bills", "To reduce the risk of waterborne diseases", "To increase the pH of water", "To encourage algal bloom"],
        "correctAnswer": "To reduce the risk of waterborne diseases"
    },
    {
        "id": 12,
        "question": "What can people do to save water?",
        "options": ["Leave taps running when not in use", "Water lawns daily during the hottest hours", "Fix leaks promptly", "Use water indiscriminately"],
        "correctAnswer": "Fix leaks promptly"
    },
    {
        "id": 13,
        "question": "Which of the following is NOT a form of water?",
        "options": ["Ice", "Steam", "Fire", "Liquid"],
        "correctAnswer": "Fire"
    },
    {
        "id": 14,
        "question": "What is the main source of drinking water for most people?",
        "options": ["Lakes", "Rivers", "Oceans", "Groundwater"],
        "correctAnswer": "Groundwater"
    },
    {
        "id": 15,
        "question": "Why is it important to conserve water?",
        "options": ["To save money", "To protect the environment", "To prevent droughts", "All of the above"],
        "correctAnswer": "All of the above"
    },
    {
        "id": 16,
        "question": "Which of the following activities mostly involves using water?",
        "options": ["Reading a book", "Playing video games", "Brushing teeth", "Listening to music"],
        "correctAnswer": "Brushing teeth"
    },
    {
        "id": 17,
        "question": "What is one way factories use water?",
        "options": ["For generating electricity", "For harvesting crops", "For cleaning and cooling machines", "For cooking meals"],
        "correctAnswer": "For cleaning and cooling machines"
    },
    {
        "id": 18,
        "question": "What is a common use of water in our homes?",
        "options": ["Driving cars", "Flying airplanes", "Washing dishes", "Mowing lawns"],
        "correctAnswer": "Washing dishes"
    },
    {
        "id": 19,
        "question": "Which of the following activities is likely to use the most water?",
        "options": ["Cooking meals", "Playing sports", "Showering", "Reading books"],
        "correctAnswer": "Showering"
    },
    {
        "id": 20,
        "question": "What role does water play in agriculture?",
        "options": ["Producing electricity", "Transporting goods", "Watering crops", "Building roads"],
        "correctAnswer": "Watering crops"
    },
    {
        "id": 21,
        "question": "What is the main purpose of watering gardens?",
        "options": ["To clean the plants", "To make the soil wet", "To make the plants grow", "To prevent weeds"],
        "correctAnswer": "To make the plants grow"
    },
    {
        "id": 22,
        "question": "What is one way water is used in recreational activities?",
        "options": ["For starting fires", "For building sandcastles", "For swimming pools", "For hiking trails"],
        "correctAnswer": "For swimming pools"
    },
    {
        "id": 23,
        "question": "What is the purpose of water in cleaning the house?",
        "options": ["To add fragrance", "To remove dirt", "To attract insects", "To provide hydration"],
        "correctAnswer": "To remove dirt"
    },
    {
        "id": 24,
        "question": "Which of the following is NOT a source of water pollution?",
        "options": ["Industrial waste", "Household cleaning products", "Rainwater harvesting", "Agricultural runoff"],
        "correctAnswer": "Rainwater harvesting"
    },
    {
        "id": 25,
        "question": "How does water contribute to electricity generation?",
        "options": ["By absorbing sunlight", "By turning turbines", "By conducting heat", "By attracting lightning"],
        "correctAnswer": "By turning turbines"
    },
    {
        "id": 26,
        "question": "What is one benefit of using water for farming?",
        "options": ["Reducing soil erosion", "Increasing air pollution", "Encouraging desertification", "Decreasing crop yields"],
        "correctAnswer": "Reducing soil erosion"
    },
    {
        "id": 27,
        "question": "What happens to water in the water cycle after it falls on the ground?",
        "options": ["It disappears", "It evaporates", "It turns into ice", "It forms clouds"],
        "correctAnswer": "It evaporates"
    },
    {
        "id": 28,
        "question": "What is the main difference between salt water and fresh water on Earth?",
        "options": ["Salt water is blue, fresh water is green", "Salt water contains salt, fresh water does not", "Fresh water is found in oceans, salt water is found in lakes", "Salt water freezes faster than fresh water"],
        "correctAnswer": "Salt water contains salt, fresh water does not"
    },
    {
        "id": 29,
        "question": "What is the name of the natural process that describes the movement of water from the sea to the land and back to the sea?",
        "options": ["Water circulation", "Ocean transfer", "Water revolution", "Water cycle"],
        "correctAnswer": "Water cycle"
    },
    {
        "id": 30,
        "question": "Which of the following is NOT a source of fresh water in nature?",
        "options": ["Rain", "Rivers", "Oceans", "Streams"],
        "correctAnswer": "Oceans"
    },
    {
        "id": 31,
        "question": "Why do people need to store water?",
        "options": ["To make it colder", "To prevent evaporation", "To have it available during droughts or emergencies", "To make it cleaner"],
        "correctAnswer": "To have it available during droughts or emergencies"
    },
    {
        "id": 32,
        "question": "Which of the following is NOT a method of storing water?",
        "options": ["Dams", "Water tanks", "Bucket hats", "Pots"],
        "correctAnswer": "Bucket hats"
    },
    {
        "id": 33,
        "question": "What is the primary reason for storing water in dams?",
        "options": ["To generate electricity", "To prevent flooding", "To supply drinking water", "To provide water for irrigation"],
        "correctAnswer": "To supply drinking water"
    },
    {
        "id": 34,
        "question": "What is the purpose of storing water in water tanks?",
        "options": ["To create artificial lakes", "To provide water for swimming pools", "To have water available during shortages", "To attract birds"],
        "correctAnswer": "To have water available during shortages"
    },
    {
        "id": 35,
        "question": "Which of the following is an example of underground storage of water?",
        "options": ["Aqueduct", "Reservoir", "Well", "Pond"],
        "correctAnswer": "Well"
    },
    {
        "id": 36,
        "question": "What is the role of wetlands in storing water?",
        "options": ["To prevent flooding", "To filter pollutants", "To provide habitat for wildlife", "All of the above"],
        "correctAnswer": "All of the above"
    },
    {
        "id": 37,
        "question": "How does water in lakes contribute to the ecosystem?",
        "options": ["By causing pollution", "By supporting aquatic life", "By increasing desertification", "By promoting wildfires"],
        "correctAnswer": "By supporting aquatic life"
    },
    {
        "id": 38,
        "question": "What is the main source of water for rivers and streams?",
        "options": ["Rainfall", "Melting glaciers", "Underground springs", "Desalination plants"],
        "correctAnswer": "Rainfall"
    },
    {
        "id": 39,
        "question": "Which of the following is NOT a method of collecting rainwater?",
        "options": ["Rain barrels", "Gutters", "Rooftops", "Solar panels"],
        "correctAnswer": "Solar panels"
    },
    {
        "id": 40,
        "question": "Why is it important to store water in pots during droughts?",
        "options": ["To prevent water from freezing", "To keep it warm", "To make it easier to transport", "To provide water for plants"],
        "correctAnswer": "To provide water for plants"
    },
    {
        "id": 41,
        "question": "What is the primary purpose of storing water in buckets?",
        "options": ["To make it dirty", "To make it cold", "To prevent it from spilling", "To make it available for later use"],
        "correctAnswer": "To make it available for later use"
    },
    {
        "id": 42,
        "question": "What is a common benefit of using water tanks for storage?",
        "options": ["It prevents soil erosion", "It allows for easy distribution", "It helps save water", "It increases water pollution"],
        "correctAnswer": "It helps save water"
    },    
    {
        "id": 43,
        "question": "What is the term for the process of water seeping into the ground?",
        "options": ["Evaporation", "Infiltration", "Transpiration", "Condensation"],
        "correctAnswer": "Infiltration"
    },
    {
        "id": 44,
        "question": "How does water benefit agricultural lands?",
        "options": ["By promoting erosion", "By helping plants grow", "By supporting aquatic life", "By increasing desertification"],
        "correctAnswer": "By helping plants grow"
    },    
    {
        "id": 45,
        "question": "What is the purpose of storing water in reservoirs?",
        "options": ["To prevent erosion", "To create recreational areas", "To regulate water flow", "To increase air pollution"],
        "correctAnswer": "To regulate water flow"
    },
    {
        "id": 46,
        "question": "Which of the following is NOT a reason for storing water?",
        "options": ["To have water available during emergencies", "To attract wildlife", "To maintain water supply during droughts", "To support agriculture"],
        "correctAnswer": "To attract wildlife"
    },
    {
        "id": 47,
        "question": "What is the main source of water for rivers?",
        "options": ["Rain", "Ice cream", "Sand", "Pebbles"],
        "correctAnswer": "Rain"
    },
    {
        "id": 48,
        "question": "What do we call the process when water turns into ice?",
        "options": ["Melting", "Freezing", "Evaporation", "Condensation"],
        "correctAnswer": "Freezing"
    },
    {
        "id": 49,
        "question": "What do we call the water that falls from the sky?",
        "options": ["Sunshine", "Moonlight", "Rain", "Wind"],
        "correctAnswer": "Rain"
    },
    {
        "id": 50,
        "question": "Which of the following is NOT a form of water?",
        "options": ["Ice", "Fire", "Steam", "Liquid"],
        "correctAnswer": "Fire"
    },
    {
        "id": 51,
        "question": "What is the purpose of watering plants?",
        "options": ["To make them sleepy", "To help them grow", "To make them cold", "To make them noisy"],
        "correctAnswer": "To help them grow"
    },
    {
        "id": 51,
        "question": "What happens to water when it gets really cold?",
        "options": ["It turns into ice", "It disappears", "It becomes hot", "It turns into steam"],
        "correctAnswer": "It turns into ice"
    },
    {
        "id": 53,
        "question": "What do we use water for when we brush our teeth?",
        "options": ["To make it colorful", "To make it warm", "To make bubbles", "To clean our teeth"],
        "correctAnswer": "To clean our teeth"
    },
    {
        "id": 54,
        "question": "What do we call the water that comes out of a tap in our house?",
        "options": ["Milk", "Juice", "Soda", "Tap water"],
        "correctAnswer": "Tap water"
    },
    {
        "id": 55,
        "question": "What do we call the big area where water is stored for people to swim in?",
        "options": ["Water bottle", "Water slide", "Swimming pool", "Water balloon"],
        "correctAnswer": "Swimming pool"
    },
    {
        "id": 56,
        "question": "Why do we need to drink water every day?",
        "options": ["To make us sleepy", "To help us run faster", "To keep us healthy", "To make us hungry"],
        "correctAnswer": "To keep us healthy"
    }

];
  
  export default socialSciencesWaterUsage;
  
import React, { useEffect } from 'react';

const GameScoreRecorder = ({ correctCount, userDetails, gameName }) => {
  useEffect(() => {
    const recordGameScore = async (correctCount) => {
      try {
        // Fetch CSRF token
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
          method: 'GET',
          credentials: 'include' // Include credentials for cookies
        });
        const { csrfToken } = await csrfResponse.json();

        // Construct payload with CSRF token
        const formData = {
          userId: userDetails._id,
          score: correctCount,
          timeOfDay: new Date().getHours(),
          gameName: gameName,
        };

        // Make POST request with CSRF token in headers
        const response = await fetch('https://lernacy.co.za/api/record-score', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken // Include CSRF token in headers
          },
          body: JSON.stringify(formData),
          credentials: 'include' // Include credentials for cookies
        });

        // Handle response
        if (response.ok) {
          const result = await response.json();
          //console.log('Score recorded successfully:', result);
        } else {
          throw new Error('Failed to record score');
        }
      } catch (error) {
        console.error('Error recording game score:', error.message);
      }
    };

    if (correctCount !== null) {
      recordGameScore(correctCount);
    }
  }, [correctCount, userDetails, gameName]);

  return null; // This component doesn't render anything
};

export default GameScoreRecorder;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../pages/pages-styling/study.css';
import axios from 'axios';

const Study = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // State variables for About form
  const [aboutText, setAboutText] = useState('');
  const [loadingAbout, setLoadingAbout] = useState(false);
  const [aboutMessage, setAboutMessage] = useState('');
  const [isAboutTextLoaded, setIsAboutTextLoaded] = useState(false);

  // State variables for Achievements form
  const [achievements, setAchievements] = useState(['']);
  const [loadingAchievements, setLoadingAchievements] = useState(false);
  const [achievementsMessage, setAchievementsMessage] = useState('');

  const [selectedFile, setSelectedFile] = useState(null);
  const [captions, setCaptions] = useState(['', '', '', '']);
  const [imageUrls, setImageUrls] = useState(['', '', '', '']);
  const [message, setMessage] = useState('');
  const [loadingStates, setLoadingStates] = useState([false, false, false, false]); // Array of loading states for each form

  // Fetch user data
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login');
      }
    };

    fetchUser();
  }, [navigate]);

  // Fetch about text
  useEffect(() => {
    const fetchAboutText = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/get-institutions-about', {
          withCredentials: true,
        });

        if (response.data.success && response.data.data.aboutText) {
          setAboutText(response.data.data.aboutText);
        } else {
          setAboutText('No about text found.');
        }
        setIsAboutTextLoaded(true);
      } catch (error) {
        setAboutText('Error fetching about text.');
        console.error('Error fetching about text:', error.message);
        setIsAboutTextLoaded(true);
      }
    };

    if (user) {
      fetchAboutText();
    }
  }, [user]);

  // Fetch achievements and initialize state
  useEffect(() => {
    const fetchAchievements = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/get-achievements', {
          withCredentials: true,
        });

        if (response.data.success) {
          const achievementsArray = response.data.data.split(',').map(item => item.trim());
          setAchievements(achievementsArray);
        } else {
          console.error('Error fetching achievements:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching achievements:', error.message);
      }
    };

    if (user) {
      fetchAchievements();
    }
  }, [user]);

  // Fetch image captions and URLs
useEffect(() => {
  const fetchImageCaptions = async () => {
      try {
          const response = await axios.get('https://lernacy.co.za/api/get-image-captions', {
              withCredentials: true,
          });

          if (response.data.success && response.data.data.length > 0) {
              // Map image URLs to their respective indices based on their names
              const urls = ['', '', '', '']; // Initialize empty array for four images
              const fetchedCaptions = ['', '', '', ''];

              response.data.data.forEach(item => {
                  const match = item.imageUrl.match(/-(\d+)\.\w+$/); // Regex to extract the index from the filename
                  if (match) {
                      const index = parseInt(match[1], 10); // Get the index from the filename
                      if (index >= 0 && index < 4) { // Ensure index is within range
                          urls[index] = item.imageUrl;
                          fetchedCaptions[index] = item.caption || ''; // Store caption at the correct index
                      }
                  }
              });

              setCaptions(fetchedCaptions);
              setImageUrls(urls);
          } else {
              console.error('No image data found.');
          }
      } catch (error) {
          console.error('Error fetching image captions:', error.message);
      }
  };

  if (user) {
      fetchImageCaptions();
  }
  }, [user]);

  // Handle changes to achievement textareas
  const handleAchievementChange = (index, event) => {
    const inputValue = event.target.value;
    
    // Filter out commas from the input value
    const sanitizedValue = inputValue.replace(/,/g, '');
    
    const newAchievements = [...achievements];
    newAchievements[index] = sanitizedValue;
    setAchievements(newAchievements);
  };

  // Add a new achievement field
  const addAchievementField = () => {
    setAchievements([...achievements, '']);
  };

  // Handle achievements submission
  const handleAchievementsSubmit = async (e) => {
    e.preventDefault();
    setLoadingAchievements(true);

    try {
        // Fetch the CSRF token
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
            method: 'GET',
            credentials: 'include',
        });

        if (!csrfResponse.ok) {
            throw new Error('Failed to fetch CSRF token');
        }

        const csrfData = await csrfResponse.json();
        const csrfToken = csrfData.csrfToken;

        const achievementsString = achievements.filter(Boolean).join(', ');

        // console.log('Submitting achievements:', achievementsString);

        // Send POST request with CSRF token included in headers
        const response = await axios.post('https://lernacy.co.za/api/submit-achievements', {
            achievements: achievementsString,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'CSRF-Token': csrfToken,
            },
            withCredentials: true,
        });

        if (response.data.success) {
            // Fetch the updated achievements
            const updatedResponse = await axios.get('https://lernacy.co.za/api/get-achievements', {
                withCredentials: true,
            });

            if (updatedResponse.data.success) {
                // Update the achievements state with the fetched data
                const updatedAchievementsArray = updatedResponse.data.data.split(',').map(item => item.trim());
                setAchievements(updatedAchievementsArray);
                setAchievementsMessage('Achievements submitted and updated successfully!');
            } else {
                setAchievementsMessage('Error fetching updated achievements.');
            }
        } else {
            setAchievementsMessage('Failed to submit achievements.');
        }
    } catch (error) {
        setAchievementsMessage('Error submitting achievements');
        console.error('Error:', error);
    } finally {
        setLoadingAchievements(false);
    }
  };

  // Handle about text submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingAbout(true);

    try {
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });

      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }

      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;

      const response = await axios.post('https://lernacy.co.za/api/submit-institutions-about', { aboutText }, {
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,
        },
        withCredentials: true,
      });

      if (response.data.success) {
        setAboutMessage('Submitted successfully!');

        const updatedResponse = await axios.get('https://lernacy.co.za/api/get-institutions-about', {
          withCredentials: true,
        });

        if (updatedResponse.data.success && updatedResponse.data.data.aboutText) {
          setAboutText(updatedResponse.data.data.aboutText);
        } else {
          setAboutText('No about text found.');
        }
      } else {
        setAboutMessage('Failed to submit.');
      }
    } catch (error) {
      setAboutMessage('Error submitting about text');
      console.error('Error submitting about text:', error);
    } finally {
      setLoadingAbout(false);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleCaptionChange = (index, e) => {
    const newCaptions = [...captions];
    newCaptions[index] = e.target.value.slice(0, 750); 
    setCaptions(newCaptions);
  };

  /*
  const handleImageSubmit = async (e, imageNamePrefix, index) => {
    e.preventDefault();

    // Set loading state for the specific form being submitted
    const newLoadingStates = [...loadingStates];
    newLoadingStates[index] = true;
    setLoadingStates(newLoadingStates);

    if (!user || !user.decoyId) {
      setMessage('User data is not loaded.');
      setLoadingStates(newLoadingStates.map(() => false)); // Reset all loading states
      return;
    }

    const formData = new FormData();

    if (!selectedFile) {
      setMessage('No file selected.');
      setLoadingStates(newLoadingStates.map(() => false)); // Reset all loading states
      return;
    }

    const fileExtension = selectedFile.name.split('.').pop();
    const fileName = `${user.decoyId}-${imageNamePrefix}.${fileExtension}`;

    formData.append('image', selectedFile, fileName);
    formData.append('caption', captions[index]); // Add caption to formData

    try {
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });

      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }

      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;

      const response = await axios.post('https://lernacy.co.za/api/upload-gallery-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'CSRF-Token': csrfToken,
        },
        withCredentials: true,
      });

      if (response.data.success) {
        setMessage('Image uploaded successfully!');

        // Fetch updated image captions and URLs after upload
        const updatedResponse = await axios.get('https://lernacy.co.za/api/get-image-captions', {
          withCredentials: true,
        });

        if (updatedResponse.data.success) {
          const updatedCaptions = updatedResponse.data.data.map(item => item.caption);
          const updatedUrls = updatedResponse.data.data.map(item => item.imageUrl);
          setCaptions(updatedCaptions);
          setImageUrls(updatedUrls);
        } else {
          setMessage('Failed to fetch updated image data.');
        }
      } else {
        setMessage('Failed to upload image.');
      }
    } catch (error) {
      setMessage('Error uploading image.');
      console.error('Error:', error);
    } finally {
      // Reset loading state for the specific form
      newLoadingStates[index] = false;
      setLoadingStates(newLoadingStates);
    }
  };
  */
  const handleImageSubmit = async (e, imageNamePrefix, index) => {
    e.preventDefault();

    // Set loading state for the specific form being submitted
    const newLoadingStates = [...loadingStates];
    newLoadingStates[index] = true;
    setLoadingStates(newLoadingStates);

    if (!user || !user.decoyId) {
      setMessage('User data is not loaded.');
      setLoadingStates(newLoadingStates.map(() => false)); // Reset all loading states
      return;
    }

    const formData = new FormData();

    if (!selectedFile) {
      setMessage('No file selected.');
      setLoadingStates(newLoadingStates.map(() => false)); // Reset all loading states
      return;
    }

    const fileExtension = selectedFile.name.split('.').pop();
    const fileName = `${user.decoyId}-${imageNamePrefix}.${fileExtension}`;
    
    // Log the generated image name
    // console.log('Generated Image Name:', fileName);

    formData.append('image', selectedFile, fileName);
    formData.append('caption', captions[index]); // Add caption to formData

    try {
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });

      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }

      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;

      const response = await axios.post('https://lernacy.co.za/api/upload-gallery-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'CSRF-Token': csrfToken,
        },
        withCredentials: true,
      });

      if (response.data.success) {
        setMessage('Image uploaded successfully!');

        // Fetch updated image captions and URLs after upload
        const updatedResponse = await axios.get('https://lernacy.co.za/api/get-image-captions', {
          withCredentials: true,
        });

        if (updatedResponse.data.success) {
          const updatedCaptions = updatedResponse.data.data.map(item => item.caption);
          const updatedUrls = updatedResponse.data.data.map(item => item.imageUrl);
          setCaptions(updatedCaptions);
          setImageUrls(updatedUrls);
        } else {
          setMessage('Failed to fetch updated image data.');
        }
      } else {
        setMessage('Failed to upload image.');
      }
    } catch (error) {
      setMessage('Error uploading image.');
      console.error('Error:', error);
    } finally {
      // Reset loading state for the specific form
      newLoadingStates[index] = false;
      setLoadingStates(newLoadingStates);
    }
  };


  return (
    <div className='page-colour-manager'>
      <h1 className='page-title'>About</h1>
      <div className="my-study-container">
        <div className='meant-to-align'>
          <div className='align-container'>
            <div className='edit-institution-about-page'>

              <div>

                <div className='institution-about-top-flexbox'>

                  <div className='edit-institution-about-page-textarea-container'>
                    <h3>About institution</h3>
                    <form onSubmit={handleSubmit}>
                      <textarea
                        className='edit-institution-about-page-textarea'
                        value={isAboutTextLoaded ? aboutText : 'Loading about text...'} 
                        onChange={(e) => setAboutText(e.target.value)}
                        maxLength={750}
                        placeholder={isAboutTextLoaded && !aboutText ? "No about text found" : ""}
                        required
                      /><br />
                      
                      <button 
                        className='edit-institution-about-page-textarea-button'
                        type="submit" 
                        disabled={loadingAbout || aboutText.trim() === ''}
                      >
                        {loadingAbout ? 'Loading...' : 'Submit'}
                      </button>{aboutMessage && <label>{aboutMessage}</label>}
                    </form>
                  </div>

                  <hr className='hr-diviver-clear' />

                  <div className='edit-about-achievements'>
                    <div>
                      <h3>Achievements</h3>
                      <form onSubmit={handleAchievementsSubmit}>
                        {achievements.map((achievement, index) => (
                          <div key={index}>
                            <textarea
                              className='achievements-textarea'
                              value={achievement}
                              onChange={(e) => handleAchievementChange(index, e)}
                              placeholder={`Achievement ${index + 1}`}
                              rows={1}
                            />
                          </div>
                        ))}

                        <button
                          type="button" 
                          onClick={addAchievementField}
                          className='achievements-add-button'
                          >
                          + Achievements
                        </button>

                        <button 
                          type="submit" 
                          disabled={loadingAchievements}
                          className='achievements-submit-button'
                          >
                          {loadingAchievements ? 'Loading...' : 'Submit'}
                        </button>

                      </form>
                      {achievementsMessage && <label>{achievementsMessage}</label>}
                    </div>
                  </div>
                
                </div>

                <hr />

                <section>
                  <h2>Image Uploads</h2>
                  <div className='image-uploads-main-div'>
                    {[0, 1, 2, 3].map((index) => (
                      <div className='image-upload-containers' key={index}>
                        {imageUrls[index] && (
                          <div>
                            <p>Current Image:</p>
                            <img 
                              loading="lazy"
                              className='current-image-own-show' 
                              src={imageUrls[index]} alt={`Uploaded Image ${index + 1}`} 
                              style={{ maxWidth: '100%' }} 
                              />
                          </div>
                        )}
                        <form onSubmit={(e) => handleImageSubmit(e, `image-${index}`, index)}>
                          <input
                            className='file-upload-input'
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            onChange={handleFileChange}
                          />
                          <textarea
                            placeholder={`Caption for image ${index + 1}`}
                            value={captions[index]}
                            onChange={(e) => handleCaptionChange(index, e)}
                            maxLength={750} 
                            className='caption-field-textarea'
                          />
                          <button className='submit-image-button' type="submit" disabled={loadingStates[index]}>
                            {loadingStates[index] ? 'Uploading...' : `Submit Image ${index + 1}`}
                          </button>
                        </form>
                        
                      </div>
                    ))}
                  </div>
                  {message && <p>{message}</p>}
                </section>
              
              </div>
              
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
};  

export default Study;



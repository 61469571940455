import React, { useState, useEffect } from 'react';
import AccessDenied from '../../components/AccessDenied';

const GrSixRoundingOffGame = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [highScore, setHighScore] = useState(null);
  const [currentRound, setCurrentRound] = useState(1);
  const [equations, setEquations] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const [gameScore, setGameScore] = useState(0);
  const [scoreRating, setScoreRating] = useState('');
  const [scoreComment, setScoreComment] = useState('');
  const gameName = 'Grade_6:_Rounding-Off_Maths_10';

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });
  
        if (response.ok) {
          const data = await response.json();
          const user = data.user;
  
          if (!user || !user.accountName) {
            setIsAuthenticated(false);
            // Redirect to "/login-second-stage"
            window.location.href = "/login-second-stage";
            return;
          }
  
          setUserDetails(user);
          setIsAuthenticated(true);
        } else {
          throw new Error('Error fetching user details: ' + response.statusText);
        }
      } catch (error) {
        console.error(error.message);
        setIsAuthenticated(false);
      }
    };
  
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchHighScore();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    generateEquations();
  }, []);

  const fetchHighScore = async () => {
    try {
      const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        console.error('Error fetching high score:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching high score:', error.message);
    }
  };

  const generateEquations = () => {
    const roundingOptions = [
      { roundingType: '5', roundingFactor: 5 },
      { roundingType: '10', roundingFactor: 10 },
      { roundingType: '100', roundingFactor: 100 },
      { roundingType: '1000', roundingFactor: 1000 },
    ];
  
    const newEquations = [];
    for (let i = 0; i < 10; i++) {
      const randomNumber = Math.floor(Math.random() * (2500 - 100 + 1)) + 100;
      const randomRounding = roundingOptions[Math.floor(Math.random() * roundingOptions.length)];
      
      const correctAnswer = Math.round(randomNumber / randomRounding.roundingFactor) * randomRounding.roundingFactor;
      
      newEquations.push({
        equation: randomNumber,
        roundingType: randomRounding.roundingType, // e.g., '5', '10', '100', '1000'
        correctAnswer: correctAnswer,
      });
    }
    
    setEquations(newEquations);
    setUserAnswers(new Array(10).fill(''));
  };  

  const handleAnswerChange = (index, value) => {
    setUserAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[index] = value;
      return newAnswers;
    });
  };

  const handleSubmit = async () => {
    const newGameScore = userAnswers.reduce((score, answer, index) => {
      return answer === equations[index].correctAnswer.toString() ? score + 1 : score;
    }, 0);
    setGameScore(newGameScore);
  
    // Calculate score rating and comment
    const percentage = (newGameScore / 10) * 100;
    const roundedPercentage = Math.round(percentage);
  
    let newScoreRating, newScoreComment;
  
    if (roundedPercentage >= 90) {
      newScoreRating = 'Outstanding';
      newScoreComment = 'Congratulations! You performed outstandingly.';
    } else if (roundedPercentage >= 75) {
      newScoreRating = 'Passed';
      newScoreComment = 'Well done! You passed the rounding off game.';
    } else if (roundedPercentage >= 50) {
      newScoreRating = 'Almost';
      newScoreComment = 'You are close to passing. Keep practicing!';
    } else {
      newScoreRating = 'Failed';
      newScoreComment = 'Keep practicing. You can do better!';
    }
  
    // Update the state with the new rating and comment
    setScoreRating(newScoreRating);
    setScoreComment(newScoreComment);
  
    // Submit game score
    try {
      // Step 1: Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      // Step 2: Send a POST request with CSRF token
      const response = await fetch('https://lernacy.co.za/api/record-score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        body: JSON.stringify({
          userId: userDetails._id,
          score: newGameScore,
          timeOfDay: new Date().getHours(),
          gameName: gameName,
        }),
        credentials: 'include', // Include credentials
      });
  
      if (response.ok) {
        //console.log('Score recorded successfully!');
      } else {
        console.error('Failed to record score:', response.statusText);
      }
    } catch (error) {
      console.error('Error recording score:', error.message);
    }
  
    setCurrentRound(11); // Set current round to 11 to trigger game over screen
  };
  
  const restartGame = async () => {
    try {
      setCurrentRound(1);
      setGameScore(0);
      generateEquations();

      const url = `https://lernacy.co.za/api/user-score/${gameName}`;
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        throw new Error('Error fetching high score: ' + response.statusText);
      }

      generateEquations();
    } catch (error) {
      console.error('Error restarting game:', error.message);
    }
  };

  const getPercentageComment = (percentage) => {
    if (percentage >= 90) {
      return 'Outstanding';
    } else if (percentage >= 75) {
      return 'Well done';
    } else if (percentage >= 50) {
      return 'Keep it up';
    } else {
      return 'You can do better';
    }
  };

  return (
    <div className='tested-activity-wall-container'>
      {isAuthenticated === null ? (
        <p>Loading authentication status...</p>
      ) : !isAuthenticated ? (
        <AccessDenied />
      ) : (
        <div>
          
          {currentRound <= 10 ? (
            <div>
              <h1 className='activity-name-tag-h1'>G6 - Grand Round-Off</h1>
              <div className='round-high-score'>
                <h2 className='activity-rounds-tag-h2'>10 Questions</h2>
                <p>
                  <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/15</strong>
                </p>
              </div>

              <h3>Complete all activities below</h3>
              <div className='equations-aligner-block-cover'>
                {equations.map((equation, index) => (
                  <div key={index} className='equations-block-box'>
                    <p><strong>Question {index + 1}</strong></p>
                    <p>Round off the number to the nearest {equation.roundingType}:</p>
                    <p>Given Number:<br /><strong>{equation.equation}</strong> = </p>
                    <p>Your Answer:</p>
                    <input 
                      className='counting-activity-input-field' 
                      type="text" 
                      value={userAnswers[index] || ''} 
                      onChange={(e) => handleAnswerChange(index, e.target.value)} 
                    /><br />
                  </div>
                ))}
              </div>
              <br/>
              <button className='activity-submit-button' onClick={handleSubmit}>Submit Answers</button>
            </div>
          ) : (
            <div>
              {/* Game over screen */}
              <h1 className='activity-name-tag-h1'>Activity Over<br/>Your Score: {gameScore}/10</h1>

              <div className='activity-game-over-data'>
                <h2 className='activity-rounds-tag-h2-left'>Score Rating: {scoreRating}</h2>
                <h3>{scoreComment}</h3>
                <p>Percentage: <strong>{Math.round((gameScore / 10) * 100)}%</strong></p>
              </div> 

              <div>
                <h3 className='activity-rounds-tag-h2'>Question Records:</h3>
                <div className='activity-over-rounds-evidence-ul'>
                  {userAnswers.map((answer, index) => (
                    <div key={index} className='activity-over-rounds-evidence'>
                      <p>
                        <strong>Question {index + 1}</strong><br /> 
                        Round Off to the nearest {equations[index].roundingType}:<br /><br />
                        Given Number: <strong>{equations[index].equation}</strong> <br />
                        Your Answer: <strong>{answer}</strong>
                        {equations[index].correctAnswer === parseInt(answer, 10) ? <span style={{ color: 'green' }}> ✔</span> : <span style={{ color: 'red' }}> ✘</span>}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <hr />
              <p>
                  <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/15</strong>
              </p>

              <div className='activity-restart-or-more'>
                  <button className='activity-restart-button' onClick={restartGame}>Play Again</button>
                  <button className='more-activity-button' onClick={() => window.location.href = "/Grade:6_Mathematics"}>Do More Activities</button>
              </div>
              <br/>
            </div>
          )}

          <br />
        </div>
      )}
    </div>
  );
};

export default GrSixRoundingOffGame;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AccessDenied from '../components/AccessDenied'; // Import the AccessDenied component
import './bodyFiles-styling/extra-style.css';

const AllOnboardInstitutions = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [blockRequests, setBlockRequests] = useState(false);
    const [initialRequestMade, setInitialRequestMade] = useState(false);
    const [institutions, setInstitutions] = useState([]);
    const [userData, setUserData] = useState(null); // State to store user data
    const [currentSection, setCurrentSection] = useState('task');
    const [school, setSchool] = useState({
        name: '',
        address: '',
        contactNumber: '',
        principal: '',
        keyMember: '', 
    });
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSchool({ ...school, [name]: value });
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                // Fetch all users
                const response = await axios.get('https://lernacy.co.za/api/check/api/check/users', {
                    withCredentials: true
                });
                //console.log('Users response:', response.data);
                setUsers(response.data.user);
                setUserData(response.data.user); // Set user data
                setInitialRequestMade(true);

                // Fetch filtered users if initial request succeeds
                const filteredResponse = await axios.get('https://lernacy.co.za/api/check/api/check/users/filtered-users', {
                    withCredentials: true
                });
                //console.log('Filtered Users response:', filteredResponse.data);
                setFilteredUsers(filteredResponse.data.users);

                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setError('Access forbidden');
                    setBlockRequests(true); // Block all requests on 403 response
                } else {
                    setError('Error fetching users');
                }
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchInstitutions = async () => {
            try {
                // Make a GET request to fetch institutions data
                const response = await axios.get('https://lernacy.co.za/api/institutions/schools', { withCredentials: true });
                //console.log('Institutions response:', response.data);
                setInstitutions(response.data.institutions);
                setLoading(false);
            } catch (error) {
                setError('Error fetching institutions data');
                setLoading(false);
            }
        };
    
        fetchInstitutions();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Fetch CSRF token
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
        
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
        
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
        
            // Send a POST request to submit the school data
            const response = await axios.post('https://lernacy.co.za/api/institutions', school, {
                headers: {
                'Content-Type': 'application/json',
                'CSRF-Token': csrfToken, // Include CSRF token in the headers
                },
                withCredentials: true,
            });
    
            setMessage('School added successfully!');
            // Clear the form
            setSchool({
                name: '',
                address: '',
                contactNumber: '',
                principal: '',
                keyMember: ''
            });
        } catch (error) {
            setMessage('Error adding school');
            console.error('Error adding school:', error);
        }
    };

    const copyTextToClipboard = (text) => {
        navigator.clipboard.writeText(text)
        .then(() => {
            alert('Text copied to clipboard!');
        })
        .catch((error) => {
            console.error('Error copying text:', error);
        });
    };

    const handleSectionChange = (section) => {
        setCurrentSection(section); 
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    }; 
 
    if (!initialRequestMade) {
        return <p>Loading...</p>;
    }

    if (!userData) {
        return <AccessDenied />; // Render AccessDenied if the user is not authenticated
    }

    return (
        <div className='tested-activity-wall-container'>
            <h1 className='activity-name-tag-h1'>All Onboard Institutions</h1>
            {/* <h2>Welcome, {userData.name} {userData.surname}</h2>  Display user's name and surname */}

            {(userData?.accountName === 'TerrenceLetsie2357') && (
                <div className='multimodal-education-teacher-menu'>
                <button
                    className={`multimodal-education-teacher-menu-button ${currentSection === 'task' ? 'active' : ''}`}
                    onClick={() => handleSectionChange('task')}
                >
                    <div>
                    Task
                    </div>
                </button>
                <button
                    className={`multimodal-education-teacher-menu-button ${currentSection === 'submissions' ? 'active' : ''}`}
                    onClick={() => handleSectionChange('submissions')}
                >
                    <div>
                    Submissions
                    </div>
                </button>
                </div>
            )}
            <br />

            {(userData?.accountName === 'TerrenceLetsie2357') && (
                <div>
                    {currentSection === 'task' && ( 
                        <div>
                        <div>
                            <div className='submit-int-form-block-container'>
                                <h3>Add School to Institution</h3>
        
                                <form onSubmit={handleSubmit} className='submit-int-form-block'>
                                    <div>
                                        <label>School Name:</label><br />
                                        <input
                                            type="text"
                                            name="name"
                                            value={school.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
        
                                    <div>
                                        <label>Address:</label><br />
                                        <input
                                            type="text"
                                            name="address"
                                            value={school.address}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
        
                                    <div>
                                        <label>Contact Number:</label><br />
                                        <input
                                            type="text"
                                            name="contactNumber"
                                            value={school.contactNumber}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
        
                                    <div>
                                        <label>Principal:</label><br />
                                        <input
                                            type="text"
                                            name="principal"
                                            value={school.principal}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
        
                                    <div>
                                        <label>Key Member:</label><br />
                                        <input
                                            type="text"
                                            name="keyMember" // Changed 'KeyMember' to 'keyMember'
                                            value={school.keyMember}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
        
                                    <button type="submit">Add School</button>
                                </form>
                                {message && <p>{message}</p>}
                            </div>
                        </div>
                        <br />
        
                        <hr />
        
                        <br />
        
                        <div>
                            <input
                                type='text'
                                placeholder='Search schools...'
                                value={searchQuery}
                                onChange={handleSearch}
                                className='search-box-for-schools'
                            />
                        </div>
        
                        <div className='schools-list-block-sections'>
                            <h3>All Onboard Schools:</h3>
                            <div>
                                {institutions.map((institution) => (
                                    <div key={institution._id}>
                                    <div className='schools-list-block-sections-ul'>
                                        {institution.schools
                                        .filter((school) =>
                                            `${school.name} ${school.address} ${school.contactNumber} ${school.principal}`.toLowerCase()
                                            .includes(searchQuery.toLowerCase())
                                        )
                                        .map((school, index) => (
                                            <div key={index} className='schools-list-block-sections-li'>
        
                                                <div className='inst-closeup-box-container'>
                                                    <label>School Name</label>
                                                    <p>{school.name}</p>
                                                </div>
        
                                                <div className='inst-closeup-box-container'>
                                                    <label>School Address</label>
                                                    <p>{school.address}</p>
                                                </div>
        
                                                <div className='inst-closeup-box-container'>
                                                    <label>Contact Number</label>
                                                    <p onClick={() => copyTextToClipboard(school.contactNumber)}>{school.contactNumber}</p>
                                                </div>
        
                                                <div className='inst-closeup-box-container'>
                                                    <label>School Principal</label>
                                                    <p>{school.principal}</p>
                                                </div>
        
                                            </div>
                                        ))}
                                    </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            )}

            {(userData?.accountName === 'TerrenceLetsie2357') && (
                <div>
                    {currentSection === 'submissions' && (
                        <div>
                            Submission Section Block
                        </div>
                    )}
                </div>
            )}

            <br />

        </div>
    );
};

export default AllOnboardInstitutions;



import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Copyright from '../../../components/Copyright';
import '../../../pages/pages-styling/study.css';
import axios from 'axios';

const Grade9SocialSciencesUnitOne = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => { 
        // Make a request to the server to get user information
        const fetchUser = async () => {
        try {
            const response = await fetch('https://lernacy.co.za/api/study', {
            method: 'GET',
            credentials: 'include', // Include credentials for cross-origin requests
            });

            if (response.ok) {
            const data = await response.json();
            setUser(data.user);

            // Check if user has an account name
            if (!data.user.accountName) {
                navigate('/login-second-stage');
            }
            } else {
            const errorData = await response.json();
            console.error('Error fetching user data:', errorData.message);

            // Redirect to login if not authenticated
            navigate('/login');
            }
        } catch (error) {
            console.error('Error fetching user data:', error.message);

            // Redirect to login on error
            navigate('/login');
        }
        };

        fetchUser();
    }, [navigate]); // Include navigate in the dependency array

    return (
        <div className='page-colour-manager'>
            <div className='topic-notes-block'>

                <h1 className='page-title'>Universal Declaration of Human Rights After World War II</h1>

                <label className='general-delaration'>
                    Read the <Link to="/general-disclaimer" className='general-delaration-link'>General Declaration</Link> before using the notes.
                </label>

                <div>
                    <p>
                        The Universal Declaration of Human Rights (UDHR) was adopted by the United Nations General 
                        Assembly on December 10, 1948, in response to the atrocities of World War II. It was 
                        created to affirm and recognize the inherent dignity and equal and inalienable rights of 
                        all members of the human family as the foundation of freedom, justice, and peace in the 
                        world.
                    </p>

                    <h2>
                        Key Articles in the UDHR
                    </h2>
                    <ul>
                        <li>
                            <strong>Article 1:</strong> All human beings are born free and equal in dignity and rights.
                        </li>
                        <li>
                            <strong>Article 2:</strong> Everyone is entitled to all the rights and freedoms set forth in this 
                            Declaration, without distinction of any kind.
                        </li>
                        <li>
                            <strong>Article 3:</strong> Everyone has the right to life, liberty, and security of person.
                        </li>
                        <li>
                            <strong>Article 4:</strong> No one shall be held in slavery or servitude; slavery and the slave trade 
                            shall be prohibited in all their forms.
                        </li>
                        <li>
                            <strong>Article 5:</strong> No one shall be subjected to torture or to cruel, inhuman, or degrading 
                            treatment or punishment.
                        </li>
                        <li>
                            <strong>Article 9:</strong> No one shall be subjected to arbitrary arrest, detention, or exile.
                        </li>
                        <li>
                            <strong>Article 18:</strong> Everyone has the right to freedom of thought, conscience, and religion.
                        </li>
                        <li>
                            <strong>Article 19:</strong> Everyone has the right to freedom of opinion and expression.
                        </li>
                        <li>
                            <strong>Article 21:</strong> Everyone has the right to take part in the government of their country, 
                            directly or through freely chosen representatives.
                        </li>
                    </ul>

                    <h2>Relevance to South Africa</h2>

                    <h3>
                        Key Rights Relevant to South Africa:
                    </h3>

                    <ul>
                        <li>
                            <strong>Equality (Article 1 and Article 2):</strong> These articles emphasize equality 
                            and non-discrimination, which are crucial in the context of South Africa's history of 
                            racial segregation and discrimination.
                        </li>
                        <li>
                            <strong>Freedom from Discrimination (Article 2):</strong> This is particularly relevant 
                            given South Africa's past under apartheid, where laws explicitly discriminated against 
                            the non-white population.
                        </li>
                        <li>
                            <strong>Right to Freedom from Arbitrary Detention (Article 9):</strong> During 
                            apartheid, many political activists were arbitrarily arrested and detained.
                        </li>
                        <li>
                            <strong>Right to Freedom of Thought, Conscience, and Religion (Article 18): </strong> 
                            This right was restricted under apartheid, where the government controlled and censored 
                            various aspects of life, including religion and belief systems.
                        </li>
                        <li>
                            <strong>Right to Take Part in Government (Article 21):</strong> Apartheid laws 
                            disenfranchised the majority black population, denying them the right to vote and 
                            participate in government.
                        </li>
                    </ul>

                    <h2>
                        South Africa's National Party and Apartheid
                    </h2>

                    <h3>
                        The National Party:
                    </h3>

                    <ul>
                        <li>
                            <strong>Formation and Rise to Power:</strong> The National Party (NP) was formed in 
                            1914 and came to power in South Africa in 1948, the same year the UDHR was adopted.
                        </li>
                        <li>
                            <strong>Apartheid Policy:</strong> The NP implemented the policy of apartheid, an 
                            institutionalized system of racial segregation and discrimination against non-white 
                            citizens of South Africa.
                        </li>
                    </ul>

                    <h3>
                        Apartheid and the UDHR:
                    </h3>
                    <p>
                        <strong>Contradiction to UDHR Principles:</strong> Apartheid policies were in direct 
                        contradiction to the principles outlined in the UDHR. The system of apartheid created a 
                        racially stratified society where rights and privileges were determined by race.
                    </p>

                    <h3>
                        Violation of Rights:
                    </h3>
                    <ul>
                        <li>
                            <strong>Equality and Non-Discrimination:</strong> Apartheid laws institutionalized 
                            racial discrimination and violated the principles of equality and non-discrimination.
                        </li>
                        <li>
                            <strong>Freedom from Arbitrary Detention:</strong> Many anti-apartheid activists, 
                            including Nelson Mandela, were detained without trial, violating the right to freedom 
                            from arbitrary detention.
                        </li>
                        <li>
                            <strong>Participation in Government:</strong> The black majority and other non-white 
                            groups were denied the right to vote and participate in the government, violating their 
                            political rights.
                        </li>
                        <li>
                            <strong>Economic and Social Rights:</strong> Apartheid policies also led to gross 
                            inequalities in access to education, healthcare, and economic opportunities, violating 
                            economic, social, and cultural rights.
                        </li>
                    </ul>

                    <h2>
                        Impact and Legacy
                    </h2>

                    <ul>
                        <li>
                            <strong>International Condemnation:</strong> The apartheid regime faced widespread 
                            international condemnation and sanctions due to its human rights abuses.
                        </li>
                        <li>
                            <strong>Transition to Democracy:</strong> The persistent struggle of the South African 
                            people, along with international pressure, eventually led to the dismantling of 
                            apartheid and the establishment of a democratic government in 1994.
                        </li>
                        <li>
                            <strong>Human Rights in South Africa Today:</strong> Post-apartheid South Africa has 
                            embraced the principles of the UDHR, enshrining human rights in its Constitution and 
                            working towards a more equitable society.
                        </li>
                    </ul>

                    <h2>
                        Conclusion
                    </h2>
                    <p>
                        The UDHR set a global standard for human rights that apartheid South Africa flagrantly 
                        violated. The fight against apartheid was a struggle to bring South African policies in 
                        line with these universal principles. The end of apartheid marked a significant step 
                        towards the realization of human rights for all South Africans, aligning the nation's 
                        policies more closely with the UDHR's vision.
                    </p>

                </div>
            
            </div>
        </div>
    );
};

export default Grade9SocialSciencesUnitOne;
  
const socialSciencesPeopleAndAgriculture = [
    {
        id: 1,
        question: 'Where does food come from?',
        options: ['From the sky', 'From the ocean', 'From plants and animals', 'From the mountains'],
        correctAnswer: 'From plants and animals'
    },
    {
        id: 2,
        question: 'What do food collectors do?',
        options: ['Grow food in their gardens', 'Catch fish, hunt animals, and find plants in the wild', 'Shop at supermarkets', 'Trade food with neighbors'],
        correctAnswer: 'Catch fish, hunt animals, and find plants in the wild'
    },
    {
        id: 3,
        question: 'How do many people get their food?',
        options: ['By growing it themselves', 'By buying from shops, stalls, or street vendors', 'By fishing in the sea', 'By hunting wild animals'],
        correctAnswer: 'By buying from shops, stalls, or street vendors'
    },
    {
        id: 4,
        question: 'What do some people do if they live on farms?',
        options: ['They buy food from supermarkets', 'They grow their own food', 'They fish in rivers', 'They hunt wild animals'],
        correctAnswer: 'They grow their own food'
    },
    {
        id: 5,
        question: 'What do some people do with extra food from their gardens?',
        options: ['Throw it away', 'Donate it to food banks', 'Feed it to animals', 'Sell it at the market'],
        correctAnswer: 'Donate it to food banks'
    },
    {
        id: 6,
        question: 'Where do some people in towns and cities grow their own food?',
        options: ['In their basements', 'In community gardens', 'In shopping malls', 'In parking lots'],
        correctAnswer: 'In their own vegetable gardens or fruit trees'
    },
    {
        id: 7,
        question: 'Where do people living in towns and cities sometimes grow their own food?',
        options: ['In shopping malls', 'On rooftops', 'In underground bunkers', 'In their own vegetable gardens or fruit trees'],
        correctAnswer: 'In their own vegetable gardens or fruit trees'
    },
    {
        id: 8,
        question: 'What is rooftop gardening?',
        options: ['Growing crops in underground tunnels', 'Growing crops on rooftops of buildings', 'Growing crops in vertical gardens', 'Growing crops indoors under artificial light'],
        correctAnswer: 'Growing crops on rooftops of buildings'
    },
    {
        id: 9,
        question: 'What are community gardens?',
        options: ['Private gardens for individual households', 'Gardens maintained by a single individual', 'Shared spaces where people grow food together', 'Commercial farms in urban areas'],
        correctAnswer: 'Shared spaces where people grow food together'
    },
    {
        id: 10,
        question: 'What food do people usually grow in their garden?',
        options: ['Flowers', 'Vegetables', 'Trees', 'Grass'],
        correctAnswer: 'Vegetables',
        imageUrl: '/activityImages/SocialSciences/food_food-garden.jfif'
    },
    {
        id: 11,
        question: 'What activity is commonly done in a garden?',
        options: ['Reading', 'Swimming', 'Playing sports', 'Planting'],
        correctAnswer: 'Planting',
        imageUrl: '/activityImages/SocialSciences/food_garden.jfif'
    },
    {
        id: 12,
        question: 'What activity is the person in the image doing?',
        options: ['Cooking', 'Fishing', 'Gardening', 'Driving'],
        correctAnswer: 'Fishing',
        imageUrl: '/activityImages/SocialSciences/food_man-fishing.jfif'
    },
    {
        id: 13,
        question: 'What is subsistence farming?',
        options: ['Farming for profit', 'Farming for self and family consumption', 'Farming without using any tools', 'Farming in large commercial farms'],
        correctAnswer: 'Farming for self and family consumption'
    },
    {
        id: 14,
        question: 'What kind of tools are commonly used in subsistence farming?',
        options: ['Tractors and harvesters', 'Spades and hoes', 'Computers and robots', 'Drills and saws'],
        correctAnswer: 'Spades and hoes'
    },
    {
        id: 15,
        question: 'Why is subsistence farming important?',
        options: ['To maximize profits', 'To feed families and communities', 'To promote industrialization', 'To compete in global markets'],
        correctAnswer: 'To feed families and communities'
    },
    {
        id: 16,
        question: 'Why is sustainability important in subsistence farming?',
        options: ['To maximize profits', 'To ensure long-term food security', 'To attract investors', 'To minimize workload'],
        correctAnswer: 'To ensure long-term food security'
    },
    {
        id: 17,
        question: 'How does subsistence farming impact local economies?',
        options: ['It leads to overproduction', 'It boosts employment and local markets', 'It causes environmental degradation', 'It decreases food availability'],
        correctAnswer: 'It boosts employment and local markets'
    },
    {
        id: 18,
        question: 'Which type of farming is commonly found in areas more available water resources?',
        options: ['Stock farming', 'Commercial farming', 'Subsistence farming', 'Mixed farming'],
        correctAnswer: 'Commercial farming'
    },
    {
        id: 19,
        question: 'What is commercial farming?',
        options: ['Farming for subsistence', 'Farming for personal consumption', 'Farming for profit and selling produce', 'Farming for environmental conservation'],
        correctAnswer: 'Farming for profit and selling produce'
    },
    {
        id: 20,
        question: 'How do commercial farms differ from subsistence farms in terms of size?',
        options: ['Commercial farms are smaller', 'Commercial farms are larger', 'Both types of farms are similar in size', 'Farm size does not matter in commercial farming'],
        correctAnswer: 'Commercial farms are larger'
    },
    {
        id: 21,
        question: 'What role do machines like tractors play in commercial farming?',
        options: ['They decrease productivity', 'They increase labor costs', 'They help with planting and harvesting', 'They have no impact on farming operations'],
        correctAnswer: 'They help with planting and harvesting'
    },
    {
        id: 22,
        question: 'How can commercial farming contribute to rural development?',
        options: ['By displacing rural communities', 'By creating job opportunities', 'By degrading natural resources', 'By increasing poverty levels'],
        correctAnswer: 'By creating job opportunities'
    },
    {
        id: 23,
        question: 'Which type of farming is commonly found in areas more available water resources?',
        options: ['Stock farming', 'Commercial farming', 'Subsistence farming', 'Mixed farming'],
        correctAnswer: 'Commercial farming'
    },
    {
        id: 24,
        question: 'How can food be packaged?',
        options: ['In paper bags', 'In glass jars', 'In plastic buckets', 'All of the above'],
        correctAnswer: 'All of the above'
    },
    {
        id: 25,
        question: 'Where can we get fresh food?',
        options: ['From the supermarket', 'From the refrigerator', 'Picked from a tree or dug up from the soil', 'Imported from other countries'],
        correctAnswer: 'Picked from a tree or dug up from the soil'
    },
    {
        id: 26,
        question: 'Which food is picked from a tree?',
        options: ['Potato', 'Banana', 'Carrot', 'Lettuce'],
        correctAnswer: 'Banana'
    },
    {
        id: 27,
        question: 'Where does most of our food come from?',
        options: ['From wild forests', 'From supermarkets', 'From farms', 'From factories'],
        correctAnswer: 'From farms'
    },
    {
        id: 28,
        question: 'Who works to produce the food we eat?',
        options: ['Chefs in restaurants', 'Teachers at schools', 'Farmers on farms', 'Doctors in hospitals'],
        correctAnswer: 'Farmers on farms'
    },
    {
        id: 29,
        question: 'What food do people usually grow in their garden?',
        options: ['Flowers', 'Vegetables', 'Trees', 'Grass'],
        correctAnswer: 'Vegetables',
        imageUrl: '/activityImages/SocialSciences/food_food-garden.jfif'
    },
    {
        id: 30,
        question: 'What is the primary work of farmers?',
        options: ['Fishing', 'Teaching', 'Manufacturing', 'Producing food'],
        correctAnswer: 'Producing food'
    },
    {
        id: 31,
        question: 'Where do most people buy their food from?',
        options: ['Pet stores', 'Farmers markets', 'Supermarkets', 'Online retailers'],
        correctAnswer: 'Supermarkets'
    },
    {
        id: 32,
        question: 'Which of the following methods is used to preserve food for long periods?',
        options: ['Freezing', 'Burying it underground', 'Leaving it in the sun', 'Throwing salt on it'],
        correctAnswer: 'Freezing'
    },
    {
        id: 33,
        question: 'What is the purpose of canning food?',
        options: ['To make it taste better', 'To keep it fresh longer', 'To change its color', 'To reduce its size'],
        correctAnswer: 'To keep it fresh longer'
    },
    {
        id: 34,
        question: 'Why is sustainability important in subsistence farming?',
        options: ['To maximize profits', 'To ensure long-term food security', 'To attract investors', 'To minimize workload'],
        correctAnswer: 'To ensure long-term food security'
    },
    {
        id: 35,
        question: 'What is urban agriculture?',
        options: ['Farming in rural areas', 'Growing food in towns and cities', 'Fishing in urban areas', 'Importing food from other countries'],
        correctAnswer: 'Growing food in towns and cities'
    },{
        id: 36,
        question: 'What are some benefits of urban agriculture?',
        options: ['Reduced food availability', 'Increased access to fresh produce', 'Environmental degradation', 'Reliance on imported food'],
        correctAnswer: 'Increased access to fresh produce'
    },
    {
        id: 37,
        question: 'How does urban agriculture contribute to food security?',
        options: ['It decreases food availability', 'It reduces reliance on imported food', 'It increases food waste', 'It disrupts local economies'],
        correctAnswer: 'It reduces reliance on imported food'
    },
    {
        id: 38,
        question: 'What is hydroponics?',
        options: ['Growing plants in soil', 'Growing plants without soil, using water and nutrients', 'Using animals for farming', 'Importing food from other countries'],
        correctAnswer: 'Growing plants without soil, using water and nutrients'
    },
    {
        id: 49,
        question: 'What are community gardens?',
        options: ['Private gardens for individual households', 'Gardens maintained by a single individual', 'Shared spaces where people grow food together', 'Commercial farms in urban areas'],
        correctAnswer: 'Shared spaces where people grow food together'
    },
    {
        id: 50,
        question: 'What are the benefits of community gardens?',
        options: ['Isolation and social disconnection', 'Improved community cohesion and food security', 'Increased reliance on store-bought food', 'Environmental degradation'],
        correctAnswer: 'Improved community unity and food security'
    },
    {
        id: 51,
        question: 'Where does most of our food come from?',
        options: ['Factories', 'Supermarkets', 'Farms', 'Restaurants'],
        correctAnswer: 'Farms',
        imageUrl: '/activityImages/SocialSciences/food_food-farm.jfif'
    },
    {
        id: 52,
        question: 'What type of food is shown in the image?',
        options: ['Fruits', 'Vegetables', 'Dairy', 'Meat'],
        correctAnswer: 'Vegetables',
        imageUrl: '/activityImages/SocialSciences/food_food-from-the-ground.jfif'
    },
    {
        id: 53,
        question: 'What do people typically grow in a food garden?',
        options: ['Flowers', 'Vegetables', 'Trees', 'Grass'],
        correctAnswer: 'Vegetables',
        imageUrl: '/activityImages/SocialSciences/food_food-garden.jfif'
    },
    {
        id: 54,
        question: 'What kind of business might own this stall?',
        options: ['Butcher', 'Bakery', 'Fishmonger', 'Fruit vendor'],
        correctAnswer: 'Fruit vendor',
        imageUrl: '/activityImages/SocialSciences/food_fruits-stall.png'
    },
    {
        id: 55,
        question: 'What activity is commonly done in a garden?',
        options: ['Reading', 'Swimming', 'Playing sports', 'Planting'],
        correctAnswer: 'Planting',
        imageUrl: '/activityImages/SocialSciences/food_garden.jfif'
    },


];
  
export default socialSciencesPeopleAndAgriculture;
import React from 'react';

const CommunityCard = ({ community }) => {
  return (
    <div className="community-card">
      <a href={community.link} className="community-card-link">
        <img loading="lazy" src={community.imageSrc} alt={community.alt} className="community-card-image" />
        <div className="community-card-content">
          <h3 className="articles-card-title">{community.name}</h3>
        </div>
        <p className='articles-date'>
          {community.date}
        </p> 
      </a>
    </div>
  );
};

export default CommunityCard;

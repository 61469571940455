import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import AdPosts from '../bodyFiles/AdPosts'; // Import AdPosts component

Modal.setAppElement('#root');

const ExtraClassesPlatform = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [adminAccess, setAdminAccess] = useState(false);
    const [formData, setFormData] = useState({
        toolType: '',
        mediaArray: [{ fileName: '', fileLink: '' }],
        subject: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [tools, setTools] = useState([]);
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');

    const openModal = (videoUrl) => {
        setCurrentVideoUrl(videoUrl);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/check/api/check/users', {
                    withCredentials: true
                });
                const loggedInUser = response.data.user;
                setUser(loggedInUser);

                // Check user admin status
                if (loggedInUser.adminValidation && loggedInUser.status === 'Admin') {
                    setAdminAccess(true);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user:', error);
                if (error.response && error.response.status === 401) {
                    navigate('/login'); 
                } else if (error.response && error.response.status === 403) {
                    setError('Access forbidden');
                } else {
                    setError('Error fetching user');
                }
                setLoading(false);
            }
        };
    
        fetchUser();
    }, [navigate]);

    useEffect(() => {
        const fetchTools = async () => {
            try {
                const toolsResponse = await axios.get('https://lernacy.co.za/api/tools', {
                    withCredentials: true
                });
                setTools(toolsResponse.data);
                console.log('Fetched tools:', toolsResponse.data); // Debugging log
            } catch (error) {
                console.error('Error fetching tools:', error);
                setError('Error fetching tools');
            }
        };

        fetchTools();
    }, []);

    const handleFormChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleMediaArrayChange = (index, e) => {
        const newMediaArray = [...formData.mediaArray];
        newMediaArray[index][e.target.name] = e.target.value;
        setFormData({
            ...formData,
            mediaArray: newMediaArray
        });
    };

    const addMediaField = () => {
        setFormData({
            ...formData,
            mediaArray: [...formData.mediaArray, { fileName: '', fileLink: '' }]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });

            const { csrfToken } = await csrfResponse.json();

            await axios.post('https://lernacy.co.za/api/submit-tool', formData, {
                withCredentials: true,
                headers: {
                    'CSRF-Token': csrfToken
                }
            });

            setFormData({
                toolType: '',
                mediaArray: [{ fileName: '', fileLink: '' }],
                subject: ''
            });
            setSuccessMessage('Form submitted successfully!');

            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const groupedTools = tools.reduce((acc, tool) => {
        acc[tool.toolType] = acc[tool.toolType] || [];
        acc[tool.toolType].push(tool);
        return acc;
    }, {});

    return (
        <div className='page-colour-manager'>
            <div>
                <h1 className='activity-name-tag-h1'>Support Classes</h1>

                {adminAccess && (
                    <section className='submit-tool-post'>
                        <h2>Admin Tool Submission</h2>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="toolType">Tool Type</label><br />
                                <select 
                                    name="toolType" 
                                    value={formData.toolType} 
                                    onChange={handleFormChange}
                                    required
                                    className='add-more-media-select'
                                >
                                    <option value="">Select Tool Type</option>
                                    <option value="Video Streaming">Video Streaming</option>
                                    <option value="Google Drive">Google Drive</option>
                                    <option value="Facebook Links">Facebook Links</option>
                                    <option value="Other Platforms">Other Platforms</option>
                                </select>
                            </div>

                            <div className='add-more-media-subject'>
                                <label htmlFor="subject">Subject:</label><br />
                                <input 
                                    type="text" 
                                    name="subject" 
                                    value={formData.subject} 
                                    onChange={handleFormChange}
                                    required
                                />
                            </div>

                            {formData.mediaArray.map((media, index) => (
                                <div key={index} className='add-more-media-div'>
                                    <label htmlFor={`fileName-${index}`}>File Name</label><br />
                                    <input 
                                        type="text" 
                                        name="fileName" 
                                        value={media.fileName} 
                                        onChange={(e) => handleMediaArrayChange(index, e)}
                                        required
                                    /><br />

                                    <label htmlFor={`fileLink-${index}`}>File Link</label><br />
                                    <input 
                                        type="url" 
                                        name="fileLink" 
                                        value={media.fileLink} 
                                        onChange={(e) => handleMediaArrayChange(index, e)}
                                        required
                                    />
                                </div>
                            ))}

                            <div className='add-more-media-bottom-buttons'>
                                <button className='add-more-media' type="button" onClick={addMediaField}>
                                    Add More Media
                                </button>

                                <button className='add-more-media-submit' type="submit">Submit Tool</button>
                            </div>
                        </form>

                        {successMessage && <p className="success-message">{successMessage}</p>}
                    </section>
                )}
            </div>

            <h3>Available Tools</h3>

            {loading ? (
                <p>Loading...</p>
            ) : (
                Object.keys(groupedTools).length > 0 ? (
                    <div>
                        {groupedTools['Video Streaming'] && (
                            <div className='main-class-collection-container'> 
                                <h3>Video Streaming</h3>
                                <div className='collection-item-links-container'>
                                    {groupedTools['Video Streaming'].map((tool, index) => (
                                        <div key={index} className='collection-item-links'>
                                            <div>
                                                <h4>{tool.subject}</h4>
                                                {tool.mediaArray.map((media, idx) => (
                                                    <div key={idx}>
                                                        <a   
                                                            href="#" 
                                                            onClick={() => openModal(media.fileLink)} 
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <p className="stream-link-block">{media.fileName}</p>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                    <AdPosts />
                                </div>
                            </div>
                        )}

                        {['Google Drive', 'Facebook Links', 'Other Platforms'].map((type) => (
                            groupedTools[type] && (
                                <div className='main-class-collection-container' key={type}>
                                    <h3>{type}</h3>
                                    <div className='collection-item-links-container'>
                                        {groupedTools[type].map((tool, index) => (
                                            <div key={index} className='collection-item-links'>
                                                <h4>{tool.subject}</h4>
                                                {tool.mediaArray.map((media, idx) => (
                                                    <div key={idx}>
                                                        <a 
                                                            href={media.fileLink} 
                                                            target="_blank" 
                                                            rel="noopener noreferrer"
                                                        >
                                                            <p className="stream-link-block">{media.fileName}</p>
                                                        </a> 
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                ) : (
                    <p>No tools available.</p>
                )
            )}

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
                <h2>Video Streaming</h2>
                <button onClick={closeModal}>Close</button>
                <iframe 
                    width="560" 
                    height="315" 
                    src={currentVideoUrl} 
                    title="Video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen
                /> 
            </Modal>
        </div>
    );
};

export default ExtraClassesPlatform;





/*
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

 
Modal.setAppElement('#root');

const ExtraClassesPlatform = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');

    const openModal = (videoUrl) => {
        setCurrentVideoUrl(videoUrl);
        setModalIsOpen(true);
    };
    
    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        // Make a request to the server to get user information
        const fetchUser = async () => {
          try {
            const response = await fetch('https://lernacy.co.za/api/study', {
              method: 'GET',
              credentials: 'include', // Include credentials for cross-origin requests
            });
    
            if (response.ok) {
              const data = await response.json();
              setUser(data.user);
    
              // Check if user has an account name
              if (!data.user.accountName) {
                navigate('/login-second-stage');
              }
            } else {
              const errorData = await response.json();
              console.error('Error fetching user data:', errorData.message);
    
              // Redirect to login if not authenticated
              navigate('/login');
            }
          } catch (error) {
            console.error('Error fetching user data:', error.message);
    
            // Redirect to login on error
            navigate('/login');
          }
        };
    
        fetchUser();
      }, [navigate]); // Include navigate in the dependency array

    return (
        <div className='page-colour-manager'>
            {/*
            {user ? (
                <p>Welcome, {user.name} {user.surname}!</p>
                // Add other user information as needed
            ) : (
                <p>Loading user data...</p>
            )}

            <div>
                <h1 className='activity-name-tag-h1'>Study</h1>
                <div className='extra-class-section-blocks'>
                    
                    <section>
                        <h2>Video Streaming</h2>
                        <div className='video-streaming-link-box'>

                            <div className='main-class-collection-container'>
                                <h3>Accounting</h3>
                                <div className='main-class-collection-inner'>

                                    <div className='collection-item-links'>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/1o4p__MY_Ko?list=PLdo-ZvlPomxdz8k2aoCGZktVJMxCM0gfW')}>
                                            <p>Grade 12 Accounting | Introduction to The Subject by 123tutors</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/bVqBxvkzo9o?list=PLdo-ZvlPomxdz8k2aoCGZktVJMxCM0gfW')}> 
                                            <p>Grade 12 Accounting | Introduction to Companies (Part 1) by 123tutors</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/bFZP2mr6mX0?list=PLdo-ZvlPomxdz8k2aoCGZktVJMxCM0gfW')}> 
                                            <p>Grade 12 Accounting | Introduction to Companies (Part 2) by 123tutors</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='main-class-collection-container'>
                                <h3>Social Sciences</h3>
                                <div className='main-class-collection-inner'>

                                    <div className='collection-item-links'>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/oh6pP6EgU_k?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}>
                                            <p>Gr 9 SS History | Term 1 Lesson 1 | An introduction to WW2</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/BiBPIfqhf_w?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}> 
                                            <p>Gr9 History | Term 1 Lesson 3 | Causes of WW2 - Part 2 | The Rise of Hitler</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/ASeFL5u6HYw?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}> 
                                            <p>Gr 9 SS History | Term 2 Lesson 1| An introduction to the Cold War</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/rHZGrWbYUko?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}> 
                                            <p>Gr 9 SS History | Term 2 Lesson 2 | Foreknowledge</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/OKeGLVb431Q?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}> 
                                            <p>Gr 9 SS History | Term 2 Lesson 3 | End of WW2 in Europe</p>
                                        </div>
                                        <div className="stream-link-block" onClick={() => openModal('https://www.youtube.com/embed/OKeGLVb431Q?list=PLJqnChTMSBNJ-huk8_8nE0FVU6Tesb5wV')}> 
                                            <p>Gr 9 SS History | Term 2 Lesson 3 | End of WW2 in Europe</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>

                    
                    <section>
                        <h2>Google Drive</h2>
                        <div className="link-block">
                        <a href="https://drive.google.com" target="_blank" rel="noopener noreferrer">Google Drive Resource</a>
                        </div>
                    </section>

                    
                    <section>
                        <h2>Facebook Links</h2>
                        <div className="link-block">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook Group</a>
                        </div>
                    </section>

                    
                    <section>
                        <h2>Other Platforms</h2>
                        <div className="link-block">
                            <a href="https://example.com" target="_blank" rel="noopener noreferrer">Example Resource</a>
                        </div>
                    </section>

                    
                    <Modal 
                        isOpen={modalIsOpen} 
                        onRequestClose={closeModal} 
                        style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            maxWidth: '800px',
                            height: 'auto',
                        }
                        }}
                    >
                        <button onClick={closeModal}>Close</button>
                        <iframe
                        width="100%"
                        height="450"
                        src={currentVideoUrl}
                        title="YouTube video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        ></iframe>
                    </Modal>
                </div>
            </div>
            
        </div>
    ); 
};

export default ExtraClassesPlatform;
*/
// ResourceLink.js
import React from 'react';

const ResourceLink = ({ resource }) => {
  return (
    <li className='oer-resource-type-link'>
      <a  href={resource.link} target="_blank" rel="noopener noreferrer">
        <div className='oer-resource-type-link-anchor'>
          {resource.title}
        </div>
      </a>
    </li>
  );
};

export default ResourceLink;

import React, { useState, useEffect } from 'react';
import AccessDenied from '../../components/AccessDenied';

const GrOneNumericFusion = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [highScore, setHighScore] = useState(null);
    const [gameScore, setGameScore] = useState(0);
    const [rounds, setRounds] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [scoreRating, setScoreRating] = useState('');
    const [isGameOver, setIsGameOver] = useState(false);
    const gameName = 'Grade_1:_Numeric-Fusion_Maths_12';

    useEffect(() => {
        const fetchUserDetails = async () => {
          try {
            const response = await fetch('https://lernacy.co.za/api/study', {
              method: 'GET',
              credentials: 'include',
            });
      
            if (response.ok) {
              const data = await response.json();
              const user = data.user;
      
              if (!user || !user.accountName) {
                setIsAuthenticated(false);
                // Redirect to "/login-second-stage"
                window.location.href = "/login-second-stage";
                return;
              }
      
              setUserDetails(user);
              setIsAuthenticated(true);
            } else {
              throw new Error('Error fetching user details: ' + response.statusText);
            }
          } catch (error) {
            console.error(error.message);
            setIsAuthenticated(false);
          }
        };
      
        fetchUserDetails();
      }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchHighScore();
            generateRounds();
        }
    }, [isAuthenticated]);

    const fetchHighScore = async () => {
        try {
            const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setHighScore(data.highScore);
            } else {
                console.error('Error fetching high score:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching high score:', error.message);
        }
    };

    const generateRounds = () => {
        const newRounds = [];
        for (let i = 0; i < 12; i++) {
            const randomNumber = Math.floor(Math.random() * 15) + 1;
            const isHalving = Math.random() < 0.5;
            const halvingNumber = isHalving ? Math.floor(Math.random() * 17) * 2 : null;

            newRounds.push({
                number: isHalving ? halvingNumber : randomNumber,
                instruction: isHalving ? 'Halve' : 'Double',
                userGuess: '',
            });
        }
        setRounds(newRounds);
    };

    const handleUserChoice = (index, value) => {
        const updatedRounds = [...rounds];
        updatedRounds[index].userGuess = value;
        setRounds(updatedRounds);
    };

    const handleSubmit = async () => {
        let score = 0;
        const updatedRounds = rounds.map((round) => {
          const correctAnswer = round.instruction === 'Double' ? round.number * 2 : Math.floor(round.number / 2);
          const isCorrect = parseInt(round.userGuess) === correctAnswer;
          if (isCorrect) score++;
          return { ...round, correctAnswer, isCorrect };
        });
      
        setRounds(updatedRounds);
        setGameScore(score);
        setIsGameOver(true);
      
        const data = {
          userId: userDetails._id,
          score,
          timeOfDay: new Date().getHours(),
          gameName,
        };
      
        try {
          // Step 1: Fetch CSRF token
          const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
            method: 'GET',
            credentials: 'include',
          });
      
          const csrfData = await csrfResponse.json();
          const csrfToken = csrfData.csrfToken;
      
          if (!csrfResponse.ok) {
            throw new Error('Failed to fetch CSRF token');
          }
      
          // Step 2: Send a POST request with CSRF token
          const response = await fetch('https://lernacy.co.za/api/record-score', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'CSRF-Token': csrfToken, // Include CSRF token in headers
            },
            body: JSON.stringify(data),
            credentials: 'include', // Include credentials
          });
      
          if (response.ok) {
            //console.log('Score recorded successfully!');
          } else {
            console.error('Failed to record score:', response.statusText);
          }
        } catch (error) {
          console.error('Error recording score:', error.message);
        }
      
        const percentageScore = Math.round((score / 12) * 100);
        setScoreRating(getScoreRating(percentageScore));
    };

    const restartGame = async () => {
        try {
            setGameScore(0);
            setUserAnswers([]);
            setIsGameOver(false);
            generateRounds();

            // Fetch high score
            const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setHighScore(data.highScore);
            } else {
                console.error('Error fetching high score:', response.statusText);
            }
        } catch (error) {
            console.error('Error restarting game:', error.message);
        }
    };

    const getScoreRating = (percentageScore) => {
        if (percentageScore >= 80) {
            return 'Outstanding';
        } else if (percentageScore >= 60) {
            return 'Passed';
        } else if (percentageScore >= 40) {
            return 'Almost';
        } else {
            return 'Failed';
        }
    };

    const getComment = (scoreRating) => {
        switch(scoreRating) {
            case 'Outstanding':
                return 'Congratulations! You performed outstandingly.';
            case 'Passed':
                return 'Well done! You passed the counting game.';
            case 'Almost':
                return 'You are close to passing. Keep practicing!';
            case 'Failed':
                return 'Keep practicing. You can do better!';
            default:
                return '';
        }
    };

    return (
        <div className='tested-activity-wall-container'>
            {isAuthenticated === null ? (
                <p>Loading authentication status...</p>
            ) : !isAuthenticated ? (
                <AccessDenied />
            ) : (
                <div>
                    <h1 style={{ display: 'none' }}>Welcome, {userDetails ? `${userDetails.name} ${userDetails.surname}` : 'User'}!</h1>

                    {!isGameOver ? (
                        <div>
                            <h1 className='activity-name-tag-h1'>G1 - Numeric Fusion</h1>
                            <div className='round-high-score'>
                                <h2 className='activity-rounds-tag-h2'>12 Questions</h2>
                                <p>
                                    <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/12</strong>
                                </p>
                            </div>
                            <h3>Answer all questions and then submit:</h3>
                            <div className='equations-aligner-block-cover'>
                                {rounds.map((round, index) => (
                                    <div key={index} className='equations-block-box'>
                                        <p><strong>Question {index + 1}</strong></p>
                                        <p>Given Number: <strong>{round.number}</strong></p>
                                        <p>Instruction: <strong>{round.instruction}</strong></p>
                                        <input
                                            className='equations-block-box-input'
                                            
                                            value={round.userGuess}
                                            onChange={(e) => handleUserChoice(index, e.target.value)}
                                            placeholder={`Enter your ${round.instruction === 'Double' ? 'doubled' : 'halved'} guess`}
                                        /><br />
                                    </div>
                                ))}
                            </div>
                            <br/>
                            <button className='activity-submit-button' onClick={handleSubmit}>Submit Answers</button>
                        </div>
                    ) : (
                        <div>
                            <h1 className='activity-name-tag-h1'>Activity Over<br/>Your Score: {gameScore}/12</h1>

                            <div className='activity-game-over-data'>
                                <h2 className='activity-rounds-tag-h2-left'>Score Rating: {scoreRating}</h2>
                                <h3>{getComment(scoreRating)}</h3>
                                <p>Percentage Score: <strong>{Math.round((gameScore / 12) * 100)}%</strong></p>
                            </div>
                            
                            <div>
                                <h3 className='activity-rounds-tag-h2'>Question Records:</h3>
                                <div className='activity-over-rounds-evidence-ul'> 
                                    {rounds.map((round, index) => (
                                        <div key={index} className='activity-over-rounds-evidence'>
                                            <p>
                                                <strong>
                                                    Question {index + 1}
                                                </strong>
                                            </p>
                                            <p style={{margin:'0px', padding:'0px'}}>
                                                Number: <strong>{round.number}</strong> | <strong>{round.instruction}</strong>
                                            </p>
                                            {round.isCorrect ? (
                                                <p style={{ color: 'green', margin:'0px' }}>Your Guess: {round.userGuess} ✔</p>
                                            ) : (
                                                <p style={{ color: 'red', margin:'0px' }}>
                                                    Your Guess: {round.userGuess}{/* , Correct Answer: {round.correctAnswer}*/} ✘
                                                </p>
                                            )}
                                            {index < rounds.length - 1 && <br />} {/* Add divider if not the last answer */}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <hr />
                            <p>
                                <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/12</strong>
                            </p>

                            <div className='activity-restart-or-more'>
                                <button className='activity-restart-button' onClick={restartGame}>Play Again</button>
                                <button className='more-activity-button' onClick={() => window.location.href = "/Grade:1_Mathematics"}>Do More Activities</button>
                            </div>
                            <br/>

                        </div>
                    )}

                    

                    <br />

                </div>
            )}
        </div>
    );
};

export default GrOneNumericFusion;

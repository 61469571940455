import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PinValidation from './PinValidation';

const StoryBlock1 = ({ user }) => {
    const [answers, setAnswers] = useState({});
    const [schoolNameInput, setSchoolNameInput] = useState('');
    const [schoolNameSuggestions, setSchoolNameSuggestions] = useState([]);
    const [pinValidated, setPinValidated] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchSchoolNameSuggestions = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/institutions/schools/names');
                const { success, schoolNames } = response.data;
                if (success) {
                    setSchoolNameSuggestions(schoolNames);
                } else {
                    console.error('Failed to fetch school names:', response.data);
                }
            } catch (error) {
                console.error('Error fetching school names:', error);
            }
        };

        const fetchUserId = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/study', { withCredentials: true });
                const { user } = response.data;
                setUserId(user._id);
            } catch (error) {
                console.error('Error fetching user ID:', error);
            }
        };

        fetchSchoolNameSuggestions();
        fetchUserId();
    }, []);

    const handleChange = (e) => {
        setAnswers({
            ...answers,
            [e.target.name]: e.target.value
        });
    };

    const handleSchoolNameInputChange = (e) => {
        const { value } = e.target;
        setSchoolNameInput(value);

        const filteredSuggestions = schoolNameSuggestions.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setSchoolNameSuggestions(filteredSuggestions);
    };

    const validatePin = () => {
        // Additional logic for validation if needed
        return pinValidated;
    };

    const onSubmit = async () => {
        if (!validatePin()) {
            alert('PIN validation failed. Submission blocked.');
            return;
        }
    
        try {
            // Fetch CSRF token
            const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
            if (!csrfResponse.data.csrfToken) {
                throw new Error('Failed to fetch CSRF token');
            }
            const csrfToken = csrfResponse.data.csrfToken;
    
            const correctAnswers = {
                question1: 'C',
                question2: 'A',
                question3: 'B',
                question4: 'B',
                question5: 'B'
            };
    
            let score = 0;
            for (let key in correctAnswers) {
                if (answers[key] === correctAnswers[key]) {
                    score++;
                }
            }
    
            const submissionData = {
                score: score,
                taskBlock: 2,
                userId: userId,
                taskName: 'Comprehension-Levels',
                schoolName: schoolNameInput,
            };
    
            // console.log('Submission Data:', submissionData); // Log the data being passed to the route
    
            // POST request with CSRF token and credentials in headers
            const response = await axios.post('https://lernacy.co.za/api/ed-support/taskSubmit', submissionData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken // Include CSRF token in the header
                }
            });
    
            if (response.data.success) {
                alert('Task submitted successfully!');
                window.location.reload(); // Reload the page after successful submission
            } else {
                console.error('Failed to submit task:', response.data.error);
                alert('Failed to submit task. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting task:', error);
            alert('Error submitting task. Please check your network connection.');
        }
    };
    /*
    const onSubmit = async () => {
        if (!validatePin()) {
            alert('PIN validation failed. Submission blocked.');
            return;
        }

        const correctAnswers = {
            question1: 'C',
            question2: 'A',
            question3: 'B',
            question4: 'B',
            question5: 'B'
        };

        let score = 0;
        for (let key in correctAnswers) {
            if (answers[key] === correctAnswers[key]) {
                score++;
            }
        }

        const submissionData = {
            score: score,
            taskBlock: 1,
            userId: userId,
            taskName: 'Comprehension Levels',
            schoolName: schoolNameInput,
        };

        //console.log('Submission Data:', submissionData); // Log the data being passed to the route

        try {
            const response = await axios.post('https://lernacy.co.za/api/ed-support/taskSubmit', submissionData);
            if (response.data.success) {
                alert('Task submitted successfully!');
            } else {
                console.error('Failed to submit task:', response.data.error);
                alert('Failed to submit task. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting task:', error);
            alert('Error submitting task. Please check your network connection.');
        }
    };
    */

    return (
        <div className='story-wall-block'>
            <h2>Block 2</h2>
            <h3><strong>The Emerald Elephant</strong></h3>
            <div className='story-div-image-text'>
                <div className='story-image-cover-block'>
                    <img loading="lazy" src='/activityImages/ed-support/the-emerald-elephant.webp' alt='The Emerald Elephant' />
                </div>
                <div> 
                    <p className='story-p-tag'>
                        In the heart of Mpumalanga, amidst the lush greenery of the Blyde River Canyon, lived 
                        a young boy named Benjamin. Benjamin was known for his keen interest in the wildlife 
                        that surrounded his village, especially the majestic elephants that roamed the nearby 
                        forests.
                    </p> 
                    <p className='story-p-tag'>
                        One breezy morning, while exploring a hidden trail with his trusty binoculars in hand, 
                        Benjamin stumbled upon a rare sight - an elephant unlike any he had seen before. Its 
                        tusks gleamed in the sunlight, but what caught Benjamin's eye was its shimmering 
                        emerald-green hide, as if it were adorned with nature's own jewelry.
                    </p>
                    <p className='story-p-tag'>
                        Intrigued and excited, Benjamin cautiously approached the emerald elephant. To his 
                        surprise, the elephant greeted him with a gentle rumble, almost as if it recognized 
                        him. "Hello there," Benjamin whispered, marveling at the creature's magnificence.
                    </p>
                    <p className='story-p-tag'>
                        The emerald elephant, named Umoya by the villagers, was said to possess mystical powers 
                        that connected it to the spirit of the land. Legend had it that those who approached 
                        Umoya with a kind heart and pure intentions would be granted wisdom beyond their years.
                    </p>
                    <p className='story-p-tag'>
                        From that day on, Benjamin visited Umoya regularly, sitting beneath the shade of 
                        ancient baobab trees and listening to the elephant's silent teachings. He learned 
                        about the delicate balance of nature, the importance of conservation, and the ancient 
                        stories passed down through generations.
                    </p>
                    <p className='story-p-tag'>
                        Years passed, and Benjamin grew into a young man revered for his wisdom and compassion. 
                        He became known as the guardian of Umoya and dedicated his life to protecting the 
                        wildlife and preserving the natural beauty of Mpumalanga.
                    </p>
                </div>
            </div>

            <hr />

            <div className='comprehension-questions'>
                <h3>Comprehension Questions</h3>
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <p>1. What unique feature did the emerald elephant have?</p>
                        <label>
                            <input type="radio" name="question1" value="A" onChange={handleChange} />
                            A) Sapphire tusks
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="B" onChange={handleChange} />
                            B) Ruby eyes
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="C" onChange={handleChange} />
                            C) Emerald-green hide
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="D" onChange={handleChange} />
                            D) Diamond hooves
                        </label>
                    </div>

                    <div>
                        <p>2. Where did Benjamin live?</p>
                        <label>
                            <input type="radio" name="question2" value="A" onChange={handleChange} />
                            A) Blyde River Canyon
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="B" onChange={handleChange} />
                            B) Okavango Delta
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="C" onChange={handleChange} />
                            C) Amazon Rainforest
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="D" onChange={handleChange} />
                            D) Rocky Mountains
                        </label>
                    </div>

                    <div>
                        <p>3. What was the emerald elephant's name?</p>
                        <label>
                            <input type="radio" name="question3" value="A" onChange={handleChange} />
                            A) Umkhosi
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="B" onChange={handleChange} />
                            B) Umoya
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="C" onChange={handleChange} />
                            C) Umdoni
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="D" onChange={handleChange} />
                            D) Umlilo
                        </label>
                    </div>

                    <div>
                        <p>4. What did Benjamin learn from Umoya?</p>
                        <label>
                            <input type="radio" name="question4" value="A" onChange={handleChange} />
                            A) How to swim in the river
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="B" onChange={handleChange} />
                            B) Stories of his ancestors
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="C" onChange={handleChange} />
                            C) How to climb trees
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="D" onChange={handleChange} />
                            D) How to build a house
                        </label>
                    </div>

                    <div>
                        <p>5. What did Benjamin become known as in his village</p>
                        <label>
                            <input type="radio" name="question5" value="A" onChange={handleChange} />
                            A) The adventurer
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="B" onChange={handleChange} />
                            B) The guardian of Umoya
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="C" onChange={handleChange} />
                            C) The healer
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="D" onChange={handleChange} />
                            D) The chief
                        </label>
                    </div>
                </form>
            </div>

            <hr />

            <div className='school-name-pin-block'>
                <h3>School Information</h3>
                <label htmlFor="schoolNameInput">School Name:</label><br />
                <input
                    type="text"
                    id="schoolNameInput"
                    name="schoolNameInput"
                    value={schoolNameInput}
                    onChange={handleSchoolNameInputChange}
                    list="schoolNameSuggestions"
                    required
                    className='tasks-school-name-input'
                /><br />
                <datalist id="schoolNameSuggestions">
                    {schoolNameSuggestions.map((schoolName, index) => (
                        <option key={index} value={schoolName} />
                    ))}
                </datalist>
                {!pinValidated && (
                    <PinValidation
                        schoolNameInput={schoolNameInput}
                        onSubmit={() => setPinValidated(true)}
                    />
                )}
                {pinValidated && (
                    <button className='activity-submit-button' type="button" onClick={onSubmit}>
                        Submit Task 
                    </button>
                )}
            </div>
        </div>
    );
};

export default StoryBlock1;
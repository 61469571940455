// components/ReadMore.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ReadMore = () => {
  return (
    <div >
        <h3>Read More Aricles:</h3>
        < Link className='read-more-link' to="/articles-list/article-one-shaping-the-future-of-south-africa-through-our-educational-app">
            <p>
                Shaping South Africa's Future: Our Educational App for a New Era
            </p>
        </Link>
        < Link className='read-more-link' to="/articles-list/Subjects-and-Activities-in-Our-Educational-App">
            <p>
                Subjects and Activities in Our Educational App
            </p>
        </Link>
    </div>
  );
};

export default ReadMore; 
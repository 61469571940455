import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PinValidation from './PinValidation';

const StoryBlock1 = ({ user }) => {
    const [answers, setAnswers] = useState({});
    const [schoolNameInput, setSchoolNameInput] = useState('');
    const [schoolNameSuggestions, setSchoolNameSuggestions] = useState([]);
    const [pinValidated, setPinValidated] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchSchoolNameSuggestions = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/institutions/schools/names');
                const { success, schoolNames } = response.data;
                if (success) {
                    setSchoolNameSuggestions(schoolNames);
                } else {
                    console.error('Failed to fetch school names:', response.data);
                }
            } catch (error) {
                console.error('Error fetching school names:', error);
            }
        };

        const fetchUserId = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/study', { withCredentials: true });
                const { user } = response.data;
                setUserId(user._id);
            } catch (error) {
                console.error('Error fetching user ID:', error);
            }
        };

        fetchSchoolNameSuggestions();
        fetchUserId();
    }, []);

    const handleChange = (e) => {
        setAnswers({
            ...answers,
            [e.target.name]: e.target.value
        });
    };

    const handleSchoolNameInputChange = (e) => {
        const { value } = e.target;
        setSchoolNameInput(value);

        const filteredSuggestions = schoolNameSuggestions.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setSchoolNameSuggestions(filteredSuggestions);
    };

    const validatePin = () => {
        // Additional logic for validation if needed
        return pinValidated;
    };

    const onSubmit = async () => {
        if (!validatePin()) {
            alert('PIN validation failed. Submission blocked.');
            return;
        }
    
        try {
            // Fetch CSRF token
            const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
            if (!csrfResponse.data.csrfToken) {
                throw new Error('Failed to fetch CSRF token');
            }
            const csrfToken = csrfResponse.data.csrfToken;
    
            const correctAnswers = {
                question1: 'B',
                question2: 'C',
                question3: 'C',
                question4: 'C',
                question5: 'C',
                question6: 'B',
                question7: 'C',
                question8: 'D',
                question9: 'C',
                question10: 'C',
                
            };
    
            let score = 0;
            for (let key in correctAnswers) {
                if (answers[key] === correctAnswers[key]) {
                    score++;
                }
            }
    
            const submissionData = {
                score: score,
                taskBlock: 7,
                userId: userId,
                taskName: 'Comprehension-Levels',
                schoolName: schoolNameInput,
            };
    
            // console.log('Submission Data:', submissionData); // Log the data being passed to the route
    
            // POST request with CSRF token and credentials in headers
            const response = await axios.post('https://lernacy.co.za/api/ed-support/taskSubmit', submissionData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken // Include CSRF token in the header
                }
            });
    
            if (response.data.success) {
                alert('Task submitted successfully!');
                window.location.reload(); // Reload the page after successful submission
            } else {
                console.error('Failed to submit task:', response.data.error);
                alert('Failed to submit task. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting task:', error);
            alert('Error submitting task. Please check your network connection.');
        }
    };

    return (
        <div className='story-wall-block'>
            <h2>Block 7</h2>
            <h3><strong>Whispers of Hope</strong></h3>
            <div className='story-div-image-text'>
                <div className='story-image-cover-block'>
                    <img loading="lazy" src='/activityImages/ed-support/whispers-of-hope.jfif' alt='Whispers of Hope' />
                </div>
                <div> 
                    <p className='story-p-tag'> 
                        In the heart of Mpumalanga's rural landscape, nestled among rolling hills and whispering 
                        winds, lived a determined young girl named Maria. She belonged to a small village where 
                        life was simple yet challenging. Maria's family struggled with poverty, living in a modest 
                        hut made of mud and thatch. Despite the hardships, Maria dreamt of becoming a nurse, 
                        inspired by the village nurse who cared for everyone with compassion and skill.
                    </p> 
                    <p className='story-p-tag'>
                        From a young age, Maria showed exceptional academic potential. She walked several 
                        kilometers each day to the village school, a humble building with worn-out desks and few 
                        resources. Despite the challenges, Maria excelled in her studies, fueled by her 
                        determination to uplift her family from poverty and make a difference in her community.
                    </p>
                    <p className='story-p-tag'>
                        Maria's journey was not easy. There were days when the family struggled to put food on 
                        the table, and nights when the cold wind pierced through their thin blankets. Yet, 
                        Maria remained resilient, her dreams of becoming a nurse shining like a beacon of hope 
                        in the darkness.
                    </p>
                    <p className='story-p-tag'>
                        One day, a visiting teacher from a nearby town noticed Maria's potential. Impressed by 
                        her dedication and intelligence, the teacher offered to tutor Maria after school. This 
                        extra help proved invaluable, filling the gaps left by the village school's limited 
                        resources. Maria soaked up every bit of knowledge like a sponge, determined to overcome 
                        any obstacle in her path.
                    </p>
                    <p className='story-p-tag'>
                        As Maria approached her final year of high school, she faced a new challenge: the cost 
                        of further education. Her family could barely afford daily necessities, let alone the 
                        fees for nursing school. Undeterred, Maria applied for scholarships and bursaries, 
                        pouring her heart into every application. Her hard work paid off when she received a 
                        full scholarship to a prestigious nursing college in a neighboring province.
                    </p>
                    <p className='story-p-tag'>
                        Leaving her village was bittersweet for Maria. She hugged her parents tightly, 
                        promising to return one day as Nurse Maria to serve her community with pride. At 
                        nursing college, Maria faced a rigorous curriculum and new experiences far from the 
                        comfort of her village. Yet, she thrived, earning top grades and gaining practical 
                        skills that would one day save lives.
                    </p>
                    <p className='story-p-tag'>
                        During her training, Maria encountered moments of doubt and exhaustion. There were 
                        times when she missed her family terribly and wondered if she could continue. But 
                        whenever doubt crept in, she remembered her humble beginnings, the faces of her village 
                        neighbors, and the unwavering support of her family. Their belief in her fueled her 
                        determination to succeed.
                    </p>
                    <p className='story-p-tag'>
                        After years of hard work and perseverance, Maria graduated as a registered nurse. Her 
                        graduation ceremony was a celebration of not just her achievements, but of hope 
                        triumphing over adversity. As she stood on stage, holding her diploma, Maria dedicated 
                        her success to her family, teachers, and the community that had shaped her journey.
                    </p>
                    <p className='story-p-tag'>
                        Today, Nurse Maria works tirelessly in a bustling hospital, caring for patients with 
                        the same compassion she witnessed in the village nurse long ago. She visits her village 
                        whenever she can, inspiring young children with dreams as big as the African sky. 
                        Maria's story is a testament to the power of perseverance, reminding us all that 
                        dreams, no matter how distant, can be achieved with determination and resilience.
                    </p>
                </div>
            </div>

            <hr />

            <div className='comprehension-questions'>
                <h3>Comprehension Questions</h3>
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <p>1. Where did Maria live in the story?</p>
                        <label>
                            <input type="radio" name="question1" value="A" onChange={handleChange} />
                            A) Johannesburg
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="B" onChange={handleChange} />
                            B) Mpumalanga
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="C" onChange={handleChange} />
                            C) Gauteng
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="D" onChange={handleChange} />
                            D) Durban
                        </label>
                    </div>

                    <div>
                        <p>2. What was Maria's dream career?</p>
                        <label>
                            <input type="radio" name="question2" value="A" onChange={handleChange} />
                            A) Teacher
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="B" onChange={handleChange} />
                            B) Engineer
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="C" onChange={handleChange} />
                            C) Nurse
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="D" onChange={handleChange} />
                            D) Farmer
                        </label>
                    </div>

                    <div>
                        <p>3. How did Maria travel to school every day?</p>
                        <label>
                            <input type="radio" name="question3" value="A" onChange={handleChange} />
                            A) By bus
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="B" onChange={handleChange} />
                            B) By car
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="C" onChange={handleChange} />
                            C) By walking several kilometers
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="D" onChange={handleChange} />
                            D) By bicycle
                        </label>
                    </div>

                    <div>
                        <p>4. What inspired Maria to pursue her dream career?</p>
                        <label>
                            <input type="radio" name="question4" value="A" onChange={handleChange} />
                            A) A visiting doctor
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="B" onChange={handleChange} />
                            B) A village teacher
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="C" onChange={handleChange} />
                            C) A nurse in her village
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="D" onChange={handleChange} />
                            D) Her best friend
                        </label>
                    </div>

                    <div>
                        <p>5. What challenge did Maria face in her final year of high school?</p>
                        <label>
                            <input type="radio" name="question5" value="A" onChange={handleChange} />
                            A) Finding a job
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="B" onChange={handleChange} />
                            B) Moving to a new village
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="C" onChange={handleChange} />
                            C) Paying for nursing school
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="D" onChange={handleChange} />
                            D) Passing her exams
                        </label>
                    </div>

                    <div>
                        <p>6. How did Maria overcome the financial challenge of nursing school?</p>
                        <label>
                            <input type="radio" name="question6" value="A" onChange={handleChange} />
                            A) She took out a loan
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="B" onChange={handleChange} />
                            B) She received a full scholarship
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="C" onChange={handleChange} />
                            C) Her parents paid for it
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="D" onChange={handleChange} />
                            D) She worked multiple jobs
                        </label>
                    </div>

                    <div>
                        <p>7. What was Maria's reaction when she received the scholarship?</p>
                        <label>
                            <input type="radio" name="question7" value="A" onChange={handleChange} />
                            A) She didn't want to go to nursing school
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="B" onChange={handleChange} />
                            B) She was disappointed
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="C" onChange={handleChange} />
                            C) She was relieved and grateful
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="D" onChange={handleChange} />
                            D) She decided to study medicine instead
                        </label>
                    </div>

                    <div>
                        <p>8. Where did Maria go for her nursing college education?</p>
                        <label>
                            <input type="radio" name="question8" value="A" onChange={handleChange} />
                            A) In her village
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="B" onChange={handleChange} />
                            B) In Johannesburg
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="C" onChange={handleChange} />
                            C) In Durban
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="D" onChange={handleChange} />
                            D) In a neighboring province
                        </label>
                    </div>

                    <div>
                        <p>9. What motivated Maria to continue during tough times at nursing college?</p>
                        <label>
                            <input type="radio" name="question9" value="A" onChange={handleChange} />
                            A) Her desire to travel
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="B" onChange={handleChange} />
                            B) Her love for the city
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="C" onChange={handleChange} />
                            C) Her family's support and her humble beginnings
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="D" onChange={handleChange} />
                            D) Her dream of becoming a teacher
                        </label>
                    </div>

                    <div>
                        <p>10. What does Maria's story teach us about?</p>
                        <label>
                            <input type="radio" name="question10" value="A" onChange={handleChange} />
                            A)  The challenges of living in a village
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="B" onChange={handleChange} />
                            B) The importance of friendships
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="C" onChange={handleChange} />
                            C) The power of perseverance and overcoming adversity
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="D" onChange={handleChange} />
                            D) The beauty of living in a bustling city
                        </label>
                    </div>

                </form>
            </div>

            <hr />

            <div className='school-name-pin-block'>
                <h3>School Information</h3>
                <label htmlFor="schoolNameInput">School Name:</label><br />
                <input
                    type="text"
                    id="schoolNameInput"
                    name="schoolNameInput"
                    value={schoolNameInput}
                    onChange={handleSchoolNameInputChange}
                    list="schoolNameSuggestions"
                    required
                    className='tasks-school-name-input'
                /><br />
                <datalist id="schoolNameSuggestions">
                    {schoolNameSuggestions.map((schoolName, index) => (
                        <option key={index} value={schoolName} />
                    ))}
                </datalist>
                {!pinValidated && (
                    <PinValidation
                        schoolNameInput={schoolNameInput}
                        onSubmit={() => setPinValidated(true)}
                    />
                )}
                {pinValidated && (
                    <button className='activity-submit-button' type="button" onClick={onSubmit}>
                        Submit Task 
                    </button>
                )}
            </div>
        </div>
    );
};

export default StoryBlock1;
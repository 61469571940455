import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AccessDenied from '../components/AccessDenied';
import Copyright from '../components/Copyright';
import AdPosts from '../bodyFiles/AdPosts'; 
import InstitutionsTour from '../components/TourGuide/InstitutionsTour';

const InstitutionsPage = () => {
  const [institutions, setInstitutions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [subscribedInstitutions, setSubscribedInstitutions] = useState([]);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchInstitutions = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/all-institutions', { withCredentials: true });
        if (response.status === 200) {
          setInstitutions(response.data.institutions);
          const subscribedIds = response.data.institutions
            .filter(institution => institution.isSubscribed)
            .map(institution => institution._id);
          setSubscribedInstitutions(subscribedIds);
        } else if (response.status === 401) {
          setIsAuthenticated(false);
          setError('Unauthorized access');
        } else if (response.status === 403) {
          setIsAuthenticated(false);
          setError('Forbidden access');
        }
      } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          setIsAuthenticated(false);
          setError(error.response.data.message);
        } else {
          setError('Failed to fetch institutions');
        }
      } finally {
        setIsLoading(false);
      }
    };

    const fetchUser = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/study', { withCredentials: true });
        if (response.data.success) {
          setUser(response.data.user);
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    fetchInstitutions();
    fetchUser();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSubscribe = async (institutionId) => {
    if (user) {
      try {
        // Set the loading state for the current institution
        setLoadingSubscriptions(prev => [...prev, institutionId]);
  
        // Fetch CSRF token
        const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
        if (!csrfResponse.data.csrfToken) {
          throw new Error('Failed to fetch CSRF token');
        }
        const csrfToken = csrfResponse.data.csrfToken;
  
        // Make POST request with CSRF token in headers
        if (subscribedInstitutions.includes(institutionId)) {
          await axios.post(
            'https://lernacy.co.za/api/unsubscribe',
            { institutionId },
            { withCredentials: true, headers: { 'CSRF-Token': csrfToken } }
          );
          setSubscribedInstitutions(prev => prev.filter(id => id !== institutionId)); // Remove from subscribed
        } else {
          await axios.post(
            'https://lernacy.co.za/api/subscribe',
            { institutionId },
            { withCredentials: true, headers: { 'CSRF-Token': csrfToken } }
          );
          setSubscribedInstitutions(prev => [...prev, institutionId]); // Add to subscribed
        }
      } catch (error) {
        console.error('Failed to update subscription:', error);
      } finally {
        // Remove the loading state for the current institution
        setLoadingSubscriptions(prev => prev.filter(id => id !== institutionId));
      }
    }
  };

  // Filter and map institutions, handling null or undefined institutions
  const filteredInstitutions = institutions.filter((institution) => {
    if (!institution) return false;
  
    const query = searchQuery.toLowerCase();
    return (
      (institution.schoolName && institution.schoolName.toLowerCase().includes(query)) ||
      (institution.accountName && institution.accountName.toLowerCase().includes(query)) ||
      (institution.email && institution.email.toLowerCase().includes(query)) || 
      (institution.province && institution.province.toLowerCase().includes(query)) ||
      (institution.emisNumber && institution.emisNumber.toLowerCase().includes(query))
    );
  });

  // Render loading, access denied, or error components based on conditions
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <AccessDenied />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Render the institutions list
  return (
    <div className='page-space-manager'>
      <InstitutionsTour />
      <div className='institutions-list-container'>
        <h1 className='activity-name-tag-h1'>Institutions</h1>
        <input
          type="text"
          placeholder="Search institutions"
          value={searchQuery}
          onChange={handleSearchChange}
          className='search-field-for-institutions'
        />
        <br /><br />
        <div className='institutions-list'>
          {filteredInstitutions.map((institution, index) => (
            <React.Fragment key={institution._id}>
              <div className='institution-card'>
                <div>
                  <Link to={`/institution/${institution.decoyId}`} className='institution-card-Link-tag'>
                    <div className='institution-card-Link-tag-img-container'>
                      <img
                        loading="lazy"
                        src={institution.profilePicture}
                        alt="Profile" 
                        className='institution-card-Link-tag-img'
                      />
                    </div>
                    <div>
                      <h2>{institution.schoolName}</h2>
                      <p>@{institution.accountName}</p>
                      <p><strong>Email:</strong><br />{institution.email}</p>
                      <p><strong>Province:</strong><br /> {institution.province}</p>
                      <p><strong>Emis Number:</strong> <br />{institution.emisNumber}</p>
                      <p><strong>Official {institution.role} Account</strong></p>
                      {institution.isSameUser && <p className='managed-by-you'>This institution is managed by you</p>}
                    </div>
                  </Link>
                </div>
                {!institution.isSameUser && (
                  <div className='institution-card-buttons'>
                    <button 
                      className='institution-card-subscribe' 
                      onClick={() => handleSubscribe(institution._id)}
                      disabled={loadingSubscriptions.includes(institution._id)}
                    >
                      {loadingSubscriptions.includes(institution._id)
                        ? 'Loading...'
                        : subscribedInstitutions.includes(institution._id)
                        ? 'Unsubscribe'
                        : 'Subscribe'} 
                    </button>

                    <button 
                      className='institution-card-email' 
                      onClick={() => window.location.href = `mailto:${institution.email}`}
                    >
                      Email
                    </button>
                  </div>
                )}
              </div>
              {/* Render AdPosts after every 2 institutions */}
              {index % 2 === 1 && <AdPosts />}
            </React.Fragment>
          ))}
        </div>
      </div>
      <br />
    </div>
  );
};

export default InstitutionsPage;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PinValidation from './PinValidation';

const StoryBlock1 = ({ user }) => {
    const [answers, setAnswers] = useState({});
    const [schoolNameInput, setSchoolNameInput] = useState('');
    const [schoolNameSuggestions, setSchoolNameSuggestions] = useState([]);
    const [pinValidated, setPinValidated] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchSchoolNameSuggestions = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/institutions/schools/names');
                const { success, schoolNames } = response.data;
                if (success) {
                    setSchoolNameSuggestions(schoolNames);
                } else {
                    console.error('Failed to fetch school names:', response.data);
                }
            } catch (error) {
                console.error('Error fetching school names:', error);
            }
        };

        const fetchUserId = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/study', { withCredentials: true });
                const { user } = response.data;
                setUserId(user._id);
            } catch (error) {
                console.error('Error fetching user ID:', error);
            }
        };

        fetchSchoolNameSuggestions();
        fetchUserId();
    }, []);

    const handleChange = (e) => {
        setAnswers({
            ...answers,
            [e.target.name]: e.target.value
        });
    };

    const handleSchoolNameInputChange = (e) => {
        const { value } = e.target;
        setSchoolNameInput(value);

        const filteredSuggestions = schoolNameSuggestions.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setSchoolNameSuggestions(filteredSuggestions);
    };

    const validatePin = () => {
        // Additional logic for validation if needed
        return pinValidated;
    };

    const onSubmit = async () => {
        if (!validatePin()) {
            alert('PIN validation failed. Submission blocked.');
            return;
        }
    
        try {
            // Fetch CSRF token
            const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
            if (!csrfResponse.data.csrfToken) {
                throw new Error('Failed to fetch CSRF token');
            }
            const csrfToken = csrfResponse.data.csrfToken;
    
            const correctAnswers = {
                question1: 'B',
                question2: 'C',
                question3: 'D',
                question4: 'D',
                question5: 'C',
                question6: 'C',
                question7: 'B',
                question8: 'D',
                question9: 'C',
                question10: 'C',
                
            };
    
            let score = 0;
            for (let key in correctAnswers) {
                if (answers[key] === correctAnswers[key]) {
                    score++;
                }
            }
    
            const submissionData = {
                score: score,
                taskBlock: 7,
                userId: userId,
                taskName: 'Comprehension-Levels',
                schoolName: schoolNameInput,
            };
    
            // console.log('Submission Data:', submissionData); // Log the data being passed to the route
    
            // POST request with CSRF token and credentials in headers
            const response = await axios.post('https://lernacy.co.za/api/ed-support/taskSubmit', submissionData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken // Include CSRF token in the header
                }
            });
    
            if (response.data.success) {
                alert('Task submitted successfully!');
                window.location.reload(); // Reload the page after successful submission
            } else {
                console.error('Failed to submit task:', response.data.error);
                alert('Failed to submit task. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting task:', error);
            alert('Error submitting task. Please check your network connection.');
        }
    };

    return (
        <div className='story-wall-block'>
            <h2>You have completed the activities!!!</h2>
            <h4 style={{textAlign:'center'}}><strong>More activities will be available soon.</strong></h4>
        </div>
    );
};

export default StoryBlock1;
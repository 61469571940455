import React from 'react';

const Equation = ({ equation1, equation2 }) => {
  return (
    <div>
      {/* Render equation2 only */}
      <p>{equation2}</p>
    </div>
  );
};

export default Equation;

import React, { useState, useEffect } from 'react';
import AccessDenied from '../../components/AccessDenied';

const GrOneSizeShowdown = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [highScore, setHighScore] = useState(null);
  const [gameScore, setGameScore] = useState(0);
  const [symbolPairs, setSymbolPairs] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [userChoices, setUserChoices] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [roundRecords, setRoundRecords] = useState([]);
  const [percentageScore, setPercentageScore] = useState(0);
  const [scoreRating, setScoreRating] = useState('');
  const gameName = 'Grade_1:_Size-Showdown_Maths_10';

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });
  
        if (response.ok) {
          const data = await response.json();
          const user = data.user;
  
          if (!user || !user.accountName) {
            setIsAuthenticated(false);
            // Redirect to "/login-second-stage"
            window.location.href = "/login-second-stage";
            return;
          }
  
          setUserDetails(user);
          setIsAuthenticated(true);
        } else {
          throw new Error('Error fetching user details: ' + response.statusText);
        }
      } catch (error) {
        console.error(error.message);
        setIsAuthenticated(false);
      }
    };
  
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchHighScore();
    }
  }, [isAuthenticated]);

  const fetchHighScore = async () => {
    try {
      const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        console.error('Error fetching high score:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching high score:', error.message);
    }
  };

  const generateSymbolPairs = () => {
    const pairs = [];
    const instructionsList = [];

    for (let i = 0; i < 10; i++) {
      const symbol1 = getRandomCircle();
      const symbol2 = getRandomCircle();
      pairs.push([symbol1, symbol2]);
      instructionsList.push(Math.random() < 0.5 ? 'Bigger' : 'Smaller');
    }

    setSymbolPairs(pairs);
    setInstructions(instructionsList);
    setUserChoices(new Array(10).fill(null));
  };

  const getRandomCircle = () => {
    // Generate a random circle with a random size
    const size = Math.floor(Math.random() * 50) + 30; // Random size between 30 and 80 pixels
    return (
      <div
        key={Math.random()}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: '50%',
          backgroundColor: 'black', // You can change the color here if needed
          margin: '10px', // Add margin for spacing
        }}
      ></div>
    );
  };

  const handleUserChoice = (roundIndex, chosenSymbol) => {
    setUserChoices((prevChoices) => {
      const newChoices = [...prevChoices];
      newChoices[roundIndex] = chosenSymbol;
      return newChoices;
    });
  };

  const handleSubmitAllAnswers = async () => {
    let score = 0;
    const records = symbolPairs.map((pair, index) => {
      const symbol1Size = parseInt(pair[0].props.style.width.replace('px', ''));
      const symbol2Size = parseInt(pair[1].props.style.width.replace('px', ''));
      const instruction = instructions[index];
      const chosenSymbol = userChoices[index];
  
      // Determine correct answer
      let correctAnswer;
      if (instruction === 'Bigger') {
        correctAnswer = symbol1Size > symbol2Size ? 'B' : 'S';
      } else {
        correctAnswer = symbol1Size < symbol2Size ? 'B' : 'S';
      }
  
      const isCorrect = chosenSymbol === correctAnswer;
      if (isCorrect) score++;
  
      return {
        roundNumber: index + 1,
        instruction: instruction,
        circleSizes: [symbol1Size, symbol2Size],
        userInput: chosenSymbol,
        isCorrect: isCorrect,
      };
    });
  
    setGameScore(score);
    setRoundRecords(records);
    setGameOver(true);
  
    const percentage = Math.round((score / 10) * 100);
    setPercentageScore(percentage);
  
    // Determine score rating
    let rating;
    if (percentage >= 80) {
      rating = 'Outstanding';
    } else if (percentage >= 60) {
      rating = 'Passed';
    } else if (percentage >= 40) {
      rating = 'Almost';
    } else {
      rating = 'Failed';
    }
    setScoreRating(rating);
  
    try {
      // Step 1: Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      // Step 2: Send a POST request with CSRF token
      const data = {
        userId: userDetails?._id,
        score: score,
        timeOfDay: new Date().getHours(),
        gameName: gameName,
      };
  
      const response = await fetch('https://lernacy.co.za/api/record-score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        body: JSON.stringify(data),
        credentials: 'include', // Include credentials
      });
  
      if (response.ok) {
        console.log('Score recorded successfully.');
      } else {
        console.error('Error recording score:', response.statusText);
      }
    } catch (error) {
      console.error('Error recording score:', error.message);
    }
  };

  const getSymbolForResult = (isCorrect) => {
    return isCorrect ? <span style={{ color: 'green' }}>✔</span> : <span style={{ color: 'red' }}>✘</span>;
  };

  const restartGame = async () => {
    setGameScore(0);
    setGameOver(false);
    setRoundRecords([]);
    generateSymbolPairs();

    try {
        // Fetch high score
        const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
            method: 'GET',
            credentials: 'include',
        });

        if (response.ok) {
            const data = await response.json();
            setHighScore(data.highScore);
        } else {
            console.error('Error fetching high score:', response.statusText);
        }
    } catch (error) {
        console.error('Error fetching high score:', error.message);
    }
  };

  useEffect(() => {
    generateSymbolPairs();
  }, []);

  return (
    <div className='tested-activity-wall-container'>
      {isAuthenticated === null ? (
        <p>Loading authentication status...</p>
      ) : !isAuthenticated ? (
        <AccessDenied />
      ) : (
        <div>
          
          {gameOver ? (
            <div>
              <h1 className='activity-name-tag-h1'>Activity Over<br/>Your Score: {gameScore}/10</h1>

              <div className='activity-game-over-data'>
                <h2>Score Rating: {scoreRating}</h2>
                <h3>{getComment(scoreRating)}</h3>
                <p>Percentage: <strong>{percentageScore}%</strong></p>
              </div>
              
              <h3 className='activity-rounds-tag-h2'>Question Records:</h3>
              <div className='activity-over-rounds-evidence-ul'>
                {roundRecords.map(record => (
                  <div className='activity-over-rounds-evidence' key={record.roundNumber}>
                    <p>
                      <strong>Question {record.roundNumber}</strong>
                      <br />
                      Instruction: <strong>{record.instruction}</strong>
                      <br />
                      Circle Sizes - {record.circleSizes.join(', ')}
                      <br />
                      User's Input: <strong>{record.userInput} {getSymbolForResult(record.isCorrect)}</strong>
                    </p>
                  </div>
                ))}
              </div>
              <hr />
              <p>
                <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/10</strong>
              </p>

              <div className='activity-restart-or-more'>
                <button className='activity-restart-button' onClick={restartGame}>Play Again</button>
                <button className='more-activity-button' onClick={() => window.location.href = "/Grade:3_Mathematics"}>Do More Activities</button>
              </div>
              <br/>

            </div>
          ) : (
            <div>
              <h1 className='activity-name-tag-h1'>G1 - Size Showdown</h1>
              <div className='round-high-score'>
                <h2 className='activity-rounds-tag-h2'>10 Questions</h2>
                <p>
                  <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/15</strong>
                </p>
              </div>
              <h3>Answer all rounds below:</h3>

              <div className='equations-aligner-block-cover'>
                {symbolPairs.map((pair, index) => (
                  <div key={index} className='equations-block-box'>
                    <p><strong>Round {index + 1}</strong></p>
                    <p>Instruction:<br/> Choose the <strong>{instructions[index] === 'Bigger' ? 'bigger' : 'smaller'}</strong> circle</p>
                    <div>
                      {pair.map((symbol, symbolIndex) => (
                        <button
                          className='size-showdown-circles-box'
                          key={symbolIndex}
                          onClick={() => handleUserChoice(index, symbolIndex === 0 ? 'B' : 'S')}
                          style={{ border: userChoices[index] === (symbolIndex === 0 ? 'B' : 'S') ? '2px solid blue' : 'none' }}
                        >
                          {symbol}
                        </button>
                      ))}
                    </div>  
                  </div>
                ))}
              </div>
              <br/>
              <button className='activity-submit-button' onClick={handleSubmitAllAnswers}>Submit All Answers</button>
            </div>
          )}

          <br />
        </div>
      )}
    </div>
  );
};

const getComment = (scoreRating) => {
  switch (scoreRating) {
    case 'Failed':
      return "Keep practicing. You can do better!";
    case 'Almost':
      return "You are close to passing. Keep practicing!";
    case 'Passed':
      return "Well done! You passed the Size Showdown game.";
    case 'Outstanding':
      return "Congratulations! You performed outstandingly.";
    default:
      return "";
  }
};

export default GrOneSizeShowdown;

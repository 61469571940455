import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AccessDenied from '../../components/AccessDenied';

const NumberMastermindGame = () => {
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [highScore, setHighScore] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [gameScore, setGameScore] = useState(0);
  const [gameName] = useState('Grade_R:_Number-Mastermind_Maths_15');
  const [scoreRating, setScoreRating] = useState('');
  const [scoreComment, setScoreComment] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [showGameOver, setShowGameOver] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          const user = data.user;

          if (!user || !user.accountName) {
            setIsAuthenticated(false);
            navigate('/login-second-stage');
            return;
          }

          setUserDetails(user);
          setIsAuthenticated(true);
        } else {
          console.error('Error fetching user details:', response.statusText);
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error fetching user details:', error.message);
        setIsAuthenticated(false);
      }
    };

    fetchUserDetails();
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchHighScore();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (rounds.length === 0) {
      generateRounds();
    }
  }, [rounds]);

  useEffect(() => {
    calculateScoreRating();
  }, [gameScore]);

  const recordGameScore = async (finalScore) => {
    const data = {
      userId: userDetails._id,
      score: finalScore,
      timeOfDay: new Date().getHours(),
      gameName: gameName,
    };
  
    try {
      // Step 1: Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      // Step 2: Send a POST request with CSRF token
      const response = await fetch('https://lernacy.co.za/api/record-score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        body: JSON.stringify(data),
        credentials: 'include', // Include credentials
      });
  
      if (response.ok) {
        const responseData = await response.json();
        //console.log(responseData);
      } else {
        console.error('Error recording game score:', response.statusText);
      }
    } catch (error) {
      console.error('Error recording game score:', error.message);
    }
  
    setShowGameOver(true);
  };
  //Stop After this part
  const restartGame = async () => {
    try {
      setRounds([]);
      setSelectedNumbers([]);
      setGameScore(0);
      setShowGameOver(false);

      const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        console.error('Error fetching high score:', response.statusText);
      }

      generateRounds();
    } catch (error) {
      console.error('Error restarting game:', error.message);
    }
  };

  const fetchHighScore = async () => {
    try {
      const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        console.error('Error fetching high score:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching high score:', error.message);
    }
  };

  const generateUniqueNumbers = (count) => {
    const uniqueNumbers = new Set();
    while (uniqueNumbers.size < count) {
      uniqueNumbers.add(Math.floor(Math.random() * 15) + 1);
    }
    return Array.from(uniqueNumbers);
  };

  const generateRounds = () => {
    const roundsArray = [];
    for (let i = 1; i <= 15; i++) {
      const numCollections = Math.floor(Math.random() * 3) + 3;
      const uniqueNumbers = generateUniqueNumbers(numCollections);
      const collections = uniqueNumbers.map((number, index) => ({
        id: index,
        count: number,
      }));
      roundsArray.push({
        roundNumber: i,
        collections: collections,
      });
    }
    setRounds(roundsArray);
  };

  const handleNumberClick = (roundNumber, number) => {
    setSelectedNumbers((prevSelected) => {
      const roundIndex = prevSelected.findIndex((item) => item.roundNumber === roundNumber);
      if (roundIndex !== -1) {
        const numbers = prevSelected[roundIndex].numbers;
        if (numbers.includes(number)) {
          return [
            ...prevSelected.slice(0, roundIndex),
            { ...prevSelected[roundIndex], numbers: numbers.filter((n) => n !== number) },
            ...prevSelected.slice(roundIndex + 1),
          ];
        } else {
          return [
            ...prevSelected.slice(0, roundIndex),
            { ...prevSelected[roundIndex], numbers: [...numbers, number] },
            ...prevSelected.slice(roundIndex + 1),
          ];
        }
      } else {
        return [...prevSelected, { roundNumber: roundNumber, numbers: [number] }];
      }
    });
  };

  const isInAscendingOrder = (array) => {
    for (let i = 0; i < array.length - 1; i++) {
      if (array[i] >= array[i + 1]) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    let totalScore = 0;
    const answers = rounds.map((round) => {
      const selected = selectedNumbers.find((item) => item.roundNumber === round.roundNumber)?.numbers || [];
      const isCorrect = isInAscendingOrder(selected);
      if (isCorrect) {
        totalScore += 1;
      }
      return {
        round: round.roundNumber,
        answer: selected,
        isCorrect: isCorrect,
      };
    });
    setGameScore(totalScore);
    setSelectedNumbers(answers);

    recordGameScore(totalScore);
  };

  const calculateScoreRating = () => {
    const newPercentage = (gameScore / 15) * 100;
    setPercentage(newPercentage.toFixed(2));

    if (newPercentage >= 90) {
      setScoreRating('Outstanding');
      setScoreComment('Congratulations! You performed outstandingly.');
    } else if (newPercentage >= 75) {
      setScoreRating('Passed');
      setScoreComment('Well done! You passed the counting game.');
    } else if (newPercentage >= 50) {
      setScoreRating('Almost');
      setScoreComment('You are close to passing. Keep practicing!');
    } else {
      setScoreRating('Failed');
      setScoreComment('Keep practicing. You can do better!');
    }
  };

  const getSymbolForNumber = (number) => {
    return String(number);
  };

  return (
    <div className='activity-page-side-aligner'>
      {isAuthenticated === null ? (
        <p>Loading authentication status...</p>
      ) : !isAuthenticated ? (
        <AccessDenied />
      ) : (
        <div className='common-over-block-walls'>
          {showGameOver ? (
            <div>
              <h1 className='activity-name-tag-h1'>Activity Over<br/> Your Score: {gameScore}/15</h1>
              <div className='activity-game-over-data'>
                <h2>Score Rating: {scoreRating}</h2>
                <h3>{scoreComment}</h3>
                <p>Percentage: <strong>{percentage}%</strong></p>
              </div>
              
              <h4>Solve all problems to submit</h4>
              <div>
                <h3 className='activity-rounds-tag-h2'>Question Records:</h3>
                <div className='number-mustermind-rounds-record'>
                  {selectedNumbers.map((answer, index) => (
                    <div key={index} className='number-mustermind-rounds-blocks'>
                      <p>
                        <strong>Question {answer.round}</strong> <br />
                        
                      </p>
                      <p>
                        Your Answer:<br/>
                        {answer.answer.join(', ')} {answer.isCorrect ? <span style={{ color: 'green' }}>✔</span> : <span style={{ color: 'red' }}>✘</span>}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              
              <hr />

              <p>
                <strong>
                  High Score: {highScore !== null ? `${highScore}` : 'N/A'}/15
                </strong>
              </p>

              <div className='activity-restart-or-more'>
                <button className='activity-restart-button' onClick={restartGame}>Play Again</button>
                <button className='more-activity-button' onClick={() => window.location.href = "/Grade:R_Mathematics"}>Do More Activities</button>
              </div>

              <br/>
            </div> 
          ) : (
            <div >
              <h1 className='activity-name-tag-h1'>GR - Number Mastermind</h1>
              <h3 className='rounds-count'>15 Questions</h3>
              <div className='round-high-score'>
                <p>
                  <strong>
                    High Score: {highScore !== null ? `${highScore}` : 'N/A'}/15
                  </strong>
                </p>
              </div>

              <div className='number-mastermind-rounds-wrapper'>
              {rounds.map((round) => (
                <div className='number-mastermind-rounds-container'>
                  <div key={round.roundNumber} className='number-mastermind-rounds-block'>
                    <h4 className='activity-rounds-tag-h4'>Question {round.roundNumber}</h4>
                    <p>Click the symbols in numerical order for each round.</p>
                    <div>
                      {round.collections.map((collection) => (
                        <button
                          key={collection.id}
                          onClick={() => handleNumberClick(round.roundNumber, collection.count)}
                          style={{
                            padding: '10px',
                            margin: '5px',
                            fontSize: '16px',
                            background: selectedNumbers.find((item) => item.roundNumber === round.roundNumber)?.numbers.includes(collection.count) ? 'darkblue' : 'lightgray',
                            color: selectedNumbers.find((item) => item.roundNumber === round.roundNumber)?.numbers.includes(collection.count) ? 'white' : 'black',
                            border: selectedNumbers.find((item) => item.roundNumber === round.roundNumber)?.numbers.includes(collection.count) ? '2px solid #fff' : '1px solid #ccc',
                            borderRadius: '5px',
                          }}
                        >
                          {getSymbolForNumber(collection.count)}
                        </button>
                      ))}
                    </div>
                    <br />
                    <label>
                      <strong>Selected Order:</strong><br/> {selectedNumbers.find((item) => item.roundNumber === round.roundNumber)?.numbers.join(', ') || ''}
                    </label>
                  </div>
                </div>
              ))}
              </div>
              <br/>

              <button className='activity-submit-button' onClick={handleSubmit} disabled={selectedNumbers.length < 15}>
                Submit All Answers
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NumberMastermindGame;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './pages-styling/pages.css';
import useFetchUserData from '../../components/useFetchUserData';

const GradeFourSocialSciences = () => {
  const user = useFetchUserData();
  const [showUrbanModal, setShowUrbanModal] = useState(false);
  const [showPathfinderosModal, setShowPathfinderosModal] = useState(false);
  const [showMapSkillsModal, setShowMapSkillsModal] = useState(false);
  const [showCardinalPointsModal, setShowCardinalPointsModal] = useState(false);
  const [showFoodAndFarmingModal, setShowFoodAndFarmingModal] = useState(false);
  const [showPeopleAndAgricultureModal, setShowPeopleAndAgricultureModal] = useState(false);
  const [showWaterUsageModal, setShowWaterUsageModal] = useState(false);
  const [showWaterAccessAndControlModal, setShowWaterAccessAndControlModal] = useState(false);
  const navigate = useNavigate();

  const handleUrbanProceed = () => {
    setShowUrbanModal(false);
    navigate('/grade-four-urban-odyssey');
  };

  const handlePathfinderosProceed = () => {
    setShowPathfinderosModal(false);
    navigate('/Grade-Four-Pathfinderos');
  };

  const handleMapSkillsProceed = () => {
    setShowMapSkillsModal(false);
    navigate('/Grade-Four-Map-Skills');
  };

  const handleCardinalPointsProceed = () => {
    setShowCardinalPointsModal(false);
    navigate('/Grade-Four-Cardinal-Points');
  };

  const handleFoodAndFarmingProceed = () => {
    setShowFoodAndFarmingModal(false);
    navigate('/Grade-Four-Food-And-Farming');
  };

  const handlePeopleAndAgricultureProceed = () => {
    setShowPeopleAndAgricultureModal(false);
    navigate('/Grade-Four-People-And-Farming');
  };

  const handleWaterUsageProceed = () => {
    setShowWaterUsageModal(false);
    navigate('/Grade-Four-Water-Usage');
  };

  const handleWaterAccessAndControlProceed = () => {
    setShowWaterAccessAndControlModal(false);
    navigate('/Water-Access-And-Control');
  };

  return (
    <div className='page-colour-manager'>
      <div style={{display:'flex', justifyContent:'center'}}>
        {user && user.accountName ? (
          <div className='align-container'>
            <>
              <h1 className='page-title'>Grade 4 - Social Sciences</h1>
              {/*<p>Welcome, {user.name} {user.surname}!</p>*/}

              {/* Urban Game Modal */}
              <button onClick={() => setShowUrbanModal(true)} className="my-game-name-text">Urban Odyssey</button>
              {showUrbanModal && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={() => setShowUrbanModal(false)}>&times;</span>
                    <p>Are you sure you want to proceed?</p>
                    <button className='proceed-modal-button' onClick={handleUrbanProceed}>Proceed</button>
                    <button onClick={() => setShowUrbanModal(false)}>Cancel</button>
                  </div>
                </div>
              )}

              {/* Number Pathfinderos Modal */}
              <button onClick={() => setShowPathfinderosModal(true)} className="my-game-name-text">Pathfinderos</button>
              {showPathfinderosModal && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={() => setShowPathfinderosModal(false)}>&times;</span>
                    <p>Are you sure you want to proceed?</p>
                    <button className='proceed-modal-button' onClick={handlePathfinderosProceed}>Proceed</button>
                    <button onClick={() => setShowPathfinderosModal(false)}>Cancel</button>
                  </div>
                </div>
              )}

              {/* MapSkills Game Modal */}
              <button onClick={() => setShowMapSkillsModal(true)} className="my-game-name-text">Map Skills</button>
              {showMapSkillsModal && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={() => setShowMapSkillsModal(false)}>&times;</span>
                    <p>Are you sure you want to proceed?</p>
                    <button className='proceed-modal-button' onClick={handleMapSkillsProceed}>Proceed</button>
                    <button onClick={() => setShowMapSkillsModal(false)}>Cancel</button>
                  </div>
                </div>
              )}

              {/* Box Number Modal */}
              <button onClick={() => setShowCardinalPointsModal(true)} className="my-game-name-text">Cardinal Points</button>
              {showCardinalPointsModal && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={() => setShowCardinalPointsModal(false)}>&times;</span>
                    <p>Are you sure you want to proceed?</p>
                    <button className='proceed-modal-button' onClick={handleCardinalPointsProceed}>Proceed</button>
                    <button onClick={() => setShowCardinalPointsModal(false)}>Cancel</button>
                  </div>
                </div>
              )}

              {/* Repeated Addition Modal */}
              <button onClick={() => setShowFoodAndFarmingModal(true)} className="my-game-name-text">Farming And Foods</button>
              {showFoodAndFarmingModal && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={() => setShowFoodAndFarmingModal(false)}>&times;</span>
                    <p>Are you sure you want to proceed?</p>
                    <button className='proceed-modal-button' onClick={handleFoodAndFarmingProceed}>Proceed</button>
                    <button onClick={() => setShowFoodAndFarmingModal(false)}>Cancel</button>
                  </div>
                </div>
              )}

              {/* Big Number Challenge Modal */}
              <button onClick={() => setShowPeopleAndAgricultureModal(true)} className="my-game-name-text">People And agriculture</button>
              {showPeopleAndAgricultureModal && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={() => setShowPeopleAndAgricultureModal(false)}>&times;</span>
                    <p>Are you sure you want to proceed?</p>
                    <button className='proceed-modal-button' onClick={handlePeopleAndAgricultureProceed}>Proceed</button>
                    <button onClick={() => setShowPeopleAndAgricultureModal(false)}>Cancel</button>
                  </div>
                </div>
              )}

              {/* Numeric Fusion Modal */}
              <button onClick={() => setShowWaterUsageModal(true)} className="my-game-name-text">Water Usage</button>
              {showWaterUsageModal && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={() => setShowWaterUsageModal(false)}>&times;</span>
                    <p>Are you sure you want to proceed?</p>
                    <button className='proceed-modal-button' onClick={handleWaterUsageProceed}>Proceed</button>
                    <button onClick={() => setShowWaterUsageModal(false)}>Cancel</button>
                  </div>
                </div>
              )}

              {/* Size Showdown Modal */}
              <button onClick={() => setShowWaterAccessAndControlModal(true)} className="my-game-name-text">Water Access and Control</button>
              {showWaterAccessAndControlModal && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={() => setShowWaterAccessAndControlModal(false)}>&times;</span>
                    <p>Are you sure you want to proceed?</p>
                    <button className='proceed-modal-button' onClick={handleWaterAccessAndControlProceed}>Proceed</button>
                    <button onClick={() => setShowWaterAccessAndControlModal(false)}>Cancel</button>
                  </div>
                </div>
              )}

            </>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default GradeFourSocialSciences;
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ActivityPage = () => {
    const { id } = useParams();
    const [activity, setActivity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [accessError, setAccessError] = useState(null);
    const navigate = useNavigate();
    const [startTime, setStartTime] = useState(null);
    const [remainingTime, setRemainingTime] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false); // New state for completion
    const [laqAnswers, setLaqAnswers] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitButtonRef = useRef(null);

    useEffect(() => {
        const checkAccess = async () => {
            try {
                // Fetch request from the client
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}/access`, {
                    method: 'GET',
                    credentials: 'include', // Include cookies or credentials if needed
                });
    
                // Parse JSON response
                const data = await response.json();
    
                if (response.ok) {
                    if (data.message.includes('Access granted')) {
                        // Access granted, process accordingly
                        // console.log('Access granted:', data.message);
                        // console.log('Enrolled students:', data.enrolledStudents);
                        // Display activity logic
                    } else {
                        // Access denied, handle accordingly
                        // console.log(data.message);
                        alert(data.message); // Show the access denied message
                        
                        // Immediately redirect to '/activity-take'
                        navigate('/activity-take'); // Use navigate for redirection
                    }
                } else { 
                    // Handle non-200 responses (e.g., 403, 404)
                    // console.log('Error:', data.message);
                    alert('Access denied: ' + data.message);
                    navigate('/activity-take'); // Redirect for non-200 responses
                }
            } catch (error) {
                console.error('Error fetching access:', error);
                alert('Server error. Please try again later.');
            }
        };
    
        checkAccess();  // Call the async function
    }, [id, navigate]);    
    

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}`, {
                    credentials: 'include',
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        navigate('/unauthorized');
                        return;
                    }
                    throw new Error('Failed to fetch activity');
                }

                const data = await response.json();
                setActivity(data.activity);

                // Check if the user has completed the task
                if (data.userAttempt && data.userAttempt.completed) {
                    setIsCompleted(true); // Set completion state
                    alert('You have completed this task!, you score should be captured');
                    setTimeout(() => {
                        navigate('/activity-take'); // Redirect after 5 seconds
                    }, 1000);
                    return; // Exit early if completed
                }

                if (!data.userAttempt || !data.userAttempt.startTime) {
                    await updateStartTime();
                } else {
                    const { startTime } = data.userAttempt;
                    setStartTime(new Date(startTime));
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchActivity();
    }, [id, navigate]);

    const updateStartTime = async () => {
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });

            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }

            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;

            const response = await fetch(`https://lernacy.co.za/api/activity/${id}/attempt`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to start activity');
            }

            const data = await response.json();
            const { startTime } = data;
            setStartTime(new Date(startTime));
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const updateRemainingTime = () => {
            if (startTime && activity) {
                const currentTime = new Date();
                const endTime = new Date(startTime.getTime() + activity.timeLimit * 60 * 1000);
                const remaining = Math.max(0, Math.floor((endTime - currentTime) / 1000));
                setRemainingTime(remaining);
        
                // Check if time is up and we haven't submitted yet
                if (remaining === 0 && !submitted && !isSubmitting) {
                    setIsSubmitting(true); // Prevent further submissions
                    if (activity.activityType === 'MCQ') {
                        submitActivity(0); // Trigger MCQ submission with a score of 0
                    } else if (activity.activityType === 'LAQ') {
                        submitButtonRef.current.click();
                    }
                }
            }
        };
        
        updateRemainingTime();
        const interval = setInterval(updateRemainingTime, 1000);
        return () => clearInterval(interval);
    }, [startTime, activity, submitted, isSubmitting]);    

    const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = ''; 
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleOptionChange = (questionId, optionIndex) => {
        setSelectedAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: optionIndex,
        }));
    };

    // New handler for LAQ submission
    const handleLAQSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) return; // Prevent duplicate submissions
        setIsSubmitting(true); // Set submitting state to true
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
    
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
    
            // Prepare the data to send
            const answersWithQuestions = activity.questions.map((question) => {
                const answer = selectedAnswers[question._id]; // Get user's answer based on question ID
                return {
                    question: question.question, // Use question text instead of ID
                    answer: answer || '', // User's answer or empty string if not provided
                };
            });
    
            const submissionData = {
                activityName: activity.activityName,
                activityType: activity.activityType,
                activityId: id,
                answers: answersWithQuestions,
            };
    
            // Log the data as a string
            // console.log('Sending LAQ data to server:', JSON.stringify(submissionData));
    
            // Send the data to the server
            const response = await fetch(`https://lernacy.co.za/api/submit-laq/${id}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
                body: JSON.stringify(submissionData),
            });
    
            if (!response.ok) {
                throw new Error('Failed to submit LAQ');
            }
    
            alert('Your LAQ has been submitted successfully!');
            setTimeout(() => {
                navigate('/activity-take');
            }, 1000);
        } catch (err) {
            console.error(err.message);
            alert('An error occurred while submitting your LAQ. Please try again later.');
        } finally {
            setIsSubmitting(false); // Reset submitting state
        }
    };    

    // Extracted function to handle activity submission
    const submitActivity = async (defaultScore = null) => {
        const allAnswered = activity.questions.every((question) =>
            selectedAnswers.hasOwnProperty(question._id)
        );
    
        if (!allAnswered && defaultScore === null) {
            alert('Please answer all questions before submitting.');
            return;
        }
    
        let totalScore = 0;
    
        if (defaultScore !== null) {
            totalScore = defaultScore; // If time is up, score is 0
        } else {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}/new-activity`, {
                    credentials: 'include',
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch correct answers');
                }
    
                const { correctAnswers } = await response.json();
    
                const results = correctAnswers.map(({ questionId, correctAnswer, score }) => {
                    const userAnswer = selectedAnswers[questionId];
                    const isCorrect = userAnswer !== undefined && (userAnswer + 1).toString() === correctAnswer;
    
                    return {
                        questionId,
                        isCorrect,
                        score: isCorrect ? score : 0,
                    };
                });
    
                totalScore = results.reduce((acc, result) => acc + result.score, 0);
            } catch (err) {
                console.error(err.message);
                alert('An error occurred while calculating the score. Please try again later.');
                return;
            }
        }
    
        setSubmitted(true);
    
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
    
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
    
            // Adjust the scoreData to include activityName, score, and activity type
            const scoreData = {
                score: totalScore,
                activityName: activity.activityName, // Use activityName directly
                activityType: activity.activityType // Include activity type
            };
    
            // console.log('Submitting data:', scoreData); // Log the data being submitted for testing
    
            // Send request to the server with activityId in the URL
            const scoreResponse = await fetch(`https://lernacy.co.za/api/submit-activity/${id}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
                body: JSON.stringify(scoreData),
            });
    
            if (!scoreResponse.ok) {
                throw new Error('Failed to record score');
            }
    
            alert(`Thanks for submitting! Your score is captured: ${totalScore}`);
    
            setTimeout(() => {
                navigate('/activity-take');
            }, 1000);
        } catch (err) {
            console.error(err.message);
            alert('An error occurred while submitting your activity. Please try again later.');
        }
    };             

    const handleSubmit = (e) => {
        e.preventDefault(); 
        submitActivity(); // Call the extracted function
    };

    if (loading) return <div>Loading...</div>;
    if (accessError) return <div>{accessError}</div>; 
    if (error) return <div>{error}</div>;
    if (!activity) return <div>Activity not found</div>;

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
        <div className='activity-page-main-border'>
            
            <h1 className='activity-name-tag-h1'>{activity.activityName}</h1>
            <div className='all-activity-block' style={{ userSelect: 'none' }}>
                <p>Type: {activity.activityType}</p>
                <p>Time Given: {activity.timeLimit} minutes</p>
                <p>Author: {activity.authorId.accountName}</p>
                <p>Remaining Time: {remainingTime > 0 ? `${minutes}m ${seconds}s` : 'Time is up!'}</p>
                <strong>Activity will be submitted for you when time runs out!!! </strong>
        
                {activity.activityType === 'MCQ' ? (
                    <form onSubmit={handleSubmit}>
                        {activity.questions.map((question) => (
                            <div key={question._id} className='activity-question-select'> 
                                <h2>{question.question}</h2>
                                {question.options.map((option, index) => (
                                    <div key={index}>
                                        <input
                                            type="radio"
                                            name={question._id}
                                            value={index}
                                            checked={selectedAnswers[question._id] === index}
                                            onChange={() => handleOptionChange(question._id, index)}
                                        />
                                        {option}
                                    </div>
                                ))}
                            </div>
                        ))}
                        <button type="submit" disabled={submitted || isCompleted} className='activity-submit-button'>
                            {submitted ? 'Submitted' : 'Submit Answers'}
                        </button>
                    </form>
                ) : activity.activityType === 'LAQ' ? (
                    <form onSubmit={handleLAQSubmit}>
                        {activity.questions.map((question) => (
                            <div key={question._id}>
                                <h2>{question.question}</h2>
                                
                                <textarea
                                    value={selectedAnswers[question._id] || ''}
                                    onChange={(e) =>
                                        setSelectedAnswers((prevAnswers) => ({
                                            ...prevAnswers,
                                            [question._id]: e.target.value,
                                        }))
                                    }
                                    placeholder="Write your answer here..."
                                    rows="5"
                                    style={{ width: '92%' }}
                                    className='LAQ-activity-input-field'
                                />
                               
                                <p className='LAQ-activity-mark-value'>Answer for <strong>{question.score} Mark(s)</strong></p>
                            </div>
                        ))}
                        <button type="submit" ref={submitButtonRef} className='LAQ-activity-submit'>
                            Submit LAQ
                        </button>
                    </form>
                ) : null}
            </div>
        </div>
    );    
};
 
export default ActivityPage;
/* The most ready set 11*/




/*
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ActivityPage = () => {
    const { id } = useParams();
    const [activity, setActivity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [accessError, setAccessError] = useState(null);
    const navigate = useNavigate();
    const [startTime, setStartTime] = useState(null);
    const [remainingTime, setRemainingTime] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false); // New state for completion
    const [laqAnswers, setLaqAnswers] = useState({});

    useEffect(() => {
        const checkAccess = async () => {
            try {
                // Fetch request from the client
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}/access`, {
                    method: 'GET',
                    credentials: 'include', // Include cookies or credentials if needed
                });
    
                // Parse JSON response
                const data = await response.json();
    
                if (response.ok) {
                    if (data.message.includes('Access granted')) {
                        // Access granted, process accordingly
                        console.log('Access granted:', data.message);
                        console.log('Enrolled students:', data.enrolledStudents);
                        // Display activity logic
                    } else {
                        // Access denied, handle accordingly
                        console.log(data.message);
                        alert(data.message); // Show the access denied message
                        
                        // Immediately redirect to '/activity-take'
                        navigate('/activity-take'); // Use navigate for redirection
                    }
                } else { 
                    // Handle non-200 responses (e.g., 403, 404)
                    console.log('Error:', data.message);
                    alert('Access denied: ' + data.message);
                    navigate('/activity-take'); // Redirect for non-200 responses
                }
            } catch (error) {
                console.error('Error fetching access:', error);
                alert('Server error. Please try again later.');
            }
        };
    
        checkAccess();  // Call the async function
    }, [id, navigate]);    
    

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}`, {
                    credentials: 'include',
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        navigate('/unauthorized');
                        return;
                    }
                    throw new Error('Failed to fetch activity');
                }

                const data = await response.json();
                setActivity(data.activity);

                // Check if the user has completed the task
                if (data.userAttempt && data.userAttempt.completed) {
                    setIsCompleted(true); // Set completion state
                    alert('You have completed this task!, you score should be captured');
                    setTimeout(() => {
                        navigate('/activity-take'); // Redirect after 5 seconds
                    }, 1000);
                    return; // Exit early if completed
                }

                if (!data.userAttempt || !data.userAttempt.startTime) {
                    await updateStartTime();
                } else {
                    const { startTime } = data.userAttempt;
                    setStartTime(new Date(startTime));
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchActivity();
    }, [id, navigate]);

    const updateStartTime = async () => {
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });

            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }

            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;

            const response = await fetch(`https://lernacy.co.za/api/activity/${id}/attempt`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to start activity');
            }

            const data = await response.json();
            const { startTime } = data;
            setStartTime(new Date(startTime));
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const updateRemainingTime = () => {
            if (startTime && activity) {
                const currentTime = new Date();
                const endTime = new Date(startTime.getTime() + activity.timeLimit * 60 * 1000);
                const remaining = Math.max(0, Math.floor((endTime - currentTime) / 1000));
                setRemainingTime(remaining);

                // Check if time is up
                if (remaining === 0 && !submitted) {
                    submitActivity(0); // Trigger submission with a score of 0
                }
            }
        };

        updateRemainingTime();
        const interval = setInterval(updateRemainingTime, 1000);
        return () => clearInterval(interval);
    }, [startTime, activity, submitted]);

    const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = ''; 
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleOptionChange = (questionId, optionIndex) => {
        setSelectedAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: optionIndex,
        }));
    };

    // New handler for LAQ submission
    const handleLAQSubmit = async (e) => {
        e.preventDefault();
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
    
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
    
            // Prepare the data to send
            const answersWithQuestions = activity.questions.map((question) => {
                const answer = selectedAnswers[question._id]; // Get user's answer based on question ID
                return {
                    question: question.question, // Use question text instead of ID
                    answer: answer || '', // User's answer or empty string if not provided
                };
            });
    
            const submissionData = {
                activityName: activity.activityName,
                activityType: activity.activityType,
                activityId: id,
                answers: answersWithQuestions, // Now includes both question text and answer
            };
    
            // Log the data as a string
            // console.log('Sending data to server:', JSON.stringify(submissionData));
    
            // Send the data to the server
            const response = await fetch(`https://lernacy.co.za/api/submit-laq/${id}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
                body: JSON.stringify(submissionData),
            });
    
            if (!response.ok) {
                throw new Error('Failed to submit LAQ');
            }
    
            alert('Your LAQ has been submitted successfully!');
            setTimeout(() => {
                navigate('/activity-take');
            }, 1000);
        } catch (err) {
            console.error(err.message);
            alert('An error occurred while submitting your LAQ. Please try again later.');
        }
    };        

    // Extracted function to handle activity submission
    const submitActivity = async (defaultScore = null) => {
        const allAnswered = activity.questions.every((question) =>
            selectedAnswers.hasOwnProperty(question._id)
        );
    
        if (!allAnswered && defaultScore === null) {
            alert('Please answer all questions before submitting.');
            return;
        }
    
        let totalScore = 0;
    
        if (defaultScore !== null) {
            totalScore = defaultScore; // If time is up, score is 0
        } else {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}/new-activity`, {
                    credentials: 'include',
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch correct answers');
                }
    
                const { correctAnswers } = await response.json();
    
                const results = correctAnswers.map(({ questionId, correctAnswer, score }) => {
                    const userAnswer = selectedAnswers[questionId];
                    const isCorrect = userAnswer !== undefined && (userAnswer + 1).toString() === correctAnswer;
    
                    return {
                        questionId,
                        isCorrect,
                        score: isCorrect ? score : 0,
                    };
                });
    
                totalScore = results.reduce((acc, result) => acc + result.score, 0);
            } catch (err) {
                console.error(err.message);
                alert('An error occurred while calculating the score. Please try again later.');
                return;
            }
        }
    
        setSubmitted(true);
    
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
    
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
    
            // Adjust the scoreData to include activityName, score, and activity type
            const scoreData = {
                score: totalScore,
                activityName: activity.activityName, // Use activityName directly
                activityType: activity.activityType // Include activity type
            };
    
            console.log('Submitting data:', scoreData); // Log the data being submitted for testing
    
            // Send request to the server with activityId in the URL
            const scoreResponse = await fetch(`https://lernacy.co.za/api/submit-activity/${id}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
                body: JSON.stringify(scoreData),
            });
    
            if (!scoreResponse.ok) {
                throw new Error('Failed to record score');
            }
    
            alert(`Thanks for submitting! Your score is captured: ${totalScore}`);
    
            setTimeout(() => {
                navigate('/activity-take');
            }, 1000);
        } catch (err) {
            console.error(err.message);
            alert('An error occurred while submitting your activity. Please try again later.');
        }
    };             

    const handleSubmit = (e) => {
        e.preventDefault(); 
        submitActivity(); // Call the extracted function
    };

    if (loading) return <div>Loading...</div>;
    if (accessError) return <div>{accessError}</div>; 
    if (error) return <div>{error}</div>;
    if (!activity) return <div>Activity not found</div>;

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
        <div className='activity-page-main-border'>
            
            <h1 className='activity-name-tag-h1'>{activity.activityName}</h1>
            <div className='all-activity-block' style={{ userSelect: 'none' }}>
                <p>Type: {activity.activityType}</p>
                <p>Time Given: {activity.timeLimit} minutes</p>
                <p>Author: {activity.authorId.accountName}</p>
                <p>Remaining Time: {remainingTime > 0 ? `${minutes}m ${seconds}s` : 'Time is up!'}</p>
        
                {activity.activityType === 'MCQ' ? (
                    <form onSubmit={handleSubmit}>
                        {activity.questions.map((question) => (
                            <div key={question._id} className='activity-question-select'> 
                                <h2>{question.question}</h2>
                                {question.options.map((option, index) => (
                                    <div key={index}>
                                        <input
                                            type="radio"
                                            name={question._id}
                                            value={index}
                                            checked={selectedAnswers[question._id] === index}
                                            onChange={() => handleOptionChange(question._id, index)}
                                        />
                                        {option}
                                    </div>
                                ))}
                            </div>
                        ))}
                        <button type="submit" disabled={submitted || isCompleted} className='activity-submit-button'>
                            {submitted ? 'Submitted' : 'Submit Answers'}
                        </button>
                    </form>
                ) : activity.activityType === 'LAQ' ? (
                    <form onSubmit={handleLAQSubmit}>
                        {activity.questions.map((question) => (
                            <div key={question._id}>
                                <h2>{question.question}</h2>
                                
                                <textarea
                                    value={selectedAnswers[question._id] || ''}
                                    onChange={(e) =>
                                        setSelectedAnswers((prevAnswers) => ({
                                            ...prevAnswers,
                                            [question._id]: e.target.value,
                                        }))
                                    }
                                    placeholder="Write your answer here..."
                                    rows="5"
                                    style={{ width: '92%' }}
                                    className='LAQ-activity-input-field'
                                />
                               
                                <p className='LAQ-activity-mark-value'>Answer for <strong>{question.score} Mark(s)</strong></p>
                            </div>
                        ))}
                        <button type="submit" className='LAQ-activity-submit'>
                            Submit LAQ
                        </button>
                    </form>
                ) : null}
            </div>
        </div>
    );    
};
 
export default ActivityPage;
*/

/*
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ActivityPage = () => {
    const { id } = useParams();
    const [activity, setActivity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [accessError, setAccessError] = useState(null);
    const navigate = useNavigate();
    const [startTime, setStartTime] = useState(null);
    const [remainingTime, setRemainingTime] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false); // New state for completion
    const [laqAnswers, setLaqAnswers] = useState({});

    useEffect(() => {
        const checkAccess = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}/access`, {
                    credentials: 'include',
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        setAccessError('You do not have permission to access this activity.');
                        return;
                    }
                    throw new Error('Failed to verify access');
                }

                const accessData = await response.json();
                if (accessData.message === 'Access granted as author' || accessData.hasAccess) {
                    setAccessError(null);
                } else {
                    setAccessError('You do not have permission to access this activity.');
                }
            } catch (err) {
                setAccessError(err.message);
            }
        };

        checkAccess();
    }, [id]);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}`, {
                    credentials: 'include',
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        navigate('/unauthorized');
                        return;
                    }
                    throw new Error('Failed to fetch activity');
                }

                const data = await response.json();
                setActivity(data.activity);

                // Check if the user has completed the task
                if (data.userAttempt && data.userAttempt.completed) {
                    setIsCompleted(true); // Set completion state
                    alert('You have completed this task!, you score should be captured');
                    setTimeout(() => {
                        navigate('/activity-take'); // Redirect after 5 seconds
                    }, 5000);
                    return; // Exit early if completed
                }

                if (!data.userAttempt || !data.userAttempt.startTime) {
                    await updateStartTime();
                } else {
                    const { startTime } = data.userAttempt;
                    setStartTime(new Date(startTime));
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchActivity();
    }, [id, navigate]);

    const updateStartTime = async () => {
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });

            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }

            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;

            const response = await fetch(`https://lernacy.co.za/api/activity/${id}/attempt`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to start activity');
            }

            const data = await response.json();
            const { startTime } = data;
            setStartTime(new Date(startTime));
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const updateRemainingTime = () => {
            if (startTime && activity) {
                const currentTime = new Date();
                const endTime = new Date(startTime.getTime() + activity.timeLimit * 60 * 1000);
                const remaining = Math.max(0, Math.floor((endTime - currentTime) / 1000));
                setRemainingTime(remaining);

                // Check if time is up
                if (remaining === 0 && !submitted) {
                    submitActivity(0); // Trigger submission with a score of 0
                }
            }
        };

        updateRemainingTime();
        const interval = setInterval(updateRemainingTime, 1000);
        return () => clearInterval(interval);
    }, [startTime, activity, submitted]);

    const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = ''; 
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleOptionChange = (questionId, optionIndex) => {
        setSelectedAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: optionIndex,
        }));
    };

    // New handler for LAQ submission
    const handleLAQSubmit = async (e) => {
        e.preventDefault();
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
    
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
    
            // Prepare the data to send
            const answersWithQuestions = activity.questions.map((question) => {
                const answer = selectedAnswers[question._id]; // Get user's answer based on question ID
                return {
                    question: question.question, // Use question text instead of ID
                    answer: answer || '', // User's answer or empty string if not provided
                };
            });
    
            const submissionData = {
                activityName: activity.activityName,
                activityType: activity.activityType,
                activityId: id,
                answers: answersWithQuestions, // Now includes both question text and answer
            };
    
            // Log the data as a string
            // console.log('Sending data to server:', JSON.stringify(submissionData));
    
            // Send the data to the server
            const response = await fetch(`https://lernacy.co.za/api/submit-laq/${id}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
                body: JSON.stringify(submissionData),
            });
    
            if (!response.ok) {
                throw new Error('Failed to submit LAQ');
            }
    
            alert('Your LAQ has been submitted successfully!');
            setTimeout(() => {
                navigate('/activity-take');
            }, 1000);
        } catch (err) {
            console.error(err.message);
            alert('An error occurred while submitting your LAQ. Please try again later.');
        }
    };        

    // Extracted function to handle activity submission
    const submitActivity = async (defaultScore = null) => {
        const allAnswered = activity.questions.every((question) =>
            selectedAnswers.hasOwnProperty(question._id)
        );
    
        if (!allAnswered && defaultScore === null) {
            alert('Please answer all questions before submitting.');
            return;
        }
    
        let totalScore = 0;
    
        if (defaultScore !== null) {
            totalScore = defaultScore; // If time is up, score is 0
        } else {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}/new-activity`, {
                    credentials: 'include',
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch correct answers');
                }
    
                const { correctAnswers } = await response.json();
    
                const results = correctAnswers.map(({ questionId, correctAnswer, score }) => {
                    const userAnswer = selectedAnswers[questionId];
                    const isCorrect = userAnswer !== undefined && (userAnswer + 1).toString() === correctAnswer;
    
                    return {
                        questionId,
                        isCorrect,
                        score: isCorrect ? score : 0,
                    };
                });
    
                totalScore = results.reduce((acc, result) => acc + result.score, 0);
            } catch (err) {
                console.error(err.message);
                alert('An error occurred while calculating the score. Please try again later.');
                return;
            }
        }
    
        setSubmitted(true);
    
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
    
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
    
            // Adjust the scoreData to include activityName, score, and activity type
            const scoreData = {
                score: totalScore,
                activityName: activity.activityName, // Use activityName directly
                activityType: activity.activityType // Include activity type
            };
    
            console.log('Submitting data:', scoreData); // Log the data being submitted for testing
    
            // Send request to the server with activityId in the URL
            const scoreResponse = await fetch(`https://lernacy.co.za/api/submit-activity/${id}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
                body: JSON.stringify(scoreData),
            });
    
            if (!scoreResponse.ok) {
                throw new Error('Failed to record score');
            }
    
            alert(`Thanks for submitting! Your score is captured: ${totalScore}`);
    
            setTimeout(() => {
                navigate('/activity-take');
            }, 1000);
        } catch (err) {
            console.error(err.message);
            alert('An error occurred while submitting your activity. Please try again later.');
        }
    };             

    const handleSubmit = (e) => {
        e.preventDefault(); 
        submitActivity(); // Call the extracted function
    };

    if (loading) return <div>Loading...</div>;
    if (accessError) return <div>{accessError}</div>; 
    if (error) return <div>{error}</div>;
    if (!activity) return <div>Activity not found</div>;

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
        <div className='activity-page-main-border'>
            
            <h1 className='activity-name-tag-h1'>{activity.activityName}</h1>
            <div className='all-activity-block' style={{ userSelect: 'none' }}>
                <p>Type: {activity.activityType}</p>
                <p>Time Given: {activity.timeLimit} minutes</p>
                <p>Author: {activity.authorId.accountName}</p>
                <p>Remaining Time: {remainingTime > 0 ? `${minutes}m ${seconds}s` : 'Time is up!'}</p>
        
                {activity.activityType === 'MCQ' ? (
                    <form onSubmit={handleSubmit}>
                        {activity.questions.map((question) => (
                            <div key={question._id} className='activity-question-select'> 
                                <h2>{question.question}</h2>
                                {question.options.map((option, index) => (
                                    <div key={index}>
                                        <input
                                            type="radio"
                                            name={question._id}
                                            value={index}
                                            checked={selectedAnswers[question._id] === index}
                                            onChange={() => handleOptionChange(question._id, index)}
                                        />
                                        {option}
                                    </div>
                                ))}
                            </div>
                        ))}
                        <button type="submit" disabled={submitted || isCompleted} className='activity-submit-button'>
                            {submitted ? 'Submitted' : 'Submit Answers'}
                        </button>
                    </form>
                ) : activity.activityType === 'LAQ' ? (
                    <form onSubmit={handleLAQSubmit}>
                        {activity.questions.map((question) => (
                            <div key={question._id}>
                                <h2>{question.question}</h2>
                                
                                <textarea
                                    value={selectedAnswers[question._id] || ''}
                                    onChange={(e) =>
                                        setSelectedAnswers((prevAnswers) => ({
                                            ...prevAnswers,
                                            [question._id]: e.target.value,
                                        }))
                                    }
                                    placeholder="Write your answer here..."
                                    rows="5"
                                    style={{ width: '92%' }}
                                    className='LAQ-activity-input-field'
                                />
                               
                                <p className='LAQ-activity-mark-value'>Answer for <strong>{question.score} Mark(s)</strong></p>
                            </div>
                        ))}
                        <button type="submit" className='LAQ-activity-submit'>
                            Submit LAQ
                        </button>
                    </form>
                ) : null}
            </div>
        </div>
    );    
};

export default ActivityPage;
*/

/*
I now want to make major adjustments. Since i Have LAQ and MCQ, i only made the ui and code to handle LAQ, can we make it so if it turns out that the activity is LAQ, then change the UI to accomodate it. Since LAQ do not have option fields, just Question(s) and score, i would like the ui to show the question and an input field with the score for that question to guide the user on how much the question is worth. I would also like to add a submission button that works separate from 'const submitActivity' because I dont want the logic to assess the question but rather have the question, activityName, activityType, activityId and the answer(s) the user provided to be submitted to the server, we'll build a route for it but it should be a custom route with custom url. 
*/

/* 
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ActivityPage = () => {
    const { id } = useParams();
    const [activity, setActivity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [accessError, setAccessError] = useState(null);
    const navigate = useNavigate();
    const [startTime, setStartTime] = useState(null);
    const [remainingTime, setRemainingTime] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false); // New state for completion

    useEffect(() => {
        const checkAccess = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}/access`, {
                    credentials: 'include',
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        setAccessError('You do not have permission to access this activity.');
                        return;
                    }
                    throw new Error('Failed to verify access');
                }

                const accessData = await response.json();
                if (accessData.message === 'Access granted as author' || accessData.hasAccess) {
                    setAccessError(null);
                } else {
                    setAccessError('You do not have permission to access this activity.');
                }
            } catch (err) {
                setAccessError(err.message);
            }
        };

        checkAccess();
    }, [id]);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}`, {
                    credentials: 'include',
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        navigate('/unauthorized');
                        return;
                    }
                    throw new Error('Failed to fetch activity');
                }

                const data = await response.json();
                setActivity(data.activity);

                // Check if the user has completed the task
                if (data.userAttempt && data.userAttempt.completed) {
                    setIsCompleted(true); // Set completion state
                    alert('You have completed this task!, you score should be captured');
                    setTimeout(() => {
                        navigate('/activity-take'); // Redirect after 5 seconds
                    }, 5000);
                    return; // Exit early if completed
                }

                if (!data.userAttempt || !data.userAttempt.startTime) {
                    await updateStartTime();
                } else {
                    const { startTime } = data.userAttempt;
                    setStartTime(new Date(startTime));
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchActivity();
    }, [id, navigate]);

    const updateStartTime = async () => {
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });

            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }

            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;

            const response = await fetch(`https://lernacy.co.za/api/activity/${id}/attempt`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to start activity');
            }

            const data = await response.json();
            const { startTime } = data;
            setStartTime(new Date(startTime));
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const updateRemainingTime = () => {
            if (startTime && activity) {
                const currentTime = new Date();
                const endTime = new Date(startTime.getTime() + activity.timeLimit * 60 * 1000);
                const remaining = Math.max(0, Math.floor((endTime - currentTime) / 1000));
                setRemainingTime(remaining);

                // Check if time is up
                if (remaining === 0 && !submitted) {
                    submitActivity(0); // Trigger submission with a score of 0
                }
            }
        };

        updateRemainingTime();
        const interval = setInterval(updateRemainingTime, 1000);
        return () => clearInterval(interval);
    }, [startTime, activity, submitted]);

    const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = ''; 
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleOptionChange = (questionId, optionIndex) => {
        setSelectedAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: optionIndex,
        }));
    };

    // Extracted function to handle activity submission
    const submitActivity = async (defaultScore = null) => {
        const allAnswered = activity.questions.every((question) =>
            selectedAnswers.hasOwnProperty(question._id)
        );
    
        if (!allAnswered && defaultScore === null) {
            alert('Please answer all questions before submitting.');
            return;
        }
    
        let totalScore = 0;
    
        if (defaultScore !== null) {
            totalScore = defaultScore; // If time is up, score is 0
        } else {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}/new-activity`, {
                    credentials: 'include',
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch correct answers');
                }
    
                const { correctAnswers } = await response.json();
    
                const results = correctAnswers.map(({ questionId, correctAnswer, score }) => {
                    const userAnswer = selectedAnswers[questionId];
                    const isCorrect = userAnswer !== undefined && (userAnswer + 1).toString() === correctAnswer;
    
                    return {
                        questionId,
                        isCorrect,
                        score: isCorrect ? score : 0,
                    };
                });
    
                totalScore = results.reduce((acc, result) => acc + result.score, 0);
            } catch (err) {
                console.error(err.message);
                alert('An error occurred while calculating the score. Please try again later.');
                return;
            }
        }
    
        setSubmitted(true);
    
        try {
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
    
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
    
            // Adjust the scoreData to include activityName, score, and activity type
            const scoreData = {
                score: totalScore,
                activityName: activity.activityName, // Use activityName directly
                activityType: activity.activityType // Include activity type
            };
    
            // console.log('Submitting data:', scoreData); // Log the data being submitted for testing
    
            // Send request to the server with activityId in the URL
            const scoreResponse = await fetch(`https://lernacy.co.za/api/submit-activity/${id}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,
                },
                body: JSON.stringify(scoreData),
            });
    
            if (!scoreResponse.ok) {
                throw new Error('Failed to record score');
            }
    
            alert(`Thanks for submitting! Your score is captured: ${totalScore}`);
    
            setTimeout(() => {
                navigate('/activity-take');
            }, 1000);
        } catch (err) {
            console.error(err.message);
            alert('An error occurred while submitting your activity. Please try again later.');
        }
    };             

    const handleSubmit = (e) => {
        e.preventDefault(); 
        submitActivity(); // Call the extracted function
    };

    if (loading) return <div>Loading...</div>;
    if (accessError) return <div>{accessError}</div>; 
    if (error) return <div>{error}</div>;
    if (!activity) return <div>Activity not found</div>;

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
        <div style={{ userSelect: 'none' }}>
            <h1>{activity.activityName}</h1>
            <p>Type: {activity.activityType}</p>
            <p>Time Given: {activity.timeLimit} minutes</p>
            <p>Author: {activity.authorId.accountName}</p>
            <p>Remaining Time: {remainingTime > 0 ? `${minutes}m ${seconds}s` : 'Time is up!'}</p>
    
            <form onSubmit={handleSubmit}>
                {activity.questions.map((question) => (
                    <div key={question._id}>
                        <h2>{question.question}</h2>
                        {question.options.map((option, index) => (
                            <div key={index}>
                                <input
                                    type="radio"
                                    name={question._id}
                                    value={index}
                                    checked={selectedAnswers[question._id] === index}
                                    onChange={() => handleOptionChange(question._id, index)}
                                />
                                {option}
                            </div>
                        ))}
                    </div> 
                ))}
                <button type="submit" disabled={submitted || isCompleted}>
                    {submitted ? 'Submitted' : 'Submit Answers'}
                </button>
            </form>
        </div>
    );    
};

export default ActivityPage;
*/

/* 
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ActivityPage = () => {
    const { id } = useParams();
    const [activity, setActivity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [accessError, setAccessError] = useState(null); // To display access error
    const navigate = useNavigate();
    const [startTime, setStartTime] = useState(null); // To store the start time
    const [remainingTime, setRemainingTime] = useState(0); // To store remaining time
    const [selectedAnswers, setSelectedAnswers] = useState({}); // Store selected answers
    const [submitted, setSubmitted] = useState(false);

    // Access control effect 
    useEffect(() => { 
        const checkAccess = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}/access`, {
                    credentials: 'include',
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        setAccessError('You do not have permission to access this activity.');
                        return;
                    }
                    throw new Error('Failed to verify access');
                }

                const accessData = await response.json();
                // Check the response message or status to determine if access is granted
                if (accessData.message === 'Access granted as author' || accessData.hasAccess) {
                    // Access granted, proceed to load the activity
                    setAccessError(null); // Clear any previous access error
                } else {
                    setAccessError('You do not have permission to access this activity.');
                }
            } catch (err) {
                setAccessError(err.message);
            }
        };

        checkAccess();
    }, [id]);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}`, {
                    credentials: 'include',
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        navigate('/unauthorized');
                        return;
                    }
                    throw new Error('Failed to fetch activity');
                }

                const data = await response.json();
                setActivity(data.activity);

                // Ensure data.userAttempt exists and has startTime
                if (!data.userAttempt || !data.userAttempt.startTime) {
                    // If no attempt found or no startTime, update startTime
                    await updateStartTime();
                } else {
                    // Use existing startTime
                    const { startTime } = data.userAttempt;
                    setStartTime(new Date(startTime)); // Store as Date object
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchActivity();
    }, [id, navigate]);

    const updateStartTime = async () => {
        try {
            // Step 1: Get CSRF token
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include', // Ensure cookies are included in the request
            });

            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }

            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken; // Assuming the CSRF token is in csrfData.csrfToken

            // Step 2: Use the CSRF token in the POST request
            const response = await fetch(`https://lernacy.co.za/api/activity/${id}/attempt`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken, // Include the CSRF token here
                },
            });

            if (!response.ok) {
                throw new Error('Failed to start activity');
            }

            const data = await response.json();
            const { startTime } = data;
            setStartTime(new Date(startTime)); // Store as Date object
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const updateRemainingTime = () => {
            if (startTime && activity) {
                const currentTime = new Date();
                const endTime = new Date(startTime.getTime() + activity.timeLimit * 60 * 1000); // Calculate end time
                const remaining = Math.max(0, Math.floor((endTime - currentTime) / 1000)); // Remaining time in seconds
                setRemainingTime(remaining);
            }
        };

        updateRemainingTime(); // Update immediately on load
        const interval = setInterval(updateRemainingTime, 1000); // Update every second
        return () => clearInterval(interval);
    }, [startTime, activity]);

    const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = ''; // This triggers the confirmation dialog
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // Handle answer selection
    const handleOptionChange = (questionId, optionIndex) => {
        setSelectedAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: optionIndex, // Store the selected option index for each question
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.clear();

        // Check if all questions have been answered
        const allAnswered = activity.questions.every((question) =>
            selectedAnswers.hasOwnProperty(question._id)
        );

        if (!allAnswered) {
            alert('Please answer all questions before submitting.');
            return; // Prevent submission
        }

        // Fetch the correct answers from the server
        try {
            const response = await fetch(`https://lernacy.co.za/api/activity/${id}/new-activity`, {
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch correct answers');
            }

            const { correctAnswers } = await response.json();

            // Assess the activity
            const results = correctAnswers.map(({ questionId, correctAnswer, score }) => {
                const userAnswer = selectedAnswers[questionId];
                const isCorrect = userAnswer !== undefined && (userAnswer + 1).toString() === correctAnswer; // Compare with user input (1-indexed)

                return {
                    questionId,
                    isCorrect,
                    score: isCorrect ? score : 0 // Only assign the score if the answer is correct
                };
            });

            // Calculate total score
            const totalScore = results.reduce((acc, result) => acc + result.score, 0);

            // console.log('Assessment Results:', results);
            // console.log('Total Score:', totalScore); // Log the total score

            // Set submitted state and inform the user
            setSubmitted(true);
            alert(`Thanks for submitting! Your score is captured: ${totalScore}`);

            // Redirect to '/activity-take' after a short delay
            setTimeout(() => {
                navigate('/activity-take');
            }, 5000);

        } catch (err) {
            console.error(err.message);
        }
    };   

    if (loading) return <div>Loading...</div>;
    if (accessError) return <div>{accessError}</div>; 
    if (error) return <div>{error}</div>;
    if (!activity) return <div>Activity not found</div>;

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
        <div style={{ userSelect: 'none' }}>
            <h1>{activity.activityName}</h1>
            <p>Type: {activity.activityType}</p>
            <p>Time Given: {activity.timeLimit} minutes</p>
            <p>Author: {activity.authorId.accountName}</p>
            <p>Remaining Time: {remainingTime > 0 ? `${minutes}m ${seconds}s` : 'Time is up!'}</p>

            {submitted ? (
                <div>
                    <h3>Thank you for participating! Your score has been recorded.</h3>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    {activity.questions.map((question, index) => (
                        <div key={question._id}>
                            <h3>{`${index + 1}. ${question.question}`}</h3>
                            {question.options.map((option, optionIndex) => (
                                <div key={optionIndex}>
                                    <label>
                                        <input
                                            type="radio"
                                            name={`question_${question._id}`}
                                            value={optionIndex}
                                            checked={selectedAnswers[question._id] === optionIndex}
                                            onChange={() => handleOptionChange(question._id, optionIndex)}
                                        />
                                        {`${optionIndex + 1}. ${option}`} 
                                    </label>
                                </div>
                            ))}
                        </div>
                    ))}
                    <button type="submit">Submit</button>
                </form>
            )}
        </div>
    );
};

export default ActivityPage;

*/

/*
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ActivityPage = () => {
    const { id } = useParams();
    const [activity, setActivity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [accessError, setAccessError] = useState(null); // To display access error
    const navigate = useNavigate();
    const [startTime, setStartTime] = useState(null); // To store the start time
    const [remainingTime, setRemainingTime] = useState(0); // To store remaining time
    const [selectedAnswers, setSelectedAnswers] = useState({}); // Store selected answers

    // Access control effect
    useEffect(() => {
        const checkAccess = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}/access`, {
                    credentials: 'include',
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        setAccessError('You do not have permission to access this activity.');
                        return;
                    }
                    throw new Error('Failed to verify access');
                }

                const accessData = await response.json();
                // Check the response message or status to determine if access is granted
                if (accessData.message === 'Access granted as author' || accessData.hasAccess) {
                    // Access granted, proceed to load the activity
                    setAccessError(null); // Clear any previous access error
                } else {
                    setAccessError('You do not have permission to access this activity.');
                }
            } catch (err) {
                setAccessError(err.message);
            }
        };

        checkAccess();
    }, [id]);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}`, {
                    credentials: 'include',
                });

                if (!response.ok) {
                    if (response.status === 403) {
                        navigate('/unauthorized');
                        return;
                    }
                    throw new Error('Failed to fetch activity');
                }

                const data = await response.json();
                setActivity(data.activity);

                // Ensure data.userAttempt exists and has startTime
                if (!data.userAttempt || !data.userAttempt.startTime) {
                    // If no attempt found or no startTime, update startTime
                    await updateStartTime();
                } else {
                    // Use existing startTime
                    const { startTime } = data.userAttempt;
                    setStartTime(new Date(startTime)); // Store as Date object
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchActivity();
    }, [id, navigate]);

    const updateStartTime = async () => {
        try {
            // Step 1: Get CSRF token
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include', // Ensure cookies are included in the request
            });

            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }

            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken; // Assuming the CSRF token is in csrfData.csrfToken

            // Step 2: Use the CSRF token in the POST request
            const response = await fetch(`https://lernacy.co.za/api/activity/${id}/attempt`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken, // Include the CSRF token here
                },
            });

            if (!response.ok) {
                throw new Error('Failed to start activity');
            }

            const data = await response.json();
            const { startTime } = data;
            setStartTime(new Date(startTime)); // Store as Date object
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const updateRemainingTime = () => {
            if (startTime && activity) {
                const currentTime = new Date();
                const endTime = new Date(startTime.getTime() + activity.timeLimit * 60 * 1000); // Calculate end time
                const remaining = Math.max(0, Math.floor((endTime - currentTime) / 1000)); // Remaining time in seconds
                setRemainingTime(remaining);
            }
        };

        updateRemainingTime(); // Update immediately on load
        const interval = setInterval(updateRemainingTime, 1000); // Update every second
        return () => clearInterval(interval);
    }, [startTime, activity]);

    const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = ''; // This triggers the confirmation dialog
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // Handle answer selection
    const handleOptionChange = (questionId, optionIndex) => {
        setSelectedAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: optionIndex, // Store the selected option index for each question
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.clear(); // Clear previous logs
        
        // Check if all questions have been answered
        const allAnswered = activity.questions.every((question) =>
            selectedAnswers.hasOwnProperty(question._id)
        );

        if (!allAnswered) {
            alert('Please answer all questions before submitting.');
            return; // Prevent submission
        }

        // Fetch the correct answers from the server
        try {
            const response = await fetch(`https://lernacy.co.za/api/activity/${id}/new-activity`, {
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch correct answers');
            }

            const { correctAnswers } = await response.json();

            // Assess the activity
            const results = correctAnswers.map(({ questionId, correctAnswer }) => {
                const userAnswer = selectedAnswers[questionId];
                return {
                    questionId,
                    isCorrect: userAnswer !== undefined && (userAnswer + 1).toString() === correctAnswer, // Compare with user input (1-indexed)
                };
            });

            // console.log('Assessment Results:', results);
        } catch (err) {
            console.error(err.message);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (accessError) return <div>{accessError}</div>; // Display access error if any
    if (error) return <div>{error}</div>;
    if (!activity) return <div>Activity not found</div>;

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
        <div style={{ userSelect: 'none' }}>
            <h1>{activity.activityName}</h1>
            <p>Type: {activity.activityType}</p>
            <p>Time Given: {activity.timeLimit} minutes</p>
            <p>Author: {activity.authorId.accountName}</p>
            <p>Remaining Time: {remainingTime > 0 ? `${minutes}m ${seconds}s` : 'Time is up!'}</p>

            <form onSubmit={handleSubmit}>
                {activity.questions.map((question, index) => (
                    <div key={question._id}>
                        <h3>{`${index + 1}. ${question.question}`}</h3>
                        {question.options.map((option, optionIndex) => (
                            <div key={optionIndex}>
                                <label>
                                    <input
                                        type="radio"
                                        name={`question_${question._id}`}
                                        value={optionIndex}
                                        checked={selectedAnswers[question._id] === optionIndex}
                                        onChange={() => handleOptionChange(question._id, optionIndex)}
                                    />
                                    {`${optionIndex + 1}. ${option}`} 
                                    </label>
                                    </div>
                                ))}
                            </div>
                        ))}
                        <button type="submit">Submit</button>
                    </form>
                </div>
            );
        };
        
        export default ActivityPage;
*/
 
/*
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ActivityPage = () => {
    const { id } = useParams();
    const [activity, setActivity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [accessError, setAccessError] = useState(null); // To display access error
    const navigate = useNavigate();
    const [startTime, setStartTime] = useState(null); // To store the start time
    const [remainingTime, setRemainingTime] = useState(0); // To store remaining time

    // Access control effect
    useEffect(() => {
        const checkAccess = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}/access`, {
                    credentials: 'include',
                });
    
                if (!response.ok) {
                    if (response.status === 403) {
                        setAccessError('You do not have permission to access this activity.');
                        return;
                    }
                    throw new Error('Failed to verify access');
                }
    
                const accessData = await response.json();
                // Check the response message or status to determine if access is granted
                if (accessData.message === 'Access granted as author' || accessData.hasAccess) {
                    // Access granted, proceed to load the activity
                    setAccessError(null); // Clear any previous access error
                } else {
                    setAccessError('You do not have permission to access this activity.');
                }
            } catch (err) {
                setAccessError(err.message);
            }
        };
    
        checkAccess();
    }, [id]);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const response = await fetch(`https://lernacy.co.za/api/activity/${id}`, {
                    credentials: 'include',
                });
    
                if (!response.ok) {
                    if (response.status === 403) {
                        navigate('/unauthorized');
                        return;
                    }
                    throw new Error('Failed to fetch activity');
                }
    
                const data = await response.json();
                setActivity(data.activity);
    
                // Ensure data.userAttempt exists and has startTime
                if (!data.userAttempt || !data.userAttempt.startTime) {
                    // If no attempt found or no startTime, update startTime
                    await updateStartTime();
                } else {
                    // Use existing startTime
                    const { startTime } = data.userAttempt;
                    setStartTime(new Date(startTime)); // Store as Date object
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
    
        fetchActivity();
    }, [id, navigate]);

    const updateStartTime = async () => {
        try {
            // Step 1: Get CSRF token
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include', // Ensure cookies are included in the request
            });
    
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
    
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken; // Assuming the CSRF token is in csrfData.csrfToken
    
            // Step 2: Use the CSRF token in the POST request
            const response = await fetch(`https://lernacy.co.za/api/activity/${id}/attempt`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken, // Include the CSRF token here
                },
            });
    
            if (!response.ok) {
                throw new Error('Failed to start activity');
            }
    
            const data = await response.json();
            const { startTime } = data;
            setStartTime(new Date(startTime)); // Store as Date object
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const updateRemainingTime = () => {
            if (startTime && activity) {
                const currentTime = new Date();
                const endTime = new Date(startTime.getTime() + activity.timeLimit * 60 * 1000); // Calculate end time
                const remaining = Math.max(0, Math.floor((endTime - currentTime) / 1000)); // Remaining time in seconds
                setRemainingTime(remaining);
            }
        };

        updateRemainingTime(); // Update immediately on load
        const interval = setInterval(updateRemainingTime, 1000); // Update every second
        return () => clearInterval(interval);
    }, [startTime, activity]);

    const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = ''; // This triggers the confirmation dialog
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    if (loading) return <div>Loading...</div>;
    if (accessError) return <div>{accessError}</div>; // Display access error if any
    if (error) return <div>{error}</div>;
    if (!activity) return <div>Activity not found</div>;

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
        <div style={{ userSelect: 'none' }}>
            <h1>{activity.activityName}</h1>
            <p>Type: {activity.activityType}</p>
            <p>Time Given: {activity.timeLimit} minutes</p>
            <p>Author: {activity.authorId.accountName}</p>
            <p>Remaining Time: {remainingTime > 0 ? `${minutes}m ${seconds}s` : 'Time is up!'}</p>
        </div> 
    );
};

export default ActivityPage;
*/
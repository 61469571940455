import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './pages-styling/study.css';
import axios from 'axios';

const PrivacyPolicy = () => {

  return (
    <div style={{paddingTop:'10px'}}>  

      <div className="my-study-container">  

        <div className='meant-to-align'>
          <div className='align-container'>

            <div className="my-text-container" to="/application-practice">
              <div className="terms-block">

                <h1>
                  Privacy Policy 
                </h1>
                <p>
                  At Lernacy, we are committed to protecting your privacy and ensuring that your personal 
                  information is handled in a safe and responsible manner. This Privacy Policy outlines how 
                  we collect, use, disclose, and safeguard your information when you use our application.
                </p>

                <h2>
                  Information We Collect
                </h2>
                <p>
                  As part of the registration process, we collect personal information, including but not limited to:
                </p>

                <ul className='terms-list'>
                  <li>Email address</li>
                  <li>Name</li>
                  <li>Phone number</li>
                  <li>Profile information (such as preferences and settings)</li>
                </ul>
                <p>
                  We may also collect usage data as you navigate through the app, which helps us improve our 
                  services and user experience.
                </p>

                <h2>Use of Personal Information</h2>
                <p>We use the personal information we collect for various purposes, including:</p>
                <ul className='terms-list'>
                  <li>To create and manage your account</li>
                  <li>To provide you with customer support</li>
                  <li>To send you updates, newsletters, and marketing communications (if you have opted in)</li>
                  <li>To improve our application and develop new features</li>
                </ul>

                <h2>Data Protection</h2>
                <p>We take the security of your personal information seriously. We implement appropriate technical and organizational measures to protect your data, including the use of up-to-date software packages to secure your information from unauthorized access and data breaches.</p>

                <h2>Sharing of Personal Information</h2>
                <p>We do not sell, trade, or rent your personal information to third-party companies unless we explicitly state otherwise. However, we may share your information with trusted third-party service providers who assist us in operating our application, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</p>

                <h2>Research and Data Exchange</h2>
                <p>We may also generate aggregate data through the app, which may be exchanged with third-party services or tertiary institutions for research and study purposes. We guarantee that this data will be anonymized and will not include any personal information that could identify you as an individual.</p>

                <h2>User Controls and Privacy Settings</h2>
                <p>Our application provides users with settings to control their personal data visibility and privacy. Users can:</p>
                <ul className='terms-list'>
                  <li>Adjust privacy settings to conceal personal information</li>
                  <li>Manage preferences for communication and marketing materials</li>
                  <li>Request to access, update, or delete their personal information at any time</li>
                </ul>

                <h2>Cookies and Tracking Technologies</h2>
                <p>We use cookies and similar tracking technologies to monitor activity on our application and store certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our application.</p>

                <h2>Children's Privacy</h2>
                <p>
                  At Lernacy, we believe that parental guidance is crucial in ensuring a safe and positive 
                  online experience for children. We encourage parents and guardians to supervise their 
                  children's use of our application, particularly for users under the age of 13. While we do 
                  not knowingly collect personal information from children, we understand that younger users 
                  may access our platform for educational purposes.
                </p>
                <p>
                  We recommend that parents engage with their children as they explore the features of our app, 
                  including discussing the information that may be shared during the registration process. 
                  Parents can also utilize our privacy settings to help manage their child's account, including 
                  options to restrict visibility and control communications.
                </p>
                <p>
                  If you are a parent or guardian and believe that your child has provided us with personal 
                  information without your consent, please contact us immediately. We will take the necessary 
                  steps to delete such information from our records. Ensuring the privacy and safety of all our 
                  users, especially children, is a top priority for us.
                </p>

                <h2>Changes to This Privacy Policy</h2>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us:</p>
                <ul className='terms-list'>
                  <li>Email: admin@lernacy.co.za</li>
                  <li>Phone: +27 71 169 0833</li>
                </ul>

                <p>Thank you for choosing Lernacy. Your trust is important to us, and we are committed to protecting your personal information.</p>

                <br />
                <hr />

                <label><strong>Effective Date:</strong> October 31, 2024</label><br />
                <label><strong>Last Updated:</strong> October 26, 2024</label>

              </div>

            </div>

          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default PrivacyPolicy;
  
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PinValidation from './PinValidation';

const StoryBlock1 = ({ user }) => {
    const [answers, setAnswers] = useState({});
    const [schoolNameInput, setSchoolNameInput] = useState('');
    const [schoolNameSuggestions, setSchoolNameSuggestions] = useState([]);
    const [pinValidated, setPinValidated] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchSchoolNameSuggestions = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/institutions/schools/names');
                const { success, schoolNames } = response.data;
                if (success) {
                    setSchoolNameSuggestions(schoolNames);
                } else {
                    console.error('Failed to fetch school names:', response.data);
                }
            } catch (error) {
                console.error('Error fetching school names:', error);
            }
        };

        const fetchUserId = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/study', { withCredentials: true });
                const { user } = response.data;
                setUserId(user._id);
            } catch (error) {
                console.error('Error fetching user ID:', error);
            }
        };

        fetchSchoolNameSuggestions();
        fetchUserId();
    }, []);

    const handleChange = (e) => {
        setAnswers({
            ...answers,
            [e.target.name]: e.target.value
        });
    };

    const handleSchoolNameInputChange = (e) => {
        const { value } = e.target;
        setSchoolNameInput(value);

        const filteredSuggestions = schoolNameSuggestions.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setSchoolNameSuggestions(filteredSuggestions);
    };

    const validatePin = () => {
        // Additional logic for validation if needed
        return pinValidated;
    };

    const onSubmit = async () => {
        if (!validatePin()) {
            alert('PIN validation failed. Submission blocked.');
            return;
        }
    
        try {
            // Fetch CSRF token
            const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
            if (!csrfResponse.data.csrfToken) {
                throw new Error('Failed to fetch CSRF token');
            }
            const csrfToken = csrfResponse.data.csrfToken;
    
            const correctAnswers = {
                question1: 'C',
                question2: 'C',
                question3: 'C',
                question4: 'B',
                question5: 'A',
                question6: 'B',
                question7: 'C',
                question8: 'C',
                question9: 'B',
                question10: 'C',
                
            };
    
            let score = 0;
            for (let key in correctAnswers) {
                if (answers[key] === correctAnswers[key]) {
                    score++;
                }
            }
    
            const submissionData = {
                score: score,
                taskBlock: 3,
                userId: userId,
                taskName: 'Comprehension-Levels',
                schoolName: schoolNameInput,
            };
    
            // console.log('Submission Data:', submissionData); // Log the data being passed to the route
    
            // POST request with CSRF token and credentials in headers
            const response = await axios.post('https://lernacy.co.za/api/ed-support/taskSubmit', submissionData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken // Include CSRF token in the header
                }
            });
    
            if (response.data.success) {
                alert('Task submitted successfully!');
                window.location.reload(); // Reload the page after successful submission
            } else {
                console.error('Failed to submit task:', response.data.error);
                alert('Failed to submit task. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting task:', error);
            alert('Error submitting task. Please check your network connection.');
        }
    };

    return (
        <div className='story-wall-block'>
            <h2>Block 3</h2>
            <h3><strong>Echoes of Jozi</strong></h3>
            <div className='story-div-image-text'>
                <div className='story-image-cover-block'>
                    <img loading="lazy" src='/activityImages/ed-support/echoes-of-jozi.jfif' alt='Echoes of Jozi' />
                </div>
                <div> 
                    <p className='story-p-tag'>
                        In the heart of Johannesburg, where the city pulses with a rhythm as vibrant as its 
                        people, lived Nomalizo. She was a girl of eleven, with eyes that gleamed like the 
                        golden sunsets over the African savannah and a spirit as free as the winds that swept 
                        across the Highveld.
                    </p> 
                    <p className='story-p-tag'>
                        Nomalizo's days were woven with the threads of her city's rich tapestry. Each morning, 
                        she woke to the cacophony of sounds: the chatter of street vendors setting up their 
                        stalls, the distant hum of taxis bustling through the streets, and the laughter of 
                        children playing in the dusty parks.
                    </p>
                    <p className='story-p-tag'>
                        Her home was a modest one in Soweto, where the red earth bore witness to a history of 
                        struggle and resilience. Nomalizo loved to wander the bustling streets, her feet bare 
                        against the warm concrete, soaking in the stories whispered by the walls adorned with 
                        vibrant street art.
                    </p>
                    <p className='story-p-tag'>
                        One day, as she strolled through the lively market, a burst of color caught her eye. 
                        It was a mural depicting the heroes of South Africa's fight for freedom: faces of 
                        Nelson Mandela, Winnie Madikizela-Mandela, and Desmond Tutu smiling down at her. 
                        Nomalizo stood mesmerized, tracing their journeys from the dark days of apartheid 
                        to the dawn of democracy.
                    </p>
                    <p className='story-p-tag'>
                        Inspired by their courage, Nomalizo dreamt of her own future. She wanted to be a 
                        storyteller, weaving tales of hope and resilience, just like the city that raised her. 
                        With each passing day, she collected moments in her heart: the kindness of strangers, 
                        the music that echoed through the streets at night, and the flavors of her 
                        grandmother's cooking that spoke of a heritage as rich as the red soil beneath her feet.
                    </p>
                    <p className='story-p-tag'>
                        One evening, under the star-studded sky, Nomalizo sat on the stoop of her home, a 
                        notebook resting on her lap. As she scribbled down her thoughts, she felt the 
                        heartbeat of Jozi beneath her, a city that had seen struggle and triumph, pain and 
                        joy, and yet always emerged with an indomitable spirit.
                    </p>
                    <p className='story-p-tag'>
                        In that moment, Nomalizo knew that she carried within her the echoes of Jozi – the 
                        voices of her ancestors, the dreams of her people, and the promise of a future where 
                        every story mattered. And as she looked up at the sky, she whispered a promise to 
                        herself: to always listen, to always learn, and to always share the untold tales that 
                        made her city shine with resilience and hope.
                    </p>
                    <p className='story-p-tag'>
                        Thus, in the heart of Johannesburg, Nomalizo's story began – a story of a girl who 
                        found her voice amidst the echoes of Jozi, forever intertwined with the rhythm of her 
                        city's beating heart.
                    </p>
                </div>
            </div>

            <hr />

            <div className='comprehension-questions'>
                <h3>Comprehension Questions</h3>
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <p>1. What is the main character's name in the story?</p>
                        <label>
                            <input type="radio" name="question1" value="A" onChange={handleChange} />
                            A) Nompumelelo
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="B" onChange={handleChange} />
                            B) Nomvula
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="C" onChange={handleChange} />
                            C) Nomalizo
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="D" onChange={handleChange} />
                            D) Nomsa
                        </label>
                    </div>

                    <div>
                        <p>2. Where does Nomalizo live?</p>
                        <label>
                            <input type="radio" name="question2" value="A" onChange={handleChange} />
                            A) Cape Town
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="B" onChange={handleChange} />
                            B) Durban
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="C" onChange={handleChange} />
                            C) Johannesburg
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="D" onChange={handleChange} />
                            D) Pretoria
                        </label>
                    </div>

                    <div>
                        <p>3. What is unique about Nomalizo's eyes?</p>
                        <label>
                            <input type="radio" name="question3" value="A" onChange={handleChange} />
                            A) They change color with her mood
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="B" onChange={handleChange} />
                            B) They are a deep shade of blue
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="C" onChange={handleChange} />
                            C) They sparkle like the sunsets
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="D" onChange={handleChange} />
                            D) They have a piercing gaze
                        </label>
                    </div>

                    <div>
                        <p>4. What is Soweto known for in South Africa?</p>
                        <label>
                            <input type="radio" name="question4" value="A" onChange={handleChange} />
                            A) Its beaches and coastline
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="B" onChange={handleChange} />
                            B) Its rich cultural heritage
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="C" onChange={handleChange} />
                            C) Its snowy mountains
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="D" onChange={handleChange} />
                            D) Its dense forests
                        </label>
                    </div>

                    <div>
                        <p>5. What inspired Nomalizo to dream of becoming a storyteller?</p>
                        <label>
                            <input type="radio" name="question5" value="A" onChange={handleChange} />
                            A) A mural depicting South African heroes
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="B" onChange={handleChange} />
                            B) A book about Johannesburg
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="C" onChange={handleChange} />
                            C) A movie about African wildlife
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="D" onChange={handleChange} />
                            D) A song sung by her grandmother
                        </label>
                    </div>

                    <div>
                        <p>6. What did Nomalizo want to weave tales about?</p>
                        <label>
                            <input type="radio" name="question6" value="A" onChange={handleChange} />
                            A) Adventure in far-off lands
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="B" onChange={handleChange} />
                            B) Stories of hope and resilience
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="C" onChange={handleChange} />
                            C) Mysteries of ancient civilizations
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="D" onChange={handleChange} />
                            D) Legends of mythical creatures
                        </label>
                    </div>

                    <div>
                        <p>7. Where did Nomalizo often wander in the city?</p>
                        <label>
                            <input type="radio" name="question7" value="A" onChange={handleChange} />
                            A) Shopping malls
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="B" onChange={handleChange} />
                            B) National parks
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="C" onChange={handleChange} />
                            C) Marketplaces
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="D" onChange={handleChange} />
                            D) Movie theaters
                        </label>
                    </div>

                    <div>
                        <p>8. What did Nomalizo's grandmother cook that spoke of their heritage?</p>
                        <label>
                            <input type="radio" name="question8" value="A" onChange={handleChange} />
                            A) Pizza and pasta
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="B" onChange={handleChange} />
                            B) Sushi and seafood
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="C" onChange={handleChange} />
                            C) Traditional African dishes
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="D" onChange={handleChange} />
                            D) Indian curries
                        </label>
                    </div>

                    <div>
                        <p>9. What promise did Nomalizo make to herself at the end of the story?</p>
                        <label>
                            <input type="radio" name="question9" value="A" onChange={handleChange} />
                            A) To travel the world
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="B" onChange={handleChange} />
                            B) To always listen and learn
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="C" onChange={handleChange} />
                            C) To become a famous singer
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="D" onChange={handleChange} />
                            D) To move to another city
                        </label>
                    </div>

                    <div>
                        <p>10. What was the main theme of the story "Echoes of Jozi"?</p>
                        <label>
                            <input type="radio" name="question10" value="A" onChange={handleChange} />
                            A)  The dangers of urban life
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="B" onChange={handleChange} />
                            B) The beauty of nature
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="C" onChange={handleChange} />
                            C) The resilience of a city and its people
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="D" onChange={handleChange} />
                            D) The mysteries of ancient civilizations
                        </label>
                    </div>

                </form>
            </div>

            <hr />

            <div className='school-name-pin-block'>
                <h3>School Information</h3>
                <label htmlFor="schoolNameInput">School Name:</label><br />
                <input
                    type="text"
                    id="schoolNameInput"
                    name="schoolNameInput"
                    value={schoolNameInput}
                    onChange={handleSchoolNameInputChange}
                    list="schoolNameSuggestions"
                    required
                    className='tasks-school-name-input'
                /><br />
                <datalist id="schoolNameSuggestions">
                    {schoolNameSuggestions.map((schoolName, index) => (
                        <option key={index} value={schoolName} />
                    ))}
                </datalist>
                {!pinValidated && (
                    <PinValidation
                        schoolNameInput={schoolNameInput}
                        onSubmit={() => setPinValidated(true)}
                    />
                )}
                {pinValidated && (
                    <button className='activity-submit-button' type="button" onClick={onSubmit}>
                        Submit Task 
                    </button>
                )}
            </div>
        </div>
    );
};

export default StoryBlock1;
import React from 'react';
import { Link } from 'react-router-dom';

const Copyright = ({ errorMessage }) => {
  return (
    <footer className='copyright-text-block-main'>
      <div className='copyright-text-block'>
        <p>
          © 2024 Lernacy. All Rights Reserved.
        </p> 
        <nav> 
          <Link to="/terms-of-use" style={{margin: '0px 5px', color:'blue', textDecoration:'underline'}}>
            Terms of Service
          </Link>
           |   
          <Link to="/privacy-policy" style={{margin: '0px 5px', color:'blue', textDecoration:'underline'}}>
            Privacy Policy
          </Link>
        </nav>
      </div> 
    </footer>
  );
};

export default Copyright; 
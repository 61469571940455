// Ensure all imports are at the top
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const MultiModalLearning = () => {
  const [user, setUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include', // Include credentials for cross-origin requests
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          // Check if user has an account name
          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);

          // Redirect to login if not authenticated
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);

        // Redirect to login on error
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  // Sample data that represents the items to be searched
  const items = [
    {
        text: ["Social Sciences", "People And Places", "Grade 4", "Session: 1"],
        link: "/grade4_social-sciences_session-1"
    },
    {
      text: ["Social Sciences", "Natural Vegetation", "Grade 5", "Session: 2"],
      link: "/grade5_social-sciences_session-2"
    },
    {
      text: ["Social Sciences", "Exploring Urbanization", "Grade 8", "Session: 3"],
      link: "/grade8_social-sciences_session-3"
    },
    // Add more items as needed
  ];

  // Filter items based on search query
  const filteredItems = items.filter(item =>
    item.text.some(text => text.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className='page-colour-manager'>
        <h1 className='activity-name-tag-h1'>Multimodal Learning</h1> 

        {/*
        {user ? (
            <p>Welcome, {user.name} {user.surname}!</p>
        ) : (
            <p>Loading user data...</p>
        )}
        */}

        <div className='search-box-container'>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-box"
            />
        </div>

        <div className="my-study-container">
            <div className='meant-to-align'>
                <div className='align-container'>
                    {filteredItems.map((item, index) => (
                    <Link
                        key={index}
                        style={{ backgroundColor: '#fff' }}
                        className="my-text-container"
                        to={item.link}
                    >
                        <div className="my-multimodal-text">
                            {item.text.map((text, idx) => (
                                <p key={idx} className={idx === 0 ? 'subject-name-in-multimodal' : idx === 1 ? 'topic-text' : ''}>
                                    {text}
                                </p>
                            ))}
                        </div>
                    </Link>
                    ))}
                </div>
            </div>
        </div>
    </div>
  );
};

export default MultiModalLearning;


// src/pages/SensoryPlay.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './pages-styling/early-child.css';

const SensoryPlay = () => {
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    playerName: '',
    playerSurname: '',
    playerAge: '',
    playerGender: '',
  });

  useEffect(() => {
    // Make a request to the server to get user information
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/play-block', {
          method: 'GET',
          credentials: 'include', // Include credentials for cross-origin requests
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchUser();
  }, []); // Run the effect once on component mount

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validate playerAge to allow only numbers
    if (name === 'playerAge' && isNaN(value)) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate the form data on the client side
    if (!formData.playerGender) {
      alert('Please select a gender.');
      return;
    }
  
    try {
      const response = await fetch('https://lernacy.co.za/api/play-block', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include credentials for cross-origin requests
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
  
      if (response.ok) {
       // console.log('Form submission successful:', data.message);
        alert('Form submitted successfully!');
      } else {
        console.error('Error submitting form:', data.message);
        alert('Error submitting form: ' + data.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error.message);
      alert('Error submitting form');
    }
  };

  return (
    <div>
      <h1>Sensory Play Page</h1>

      {user ? (
        <p>
          Welcome, {user.name} {user.surname}!{' '}
          {user.childAccess ? 'Child access is enabled.' : 'Child access is disabled.'}
        </p>
      ) : (
        <p>Loading user data...</p>
      )}

      {user && !user.childAccess ? (
        <div>
          <form onSubmit={handleSubmit}>
            <label>
              Player Name:
            </label><br />
            <input type="text" name="playerName" value={formData.playerName} onChange={handleInputChange}/>
            <br />

            <label>
              Player Surname:
            </label><br />
            <input type="text" name="playerSurname" value={formData.playerSurname} onChange={handleInputChange}/>
            <br />

            <label>
              Player Age:
            </label><br />
            <input type="number" name="playerAge" value={formData.playerAge} onChange={handleInputChange}/>
            <br />

            <label>
              Player Gender:
            </label><br />
            <select name="playerGender" value={formData.playerGender} onChange={handleInputChange} required>
              <option value="" disabled>Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            <br /><br />

            <button type="submit">Submit</button>
          </form>
        </div>
      ) : (
        <div className="sensory-main-card-block">
          
          <div className="main-card-block">
            <Link to="/animal-names" className="cover-link">
              <div className='title-cover'>
                <h2 className="card-title">Animal Names</h2>
              </div>

              <div className='record-bar'>

                <div className='record-div'>
                  <p>Name</p>
                </div>

                <div className='record-div'>
                  <p>Score</p>
                </div>

                <div className='record-div'>
                  <p>Time</p>
                </div>

                <div className='record-div'>
                  <p>Solved</p>
                </div>

              </div>
            </Link>
          </div>

          <div className="main-card-block">
            <Link to="/your-link-target" className="cover-link">
              <div className='title-cover'>
                <h2 className="card-title">Animal Names</h2>
              </div>

              <div className='record-bar'>

                <div className='record-div'>
                  <p>Name</p>
                </div>

                <div className='record-div'>
                  <p>Score</p>
                </div>

                <div className='record-div'>
                  <p>Time</p>
                </div>

                <div className='record-div'>
                  <p>Solved</p>
                </div>

              </div>
            </Link>
          </div>

          <div className="main-card-block">
            <Link to="/your-link-target" className="cover-link">
              <div className='title-cover'>
                <h2 className="card-title">Animal Names</h2>
              </div>

              <div className='record-bar'>

                <div className='record-div'>
                  <p>Name</p>
                </div>

                <div className='record-div'>
                  <p>Score</p>
                </div>

                <div className='record-div'>
                  <p>Time</p>
                </div>

                <div className='record-div'>
                  <p>Solved</p>
                </div>

              </div>
            </Link>
          </div>

        </div>
      )}

    </div>
  );
};

export default SensoryPlay;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './bodyFiles-styling/GiveBack.css';

const GiveBack = () => {
    const [data, setData] = useState([]);
    const [userStatus, setUserStatus] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAdmin, setShowAdmin] = useState(false); // State to toggle between sections
    const [formData, setFormData] = useState({ text: '', includeCredentials: '', sharePost: '' }); // State for form data
    const [documentsWithAuthors, setDocumentsWithAuthors] = useState([]); // New state for documents with authors
    const navigate = useNavigate();
    const [user, setUser] = useState({ name: '', surname: '' });
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        const fetchGiveBackData = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/give-back', {
                    withCredentials: true // Ensures cookies (like tokens) are sent with the request
                });
    
                if (response.data) {
                    setData(response.data.giveBackDocuments || []); // Default to an empty array if no documents
                    setUser(response.data.user || { name: '', surname: '' }); // Set user info from response
                    setUserStatus(response.data.userStatus); // Set user status from response
                }
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    setError('You are not authenticated. Redirecting to login...');
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                } else {
                    setError('An error occurred while fetching data.');
                }
                setLoading(false);
            }
        };
    
        fetchGiveBackData();
    }, [navigate]);

    // Fetch the unapproved documents on component mount
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/give-back-approve', {
                    withCredentials: true,
                });
                setDocuments(response.data.giveBackDocuments || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching documents:', err);
                setError('Failed to load documents');
                setLoading(false);
            }
        };

        fetchDocuments();
    }, []);

    const handleToggleSection = () => {
        setShowAdmin((prev) => !prev); // Toggle the section
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    // Function to handle approval
    const handleApprove = async (doc) => {
        try {
            // Step 1: Fetch the CSRF token from the server
            const csrfResponse = await axios.get('https://lernacy.co.za/api/form', {
                withCredentials: true,
            });
    
            if (!csrfResponse.data.csrfToken) {
                console.error('CSRF token not found');
                return;
            }
    
            const csrfToken = csrfResponse.data.csrfToken;
    
            // Step 2: Prepare the data with the CSRF token included
            const dataToSend = {
                ...doc,
                _csrf: csrfToken, // Include the CSRF token
            };
    
            // Step 3: Console log the data being sent
            console.log('Data being sent to the server:', dataToSend);
    
            // Step 4: Send the approval request with the CSRF token
            await axios.post('https://lernacy.co.za/api/give-back-approve', dataToSend, {
                withCredentials: true,
            });
    
            // Remove the approved document from the local state
            setDocuments((prevDocs) => prevDocs.filter((item) => item._id !== doc._id));
        } catch (err) {
            console.error('Error approving document:', err);
            setError('Failed to approve document');
        }
    };    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // Step 1: Fetch the CSRF token from the server
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include', // Include credentials for the request
            });
    
            if (!csrfResponse.ok) {
                console.error('Error fetching CSRF token:', csrfResponse.statusText);
                return; // Exit if unable to get the CSRF token
            }
    
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken; // Assuming the token is sent in this field
    
            // Step 2: Prepare the data for the POST request, including the CSRF token
            const dataToSend = {
                ...formData,  // Spread the existing formData to include all its properties
                _csrf: csrfToken, // Include the CSRF token in the data
            };
    
            // Step 3: Make the POST request with the CSRF token
            const response = await fetch('https://lernacy.co.za/api/give-back', {
                method: 'POST',
                credentials: 'include', // Include credentials for the request
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend), // Send the form data including the CSRF token
            });
    
            if (response.ok) {
                const responseData = await response.json(); // Parse the response data
                setData((prev) => [...prev, responseData]); // Add the new document to the existing state
                setFormData({ text: '', includeCredentials: false, sharePost: false }); // Reset form
    
                // Show alert message on successful form submission
                alert("Form submitted successfully!");
            } else {
                console.error('Error submitting form:', response.statusText);
            }
        } catch (error) {
            console.error('Error during form submission:', error);
        }
    };      

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className='activity-page-main-border'>
            <h1 className='activity-name-tag-h1'>Giving Back</h1>
            <div className='filing-system-page-container'>
                <div className="filing-system-page">
                    <div >
                        {error ? (
                            <p>{error}</p> 
                        ) : (
                            <>
                                {/* Buttons for navigation */}
                                {userStatus && userStatus.toLowerCase() === 'admin' && (
                                    <div className='admin-button-switch-point'>
                                        <button onClick={handleToggleSection}>
                                            {showAdmin ? 'Show Public Documents' : 'Show Admin Approve'}
                                        </button>
                                    </div>
                                )}

                                {/* Public Documents Section */}
                                {!showAdmin && (
                                    <div>
                                        {/* Form to submit new GiveBack documents */}
                                        <h2>Submit your statement</h2>
                                        <form onSubmit={handleSubmit} className='submit-statement-form'>
                                            <div>
                                                <label htmlFor="text">Document Text (max 750 characters):</label><br />
                                                <textarea
                                                    id="text"
                                                    name="text"
                                                    value={formData.text}
                                                    onChange={handleChange}
                                                    maxLength={750}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label>Do you want your name and surname to show?:</label><br />
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="includeCredentials"
                                                        value="true"
                                                        checked={formData.includeCredentials === 'true'}
                                                        onChange={handleChange}
                                                    />
                                                    Yes
                                                </label><br />
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="includeCredentials"
                                                        value="false"
                                                        checked={formData.includeCredentials === 'false'}
                                                        onChange={handleChange}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                            <div>
                                                <label>Do you want us to share this post to our social media accounts:</label><br />
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="sharePost"
                                                        value="true"
                                                        checked={formData.sharePost === 'true'}
                                                        onChange={handleChange}
                                                    />
                                                    Yes
                                                </label><br /> 
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="sharePost"
                                                        value="false"
                                                        checked={formData.sharePost === 'false'}
                                                        onChange={handleChange}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                            <button type="submit">Submit Document</button>
                                        </form>

                                        <hr />

                                        <h2>GiveBack Documents</h2>
                                        {data.length > 0 ? (
                                            <ul className='Give-back-block-list-ul'>
                                                {data.map((doc) => (
                                                    <li key={doc._id}>
                                                        {doc.includeCredentials && (
                                                            <p><strong>{doc.authorName} {doc.authorSurname}</strong> </p>
                                                        )}
                                                        <p>{doc.text}</p>
                                                        
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : ( 
                                            <p>No documents available for the public section.</p>
                                        )}
                                    </div>
                                )}

                                {/* Admin Section */}
                                {showAdmin && (
                                    <div>
                                        <h1>Unapproved GiveBack Documents</h1>
                                        {documents.length > 0 ? (
                                            documents.map((doc) => (
                                                <div key={doc._id} style={{ border: '1px solid #ccc', padding: '1rem', marginBottom: '1rem' }}>
                                                    <p><strong>Text:</strong> {doc.text}</p>
                                                    <p><strong>Include Credentials:</strong> {doc.includeCredentials ? 'Yes' : 'No'}</p>
                                                    <p><strong>Share Post:</strong> {doc.sharePost ? 'Yes' : 'No'}</p>
                                                    <button onClick={() => handleApprove(doc)}>Approve</button>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No documents to approve</p>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <br /> <br />
        </div>
    );
};

export default GiveBack;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; 

const ServiceHub = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Make a request to the server to get user information
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include', // Include credentials for cross-origin requests
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          // Check if user has an account name
          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);

          // Redirect to login if not authenticated
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);

        // Redirect to login on error
        navigate('/login');
      }
    };

    fetchUser();
  }, [navigate]); // Include navigate in the dependency array

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');
  
    const formData = new FormData();
    formData.append('profilePicture', file);
  
    try {
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      // Upload the image
      const response = await fetch('https://lernacy.co.za/api/test-uploadImage', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'CSRF-Token': csrfToken, // Include the CSRF token in the headers
        },
        body: formData,
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to upload image');
      }
  
      setMessage(responseData.message);
    } catch (error) {
      console.error('Error uploading image:', error);
      setMessage('Error uploading image: ' + error.message);
    } finally {
      setLoading(false);
    }
  };  

  return (
    <div className='page-colour-manager'>

      <h1 className='activity-name-tag-h1'>Service Hub</h1>

      {/* 
      {user ? (
        <p>Welcome, {user.name} {user.surname}!</p>
        // Add other user information as needed
      ) : (
        <p>Loading user data...</p>
      )}
      */}

      <div className="my-study-container">  

        <div className='meant-to-align'>
            <div className='align-container'>

                <div className=''>
                    <h4>
                        We are actively verifying our service providers to ensure quality and reliability. 
                        If you'd like to <a className='reach-out-email-tab' href="mailto:admin@lernacy.co.za">reach out</a>, to us, please 
                        email us, and we’ll be happy to assist.
                    </h4>
                </div>

                <br />

            </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceHub;
// components/UnderConstruction.js
import React from 'react';
import './auth.css';

const UnderConstruction = () => {
  return (
    <div className="under-construction-container">
      <div className="under-construction-content">
        <img 
            src='/activityImages/site-under-construction.jfif'
            alt="Under Construction" 
            loading="lazy"
            className="construction-image" 
        />
      </div>
    </div>
  );
};

export default UnderConstruction;

import React, { useState, useEffect } from 'react';
import AccessDenied from '../../components/AccessDenied';

const GrThreeMultiVide = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [highScore, setHighScore] = useState(null);
  const [equations, setEquations] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const [gameScore, setGameScore] = useState(0);
  const [scoreRating, setScoreRating] = useState('');
  const [scoreComment, setScoreComment] = useState('');
  const [gameOver, setGameOver] = useState(false);
  const gameName = 'Grade_3:_MultiVide_Maths_10';

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          const user = data.user;

          if (!user || !user.accountName) {
            setIsAuthenticated(false);
            window.location.href = "/login-second-stage";
            return;
          }

          setUserDetails(user);
          setIsAuthenticated(true);
        } else {
          throw new Error('Error fetching user details: ' + response.statusText);
        }
      } catch (error) {
        console.error(error.message);
        setIsAuthenticated(false);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchHighScore();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      generateEquations();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Calculate score rating and comment
    const percentage = (gameScore / 10) * 100;
    const roundedPercentage = Math.round(percentage);

    if (roundedPercentage >= 90) {
      setScoreRating('Outstanding');
      setScoreComment('Congratulations! You performed outstandingly.');
    } else if (roundedPercentage >= 75) {
      setScoreRating('Passed');
      setScoreComment('Well done! You passed the game.');
    } else if (roundedPercentage >= 50) {
      setScoreRating('Almost');
      setScoreComment('You are close to passing. Keep practicing!');
    } else {
      setScoreRating('Failed');
      setScoreComment('Keep practicing. You can do better!');
    }
  }, [gameScore]);

  const fetchHighScore = async () => {
    try {
      const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        console.error('Error fetching high score:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching high score:', error.message);
    }
  };

  const generateEquations = () => {
    const newEquations = [];
    for (let i = 0; i < 10; i++) {
      let num1, num2, operator, result;

      if (Math.random() < 0.5) { // 50% chance for multiplication
        num1 = Math.floor(Math.random() * 21); // Numbers between 0 and 20
        num2 = Math.floor(Math.random() * 11); // Numbers between 0 and 10
        operator = '*';
        result = num1 * num2;
      } else { // 50% chance for division
        do {
          num1 = Math.floor(Math.random() * 101); // Numbers between 0 and 100
          num2 = Math.floor(Math.random() * 101); // Numbers between 0 and 100
          operator = '/';
          result = num1 / num2;
        } while (!Number.isInteger(result) || result < 0); // Repeat if division result is not a natural number
      }

      newEquations.push({ equation: `${num1} ${operator} ${num2}`, correctAnswer: result });
    }

    setEquations(newEquations);
    setUserAnswers(new Array(10).fill(''));
  };

  const handleAnswerChange = (index, value) => {
    setUserAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[index] = value;
      return newAnswers;
    });
  };

  const handleSubmit = async () => {
    const newGameScore = userAnswers.reduce((score, answer, index) => {
      return parseInt(answer, 10) === equations[index].correctAnswer ? score + 1 : score;
    }, 0);
    setGameScore(newGameScore);
  
    const percentage = (newGameScore / 10) * 100;
    const roundedPercentage = Math.round(percentage);
  
    let newScoreRating;
    let newScoreComment;
  
    if (roundedPercentage >= 90) {
      newScoreRating = 'Outstanding';
      newScoreComment = 'Congratulations! You performed outstandingly.';
    } else if (roundedPercentage >= 75) {
      newScoreRating = 'Passed';
      newScoreComment = 'Well done! You passed the game.';
    } else if (roundedPercentage >= 50) {
      newScoreRating = 'Almost';
      newScoreComment = 'You are close to passing. Keep practicing!';
    } else {
      newScoreRating = 'Failed';
      newScoreComment = 'Keep practicing. You can do better!';
    }
  
    setScoreRating(newScoreRating);
    setScoreComment(newScoreComment);
  
    try {
      // Step 1: Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      // Step 2: Send a POST request with CSRF token
      const response = await fetch('https://lernacy.co.za/api/record-score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        body: JSON.stringify({
          userId: userDetails._id,
          score: newGameScore,
          timeOfDay: new Date().getHours(),
          gameName: gameName,
        }),
        credentials: 'include', // Include credentials
      });
  
      if (response.ok) {
        console.log('Score recorded successfully!');
      } else {
        console.error('Failed to record score:', response.statusText);
      }
    } catch (error) {
      console.error('Error recording score:', error.message);
    }
  
    // Trigger game over screen
    setGameOver(true);
  };

  const restartGame = async () => {
    setGameOver(false);
    setGameScore(0);
    generateEquations();
    setUserAnswers(new Array(10).fill(''));

    try {
      const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        throw new Error('Error fetching high score: ' + response.statusText);
      }
    } catch (error) {
      console.error('Error restarting game:', error.message);
    }
  };

  return (
    <div className='tested-activity-wall-container'>
      {isAuthenticated === null ? (
        <p>Loading authentication status...</p>
      ) : !isAuthenticated ? (
        <AccessDenied />
      ) : (
        <div>
          
          {!gameOver ? (
            <div>
              <h1 className='activity-name-tag-h1'>G3 - Multivide</h1>
              <div className='round-high-score'>
                <h2 className='activity-rounds-tag-h2'>10 Questions</h2>
                <p>
                  <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/15</strong>
                </p>
              </div>
              <h3>Answer all questions below:</h3>

              <div className='equations-aligner-block-cover'>
                {equations.map((equation, index) => (
                  <div key={index} className='equations-block-box'>
                    <p><strong>Question {index + 1}</strong></p>
                    <p>Solve the following equation:</p>
                    <p><strong className='equation-p-tag'>{equation.equation.replace('/', '÷').replace('*', 'x')} = </strong></p>
                    <input
                      type="text"
                      value={userAnswers[index] || ''}
                      onChange={(e) => handleAnswerChange(index, e.target.value)}
                      className='equations-block-box-input'
                    />
                  </div>
                ))}
              </div>

              <br/>
              <button className='activity-submit-button' onClick={handleSubmit}>Submit All Answers</button>
            </div>
          ) : (
            <div>
              <h1 className='activity-name-tag-h1'>Activity Over<br/>Your Score: {gameScore}/10</h1>

              <div className='activity-game-over-data'>
                <h2>Score Rating: {scoreRating}</h2>
                <h3>{scoreComment}</h3>
                <p>Percentage: <strong>{Math.round((gameScore / 10) * 100)}%</strong></p>
              </div>

              <div>
                <h3 className='activity-rounds-tag-h2'>Question Records:</h3>
                <div className='activity-over-rounds-evidence-ul'> 
                  {equations.map((equation, index) => (
                    <div key={index} className='activity-over-rounds-evidence'>
                      <p>
                        <strong>Question {index + 1}</strong><br />
                        Equation: {equation.equation.replace('/', '÷').replace('*', 'x')}<br />
                        Your Answer: {userAnswers[index]}
                        {equation.correctAnswer === parseInt(userAnswers[index], 10) ? (
                          <span style={{ color: 'green' }}> ✔</span>
                        ) : (
                          <span style={{ color: 'red' }}> ✘ {/*(Correct: {equation.correctAnswer})*/}</span>
                        )}<br />
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <hr />
              <p>
                <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/10</strong>
              </p>

              <div className='activity-restart-or-more'>
                <button className='activity-restart-button' onClick={restartGame}>Play Again</button>
                <button className='more-activity-button' onClick={() => window.location.href = "/Grade:3_Mathematics"}>Do More Activities</button>
              </div>
              <br/>
            </div>
          )}

          <br />
        </div>
      )}
    </div>
  );
};

export default GrThreeMultiVide;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PinValidation from './PinValidation';

const StoryBlock1 = ({ user }) => {
    const [answers, setAnswers] = useState({});
    const [schoolNameInput, setSchoolNameInput] = useState('');
    const [schoolNameSuggestions, setSchoolNameSuggestions] = useState([]);
    const [pinValidated, setPinValidated] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchSchoolNameSuggestions = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/institutions/schools/names');
                const { success, schoolNames } = response.data;
                if (success) {
                    setSchoolNameSuggestions(schoolNames);
                } else {
                    console.error('Failed to fetch school names:', response.data);
                }
            } catch (error) {
                console.error('Error fetching school names:', error);
            }
        };

        const fetchUserId = async () => {
            try {
                const response = await axios.get('https://lernacy.co.za/api/study', { withCredentials: true });
                const { user } = response.data;
                setUserId(user._id);
            } catch (error) {
                console.error('Error fetching user ID:', error);
            }
        };

        fetchSchoolNameSuggestions();
        fetchUserId();
    }, []);

    const handleChange = (e) => {
        setAnswers({
            ...answers,
            [e.target.name]: e.target.value
        });
    };

    const handleSchoolNameInputChange = (e) => {
        const { value } = e.target;
        setSchoolNameInput(value);

        const filteredSuggestions = schoolNameSuggestions.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setSchoolNameSuggestions(filteredSuggestions);
    };

    const validatePin = () => {
        // Additional logic for validation if needed
        return pinValidated;
    };

    const onSubmit = async () => {
        if (!validatePin()) {
            alert('PIN validation failed. Submission blocked.');
            return;
        }
    
        try {
            // Fetch CSRF token
            const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
            if (!csrfResponse.data.csrfToken) {
                throw new Error('Failed to fetch CSRF token');
            }
            const csrfToken = csrfResponse.data.csrfToken;
    
            const correctAnswers = {
                question1: 'B',
                question2: 'C',
                question3: 'B',
                question4: 'C',
                question5: 'B',
                question6: 'C',
                question7: 'C',
                question8: 'B',
                question9: 'C',
                question10: 'B',
                
            };
    
            let score = 0;
            for (let key in correctAnswers) {
                if (answers[key] === correctAnswers[key]) {
                    score++;
                }
            }
    
            const submissionData = {
                score: score,
                taskBlock: 4,
                userId: userId,
                taskName: 'Comprehension-Levels',
                schoolName: schoolNameInput,
            };
    
            // console.log('Submission Data:', submissionData); // Log the data being passed to the route
    
            // POST request with CSRF token and credentials in headers
            const response = await axios.post('https://lernacy.co.za/api/ed-support/taskSubmit', submissionData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken // Include CSRF token in the header
                }
            });
    
            if (response.data.success) {
                alert('Task submitted successfully!');
                window.location.reload(); // Reload the page after successful submission
            } else {
                console.error('Failed to submit task:', response.data.error);
                alert('Failed to submit task. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting task:', error);
            alert('Error submitting task. Please check your network connection.');
        }
    };

    return (
        <div className='story-wall-block'>
            <h2>Block 4</h2>
            <h3><strong>Chantel's Journey of Perspective</strong></h3>
            <div className='story-div-image-text'>
                <div className='story-image-cover-block'>
                    <img loading="lazy" src='/activityImages/ed-support/chantels-journey-of-perspective.jfif' alt='Chantel Journey of Perspective' />
                </div>
                <div> 
                    <p className='story-p-tag'> 
                        In the bustling city of Pretoria, South Africa, lived a curious and bright girl named 
                        Chantel. Chantel had always been fascinated by the world around her, constantly 
                        questioning and exploring new ideas. One sunny morning, while walking to school, she 
                        overheard a conversation that piqued her interest. A group of university students were 
                        discussing how perspectives shape our understanding of the world.
                    </p> 
                    <p className='story-p-tag'>
                        Intrigued, Chantel decided to learn more about this concept. That evening, she 
                        approached her mother, a sociology professor at the local university, and asked, "Mom, 
                        what does it mean to see things from different perspectives?"
                    </p>
                    <p className='story-p-tag'>
                        Her mother smiled and replied, "It means understanding that everyone has their own 
                        unique way of looking at the world, influenced by their experiences, culture, and 
                        background. By seeing things from others' perspectives, we can gain a deeper 
                        understanding of the world and foster empathy."
                    </p>
                    <p className='story-p-tag'>
                        Inspired by her mother's words, Chantel embarked on a journey to explore different 
                        perspectives. She started with her classmates, asking them about their favorite books 
                        and why they loved them. Each answer was unique, and Chantel began to see how 
                        individual experiences shaped their views.
                    </p>
                    <p className='story-p-tag'>
                        One day, her teacher, Mr. Nkosi, announced a project on cultural perspectives. Each 
                        student was to research a different culture and present their findings to the class. 
                        Chantel chose to study the Zulu culture, one of South Africa's largest ethnic groups. 
                        She learned about their traditions, values, and way of life. During her presentation, 
                        Chantel shared a Zulu proverb: "Umuntu ngumuntu ngabantu" – "A person is a person 
                        through other people." This proverb emphasized the importance of community and how 
                        one's identity is shaped by their relationships with others.
                    </p>
                    <p className='story-p-tag'>
                        Chantel's presentation was a hit. Her classmates were fascinated by the Zulu culture, 
                        and many of them shared stories from their own cultures. The classroom became a vibrant 
                        tapestry of diverse perspectives, each adding to Chantel's understanding of the world.
                    </p>
                    <p className='story-p-tag'>
                        As months passed, Chantel's quest for perspective led her to volunteer at a local 
                        community center. There, she met people from various backgrounds, each with their own 
                        stories and challenges. She listened to their experiences, learning how different 
                        factors like socio-economic status, education, and family upbringing influenced their 
                        worldviews.
                    </p>
                    <p className='story-p-tag'>
                        One day, while helping out at the center, Chantel met an elderly woman named Thandiwe. 
                        Thandiwe shared her life story, filled with struggles and triumphs. She spoke about the 
                        apartheid era and how it shaped her perspective on justice and equality. Chantel was 
                        moved by Thandiwe's resilience and began to understand the profound impact of 
                        historical events on individual perspectives.
                    </p>
                    <p className='story-p-tag'>
                        Through her journey, Chantel realized that understanding different perspectives not 
                        only enriched her knowledge but also made her more compassionate. She saw the world in 
                        a new light, appreciating the diversity of thought and experience that made each person 
                        unique.
                    </p>
                    <p className='story-p-tag'>
                        By the end of the year, Chantel had become an advocate for empathy and understanding. 
                        She encouraged her peers to embrace different perspectives and to see the world through
                         the eyes of others. Chantel's journey taught her that while our perspectives may 
                         differ, they all contribute to the rich, complex tapestry of human experience.
                    </p>
                    <p className='story-p-tag'>
                        And so, in the heart of Pretoria, Chantel's world expanded, one perspective at a time.
                    </p>
                </div>
            </div>

            <hr />

            <div className='comprehension-questions'>
                <h3>Comprehension Questions</h3>
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <p>1. What sparked Chantel's interest in learning about perspectives?</p>
                        <label>
                            <input type="radio" name="question1" value="A" onChange={handleChange} />
                            A) A book she read
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="B" onChange={handleChange} />
                            B) A conversation she overheard
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="C" onChange={handleChange} />
                            C) A school project
                        </label><br />
                        <label>
                            <input type="radio" name="question1" value="D" onChange={handleChange} />
                            D) Her mother's lecture
                        </label>
                    </div>

                    <div>
                        <p>2. Who explained the concept of perspectives to Chantel?</p>
                        <label>
                            <input type="radio" name="question2" value="A" onChange={handleChange} />
                            A) Her teacher
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="B" onChange={handleChange} />
                            B) Her friend
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="C" onChange={handleChange} />
                            C) Her mother
                        </label><br />
                        <label>
                            <input type="radio" name="question2" value="D" onChange={handleChange} />
                            D) Thandiwe
                        </label>
                    </div>

                    <div>
                        <p>3. What culture did Chantel choose to research for her school project?</p>
                        <label>
                            <input type="radio" name="question3" value="A" onChange={handleChange} />
                            A) Xhosa
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="B" onChange={handleChange} />
                            B) Zulu
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="C" onChange={handleChange} />
                            C) Ndebele
                        </label><br />
                        <label>
                            <input type="radio" name="question3" value="D" onChange={handleChange} />
                            D) Sotho
                        </label>
                    </div>

                    <div>
                        <p>4. What Zulu proverb did Chantel share in her presentation?</p>
                        <label>
                            <input type="radio" name="question4" value="A" onChange={handleChange} />
                            A) "Ubuntu"
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="B" onChange={handleChange} />
                            B) "Umuzi ukhula ngabanikazi"
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="C" onChange={handleChange} />
                            C) "Umuntu ngumuntu ngabantu"
                        </label><br />
                        <label>
                            <input type="radio" name="question4" value="D" onChange={handleChange} />
                            D) "Inkosi yinkosi ngabantu"
                        </label>
                    </div>

                    <div>
                        <p>5. Where did Chantel volunteer to learn more about different perspectives?</p>
                        <label>
                            <input type="radio" name="question5" value="A" onChange={handleChange} />
                            A) A local library
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="B" onChange={handleChange} />
                            B) A community center
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="C" onChange={handleChange} />
                            C) A hospital
                        </label><br />
                        <label>
                            <input type="radio" name="question5" value="D" onChange={handleChange} />
                            D) Her school
                        </label>
                    </div>

                    <div>
                        <p>6. Who shared a life story about the apartheid era with Chantel?</p>
                        <label>
                            <input type="radio" name="question6" value="A" onChange={handleChange} />
                            A) Mr. Nkosi
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="B" onChange={handleChange} />
                            B) Her mother
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="C" onChange={handleChange} />
                            C) Thandiwe
                        </label><br />
                        <label>
                            <input type="radio" name="question6" value="D" onChange={handleChange} />
                            D) Her classmate
                        </label>
                    </div>

                    <div>
                        <p>7. What was the main lesson Chantel learned from her journey?</p>
                        <label>
                            <input type="radio" name="question7" value="A" onChange={handleChange} />
                            A) The importance of education
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="B" onChange={handleChange} />
                            B) The impact of socio-economic status
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="C" onChange={handleChange} />
                            C) The value of different perspectives
                        </label><br />
                        <label>
                            <input type="radio" name="question7" value="D" onChange={handleChange} />
                            D) The history of South Africa
                        </label>
                    </div>

                    <div>
                        <p>8. How did Chantel's classmates react to her presentation on the Zulu culture?</p>
                        <label>
                            <input type="radio" name="question8" value="A" onChange={handleChange} />
                            A) They were uninterested
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="B" onChange={handleChange} />
                            B) They were fascinated
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="C" onChange={handleChange} />
                            C) They disagreed with her
                        </label><br />
                        <label>
                            <input type="radio" name="question8" value="D" onChange={handleChange} />
                            D) They found it boring
                        </label>
                    </div>

                    <div>
                        <p>9. What did Chantel encourage her peers to do by the end of the story?</p>
                        <label>
                            <input type="radio" name="question9" value="A" onChange={handleChange} />
                            A) Study harder
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="B" onChange={handleChange} />
                            B) Volunteer more
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="C" onChange={handleChange} />
                            C) Embrace different perspectives
                        </label><br />
                        <label>
                            <input type="radio" name="question9" value="D" onChange={handleChange} />
                            D) Travel the world
                        </label>
                    </div>

                    <div>
                        <p>10. What impact did understanding different perspectives have on Chantel?</p>
                        <label>
                            <input type="radio" name="question10" value="A" onChange={handleChange} />
                            A)  It made her more competitive
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="B" onChange={handleChange} />
                            B) It made her more compassionate
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="C" onChange={handleChange} />
                            C) It made her more skeptical
                        </label><br />
                        <label>
                            <input type="radio" name="question10" value="D" onChange={handleChange} />
                            D) It made her more isolated
                        </label>
                    </div>

                </form>
            </div>

            <hr />

            <div className='school-name-pin-block'>
                <h3>School Information</h3>
                <label htmlFor="schoolNameInput">School Name:</label><br />
                <input
                    type="text"
                    id="schoolNameInput"
                    name="schoolNameInput"
                    value={schoolNameInput}
                    onChange={handleSchoolNameInputChange}
                    list="schoolNameSuggestions"
                    required
                    className='tasks-school-name-input'
                /><br />
                <datalist id="schoolNameSuggestions">
                    {schoolNameSuggestions.map((schoolName, index) => (
                        <option key={index} value={schoolName} />
                    ))}
                </datalist>
                {!pinValidated && (
                    <PinValidation
                        schoolNameInput={schoolNameInput}
                        onSubmit={() => setPinValidated(true)}
                    />
                )}
                {pinValidated && (
                    <button className='activity-submit-button' type="button" onClick={onSubmit}>
                        Submit Task 
                    </button>
                )}
            </div>
        </div>
    );
};

export default StoryBlock1;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddSchoolAccountForm = () => {
  const [users, setUsers] = useState([]);
  const [initialRequestMade, setInitialRequestMade] = useState(false);
  const [blockRequests, setBlockRequests] = useState(false); 
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    bio: 'I am officially a part of Lernacy',
    profilePicture: 'https://storage.googleapis.com/lernacy-client-bucket/profilePics/favicon1.png',
    role: 'School',
    worksAt: 'Unspecified',
    accountName: '',
    province: '',
    status: 'Teacher',
    decoyId: '',
    dob: '',
    schoolName: '',
    town: '',
    address: '',
    postalAddress: '',
    telNumber: '',
    fax: '',
    emisNumber: '',
    isSchool: 'Yes',
    authorizationCode: '',
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Fetch all users
        const response = await axios.get('https://lernacy.co.za/api/check/api/check/users', {
          withCredentials: true
        });
        setUsers(response.data.user);
        setInitialRequestMade(true);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setError('Access forbidden');
          setBlockRequests(true); // Block all requests on 403 response
        } else {
          setError('Error fetching users');
          setLoading(false);
        }
      }
    };
  
    fetchUsers();
  }, []);  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Fetch CSRF token
      const csrfResponse = await axios.get('https://lernacy.co.za/api/form', { withCredentials: true });
      if (!csrfResponse.data.csrfToken) {
        throw new Error('Failed to fetch CSRF token');
      }
      const csrfToken = csrfResponse.data.csrfToken;
  
      // Log the formData for debugging
      // console.log('Form Data:', formData);
  
      // Send POST request to add school account
      const response = await axios.post('https://lernacy.co.za/api/institutions/school/add', formData, {
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,
        },
        withCredentials: true,
      });
  
      if (response.status !== 201) {
        throw new Error('Failed to add school account');
      }
  
      // Show success message
      alert('School account added successfully');
  
      // Optionally, clear the form fields after submission
      setFormData({
        name: '',
        surname: '',
        email: '',
        password: '',
        bio: 'I am officially a part of Xelear',
        profilePicture: 'profile.png',
        role: 'School',
        worksAt: 'Unspecified',
        accountName: '',
        province: '',
        status: 'Teacher',
        decoyId: '',
        dob: '',
        schoolName: '',
        town: '',
        address: '',
        postalAddress: '',
        telNumber: '',
        fax: '',
        emisNumber: '',
        isSchool: 'Yes',
        authorizationCode: '',
      });
    } catch (error) {
      console.error('Error adding school account:', error);
      // Show error message
      alert(error.message || 'Failed to add school account. Please try again.');
    }
  };

  if (!initialRequestMade) {
    return <p>Loading...</p>;
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (blockRequests) {
    return <p>Access forbidden</p>;
  }

  return (
    <div className='tested-activity-wall-container'>
      <h1 className='activity-name-tag-h1'>Add School Posting Account</h1>
      <div className='submit-int-form-block-container'>
        <div >
          <form onSubmit={handleSubmit} className='submit-int-form-block'>
            <label htmlFor="name">Name:</label><br />
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} /><br />
            
            <label htmlFor="surname">Surname:</label><br />
            <input type="text" id="surname" name="surname" value={formData.surname} onChange={handleChange} /><br />
            
            <label htmlFor="email">Email:</label><br />
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} /><br />
            
            <label htmlFor="password">Password:</label><br />
            <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} /><br />
            
            <label htmlFor="bio">Bio:</label><br />
            <input type="text" id="bio" name="bio" value={formData.bio} onChange={handleChange} /><br />
            
            <label htmlFor="profilePicture">Profile Picture:</label><br />
            <input type="text" id="profilePicture" name="profilePicture" value={formData.profilePicture} onChange={handleChange} /><br />
            
            <label htmlFor="role">Role:</label><br />
            <select id="role" name="role" value={formData.role} onChange={handleChange}>
              <option value="User">User</option>
              <option value="Admin">Admin</option>
              <option value="Moderator">Moderator</option>
              <option value="Scheme">Scheme</option>
              <option value="School">School</option>
            </select><br />
            
            <label htmlFor="worksAt">Works At:</label><br />
            <input type="text" id="worksAt" name="worksAt" value={formData.worksAt} onChange={handleChange} /><br />
            
            <label htmlFor="accountName">Account Name:</label><br />
            <input type="text" id="accountName" name="accountName" value={formData.accountName} onChange={handleChange} /><br />
            
            <label htmlFor="province">Province:</label><br />
            <select id="province" name="province" value={formData.province} onChange={handleChange}>
              <option className='second-stage-option-field'>Select province</option>
              <option value="Eastern Cape">Eastern Cape</option>
              <option value="Free State">Free State</option>
              <option value="Gauteng">Gauteng</option>
              <option value="KwaZulu-Natal">KwaZulu-Natal</option>
              <option value="Limpopo">Limpopo</option>
              <option value="Mpumalanga">Mpumalanga</option>
              <option value="North West">North West</option>
              <option value="Northern Cape">Northern Cape</option>
              <option value="Western Cape">Western Cape</option>
            </select><br />

            <label htmlFor="status">Status:</label><br />
            <select id="status" name="status" value={formData.status} onChange={handleChange}>
              <option value="Student">Student</option>
              <option value="Parent">Parent</option>
              <option value="Teacher">Teacher</option>
            </select><br />
            
            <label htmlFor="decoyId">Decoy ID: (8)</label><br />
            <input type="text" id="decoyId" name="decoyId" value={formData.decoyId} onChange={handleChange} /><br />
            
            <label htmlFor="dob">Date of Birth:</label><br />
            <input type="date" id="dob" name="dob" value={formData.dob} onChange={handleChange} /><br />
            
            <label htmlFor="schoolName">School Name:</label><br />
            <input type="text" id="schoolName" name="schoolName" value={formData.schoolName} onChange={handleChange} /><br />
            
            <label htmlFor="town">Town:</label><br />
            <input type="text" id="town" name="town" value={formData.town} onChange={handleChange} /><br />
            
            <label htmlFor="address">Address:</label><br />
            <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} /><br />
            
            <label htmlFor="postalAddress">Postal Address:</label><br />
            <input type="text" id="postalAddress" name="postalAddress" value={formData.postalAddress} onChange={handleChange} /><br />
            
            <label htmlFor="telNumber">Tel Number:</label><br />
            <input type="text" id="telNumber" name="telNumber" value={formData.telNumber} onChange={handleChange} /><br />
            
            <label htmlFor="fax">Fax:</label><br />
            <input type="text" id="fax" name="fax" value={formData.fax} onChange={handleChange} /><br />
            
            <label htmlFor="emisNumber">Emis Number:</label><br />
            <input type="text" id="emisNumber" name="emisNumber" value={formData.emisNumber} onChange={handleChange} /><br />
            
            <label htmlFor="isSchool">Is School:</label><br />
            <select id="isSchool" name="isSchool" value={formData.isSchool} onChange={handleChange}>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select><br />

            <label htmlFor="authorizationCode">Another Field:</label><br />
            <input type="text" id="authorizationCode" name="authorizationCode" value={formData.authorizationCode} onChange={handleChange} /><br />
            
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSchoolAccountForm;

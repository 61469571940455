import React from 'react';

const AnimalNames = () => {
  return (
    <div>
      <h1>Welcome to Animal Names!</h1>
    </div>
  );
};

export default AnimalNames;

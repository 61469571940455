import React, { useState, useEffect } from 'react';
import Tour from 'reactour'; // Import Reactour

const AccountsTour = () => {
  const [isTourOpen, setIsTourOpen] = useState(false); // State to control tour visibility
  const pageName = 'Results-page-instructions'; // Define the page name

  // Define steps for the tour
  const steps = [
    {
      selector: '.active-button',
      content: 'Data appearing in the following section should be score data for your account.',
    },
    {
      selector: '.inactive-button',
      content: 'Data appearing in the following section should be score data of the accounts you are monitoring. If you should not be monitoring any account data, visit the accounts page.',
    },
  ]; 

  // Function to make GET request to the '/complete-tour-guide' endpoint
  const completeTourGuide = async () => {
    try {
      const response = await fetch(`https://lernacy.co.za/api/complete-tour-guide?pageName=${encodeURIComponent(pageName)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // This will send cookies with the request
      });

      // If the response status is 404, start the tour
      if (response.status === 404) {
        setIsTourOpen(true); // Start the tour if tour guide not found
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const markTourComplete = async () => {
    try {
      // Step 1: Get CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies in the request
      });
      
      if (!csrfResponse.ok) {
        throw new Error('Failed to retrieve CSRF token');
      }
  
      // Extract CSRF token from the response
      const { csrfToken } = await csrfResponse.json();
  
      // Step 2: Send the POST request with the CSRF token
      await fetch('https://lernacy.co.za/api/mark-complete-tour-guide', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include the CSRF token in the headers
        },
        credentials: 'include', // Include cookies in the request
        body: JSON.stringify({ pageName }), // Send the page name as data
      });
  
      // Close the tour
      setIsTourOpen(false);
    } catch (error) {
      console.error('Failed to mark tour as complete:', error);
    }
  };  

  // Automatically check for the tour guide status on mount
  useEffect(() => {
    completeTourGuide(); // Call function to check tour guide status
  }, []); // Run only once on component mount

  return (
    <div>
      {/* Render the Reactour component */}
      <Tour
        steps={steps} // Pass the steps defined earlier
        isOpen={isTourOpen} // Control whether the tour is open
        onRequestClose={markTourComplete} // Handle tour close and trigger POST request
        rounded={5} // Optional: Set border radius for the tour bubble
        closeWithMask={true} // Allow closing by clicking the mask
        showCloseButton={true} // Show close button
        showNavigation={true} // Show navigation buttons
      />
    </div>
  );
};

export default AccountsTour;



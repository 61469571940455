import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import axios from 'axios';
import AccessDenied from '../components/AccessDenied';
import './bodyFiles-styling/bodyFiles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import Ads from '../bodyFiles/AdPosts'; // Import Ads component
import ProfileTour from '../components/TourGuide/ProfileTour';

const ProfilePage = () => {
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [userPosts, setUserPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    province: '',
    dob: '',
    status: '',
    bio: ''
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [blogContent, setBlogContent] = useState('');
  const [userId, setUserId] = useState(null);
  const provinces = [
    'Western Cape',
    'Eastern Cape',
    'KwaZulu Natal',
    'Northern Cape',
    'Free State',
    'North West',
    'Gauteng',
    'Mpumalanga',
    'Limpopo',
  ];
  const statuses = ['Student', 'Parent'];
  const navigate = useNavigate();
  const sortedPosts = userPosts.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/profileBlock', {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          const userId = data.user._id;
          setUserId(userId);
          data.user.dob = formatDateOfBirth(data.user.dob);
          setProfileData(data);
          setFormData({
            name: data.user.name,
            surname: data.user.surname,
            province: data.user.province,
            dob: data.user.dob,
            status: data.user.status,
            bio: data.user.bio
          });
          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else if (response.status === 401) {
          setError('Unauthorized');
        } else {
          setError('Server Error');
        }
      } catch (error) {
        setError('Network Error');
        console.error('Error fetching profile data:', error);
      }
    };
  
    fetchProfileData();
  }, [navigate]);

  const fetchUserPosts = async () => {
    try {
      const response = await axios.get('https://lernacy.co.za/api/user-posts', { withCredentials: true });
  
      if (response.status === 401) {
        setError('Unauthorized'); // Set error message for unauthorized access
      } else {
        setUserPosts(response.data.posts);
      }
  
      setIsLoading(false);
    } catch (error) {
      setError('Failed to fetch user posts');
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    fetchUserPosts();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setUploadStatus(null);
    }, 2000); // Set the timeout for 2 seconds

    return () => clearTimeout(timer); // Clear the timeout on component unmount
  }, [uploadStatus]);

  const formatDateOfBirth = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const month = monthNames[date.getMonth()];
    return `${day} ${month} ${year}`;
  };

  const formatDate = (dateString) => { 
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
      ][date.getMonth()];
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${day} ${month} ${year} - ${hours}:${minutes}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let truncatedValue = value; // Initialize truncatedValue with the original value
  
    // Define character limits for each field
    const characterLimits = {
      name: 45, // Example limit for name field
      surname: 45, // Example limit for surname field
      // Add character limits for other fields as needed
    };
  
    // Check if the current field has a character limit defined
    if (characterLimits.hasOwnProperty(name)) {
      // Truncate the value if it exceeds the character limit
      truncatedValue = value.slice(0, characterLimits[name]);
    }
  
    // Update the form data with the truncated value
    setFormData({
      ...formData,
      [name]: truncatedValue,
    });
  };

  const [privacySettings, setPrivacySettings] = useState({
    dob: true,
    email: true,
    province: true,
    status: true,
  });

  const handlePrivacyChange = (setting) => async (checked) => {
    try {
      // Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
      const { csrfToken } = await csrfResponse.json();
  
      // Send a request to update the privacy setting on the server
      const response = await fetch(`https://lernacy.co.za/api/updatePrivacy/${setting}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken, // Include CSRF token in the header
        },
        body: JSON.stringify({ value: checked, userId }), // Include userId in the request body
        credentials: 'include',
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        // Update the local state with the new privacy setting
        setPrivacySettings((prevSettings) => ({
          ...prevSettings,
          [setting]: checked,
        }));
        //alert('Privacy settings updated successfully');
      } else {
        console.error('Error updating privacy settings:', responseData.message);
        setError(`Failed to update privacy settings: ${responseData.message}`);
      }
    } catch (error) {
      console.error('Error updating privacy settings:', error.message);
      setError('Failed to update privacy settings');
    }
  };

  useEffect(() => {
    const fetchPrivacySettings = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/getPrivacySettings', {
          method: 'GET',
          credentials: 'include',
        });
  
        if (response.ok) {
          const data = await response.json();
          // Update privacySettings state with fetched data
          setPrivacySettings({

            dob: data.privacySettings.dob,
            province: data.privacySettings.province,
            status: data.privacySettings.status,
            email: data.privacySettings.email,

          });
        } else {
          setError('Failed to fetch privacy settings');
        }
      } catch (error) {
        console.error('Error fetching privacy settings:', error);
        setError('Failed to fetch privacy settings');
      }
    };
  
    fetchPrivacySettings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Fetch CSRF token from the server
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include', // Important for cookies
      });
  
      const { csrfToken } = await csrfResponse.json();
      //console.log('CSRF Token:', csrfToken);
  
      if (isEditing) {
        const formattedFormData = {
          ...formData,
          dob: formatDateOfBirth(formData.dob),
        };
  
        //console.log('Formatted Form Data:', formattedFormData);
  
        const profileResponse = await fetch('https://lernacy.co.za/api/updateProfile', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken, // Include CSRF token in the headers
          },
          body: JSON.stringify(formattedFormData),
          credentials: 'include',
        });
  
        if (profileResponse.ok) {
          const updatedProfileResponse = await fetch('https://lernacy.co.za/api/profileBlock', {
            method: 'GET',
            credentials: 'include',
          });
  
          if (updatedProfileResponse.ok) {
            const updatedData = await updatedProfileResponse.json();
            setProfileData(updatedData);
            setIsEditing(false);
          } else {
            setError('Failed to fetch updated profile data');
          }
        } else {
          const errorData = await profileResponse.json();
          console.error('Failed to update profile data:', errorData);
          setError('Failed to update profile data');
        }
      } else if (isEditingBio) {
        const bioResponse = await fetch('https://lernacy.co.za/api/updateBio', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken, // Include CSRF token in the headers
          },
          body: JSON.stringify(formData),
          credentials: 'include',
        });
  
        if (bioResponse.ok) {
          // Fetch updated bio
          const updatedProfileResponse = await fetch('https://lernacy.co.za/api/profileBlock', {
            method: 'GET',
            credentials: 'include',
          });
  
          if (updatedProfileResponse.ok) {
            const updatedData = await updatedProfileResponse.json();
            setProfileData(updatedData);
            setIsEditingBio(false);
          } else {
            setError('Failed to fetch updated profile data');
          }
        } else {
          const errorData = await bioResponse.json();
          console.error('Failed to update bio:', errorData);
          setError('Failed to update bio');
        }
      } else {
        if (selectedImage) {
          setIsLoading(true); // Set loading to true when upload starts
          setUploadStatus('Uploading...'); // Set upload status
        
          try {
            const formData = new FormData();
            formData.append('profilePicture', selectedImage);
        
            const uploadResponse = await fetch('https://lernacy.co.za/api/uploadImage', {
              method: 'POST',
              headers: {
                'CSRF-Token': csrfToken, // Include CSRF token in the headers
              },
              body: formData,
              credentials: 'include',
            });
        
            if (uploadResponse.ok) {
              setUploadStatus('Image uploaded successfully'); // Set upload status
              setFileInputKey((prevKey) => prevKey + 1); // Increment file input key to reset input field
        
              // Fetch updated profile data after successful image upload
              const updatedProfileResponse = await fetch('https://lernacy.co.za/api/profileBlock', {
                method: 'GET',
                credentials: 'include',
              });
        
              if (updatedProfileResponse.ok) {
                const updatedData = await updatedProfileResponse.json();
                setProfileData(updatedData);
              } else {
                throw new Error('Failed to fetch updated profile data');
              }
            } else {
              const errorData = await uploadResponse.json();
              throw new Error('Failed to upload image: ' + errorData.error);
            }
          } catch (error) {
            console.error('Error uploading image:', error.message);
            setUploadStatus('Failed to upload image'); // Set upload status
          }
        
          setIsLoading(false); // Set loading to false when upload completes
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit form');
      setIsLoading(false); // Set loading to false in case of error
    }
  };  

  const handleImageSelect = (e) => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
  
    if (!allowedFormats.includes(selectedFile.type)) {
      alert('Only JPEG, PNG, and JPG formats are allowed.');
      return;
    }
  
    setSelectedImage(selectedFile);
  };

  // Function to handle changes in the input field for the blog post content
  const handleBlogContentChange = (e) => {
    let content = e.target.value;
  
    // Limit the content to 350 characters
    if (content.length > 700) {
      content = content.slice(0, 700);
    }
  
    setBlogContent(content);
  };

  // Inside the function to handle blog post submission
  const handleBlogPostSubmit = async () => {
    try {
        // Fetch CSRF token
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
            method: 'GET',
            credentials: 'include',
        });

        const csrfData = await csrfResponse.json();
        const csrfToken = csrfData.csrfToken;

        if (!csrfResponse.ok) {
            throw new Error('Failed to fetch CSRF token');
        }

        // Validate blog content on client side
        if (!blogContent || typeof blogContent !== 'string' || blogContent.trim() === '') {
            throw new Error('Invalid blog content');
        }

        // Send a POST request to create a new blog post
        const response = await fetch('https://lernacy.co.za/api/make-posts', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'CSRF-Token': csrfToken, // Include CSRF token in the headers
            },
            body: JSON.stringify({
                content: blogContent,
                author: userId, // Provide the userId as the author ID here
                userId: userId, // Send the userId for verification purposes
            }),
        });

        // Check if the response is successful
        if (response.ok) {
            // Clear the blog content field
            setBlogContent('');
            // Provide feedback to the user (e.g., show a success message)

            // Fetch user posts again to get the updated list including the new post
            const userPostsResponse = await axios.get('https://lernacy.co.za/api/user-posts', { withCredentials: true });
            setUserPosts(userPostsResponse.data.posts);
        } else {
            // Handle unsuccessful response
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to create blog post');
        }
    } catch (error) {
        // Display error message to the user
        console.error('Error creating blog post:', error);
        // Provide feedback to the user (e.g., show an error message)
    }
  };

  const handleSupportButton = async (userId, postId) => {
    try {
        // Fetch CSRF token
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
            method: 'GET',
            credentials: 'include',
        });

        const csrfData = await csrfResponse.json();
        const csrfToken = csrfData.csrfToken;

        if (!csrfResponse.ok) {
            throw new Error('Failed to fetch CSRF token');
        }

        const postIndex = userPosts.findIndex(post => post._id === postId);
        if (postIndex === -1) {
            console.error('Post not found');
            return;
        }

        const updatedUserPosts = [...userPosts];
        const post = updatedUserPosts[postIndex];

        // Toggle support status and update support count
        if (post.isSupported) {
            post.support--;
        } else {
            post.support++;
        }
        post.isSupported = !post.isSupported;

        setUserPosts(updatedUserPosts);

        // Send a POST request to update support count on the backend
        const response = await fetch(`https://lernacy.co.za/api/support/${postId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'CSRF-Token': csrfToken, // Include CSRF token in the headers
            },
            body: JSON.stringify({ userId, postId }),
            credentials: 'include', // Include credentials
        });

        if (!response.ok) {
            console.error('Failed to update support count on the server');
            // Revert changes if the request fails
            setUserPosts(userPosts);
        }
    } catch (error) {
        console.error('Error supporting post:', error);
    }
  };

  const copyPostLinkToClipboard = (postId) => {
    const postLink = `${window.location.origin}/open-post/${postId}`;
    navigator.clipboard.writeText(postLink)
      .then(() => alert('Post link copied to clipboard'))
      .catch((error) => console.error('Failed to copy post link: ', error));
  };

  return (
    <div className='profile-page-main-container'>
      {error ? (
        <div>
          <p>{error}</p>
          {error === 'Unauthorized' && ( 
            <AccessDenied />
          )}
        </div>
      ) : profileData ? (
        <div className='profile-page-aligner-left-right'>
          <ProfileTour />

          <div>
            <div className='profile-page-top-section'>

              <h1 className='page-title-name'>Profile</h1>

              {/* Display profile image */}
              <div className='profile-image-aligner'>
                <img
                  loading="lazy"
                  src={profileData.user.profilePicture}
                  alt="Profile"
                /> 
              </div>

              <div className='profile-deco-links-container'>

                {profileData?.user.role !== 'School' && (
                  <Link to="/user-stats" className='profile-deco-links-link-tag'>
                    <div className='profile-deco-links'>
                      <p>Followers<br/> <strong>{profileData.user.followers}</strong></p>
                    </div>
                  </Link>
                )}

                  <Link to="/user-stats" className='profile-deco-links-link-tag'>
                    <div className='profile-deco-links'>
                      <p>Users Tracked<br/> <strong>{profileData.user.trackingRequests}</strong></p>
                    </div>
                  </Link>

                {profileData?.user.role !== 'School' && (
                  <Link to="/user-stats" className='profile-deco-links-link-tag'>
                    <div className='profile-deco-links'>
                      <p>Following<br/> <strong>{profileData.user.following}</strong></p>
                    </div>
                  </Link>
                )}

              </div>


              {profileData?.user.role === 'School' && (

                <div className='profile-section-navigation-institution'>

                  <Link to={'/profile'} className='profile-navigation-institution-p-color-back-active'>
                    <div className='institution-profile-navigation-div'>
                      Profile
                    </div>
                  </Link>

                  <Link to={'/create-schedule'} className='profile-navigation-institution-p-color-back'>
                    <div className='institution-profile-navigation-div'>
                      Schedule
                    </div>
                  </Link>

                  <Link to={'/institution-about'} className='profile-navigation-institution-p-color-back'>
                    <div className='institution-profile-navigation-div'>
                      About
                    </div>
                  </Link>

                </div>
              )}  
              
            </div>

            <div >

              <div className='under-top-profile-but-in-top'>
                {/* Edit profile details */}
                <div>
                  {isEditing ? (
                    <form onSubmit={handleSubmit} className='edit-user-info-form-container'>
                    <div>
                      <label>Name:</label>
                      <br />
                      <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
                    </div>
                    <div>
                      <label>Surname:</label>
                      <br />
                      <input type="text" name="surname" value={formData.surname} onChange={handleInputChange} />
                    </div>
                    <div>
                      <label>Province:</label>
                      <br />
                      <select name="province" value={formData.province} onChange={handleInputChange}>
                        {provinces.map((province) => (
                          <option key={province} value={province}>
                            {province}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label>Birth Day:</label>
                      <br />
                      <input type="date" name="dob" value={formData.dob} onChange={handleInputChange} />
                    </div>
                    <button type="submit" className='edit-user-info-form-save-button'>Save</button>
                    <button type="button" onClick={() => setIsEditing(false)} className='edit-user-info-form-cancel-button'>Cancel</button>
                  </form>
                  ) : (
                    <div className='main-username-block-div'>

                      <h2 className='floating-blocks-h2-tags'>User Details</h2>

                      <p className='user-details-labels-p'>Name & Surname</p>
                      <p className='user-details-actual-data'>
                        {profileData.user.name} {profileData.user.surname}
                      </p>

                      <p className='user-details-labels-p'>Email</p>
                      <p className='user-details-actual-data'>{profileData.user.email}</p>

                      <p className='user-details-labels-p'>Account Name</p>
                      <p className='user-details-actual-data'>{profileData.user.accountName}</p>

                      <p className='user-details-labels-p'>Province</p>
                      <p className='user-details-actual-data'>{profileData.user.province}</p>
                      
                      <p className='user-details-labels-p'>Birth Day</p>
                      <p className='user-details-actual-data'>{formatDateOfBirth(profileData.user.dob)}</p>
                      
                      <p className='user-details-labels-p'>Status</p>
                      <p className='user-details-actual-data'>{profileData.user.status}</p>

                      <p className='user-details-labels-p'>Violation Strikes</p>
                      <p className='user-details-actual-data'>{profileData.user.strikes}</p>
                      {/* Display userId */}
                      {/*<p>User ID: {userId}</p>*/}
                      <button onClick={() => setIsEditing(true)} id='edit-profile-details-info'>Edit Info</button>
                    </div>
                  )}
                </div>

                <div className='under-top-profile-but-in-top-left'>
                  {/* Edit bio */}

                  <div className="file-upload-container" >
                    <input 
                      key={fileInputKey} // Ensure file input resets when key changes
                      type="file" 
                      accept="image/*" 
                      onChange={handleImageSelect} 
                      className="file-input"
                    />
                    <button onClick={handleSubmit} disabled={!selectedImage || isLoading} className="upload-button">
                      {isLoading ? 'Uploading...' : 'Upload'}
                    </button><br/>
                    {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
                  </div>

                  <div id='edit-bio-block-info'>
                    {isEditingBio ? (
                      <form onSubmit={handleSubmit} className='bio-container-block'>
                        <div>
                          <p className='bio-state-label'>Bio:</p>
                          <textarea
                            name="bio"
                            value={formData.bio}
                            onChange={handleInputChange}
                            maxLength={250} // Limit bio to 250 characters
                          />
                        </div>
                        <button type="submit" className='submit-bio-button-element'>Save</button>
                        <button type="button" onClick={() => setIsEditingBio(false)} className='cancel-bio-button-element'>Back</button> {/* Added back button */}
                      </form>
                    ) : (
                      <div className='bio-container-block'>
                        <p className='bio-state-label'>Bio:</p>
                        <textarea
                          type="text"
                          value={formData.bio}
                          readOnly
                        />
                        <br />
                        <button onClick={() => setIsEditingBio(true)} className='edit-bio-button-element'>Edit Bio</button>
                      </div>
                    )}
                  </div>  

                  <div>
                    <div className='block-to-write-posts'>
                      <textarea
                        value={blogContent}
                        onChange={handleBlogContentChange}
                        placeholder="Write your post here..."
                      /><br />
                      
                      <button 
                        onClick={handleBlogPostSubmit} 
                        disabled={!blogContent.trim()}
                        className='button-to-submit-post'
                        >
                        Submit Post
                      </button>
                    </div>
                  </div>
                  
                </div>
                
              </div>
              
              <div className='tablet-block-two-section'>

                <div className='privacy-button-container-box'>
                  <h2 className='floating-blocks-h2-tags'>Privacy Settings</h2>

                  <div className='section-explain-privacy-to-user-container'>
                    <p className='section-explain-privacy-to-user'>
                      You can control which parts of your personal details are 
                      shown when other users visits your account.
                    </p>
                  </div>

                  <div className='privacy-button-div-l'>

                    <div>
                      <label>
                        <span className='privacy-toggle-switch'>Email Address:</span>
                        <Switch
                          onChange={handlePrivacyChange('email')}
                          checked={privacySettings.email}
                        />
                      </label>
                    </div>

                    <br/>

                    <div>
                      <label>
                        <span className='privacy-toggle-switch'>Birth Day:</span>
                        <Switch
                          onChange={handlePrivacyChange('dob')}
                          checked={privacySettings.dob}
                        />
                      </label>
                    </div>

                    <br/>

                    <div>
                      <label>
                        <span className='privacy-toggle-switch'>Province:</span>
                        <Switch
                          onChange={handlePrivacyChange('province')}
                          checked={privacySettings.province}
                        />
                      </label>
                    </div>

                    <br/>

                    <div>
                      <label>
                        <span className='privacy-toggle-switch'>Status:</span>
                        <Switch
                          onChange={handlePrivacyChange('status')}
                          checked={privacySettings.status}
                        />
                      </label>
                    </div>

                    <br/>

                  </div>

                </div>

                <div className='spaceholder-block'>
                  {/* Your other profile page content goes here */}
                  
                  {/* Include the Ads component */}
                  <Ads /> {/* Add Ads component here */}
                </div>
          
              </div>
              
            </div>

          </div>
  
          <div className='profile-page-content-container'>
      
            <div>

                  

              <br/>
              <hr />

              <div className='profile-page-your-posts-div'>
                <h2 className='your-post-text'>Your Posts</h2>
                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>{error}</p>
                ) : (
                  <ol className="vertical-list">
                    {sortedPosts.length === 0 ? (
                      <p>No posts found</p>
                    ) : (
                      sortedPosts.map(post => (
                        <li key={post._id}>
                          {post.ban ? (
                            <div className="post-banned">
                              <div className="profile-main-post-details">
                                <div>
                                  <Link /* to={`/open-post/${post._id}`}*/ className="user-account-link">
                                    <div className="single-post-main-block">
                                      <img
                                        loading="lazy"
                                        src={post.author.profilePicture}
                                        alt="Profile"
                                        className="posts-profileimage"
                                      />
                                      <div className="author-details-in-posts">
                                        <p className="posts-author-name-surname">
                                          {post.author.name} {post.author.surname}
                                        </p>{" "}
                                        <p className="posts-accountName">| {post.author.accountName}</p>
                                      </div>
                                    </div>
                                  </Link>
                                  <hr />

                                  <div className='user-post-violation'>
                                    <p>
                                      Your post violates the user agreement. Please review our terms and conditions.
                                    </p>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="profile-main-post-details">
                              <div>
                                <Link /* to={`/open-post/${post._id}`}*/ className="user-account-link">
                                  <div className="single-post-main-block">
                                    <img
                                      loading="lazy"
                                      src={post.author.profilePicture}
                                      alt="Profile"
                                      className="posts-profileimage"
                                    />
                                    <div className="author-details-in-posts">
                                      <p className="posts-author-name-surname">
                                        {post.author.name} {post.author.surname}
                                      </p>{" "}
                                      <p className="posts-accountName">| {post.author.accountName}</p>
                                    </div>
                                  </div>
                                </Link>
                                <hr />
                                <Link to={`/open-post/${post._id}`} className="post-link">
                                  {/*<p>Did Support: {post.isSupported.toString()}</p>*/}
                                  <p className="post-content-text">{post.content}</p>
                                  <p className="created-at">{formatDate(new Date(post.createdAt))}</p>
                                </Link>
                              </div>

                              <div className={post.ban ? "hidden" : "posts-interactions"}>
                                <Link to={`/open-post/${post._id}`} className="interaction-link-button">
                                  {post.commentCount} Comment(s)
                                </Link>

                                <button 
                                  onClick={() => handleSupportButton(userId, post._id)} 
                                  className={`interaction-link-button ${post.isSupported ? 'supported-button' : 'unsupported-button'}`}
                                >
                                  <div className="interaction-link-support-div">
                                    {post.support}
                                    <p>Support</p>
                                  </div>
                                </button>

                                <button onClick={() => copyPostLinkToClipboard(post._id)} className="interaction-link-button">
                                  Share
                                  <FontAwesomeIcon icon={faShareNodes} className="fontawesome-icon" />
                                </button>
                                
                              </div>
                            </div>
                          )}
                        </li>
                      ))
                    )}
                  </ol>
                )}
              </div> 

            </div>
          
          </div>
  
        </div>
      ) : (
        <p>Loading profile data...</p>
      )}
    </div>
  );
};

export default ProfilePage;

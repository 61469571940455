import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './bodyFiles-styling/userStats.css';
import AccessDenied from '../components/AccessDenied';
import './bodyFiles-styling/bodyFiles.css';
import './bodyFiles-styling/supportProfile.css';

const UserStats = () => {
  const [userData, setUserData] = useState(null);
  const [userScores, setUserScores] = useState([]);
  const [trackedUserScores, setTrackedUserScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isScoresOpen, setIsScoresOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [submissions, setSubmissions] = useState([]);
  const [trackedSubmissions, setTrackedSubmissions] = useState([]);
  const [currentSection, setCurrentSection] = useState('activities');
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          credentials: 'include'
        });

        if (!response.ok) {
          if (response.status === 401) {
            return;
          }
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();

        if (!data.success || !data.user.accountName) {
          navigate('/login-second-stage');
          return;
        }

        setUserData(data.user);
        fetchUserScores(data.user.decoyId);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUserScores = async (decoyId) => {
      try {
        const response = await fetch(`https://lernacy.co.za/api/user-scores/${decoyId}`, {
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user scores');
        }

        const data = await response.json();

        setUserScores(data.data);
      } catch (error) {
        console.error('Error fetching user scores:', error);
      }
    };

    fetchUserData();
  }, [navigate]);

  const fetchSubmissions = async () => {
    try {
      const response = await fetch('https://lernacy.co.za/api/multimodal/user-submissions', {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch submissions');
      }
      const data = await response.json();
      //console.log('Fetched submissions:', data.submissions); // Debug logging
      setSubmissions(data.submissions);
    } catch (error) {
      console.error('Error fetching submissions:', error);
    }
  };

  useEffect(() => {
    fetchSubmissions();
  }, []);

  useEffect(() => {
    if (!userData) return;

    const fetchTrackedUserScores = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/tracked-user-scores', {
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch tracked user scores');
        }

        const data = await response.json();

        setTrackedUserScores(data);
      } catch (error) {
        console.error('Error fetching tracked user scores:', error);
      }
    };

    fetchTrackedUserScores();
  }, [userData]);

  useEffect(() => {
    // Make a GET request to fetch tracked user submissions
    fetch('https://lernacy.co.za/api/multimodal/tracked-user-submissions', {
      method: 'GET',
      credentials: 'include', // Include credentials if needed
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Extract data from the response
      setTrackedSubmissions(data);
    })
    .catch(error => {
      console.error('Error fetching tracked user submissions:', error);
    });
  }, []);

  const handleSectionChange = (section) => {
    setCurrentSection(section);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ][date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day} ${month} ${year} - ${hours}:${minutes}`;
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredScores = trackedUserScores.filter((gameScore) => {
    return (
      gameScore.gameName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      gameScore.userId.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      gameScore.userId.surname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      gameScore.highScore.toString().includes(searchQuery) ||
      gameScore.scores.some((score) => score.score.toString().includes(searchQuery))
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return <AccessDenied />;
  }

  return (
    <div className='stats-record-page-main-container-for-two'>
      <div>
        <h1 className='activity-name-tag-h1'>Activity Records</h1>

        <div className='multimodal-education-teacher-menu'>
          <button
            className={`multimodal-education-teacher-menu-button ${currentSection === 'activities' ? 'active' : ''}`}
            onClick={() => handleSectionChange('activities')}
          >
            Activities
          </button>
          <button
            className={`multimodal-education-teacher-menu-button ${currentSection === 'multimodal' ? 'active' : ''}`}
            onClick={() => handleSectionChange('multimodal')}
          >
            Multimodal
          </button>
        </div>

        <div className='main-content'>
          {currentSection === 'activities' && (
            <div className='current-user-marks-record-sheet'>
              <div className='stats-record-page-main-contain'>
                <h2 className='multimodal-stats-for-user-container-h2'>Activities Record</h2>
                <div className='current-user-marks-record-sheet'>
                  <ul>
                    {userScores.length === 0 ? (
                      <li className='record-not-available-list'>No user scores available</li>
                    ) : (
                      userScores.map((gameScore, index) => (
                        <li key={index} className='current-user-scores'>
                          <p onClick={() => setIsScoresOpen(!isScoresOpen)}>{gameScore.gameName}</p>
                          {isScoresOpen && (
                            <>
                              <p>High Score: {gameScore.highScore}</p>
                              <p style={{ display: 'none' }}>Time of Day: {gameScore.timeOfDay}</p>
                              <h2>Tries:</h2>
                              <ul className='tries-record'>
                                {gameScore.scores.map((score, scoreIndex) => (
                                  <li key={scoreIndex} className='tries-record-list'>
                                    <p>{formatDate(score.createdAt)}</p>
                                    <p> ▹Score: {score.score}</p>
                                    <p style={{ display: 'none' }}>Time of Day: {score.timeOfDay}</p>
                                  </li>
                                )).reverse()}
                              </ul>
                            </>
                          )}
                        </li>
                      ))
                    )}
                  </ul>
                </div>
                <br />
                <hr />
                <div className='tracking-user-marks-record-sheet'>
                  <h2>Activity Tracking</h2>
                  <input
                    type='text'
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder='Search...'
                    className='search-box-field'
                  />
                  {filteredScores.length === 0 ? (
                    <div>
                      <p className='record-not-available-list'>No user activities being tracked.</p>
                    </div>
                  ) : (
                    filteredScores.map((gameScore, index) => (
                      <div key={index} className='tracked-user-scores'>
                        <h3>{gameScore.gameName}</h3>
                        <Link to={`/user/${gameScore.userId.decoyId}`} className='link-to-tracked-users-account'>
                          <p className='tracked-user-name'>
                            <strong>{gameScore.userId.name} {gameScore.userId.surname}</strong>
                          </p>
                          <p className='tracked-user-account'>
                            | {gameScore.userId.accountName}
                          </p>
                        </Link>
                        <p>High Score: {gameScore.highScore}</p>
                        <ul className='tries-record'>
                          {gameScore.scores.slice().reverse().map((score, scoreIndex) => (
                            <li key={scoreIndex} className='tries-record-list'>
                              <p>{formatDate(score.createdAt)}</p>
                              <p> ▹Score: {score.score}</p>
                              <p style={{ display: 'none' }}>Time of Day: {score.timeOfDay}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          )}

          {currentSection === 'multimodal' && (
            <div className='multimodal-stats-for-user-container'>
              <div className='multimodal-stats-for-user-container'>
                <h2 className='multimodal-stats-for-user-container-h2'>Multimodal Learning</h2>
                {submissions.length > 0 ? (
                  submissions.map((submission) => (
                    <ul key={submission._id} className='multimodal-stats-for-user'>
                      <li className='marked-list-blocks'>

                        <div className='marked-list-div-aligner'>
                          <label>
                            Group Name: 
                          </label>
                          <p className='marked-list-group-name'><strong>{submission.groupName}</strong></p>
                        </div>

                        <div className='marked-list-div-aligner'>
                          <label>
                            Activity Name:
                          </label>
                          <p>{submission.activityName}</p>
                        </div>

                        <div className='marked-list-div-aligner'>
                          <label>
                            Members: 
                          </label>
                          <p className='submission-mentions-list'>
                            {submission.mentions.map((mention, index) => (
                              <React.Fragment key={index}>
                                {mention}{index < submission.mentions.length - 1 && ','}<br />
                              </React.Fragment>
                            ))}
                          </p>
                        </div>

                        <div className='marked-list-div-aligner'>
                          <label>
                            Link: 
                          </label><br/>
                          <a href={submission.link} target='_blank' rel='noopener noreferrer'>
                            Access Soft Copy
                          </a>
                        </div>

                        <hr/>

                        <div className='page-detailed-setup'>

                          <div className='marked-moda-left-div'>
                            <div>
                              <label>
                                Creativity and Originality
                              </label>
                            </div>

                            <div>
                              <label>
                                Content Understanding
                              </label>
                            </div>

                            <div>
                              <label>
                                Clarity and Organization
                              </label>
                            </div>

                            <div>
                              <label>
                                Use of Multimodal Elements
                              </label>
                            </div>

                            <div>
                              <label>
                                Audience Engagement
                              </label>
                            </div>

                            <div>
                              <label>
                                Technical Proficiency
                              </label>
                            </div>

                            <div>
                              <label>
                                Creativity in Presentation
                              </label>
                            </div>

                            <div>
                              <label>
                                Collaboration
                              </label>
                            </div>

                            <div>
                              <label>
                                Overall Impact
                              </label>
                            </div>

                            <div>
                              <label>
                                Reflection and Self-Assessment 
                              </label>
                            </div>

                          </div>

                          <div className='marked-moda-right-div'>
                            <p>{submission.creativeOriginality} /10</p>
                            <p>{submission.contentGrasp} /10</p>
                            <p>{submission.clarity} /10</p>
                            <p>{submission.multiElements} /10</p>
                            <p>{submission.audienceEngage} /10</p>
                            <p>{submission.techUse} /10</p>
                            <p>{submission.presentation} /10</p>
                            <p>{submission.teamWork} /10</p>
                            <p>{submission.meanImpact} /10</p>
                            <p>{submission.selfAssessment} /10</p>
                          </div>

                        </div>
              
                        <hr/>

                          <div className='marked-list-div-aligner-score-total'>
                            <label>
                              Total Score: 
                            </label>
                            <p>{submission.total}%</p>
                          </div>

                      </li>
                    </ul>
                  ))
                ) : (
                  <p>No submissions found.</p>
                )}
              </div>
              <br />
              <hr />
              <div className='multimodal-stats-for-user-container'>
                <h2 className='multimodal-stats-for-user-container-h2'>Multimodal Tracking</h2>
                <div>
                  <h2>Tracked user's multimodal submissions.</h2>
                  {trackedSubmissions.length === 0 ? (
                    <p>No submissions found.</p>
                  ) : (
                    <div>
                      {trackedSubmissions.map(submission => (
                        <ul key={submission._id} className='multimodal-stats-for-user'>
                          <li className='marked-list-blocks'>

                          <div className='marked-list-div-aligner'>
                            <label>
                              Group Name: 
                            </label>
                            <p className='marked-list-group-name'><strong>{submission.groupName}</strong></p>
                          </div>

                          <div className='marked-list-div-aligner'>
                            <label>
                              Activity Name:
                            </label>
                            <p>{submission.activityName}</p>
                          </div>

                          <div className='marked-list-div-aligner'>
                            <label>
                              Members: 
                            </label>
                            <p className='submission-mentions-list'>
                              {submission.mentions.map((mention, index) => (
                                <React.Fragment key={index}>
                                  {mention}{index < submission.mentions.length - 1 && ','}<br />
                                </React.Fragment>
                              ))}
                            </p>
                          </div>

                          <div className='marked-list-div-aligner'>
                            <label>
                              Link: 
                            </label><br/>
                            <a href={submission.link} target='_blank' rel='noopener noreferrer'>
                              Access Soft Copy
                            </a>
                          </div>

                          <hr/>

                          <div className='page-detailed-setup'>

                            <div className='marked-moda-left-div'>
                              <div>
                                <label>
                                  Creativity and Originality
                                </label>
                              </div>

                              <div>
                                <label>
                                  Content Understanding
                                </label>
                              </div>

                              <div>
                                <label>
                                  Clarity and Organization
                                </label>
                              </div>

                              <div>
                                <label>
                                  Use of Multimodal Elements
                                </label>
                              </div>

                              <div>
                                <label>
                                  Audience Engagement
                                </label>
                              </div>

                              <div>
                                <label>
                                  Technical Proficiency
                                </label>
                              </div>

                              <div>
                                <label>
                                  Creativity in Presentation
                                </label>
                              </div>

                              <div>
                                <label>
                                  Collaboration
                                </label>
                              </div>

                              <div>
                                <label>
                                  Overall Impact
                                </label>
                              </div>

                              <div>
                                <label>
                                  Reflection and Self-Assessment 
                                </label>
                              </div>

                            </div>

                            <div className='marked-moda-right-div'>
                              <p>{submission.creativeOriginality} /10</p>
                              <p>{submission.contentGrasp} /10</p>
                              <p>{submission.clarity} /10</p>
                              <p>{submission.multiElements} /10</p>
                              <p>{submission.audienceEngage} /10</p>
                              <p>{submission.techUse} /10</p>
                              <p>{submission.presentation} /10</p>
                              <p>{submission.teamWork} /10</p>
                              <p>{submission.meanImpact} /10</p>
                              <p>{submission.selfAssessment} /10</p>
                            </div>

                          </div>
                          

                          <hr/>

                          <div className='marked-list-div-aligner-score-total'>
                            <label>
                              Total Score: 
                            </label>
                            <p>{submission.total}%</p>
                          </div> 

                          </li>
                        </ul>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default UserStats;


/*
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './bodyFiles-styling/userStats.css';
import AccessDenied from '../components/AccessDenied';
import './bodyFiles-styling/bodyFiles.css'; 
import './bodyFiles-styling/supportProfile.css';

const UserStats = () => {
  const [userData, setUserData] = useState(null);
  const [userScores, setUserScores] = useState([]);
  const [trackedUserScores, setTrackedUserScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isScoresOpen, setIsScoresOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [submissions, setSubmissions] = useState([]);
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          credentials: 'include'
        });

        if (!response.ok) {
          if (response.status === 401) {
            // If unauthorized, show AccessDenied component
            return;
          }
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();

        if (!data.success || !data.user.accountName) {
          // If the user doesn't have an accountName, redirect to '/login-second-stage'
          navigate('/login-second-stage');
          return;
        }

        setUserData(data.user);

        // Once userData is set, fetch user scores
        fetchUserScores(data.user.decoyId);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUserScores = async (decoyId) => {
      try {
        const response = await fetch(`https://lernacy.co.za/api/user-scores/${decoyId}`, {
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user scores');
        }

        const data = await response.json();

        setUserScores(data.data);
      } catch (error) {
        console.error('Error fetching user scores:', error);
      }
    };

    // Fetch user data when component mounts
    fetchUserData();

  }, [navigate]); // useEffect dependency

  const fetchSubmissions = async () => { 
    try {
      const response = await fetch('https://lernacy.co.za/api/multimodal/user-submissions', {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch submissions');
      }
      const data = await response.json();
      setSubmissions(data.submissions);
    } catch (error) {
      console.error('Error fetching submissions:', error); 
    }
  };
  

  React.useEffect(() => {
    fetchSubmissions(); 
  }, []);

  useEffect(() => {
    if (!userData) return;

    // Fetch tracked user scores
    const fetchTrackedUserScores = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/tracked-user-scores', {
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch tracked user scores');
        }

        const data = await response.json();

        setTrackedUserScores(data);
      } catch (error) {
        console.error('Error fetching tracked user scores:', error);
      }
    };

    fetchTrackedUserScores();
  }, [userData]);

  const formatDate = (dateString) => { 
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ][date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day} ${month} ${year} - ${hours}:${minutes}`;
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredScores = trackedUserScores.filter((gameScore) => {
    return (
      gameScore.gameName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      gameScore.userId.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      gameScore.userId.surname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      gameScore.highScore.toString().includes(searchQuery) ||
      gameScore.scores.some((score) => score.score.toString().includes(searchQuery))
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  // Show AccessDenied component if user is not authenticated
  if (!userData) {
    return <AccessDenied />;
  }

  return (
    <div className='stats-record-page-main-contain' >
      <h1 className='userlink-title-name'>User Activity Records</h1>

      <div style={{display:'none'}}>
        <p>ID: {userData._id}</p>
        <p>Name: {userData.name}</p>
        <p>Surname: {userData.surname}</p>
        <p>Account Name: {userData.accountName}</p>
        <p>Decoy ID: {userData.decoyId}</p>
      </div>

      <div className='profile-navigation'>

        <Link to={'/profile'} className='profile-navigation-p-color-back'>
          <div className='profile-navigation-div'>
            User
          </div> 
        </Link>

        <Link to={'/user-links'} className='profile-navigation-p-color-back'>
          <div className='profile-navigation-div'>
            Links
          </div>
        </Link>

        <Link to={'/user-stats'} className='profile-navigation-p-color-back-active'>
          <div className='profile-navigation-div'>
            Stats
          </div>
        </Link>

        <Link to={'/user-awards'} className='profile-navigation-p-color-back'>
          <div className='profile-navigation-div'>
            Awards
          </div>
        </Link>

      </div>

      <br />

      <div className='current-user-marks-record-sheet'>
        <ul>
          {userScores.length === 0 ? (
            <li className='record-not-available-list'>No user scores available</li>
          ) : (
            userScores.map((gameScore, index) => (
              <li key={index} className='current-user-scores'>
                <p onClick={() => setIsScoresOpen(!isScoresOpen)}>{gameScore.gameName}</p>
                {isScoresOpen && (
                  <>
                    <p>High Score: {gameScore.highScore}</p>
                    <p style={{display:'none'}}>Time of Day: {gameScore.timeOfDay}</p>
                    <h2>Tries:</h2>
                    <ul className='tries-record'>
                      {gameScore.scores.map((score, scoreIndex) => (
                        <li key={scoreIndex} className='tries-record-list'>
                          <p>{formatDate(score.createdAt)}</p>
                          <p> ▹Score: {score.score}</p>
                          <p style={{display:'none'}}>Time of Day: {score.timeOfDay}</p>
                        </li>
                      )).reverse()}
                    </ul>
                  </>
                )}
              </li>
            ))
          )}
        </ul>
      </div>

      <div className='multimodal-stats-for-user-container'>
        <h2>Submission List</h2>
        <ul className='multimodal-stats-for-user'>
          {submissions.map((submission, index) => (
            <li key={index} className='multimodal-stats-for-user-li'>
              <p>Activity Name: {submission.activityName}</p>
              <p>Mentions: {submission.mentions.join(', ')}</p>
              <p>Link: <a href={submission.link} target="_blank" rel="noopener noreferrer">Access Soft Copy</a></p>
              <p>Group Name: {submission.groupName}</p>
            </li>
          ))}
        </ul>
      </div>

      <br />
                
      <hr />

      <div className='tracking-user-marks-record-sheet'>
        <h2>Activity Tracking</h2>

        <input
          type='text'
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder='Search...'
          className='search-box-field'
        />

        {filteredScores.length === 0 ? (
          <div>
            <p className='record-not-available-list'>No user activities being tracked.</p>
          </div>
        ) : (
          filteredScores.map((gameScore, index) => (
            <div key={index} className='tracked-user-scores'>
              <h3>{gameScore.gameName}</h3>
              
              <Link to={`/user/${gameScore.userId.decoyId}`} className='link-to-tracked-users-account'>
                <p className='tracked-user-name'>
                  <strong>{gameScore.userId.name} {gameScore.userId.surname}</strong>  
                </p>
                <p className='tracked-user-account'>
                  | {gameScore.userId.accountName}
                </p>
              </Link>

              <p>High Score: {gameScore.highScore}</p>
              <ul>
                {gameScore.scores.slice().reverse().map((score, scoreIndex) => (
                  <li key={scoreIndex} className='tries-record-list'>
                    <p>{formatDate(score.createdAt)}</p>
                    <p> ▹Score: {score.score}</p>
                    <p style={{ display: 'none' }}>Time of Day: {score.timeOfDay}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))
        )}
      </div>

    </div>
  );
};

export default UserStats;
*/


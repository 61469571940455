import React, { useState, useEffect } from 'react';
import socialSciencesPathfinderosInstructions from './GrFourQuestions/socialSciencesPathfinderos';
import fetchUserDetailsGame from '../../../../components/fetchUserDetails';
import AccessDenied from '../../../../components/AccessDenied';

const gameName = 'Grade_4_Pathfinderos_SS_7';

const QuestionBlock = ({ id, question, options, correctAnswer, imageUrl, showAnswers, selectedOption, handleSelectOption }) => {
    return (
        <div className='multiple-choice-ul-block'>
            <h3>{question}</h3>
            {imageUrl && <img loading="lazy" src={imageUrl} alt="Question Image" />}
            <div>
                {options.map((option, index) => ( 
                <div key={index}>
                    <input
                    type="radio"
                    id={`option-${id}-${index}`}
                    name={`answer-option-${id}`}
                    value={option}
                    checked={selectedOption === option}
                    onChange={() => handleSelectOption(option)}
                    />
                    <label htmlFor={`option-${id}-${index}`}>{option}</label>
                </div>
                ))}
            </div>
        {/* Conditionally render the Correct Answer part */}
        {showAnswers && (
            <p>
                {selectedOption === correctAnswer ? <span style={{ color: 'green' }}>✔</span> : <span style={{ color: 'red' }}>✘</span>}
                Your Answer: {selectedOption}
            </p>
        )}
        </div>
    );
};

const GrFourPathfinderos = () => {
    const [gameOver, setGameOver] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [userName, setUserName] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Default value set to false
    const [highScore, setHighScore] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState(new Array(7).fill(null));
    const [userScore, setUserScore] = useState(null);
    const [scoreRating, setScoreRating] = useState('');
    const [scoreComment, setScoreComment] = useState('');
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', {
                    method: 'GET',
                    credentials: 'include',
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch user details');
                }
    
                const data = await response.json();
                const user = data.user;
    
                if (!user || !user.accountName) {
                    throw new Error('User details not found');
                }
    
                setIsAuthenticated(true);
                setUserName(`${user.name} ${user.surname}`);
                setUserDetails(user);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
    
        fetchUserDetails();
        fetchHighScore(); // Add this line to fetch the high score
        const shuffledQuestions = socialSciencesPathfinderosInstructions.sort(() => Math.random() - 0.5);
        const selectedQuestions = shuffledQuestions.slice(0, 7);
        setQuestions(selectedQuestions);
    }, []);

    const fetchHighScore = async () => {
        try {
            const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
                method: 'GET',
                credentials: 'include',
            });
        
            if (response.ok) {
                const data = await response.json();
                setHighScore(data.highScore);
            } else {
                throw new Error('Error fetching high score: ' + response.statusText);
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleSelectOption = (option, index) => {
        const updatedAnswers = [...selectedAnswers];
        updatedAnswers[index] = option;
        setSelectedAnswers(updatedAnswers);
    };

    const handleSubmit = async () => {
        const userScore = calculateUserScore();
        setUserScore(userScore);
        calculateScoreRating(userScore);
        setGameOver(true);
      
        const data = {
            userId: userDetails._id,
            score: userScore,
            timeOfDay: new Date().getHours(),
            gameName: gameName,
        };
      
        try {
          // Step 1: Fetch CSRF token
          const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
            method: 'GET',
            credentials: 'include',
          });
      
          const csrfData = await csrfResponse.json();
          const csrfToken = csrfData.csrfToken;
      
          if (!csrfResponse.ok) {
            throw new Error('Failed to fetch CSRF token');
          }
      
          // Step 2: Send a POST request with CSRF token
          const response = await fetch('https://lernacy.co.za/api/record-score', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'CSRF-Token': csrfToken, // Include CSRF token in headers
            },
            body: JSON.stringify(data),
            credentials: 'include', // Include credentials
          });
      
          if (response.ok) {
            // console.log('Score recorded successfully');
          } else {
            throw new Error('Failed to record score');
          }
        } catch (error) {
          console.error('Error recording score:', error.message);
        }
    }; 

    const calculateUserScore = () => {
        let correctCount = 0;
        questions.forEach((question, index) => {
        if (question.correctAnswer === selectedAnswers[index]) {
            correctCount++;
        }
        });
        return correctCount;
    };

    const calculateScoreRating = (score) => {
        const percentage = (score / questions.length) * 100;
        const roundedPercentage = Math.round(percentage);

        if (roundedPercentage >= 90) {
            setScoreRating('Outstanding');
            setScoreComment('Congratulations! You performed outstandingly.');
        } else if (roundedPercentage >= 75) {
            setScoreRating('Passed');
            setScoreComment('Well done! You passed the counting game.');
        } else if (roundedPercentage >= 50) {
            setScoreRating('Almost');
            setScoreComment('You are close to passing. Keep practicing!');
        } else {
            setScoreRating('Failed');
            setScoreComment('Keep practicing. You can do better!');
        }
    };

    const resetGameState = () => {
        window.location.reload(); // Reload the page
    
        // Reset game state
        //setGameOver(false);
        setUserScore(null);
        setSelectedAnswers(new Array(10).fill(null));
        fetchHighScore(); // Fetch high score again on game restart
    };

    // Redirect to login page if not authenticated
    if (!isAuthenticated) {
        return <AccessDenied />;
    }

    return (
        <div className='multiple-q-tested-wall-container'>
            {!gameOver && <h1 className='activity-name-tag-h1'>G4 - Pathfinderos</h1>}
      
            {gameOver && ( 

            <div>
                <h1 className='activity-name-tag-h1'>Activity Over<br/>Your Score: {userScore}/{questions.length}</h1>
                
                <div className='activity-game-over-data'>
                    <h2 className='activity-rounds-tag-h2-left'>Score Rating: {scoreRating}</h2>
                    <h3>Score Comment: {scoreComment}</h3>
                    <p>Score Percentage: <strong>{(userScore / questions.length) * 100}%</strong></p>
                </div>
                
                <h3 className='activity-rounds-tag-h2'>Questions Record:</h3>
                <ul className='multiple-questions-game-over-ul'>
                    {questions.map((question, index) => (
                    <li key={index} style={{ display: 'block', marginBottom: '10px' }}>
                        <strong>Question {index + 1}:</strong>
                        <br /> {question.question}
                        <br />
                        <strong>Your Answer:</strong> {selectedAnswers[index]}
                        {selectedAnswers[index] === question.correctAnswer ? (
                        <span style={{ color: 'green' }}>✔</span>
                        ) : (
                        <span style={{ color: 'red' }}>✘</span>
                        )}
                    </li>
                    ))}
                </ul>

                <p>
                    <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/7</strong>
                </p>

                <hr />

                <div className='activity-restart-or-more'>
                    <button className='activity-restart-button' onClick={resetGameState}>Play Again</button>
                    <button className='more-activity-button' onClick={() => window.location.href = "/Grade:4_Social-Sciences"}>Play More Games</button>
                </div>
              
            </div>
            )}
      
            {!gameOver && (
                <div>
                    <div className='round-high-score'>
                        <h2 className='activity-rounds-tag-h2'>7 Questions</h2>
                        <p>
                            <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/7</strong>
                        </p>
                    </div>
                    <h3 style={{textDecoration:'underline'}}>Answer All Questions</h3>
                    {questions.map((question, index) => (
                        <QuestionBlock
                        key={index}
                        id={index}
                        question={question.question}
                        options={question.options}
                        correctAnswer={question.correctAnswer}
                        imageUrl={question.imageUrl}
                        showAnswers={false}
                        selectedOption={selectedAnswers[index]}
                        handleSelectOption={(option) => handleSelectOption(option, index)}
                        />
                    ))}

                    <br />

                    <div>
                        <button className='activity-submit-button' onClick={handleSubmit}>Submit Answers</button>
                    </div>
                    
                </div>
            )}
            <br />
        </div>
    );
};

export default GrFourPathfinderos;

import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import AccessDenied from '../../components/AccessDenied';
import { useNavigate } from 'react-router-dom';
import './games-css/main-activity.css';

Modal.setAppElement('#root');

const GrThreeCountingGame = () => {
  const [rounds, setRounds] = useState(20);
  const [shapesList, setShapesList] = useState([]);
  const [userAnswers, setUserAnswers] = useState(Array(rounds).fill(''));
  const [correctCount, setCorrectCount] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [scoreRating, setScoreRating] = useState('');
  const [scoreComment, setScoreComment] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [userDetails, setUserDetails] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [roundRecords, setRoundRecords] = useState([]);
  const [highScore, setHighScore] = useState(null);
  const [clickedSymbols, setClickedSymbols] = useState({});
  const navigate = useNavigate();
  const gameName = 'Grade_3:_Counting-Activity_Maths_20';

  const symbols = '✱✤✦★❉✿✽✹✸✶♣♠♦♥'.split('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          const user = data.user;

          if (!user || !user.accountName) {
            setIsAuthenticated(false);
            navigate('/login-second-stage');
            return;
          }

          setUserDetails(user);
          setIsAuthenticated(true);
        } else {
          console.error('Error fetching user details:', response.statusText);
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error fetching user details:', error.message);
        setIsAuthenticated(false);
      }
    };

    fetchUserDetails();
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchHighScore(gameName);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    calculateScoreRating();
    if (showScore) {
      fetchHighScore(gameName);
    }
  }, [correctCount, rounds, showScore]);

  const fetchHighScore = async (gameName) => {
    try {
      const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        console.error('Error fetching high score:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching high score:', error.message);
    }
  };

  useEffect(() => {
    calculateScoreRating();
  }, [correctCount, rounds]);

  useEffect(() => {
    if (isAuthenticated) {
      generateAllRounds();
    }
  }, [isAuthenticated]);

  const generateAllRounds = () => {
    const allRounds = Array.from({ length: rounds }, () => generateShapes());
    setShapesList(allRounds);
    setClickedSymbols({});
  };

  const generateShapes = () => {
    const numShapes = Math.floor(Math.random() * 101) + 5;
    return Array.from({ length: numShapes }, (_, i) => ({
      id: i,
      symbol: symbols[Math.floor(Math.random() * symbols.length)],
    }));
  };

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = value;
    setUserAnswers(updatedAnswers);
  };

  const handleSubmitAll = async () => {
    if (isAuthenticated === null || !isAuthenticated) {
      return;
    }
  
    let correct = 0;
    const records = shapesList.map((shapes, index) => {
      const isCorrect = userAnswers[index] === shapes.length.toString();
      if (isCorrect) correct += 1;
  
      return {
        round: index + 1,
        instruction: '',
        circleSizes: shapes.map((shape) => shape.symbol),
        userAnswer: userAnswers[index],
        correctAnswer: shapes.length.toString(),
        isCorrect: isCorrect,
      };
    });
  
    setCorrectCount(correct);
    setRoundRecords(records);
    setShowScore(true);
  
    // Call recordGameScore after setting the correct count
    await recordGameScore(correct);
  };

  const calculateScoreRating = () => {
    const newPercentage = (correctCount / rounds) * 100;
    const roundedPercentage = Math.round(newPercentage);

    if (!isNaN(roundedPercentage)) {
      setPercentage(roundedPercentage.toFixed(2));

      if (roundedPercentage >= 90) {
        setScoreRating('Outstanding');
        setScoreComment('Congratulations! You performed outstandingly.');
      } else if (roundedPercentage >= 75) {
        setScoreRating('Passed');
        setScoreComment('Well done! You passed the counting game.');
      } else if (roundedPercentage >= 50) {
        setScoreRating('Almost');
        setScoreComment('You are close to passing. Keep practicing!');
      } else {
        setScoreRating('Failed');
        setScoreComment('Keep practicing. You can do better!');
      }
    } else {
      console.error('Error calculating percentage:', roundedPercentage);
    }
  };

  const handleRestart = async () => {
    try {
      setShowScore(false);
      setCorrectCount(0);
      setUserAnswers(Array(rounds).fill(''));
      setScoreRating('');
      setScoreComment('');
      setPercentage(0);
      generateAllRounds();

      const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setHighScore(data.highScore);
      } else {
        console.error('Error fetching high score:', response.statusText);
      }
    } catch (error) {
      console.error('Error restarting game:', error.message);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const recordGameScore = async (correctCount) => {
    try {
      const payload = {
        userId: userDetails._id,
        score: correctCount,
        timeOfDay: new Date().getHours(),
        gameName: gameName,
      };
  
      // Step 1: Fetch CSRF token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include',
      });
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      // Step 2: Send a POST request with CSRF token
      const response = await fetch('https://lernacy.co.za/api/record-score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        credentials: 'include',
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        console.error('Error recording activity score:', response.statusText);
      }
    } catch (error) {
      console.error('Error recording activity score:', error.message);
    }
  };

  const toggleSymbolColor = (roundIndex, symbolIndex) => {
    setClickedSymbols((prev) => {
      const key = `${roundIndex}-${symbolIndex}`;
      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  };

  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      border: '2px solid #ccc',
      borderRadius: '8px',
      background: '#fff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      width: '60%',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  };

  return (
    <div className='activity-wall-container'>
      {isAuthenticated === null ? (
        <p>Loading authentication status...</p>
      ) : !isAuthenticated ? (
        <AccessDenied />
      ) : showScore ? (
        <div className='running-game-over-section'>
          
          <h1 className='activity-name-tag-h1'>Activity Over <br/>Total Score: {correctCount}/{rounds}</h1>

          <div className='activity-game-over-data'>
            <h2>Score Rating: {scoreRating}</h2>
            <h3>{scoreComment}</h3>
            <p>Percentage: <strong>{percentage}%</strong></p>
          </div>

          <div className='rounds-list'>
            <h2 className='activity-rounds-tag-h2'>Question Records</h2>
            <ul className='activity-over-rounds-evidence-ul'>
              {roundRecords.map((record, index) => (
                <li key={index} className='activity-over-rounds-evidence' style={{ color: record.isCorrect ? 'green' : 'red' }}>
                  <strong>Question {record.round}:</strong> <br />  {record.instruction}Symbols to count: <br/> {record.circleSizes.join(', ')} <br />
                  Your Answer - {record.userAnswer} {record.isCorrect ? '✔' : '✘'}<br /> Correct Answer - {record.correctAnswer}
                </li>
              ))}
            </ul>
          </div>

          <hr />

          <p>
            <strong>
              High Score: {highScore !== null ? `${highScore}/${rounds}` : 'N/A'}
            </strong>
          </p>

          <div className='activity-restart-or-more'>
            <button className='activity-restart-button' onClick={handleRestart}>Play Again</button>
            <button className='more-activity-button' onClick={() => window.location.href = "/Grade:3_Mathematics"}>Do More Activities</button>
          </div>
          <br/>
        </div>
      ) : (
        <div className='running-activity-section'>
          <h1 className='activity-name-tag-h1'>G3 - Counting Activity</h1>
          <div className='round-high-score'>
            <h2>{rounds} Rounds</h2>
            <p>
              <strong>
                High Score: {highScore !== null ? `${highScore}/${rounds}` : 'N/A'}
              </strong>
            </p>
          </div>
          <div className='counting-activity-rounds-block'>
            {shapesList.map((shapes, roundIndex) => (
              <div key={roundIndex} className='counting-activity-rounds-block-divs'>
                <h3>Round {roundIndex + 1}</h3>
                <h4 className='activity-instructions-tag-h4'>Count the following symbols</h4>
                <div className='symbol-container-div'>
                  {shapes.map((shape, symbolIndex) => (
                    <span
                      className='count-symbol'
                      key={shape.id}
                      onClick={() => toggleSymbolColor(roundIndex, symbolIndex)}
                      style={{
                        color: clickedSymbols[`${roundIndex}-${symbolIndex}`] ? 'blue' : 'black',
                      }}
                    >
                      {shape.symbol}
                    </span>
                  ))}
                </div>
                <label>Enter your answer:</label>
                <br />
                <input
                  type="text"
                  value={userAnswers[roundIndex]}
                  onChange={(e) => handleAnswerChange(roundIndex, e.target.value)}
                  className='counting-activity-input-field'
                />
              </div>
            ))}
          </div>
          <br />
          <button
            onClick={handleSubmitAll}
            className='activity-submit-button'
          >
            Submit All Answers
          </button>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Symbol Selection Warning"
        style={customModalStyles}
      >
        <h2 style={{ marginBottom: '20px' }}>Please enter all answers before submitting.</h2>
        <button onClick={closeModal} style={{ padding: '10px', fontSize: '16px' }}>OK</button>
      </Modal>
      <br/>
    </div>
  );
};

export default GrThreeCountingGame;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import MenuToggle from './MenuToggle';
import './componentsCSS/auth.css';

const SignupForm = () => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordRequirements, setPasswordRequirements] = useState({
        capitalLetter: false,
        smallLetter: false,
        number: false,
        symbol: false,
        length: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [signupStatus, setSignupStatus] = useState(null);
    const [isPasswordRequirementsVisible, setIsPasswordRequirementsVisible] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true); // State variable for email validity

    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthentication = async () => {
        try {
            const response = await fetch('https://lernacy.co.za/api/check-auth', { 
            method: 'GET',
            credentials: 'include',
            });

            if (response.ok) {
            // The user has an active session, redirect to "/"
            navigate('/');
            }
        } catch (error) {
            console.error('Error checking authentication status:', error.message);
        }
        };

        checkAuthentication();
    }, [navigate]);

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        // Basic email format validation
        validateEmail(newEmail);
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        // Basic password format validation
        validatePassword(newPassword);
        // Show password requirements when user enters data
        setIsPasswordRequirementsVisible(newPassword.length > 0);
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        setConfirmPasswordError(validateConfirmPassword(newConfirmPassword));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if all password requirements are met
        const allRequirementsMet = Object.values(passwordRequirements).every((req) => req);
    
        if (!allRequirementsMet) {
            // If not all requirements are met, do not submit the form
            setSignupStatus('error');
            return;
        }
    
        setIsLoading(true); // Set loading state to true before the request
    
        try {
            // Fetch CSRF token from the server
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include', // Important
            });

            const { csrfToken } = await csrfResponse.json();

            // Your form data
            const formData = {
                name,
                surname,
                email,
                password,
                confirmPassword,
            };

            // Include CSRF token in the headers of the POST request
            const response = await fetch('https://lernacy.co.za/api/signup', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'CSRF-Token': csrfToken, // Include CSRF token in the headers
                },
                body: JSON.stringify(formData),
                credentials: 'include', // Important for cookies
        });

        // Handle the response
        if (response.ok) { 
            // The request was successful, update the signup status
            setSignupStatus('success');

            // Check authentication status after successful signup
            const authResponse = await fetch('https://lernacy.co.za/api/check-auth', {
            method: 'GET',
            credentials: 'include',
            });

            if (authResponse.ok) {
            // The user has an active session, redirect to "/"
            navigate('/');
            }
        } else {
            // The request failed, update the signup status
            if (response.status === 400) {
            // Duplicate key error (email already exists)
            setSignupStatus('duplicate-email');
            } else {
            // Other errors
            setSignupStatus('error');
            }
        }
        } catch (error) {
            // Handle network or other errors
            console.error('Error submitting form:', error.message);
            setSignupStatus('error');
        } finally {
            setIsLoading(false); // Set loading state to false after the request
        }
    };

    /*
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Check if all password requirements are met
        const allRequirementsMet = Object.values(passwordRequirements).every((req) => req);
      
        if (!allRequirementsMet) {
          // If not all requirements are met, do not submit the form
          setSignupStatus('error');
          return;
        }
      
        try {
          // Fetch CSRF token from the server
          const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
            method: 'GET',
            credentials: 'include', // Important for cookies
          });
      
          const { csrfToken } = await csrfResponse.json();
      
          // Your form data
          const formData = {
            name,
            surname,
            email,
            password,
            confirmPassword,
          };
      
          // Include CSRF token in the headers of the POST request
          const response = await fetch('https://lernacy.co.za/api/signup', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'CSRF-Token': csrfToken, // Include CSRF token in the headers
            },
            body: JSON.stringify(formData),
            credentials: 'include', // Important for cookies
          });
      
          // Handle the response
          if (response.ok) { 
            // The request was successful, update the signup status
            setSignupStatus('success');
      
            // Check authentication status after successful signup
            const authResponse = await fetch('https://lernacy.co.za/api/check-auth', {
              method: 'GET',
              credentials: 'include',
            });
      
            if (authResponse.ok) {
              // The user has an active session, redirect to "/"
              navigate('/');
            }
          } else {
            // The request failed, update the signup status
            if (response.status === 400) {
              // Duplicate key error (email already exists)
              setSignupStatus('duplicate-email');
            } else {
              // Other errors
              setSignupStatus('error');
            }
          }
        } catch (error) {
          // Handle network or other errors
          console.error('Error submitting form:', error.message);
          setSignupStatus('error');
        }
    };   
    */  
    /*
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Check if all password requirements are met
        const allRequirementsMet = Object.values(passwordRequirements).every((req) => req);
      
        if (!allRequirementsMet) {
          // If not all requirements are met, do not submit the form
          setSignupStatus('error');
          return;
        }
      
        // Your form data
        const formData = {
          name,
          surname,
          email,
          password,
          confirmPassword,
        };
      
        try {
          // Make a POST request to your server
          const response = await fetch('https://lernacy.co.za/api/signup', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
      
        // Handle the response
        if (response.ok) {
            // The request was successful, update the signup status
            setSignupStatus('success');
      
            // Check authentication status after successful signup
            const authResponse = await fetch('https://lernacy.co.za/api/check-auth', {
              method: 'GET',
              credentials: 'include',
            });
      
            if (authResponse.ok) {
              // The user has an active session, redirect to "/"
              navigate('/');
            }
        } else {
            // The request failed, update the signup status
            if (response.status === 400) {
              // Duplicate key error (email already exists)
              setSignupStatus('duplicate-email');
            } else {
              // Other errors
              setSignupStatus('error');
            }
        }
        } catch (error) {
          // Handle network or other errors
          console.error('Error submitting form:', error.message);
          setSignupStatus('error');
        }
    };
    */
  
    {/*
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(email);
        // Show basic email validation message
        const emailErrorElement = document.getElementById('email-error');
        if (isValidEmail) {
            emailErrorElement.textContent = '';
        } else {
            emailErrorElement.textContent = 'Invalid email format';
        }
    };
    */}

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(email);
        // Update the state to reflect email validity
        setIsValidEmail(isValidEmail);
    };

    const validatePassword = (password) => {
            // Password requirements
            const requirements = {
            capitalLetter: /[A-Z]/.test(password),
            smallLetter: /[a-z]/.test(password),
            number: /\d/.test(password),
            symbol: /[!@#$%^&*()_+\-[\]{};:'",.<>/?\\|]/.test(password),
            length: password.length >= 8,
        };

        // Update the state to reflect the password requirements
        setPasswordRequirements(requirements);

        // Check if all requirements are met
        const allRequirementsMet = Object.values(requirements).every((req) => req);

        // Hide password requirements if all requirements are met
        setIsPasswordRequirementsVisible(!allRequirementsMet);
    };

    const validateConfirmPassword = (confirmPassword) => {
        return confirmPassword === password ? '' : 'Passwords do not match';
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='auth-return-div'>
            <div className='auth-screen-main-div'>

                <MenuToggle />

                <div className='color-transition-block'>
                    <div className='color-transition'>
                        <img loading="lazy" src="/styling/logo.png" alt="Lernacy" className='logo-img' /> 
                        <h1>Welcome!</h1>
                        <p>Sign Up to create an account</p>     
                    </div>
                    {/**/}<img loading="lazy" src="/styling/wave-mob.png" alt="Description of the image" className='image-test-block' data-nosnippet/>
                    <img loading="lazy" src="/styling/Cloudy-large-1.png" alt="Description of the image" className='image-test-block-large' data-nosnippet/>
                </div> 

                <div className='form-container'>
                    
                    <div className='form-div-box'>

                        <div className='situation-controller-container'>
                            <Link to="/login" className='situation-controller'>
                                Login 
                            </Link>
                            <Link to="/signup" className='situation-controller-active'>
                                Sign Up
                            </Link>
                        </div>

                        <form onSubmit={handleSubmit} className='login-form-block'>
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                autoComplete="name"
                                required
                            />

                            <label htmlFor="surname">Surname:</label>
                            <input
                                type="text"
                                id="surname"
                                name="surname"
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                autoComplete="family-name"
                                required
                            />

                            <label htmlFor="email">Email:</label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                value={email}
                                onChange={handleEmailChange}
                                autoComplete="email"
                                required
                            />
                            {!isValidEmail && <span className='error-response'>Invalid email format</span>}
                            

                            <label htmlFor="password">Password:</label>
                            <div className='show-password-flex'>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    autoComplete="new-password"
                                />
                                <button type="button" onClick={togglePasswordVisibility} className='show-password'>
                                    {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                </button>
                            </div>
                            {Object.values(passwordRequirements).every((req) => req) && (
                                <p style={{color:'green'}} className='success-response'>Password meets requirements</p>
                            )}
                            {isPasswordRequirementsVisible && (
                                <div className='password-requirements'>
                                    <ul>
                                        <li style={{ color: passwordRequirements.capitalLetter ? 'green' : 'red' }}>
                                            At least 1 capital letter
                                        </li>
                                        <li style={{ color: passwordRequirements.smallLetter ? 'green' : 'red' }}>
                                            At least 1 small letter
                                        </li>
                                        <li style={{ color: passwordRequirements.number ? 'green' : 'red' }}>
                                            At least 1 number
                                        </li>
                                        <li style={{ color: passwordRequirements.symbol ? 'green' : 'red' }}>
                                            At least 1 symbol (!@#$%^&*()_+)
                                        </li>
                                        <li style={{ color: passwordRequirements.length ? 'green' : 'red' }}>
                                            At least 8 characters long
                                        </li>
                                    </ul>
                                </div>
                            )}
                            

                            <label htmlFor="confirmPassword">Confirm Password:</label>
                            <div className='show-password-flex'>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    autoComplete="new-password"
                                />
                                <button type="button" onClick={togglePasswordVisibility} className='show-password'>
                                    {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                </button>
                            </div>
                            {confirmPasswordError && <span className='error-response'>{confirmPasswordError}</span>}
                            
                            {signupStatus === 'error' && (
                                <p style={{color:'red'}} className='error-response'>Error submitting the form. Please try again.</p>
                            )}

                            {signupStatus === 'duplicate-email' && (
                                <p style={{color:'red'}} className='error-response'>
                                    This email already exists in our database. Please{' '}
                                    <Link style={{color:'red', fontWeight:'bold'}} to="/login" className='links'>log in</Link> or{' '}
                                    <Link style={{color:'red', fontWeight:'bold'}} to="/request-verification" className='links'>request an email verification link</Link>.
                                </p>
                            )}

                            {signupStatus === 'success' && (
                                <p style={{color:'green'}} className='success-response' sty>
                                    Form submitted successfully! Please check your email for verification.<br />
                                    If not found, check your email spam folder
                                </p>
                            )}

                            <button
                                type="submit"
                                disabled={
                                !isValidEmail ||
                                !Object.values(passwordRequirements).every((req) => req) ||
                                confirmPassword !== password ||
                                isLoading // Disable the button when loading
                                }
                            >
                                {isLoading ? 'Signing Up...' : 'Sign Up'}
                            </button>
                            
                        </form>

                        <br />

                        <p>
                            Already have an account?{' '}
                            <Link to="/login" className='links'>Login</Link>
                        </p>

                        <p>Or{' '}
                            <Link to="/request-verification" className='links'>request an email verification link</Link>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SignupForm;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const tests = [
    {
        school: "Phoenix High School",
        region: "(Vereeniging)",
        subject: "Geography - Grade 10",
        time: "45 min",
        total: 25,
        id: "Geo_104525_PHV_07062024",
        to: "/school-tests/Geo_104525_PHV_07062024"
    },
    {
        school: "Seageng Secondary School",
        region: "(Soshanguve)",
        subject: "History - Grade 11",
        time: "50 min",
        total: 75,
        id: "His_115075_SSS_08152024",
        to: "/school-tests/His_115075_SSS_08152024"
    }
    // Add more test data as needed
];

const SchoolTest = () => {
    const [searchQuery, setSearchQuery] = useState('');

    const filteredTests = tests.filter(test =>
        test.school.toLowerCase().includes(searchQuery.toLowerCase()) ||
        test.region.toLowerCase().includes(searchQuery.toLowerCase()) ||
        test.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
        test.id.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className='page-colour-manager'>
            <h1 className='page-title'>School Tests</h1>
            <div className='subject-search-input-container'>
                <input
                    type="text"
                    placeholder="Search by school, region, subject, or ID"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    className='subject-search-input'
                />
            </div>
            <div className="my-study-container">
                <div className='meant-to-align'>
                    <div className='align-container'>
                        {filteredTests.map(test => (
                            <Link key={test.id} style={{ backgroundColor: '#fff' }} className="my-text-container" to={test.to}>
                                <div className="my-text">
                                    <h2>{test.school}</h2>
                                    <p className='school-region-name'>{test.region}</p>
                                    <h3>{test.subject}</h3>
                                    <h4>Time: {test.time} - Total: {test.total}</h4>
                                    <p>Id: {test.id}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SchoolTest;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AccessDenied from '../components/AccessDenied';
import './bodyFiles-styling/userAccount.css';
import AdPosts from '../bodyFiles/AdPosts';
import AccountsTour from '../components/TourGuide/AccountsTour';

const Accounts = () => {
  const [activeUsers, setActiveUsers] = useState([]);
  const [currentUserId, setCurrentUserId] = useState('');
  const [currentAccountName, setCurrentAccountName] = useState('');  // Added
  const [accessDenied, setAccessDenied] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [followingCount, setFollowingCount] = useState(0);
  const [followedUsers, setFollowedUsers] = useState([]);
  const [trackRequestStatus, setTrackRequestStatus] = useState('');
  const [blockedByUsers, setBlockedByUsers] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');
  const [isAdmin, setIsAdmin] = useState(false); 
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await fetch('https://lernacy.co.za/api/users/active-users', {
        credentials: 'include'
      });
  
      setLoading(false);
  
      if (response.status === 401) {
        setAccessDenied(true);
        return;
      }
  
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
  
      const data = await response.json();
  
      // Set blockedByUsers state
      const blockedByUsersList = data.blockedByUsers || [];
      setBlockedByUsers(blockedByUsersList);
  
      const sortedUsers = data.activeUsers.sort((a, b) => {
        const nameA = `${a.name} ${a.surname}`.toUpperCase();
        const nameB = `${b.name} ${b.surname}`.toUpperCase();
        return nameA.localeCompare(nameB);
      });
  
      const updatedUsers = sortedUsers.map(user => ({
        ...user,
        hasSentTrackingRequest: user.trackingRequests.some(request => request.sender === data.currentUserId)
      }));
  
      setActiveUsers(updatedUsers);
      setCurrentUserId(data.currentUserId);
      setCurrentAccountName(data.currentAccountName);
  
      const currentUser = updatedUsers.find(user => user._id === data.currentUserId);
  
      if (currentUser) {
        setFollowingCount(currentUser.followingCount);
      }
  
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); 

  const handleStrike = async (userId) => {
    try {
      // Fetch CSRF token from the server
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include', // Include credentials for the server to identify the user
      });
  
      const { csrfToken } = await csrfResponse.json();
  
      // Include CSRF token in the headers of the POST request
      const response = await fetch('https://lernacy.co.za/api/check/strike-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken, // Include CSRF token in the headers
        },
        credentials: 'include', // Include credentials for the server to identify the user
        body: JSON.stringify({ userId }),
      });
  
      const responseData = await response.json(); // Parse response data as JSON
  
      if (!response.ok) {
        console.error('Failed to strike user:', responseData.message);
        return;
      }
  
      //console.log('User striked successfully');
    } catch (error) {
      console.error('Error striking user:', error);
    }
  };
  
  

  useEffect(() => {
    const fetchDataAndAccountName = async () => {
      try {
        // Fetch active users and current user data
        const response = await fetch('https://lernacy.co.za/api/users/active-users', {
          credentials: 'include'
        });
  
        if (response.status === 401) {
          setAccessDenied(true);
          return;
        }
  
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
  
        const data = await response.json();
  
        // Set blockedByUsers state
        const blockedByUsersList = data.blockedByUsers || [];
        setBlockedByUsers(blockedByUsersList);
  
        const sortedUsers = data.activeUsers.sort((a, b) => {
          const nameA = `${a.name} ${a.surname}`.toUpperCase();
          const nameB = `${b.name} ${b.surname}`.toUpperCase();
          return nameA.localeCompare(nameB);
        });
  
        const updatedUsers = sortedUsers.map(user => ({
          ...user,
          hasSentTrackingRequest: user.trackingRequests.some(request => request.sender === data.currentUserId)
        }));
  
        setActiveUsers(updatedUsers);
        setCurrentUserId(data.currentUserId);
        setCurrentAccountName(data.currentAccountName);
  
        const currentUser = updatedUsers.find(user => user._id === data.currentUserId);
  
        if (currentUser) {
          setFollowingCount(currentUser.followingCount);
        }
  
        // Fetch account name
        const accountNameResponse = await fetch('https://lernacy.co.za/api/study', {
          credentials: 'include',
        });
  
        if (!accountNameResponse.ok) {
          throw new Error('Failed to fetch account name');
        }
  
        const accountNameData = await accountNameResponse.json();
  
        if (accountNameData.success) {
          setCurrentAccountName(accountNameData.user.accountName);
        } else {
          throw new Error('User not found');
        }
  
        // Redirect if accountName is null or empty
        if (!accountNameData.user.accountName) {
          navigate('/login-second-stage');
        }
  
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchDataAndAccountName();
  }, []);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/check/check-user-primary-status', {
          credentials: 'include'
        });
  
        if (response.ok) {
          setResponseMessage('Admin status check successful.');
          setIsAdmin(true); // Set isAdmin to true if the response is 'ok'
        } else {
          setResponseMessage('Admin status check failed.');
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        setResponseMessage('Internal server error.'); // Set response message for internal server error
      }
    };
  
    checkAdminStatus();
  }, []);

  const blockedByUsersList = blockedByUsers || [];

  const filteredUsers = activeUsers.filter(user =>
    user.accountName &&  // Check if accountName exists
    `${user.name} ${user.surname} ${user.accountName}`.toLowerCase().includes(searchQuery.toLowerCase()) &&
    !user.isBlockedByCurrentUser // Exclude blocked by users
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const fetchFollowedUsers = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/users/followed-users', {
          credentials: 'include'
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch followed users');
        }
    
        const data = await response.json();
        setFollowedUsers(data.followedUsers);
    
      } catch (error) {
        console.error('Error fetching followed users:', error);
        // Handle error: Display an error message to the user or retry the request
      }
    };

    fetchFollowedUsers();
  }, []);

  const handleTrackActivities = async (event, targetUserId) => {
    event.preventDefault();  // Prevent default link behavior
    
    try {
        // First, get the CSRF token
        const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!csrfResponse.ok) {
            throw new Error(await csrfResponse.text());
        }

        const { csrfToken } = await csrfResponse.json();

        // Prepare the data to be sent
        const requestData = { currentUserId, targetUserId };
        //console.log('Request Data:', requestData); // Log the data being sent

        // Now make the POST request with the CSRF token
        const response = await fetch('https://lernacy.co.za/api/users/track-activities', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'CSRF-Token': csrfToken  // Include the CSRF token in the headers
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message);  // Throw the error message from the server
        }

        // Update the tracking request status
        const updatedUsers = activeUsers.map(user => {
            if (user._id === targetUserId) {
                return {
                    ...user,
                    trackingRequestStatus: 'pending',
                };
            }
            return user;
        });

        setActiveUsers(updatedUsers);
        setTrackRequestStatus('Request sent successfully');

        // Make a GET request to update the track activities button
        const getResponse = await fetch('https://lernacy.co.za/api/users/active-users', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!getResponse.ok) {
            throw new Error(await getResponse.text());
        }

        const data = await getResponse.json();
        // Update the button or other state based on the response
        setActiveUsers(data.activeUsers);  // Update the activeUsers state with the new data

    } catch (error) {
        console.error('Error sending tracking request:', error);
        setTrackRequestStatus(error.message);  // Set the error message to the state
    }
  };

  const isFollowing = (userId) => {
    return followedUsers.includes(userId);
  };

  const handleFollow = async (currentUserId, targetUserId, currentAccountName) => {
    try {
      // Fetch accountName for the current user
      const currentUser = activeUsers.find(user => user._id === currentUserId);
      const accountName = currentUser ? currentUser.accountName : '';
  
      // Optimistically update UI
      setFollowedUsers(prevFollowedUsers => {
        if (prevFollowedUsers.includes(targetUserId)) {
          return prevFollowedUsers.filter(userId => userId !== targetUserId);
        } else {
          return [...prevFollowedUsers, targetUserId];
        }
      });
  
      setActiveUsers(prevActiveUsers =>
        prevActiveUsers.map(user => {
          if (user._id === currentUserId) {
            return {
              ...user,
              followingCount: followedUsers.includes(targetUserId) ? user.followingCount - 1 : user.followingCount + 1,
              followingIds: followedUsers.includes(targetUserId)
                ? user.followingIds.filter(id => id !== targetUserId)
                : [...user.followingIds, targetUserId],
            };
          }
          if (user._id === targetUserId) {
            return {
              ...user,
              followersCount: followedUsers.includes(targetUserId) ? user.followersCount - 1 : user.followersCount + 1,
              followers: followedUsers.includes(targetUserId)
                ? user.followers.filter(id => id !== currentUserId)
                : [...user.followers, currentUserId],
            };
          }
          return user;
        })
      );
  
      // Update the followingCount state for the current user
      setFollowingCount(prevFollowingCount =>
        followedUsers.includes(targetUserId) ? prevFollowingCount - 1 : prevFollowingCount + 1
      );
  
      // Fetch CSRF token
      const csrfResponse = await fetch(`https://lernacy.co.za/api/form`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!csrfResponse.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
  
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;
  
      const response = await fetch(`https://lernacy.co.za/api/users/follow`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrfToken,  // Include CSRF token
        },
        body: JSON.stringify({ currentUserId, targetUserId, currentAccountName }),  // Added currentAccountName
      });
  
      if (!response.ok) {
        throw new Error('Failed to toggle follow status');
      }
    } catch (error) {
      // If there's an error, revert the changes made to the UI
      setFollowedUsers(prevFollowedUsers => {
        if (prevFollowedUsers.includes(targetUserId)) {
          return [...prevFollowedUsers, targetUserId];
        } else {
          return prevFollowedUsers.filter(userId => userId !== targetUserId);
        }
      });
  
      setActiveUsers(prevActiveUsers =>
        prevActiveUsers.map(user => {
          if (user._id === currentUserId) {
            return {
              ...user,
              followingCount: followedUsers.includes(targetUserId) ? user.followingCount + 1 : user.followingCount - 1,
              followingIds: followedUsers.includes(targetUserId)
                ? [...user.followingIds, targetUserId]
                : user.followingIds.filter(id => id !== targetUserId),
            };
          }
          if (user._id === targetUserId) {
            return {
              ...user,
              followersCount: followedUsers.includes(targetUserId) ? user.followersCount + 1 : user.followersCount - 1,
              followers: followedUsers.includes(targetUserId)
                ? [...user.followers, currentUserId]
                : user.followers.filter(id => id !== currentUserId),
            };
          }
          return user;
        })
      );
  
      // Revert the followingCount state for the current user
      setFollowingCount(prevFollowingCount =>
        followedUsers.includes(targetUserId) ? prevFollowingCount + 1 : prevFollowingCount - 1
      );
  
      console.error('Error toggling follow status:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (accessDenied) {
    return <AccessDenied />;
  }

  return (
    <div className='page-space-manager'>

      <AccountsTour />
      
      <h1 className='activity-name-tag-h1'>Active Users</h1>
      
      <br />
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name, surname, or accountName"
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>

      <div className='account-main-container-box'>
        <div className='account-main-container-box-aligned'>
          <ol type="none" className='accounts-ol-block-list'>
            {loading ? (
              <p>Loading...</p>
            ) : (
              filteredUsers.map((user, index) => (
                <React.Fragment key={user.decoyId}>
                  <li className='user-container-main-box'>
                    <div className='accounts-user-details'>
                      <Link to={user.isCurrentUser ? '/profile' : `/user/${user.decoyId}`} className='user-details-block'>
                        <img
                          loading="lazy"
                          src={user.profilePicture}
                          alt="Profile" 
                          className='user-details-profile-image' 
                        />
                        <div className='details-about-theuser-box'>
                          <p className='account-name-surname'>{user.name} {user.surname}</p>
                          <p>{user.accountName}</p>
                          <p className='account-name-tag'>{user.role}</p>
                          <p className='account-name-tag'>Followers: {user.followersCount}</p>
                          {user.isCurrentUser && <p className='account-name-tag'>Following: {followingCount}</p>}
                          {user.isBlockedByCurrentUser && <p className='account-name-tag'>Blocked by Current User</p>}
                        </div> 
                      </Link>

                      <div className='buttons-position-control'>
                        <button className='active-activities-button'>
                          Active
                        </button>

                        {!user.isCurrentUser && ( 
                          <div>
                            <button 
                              className='add-connection-button' 
                              onClick={() => handleFollow(currentUserId, user._id, currentAccountName)}
                            >
                              {isFollowing(user._id) ? 'Unfollow' : 'Follow'}
                            </button>
                          </div>
                        )}

                        {user.status === 'Student' && !user.isCurrentUser && (
                          <div>
                            <button 
                              className='track-activities-button' 
                              onClick={(event) => handleTrackActivities(event, user._id)}
                              disabled={user.hasSentTrackingRequest}
                            >
                              {user.hasSentTrackingRequest 
                                ? user.trackingRequests[0]?.status 
                                : 'Monitor'}
                            </button>
                          </div>
                        )}

                        {!user.isCurrentUser && isAdmin && (
                          <div>
                            <button 
                              className='strike-button'
                              onClick={() => handleStrike(user._id)}
                            >
                              Strike
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                  {/* Insert Ad after every 5 posts */}
                  {index % 5 === 4 && (
                    <li className='accounts-ad-container'>
                      <div>
                        <AdPosts />
                      </div>
                    </li>
                  )}
                </React.Fragment>
              ))
            )}
          </ol>
        </div>
      </div>

      
      <br />
    </div>
  );
}; 

export default Accounts;

/*
<div className='account-main-container-box'>
        <div className='account-main-container-box-aligned'>
          <ol type="none" className='accounts-ol-block-list'>
            {loading ? (
                <p>Loading...</p>
              ) : (
                filteredUsers.map((user, index) => (
                <div> 
                  <React.Fragment key={user.decoyId}>
                    <li className='user-container-main-box'>
                      <div className='accounts-user-details'>
                        <Link to={user.isCurrentUser ? '/profile' : `/user/${user.decoyId}`} className='user-details-block'>
                          <img
                            src={user.profilePicture}
                            alt="Profile" 
                            className='user-details-profile-image' 
                          />
                          <div className='details-about-theuser-box'>
                            <p className='account-name-surname'>{user.name} {user.surname}</p>
                            <p>{user.accountName}</p>
                            <p className='account-name-tag'>{user.role}</p>
                            <p className='account-name-tag'>Followers: {user.followersCount}</p>
                            {user.isCurrentUser && <p className='account-name-tag'>Following: {followingCount}</p>}
                            {user.isBlockedByCurrentUser && <p className='account-name-tag'>Blocked by Current User</p>} 
                            </div> 
                          
                            </Link>
    
                            <div className='buttons-position-control'>
    
                              <button className='active-activities-button'  >
                                Active
                              </button>
    
    
                              {!user.isCurrentUser && ( 
                                <div>
                                  <button 
                                    className='add-connection-button' 
                                    onClick={() => handleFollow(currentUserId, user._id, currentAccountName)}
                                  >
                                    {isFollowing(user._id) ? 'Unfollow' : 'Follow'}
                                  </button>
                                </div>
                              )} 
    
                              <div>
                                {user.status === 'Student' && !user.isCurrentUser && (
                                  <div>
                                    <button 
                                      className='track-activities-button' 
                                      onClick={(event) => handleTrackActivities(event, user._id)}
                                      disabled={user.hasSentTrackingRequest} // Disable the button if the user has sent a tracking request
                                    >
                                      {user.hasSentTrackingRequest 
                                        ? user.trackingRequests[0]?.status 
                                        : 'Monitor'}
                                    </button>
                                  </div>
                                )}
                              </div>
    
                              {!user.isCurrentUser && isAdmin && ( // Show the strike button only if the current user is an admin
                                <div>
                                  <button 
                                    className='strike-button'
                                    onClick={() => handleStrike(user._id)}
                                  >
                                    Strike
                                  </button>
                                </div>
                              )}
                              
                            </div>
    
                            
                            
                          </div>
                          
                        </li>
                        
                        {index % 5 === 4 && ( 
                          <li className='accounts-ad-container'>
                            <AdPosts />
                          </li> 
                        )}
                      </React.Fragment>
                    </div>
                  ))
                )}
              </ol>
            </div>
          </div> 
*/
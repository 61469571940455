import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../pages-styling/pages.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import AdPosts from '../../bodyFiles/AdPosts'; // Import the AdPosts component

const TopicNotesBlock = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [refreshAd, setRefreshAd] = useState(false); // Add state to refresh ads

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include', // Include credentials for cross-origin requests
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          // Redirect to login if not authenticated
          if (!data.user) {
            navigate('/login');
          }

          // Redirect to "/login-second-stage" if the user does not have accountName
          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);

          // Redirect to login if not authenticated
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);

        // Redirect to login on error
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const filterSubjects = () => {
      const filtered = [
        { grade: 'Grade 8', topic: 'Africa Before European Colonization', subject: 'Social Sciences', author: 'Lernacy', to: 'Grade:8_Social-Sciences_Unit1.0' },
        { grade: 'Grade 9', topic: 'Universal Declaration of Human Rights After World War II', subject: 'Social Sciences', author: 'Lernacy', to: 'Grade:9_Social-Sciences_Unit1.0' },
      ].filter(subject =>
        subject.grade.toLowerCase().includes(searchQuery.toLowerCase()) || 
        subject.author.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subject.topic.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subject.subject.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredSubjects(filtered);
    };

    filterSubjects();
  }, [searchQuery]);

  const handleSearchChange = e => {
    setSearchQuery(e.target.value);
  };

  // Logic to insert ads after a certain number of blocks
  const renderContentWithAds = (items, adsEveryNthBlock = 2) => {
    const contentWithAds = [];
    items.forEach((subject, index) => {
      contentWithAds.push(renderSubjectCard(subject.grade, subject.topic, subject.subject, subject.author, subject.to));

      // Insert ad after every nth block
      if ((index + 1) % adsEveryNthBlock === 0) {
        contentWithAds.push(<AdPosts key={`ad-${index}`} refreshAd={refreshAd} />);
      }
    });
    return contentWithAds;
  };

  return (
    <div className='subjects-by-Grade'>
      {user && user.accountName ? (
        <>
          <h1 className='page-title'>Subjects by Grade</h1>
          <div className='subject-search-input-container'>
            <input
              type="text"
              placeholder="Search by grade or subject"
              value={searchQuery}
              onChange={handleSearchChange}
              className='subject-search-input'
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} className='subject-search-input-icon' size='lg'/>
          </div>

          <div className="flex-container">
            {renderContentWithAds(filteredSubjects)} {/* Render content with ads */}
          </div>
        </>
      ) : null}
    </div>
  );
};

const renderSubjectCard = (grade, topic, subject, author, to) => (
  <div className='subjects-container'>
    <div className="subject-card" key={to}>
      <Link className="my-subjects-text" to={`/${to}`}>
        <div className='my-subjects-text-inner'>
          <h3 style={{marginBottom:'10px'}}>{`${grade} - ${subject}`}</h3>
          <p style={{marginTop:'0px'}}>{`${topic}`}</p>
          <p style={{color:'#d1d2d3', margin:'10px 0px'}}>{`${author}`}</p>
        </div>
      </Link>  
    </div>
  </div>
);

export default TopicNotesBlock;


/*
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../pages-styling/pages.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const TopicNotesBlock = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSubjects, setFilteredSubjects] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include', // Include credentials for cross-origin requests
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          // Redirect to login if not authenticated
          if (!data.user) {
            navigate('/login');
          }

          // Redirect to "/login-second-stage" if the user does not have accountName
          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);

          // Redirect to login if not authenticated
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);

        // Redirect to login on error
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const filterSubjects = () => {
      const filtered = [
        // { grade: 'Grade 4', topic: 'text-1', subject: 'Mathematics', author: 'Lernacy', to: 'Grade:4_Mathematics' },
        // { grade: 'Grade 4', topic: 'text-2', subject: 'Social Sciences', author: 'Lernacy', to: 'Grade:4_Social-Sciences' },

        // { grade: 'Grade 5', topic: 'text-1', subject: 'Social Sciences', author: 'Lernacy', to: 'Grade:5_Social-Sciences' },
        // { grade: 'Grade 5', topic: 'text-1', subject: 'Natural Sciences', author: 'Lernacy', to: 'Grade:5_Natural-Sciences' },

        // { grade: 'Grade 6', topic: 'text-1', subject: 'Social Sciences', author: 'Lernacy', to: 'Grade:6_Social-Sciences' },
        // { grade: 'Grade 6', topic: 'text-1', subject: 'Natural Sciences', author: 'Lernacy', to: 'Grade:6_Natural-Sciences' }

        // { grade: 'Grade 7', topic: 'text-1', subject: 'Social Sciences', author: 'Lernacy', to: 'Grade:6_Social-Sciences' },
        { grade: 'Grade 8', topic: 'Africa Before European Colonization', subject: 'Social Sciences', author: 'Lernacy', to: 'Grade:8_Social-Sciences_Unit1.0' },

        { grade: 'Grade 9', topic: 'Universal Declaration of Human Rights After World War II', subject: 'Social Sciences', author: 'Lernacy', to: 'Grade:9_Social-Sciences_Unit1.0' },

      ].filter(subject =>
        subject.grade.toLowerCase().includes(searchQuery.toLowerCase()) || 
        subject.author.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subject.topic.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subject.subject.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredSubjects(filtered);
    };

    filterSubjects();
  }, [searchQuery]);

  const handleSearchChange = e => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className='subjects-by-Grade'>
      {user && user.accountName ? (
        <>
          <h1 className='page-title'>Subjects by Grade</h1>
          <div className='subject-search-input-container'> 
            <input
              type="text"
              placeholder="Search by grade or subject"
              value={searchQuery}
              onChange={handleSearchChange}
              className='subject-search-input'
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} className='subject-search-input-icon' size='lg'/>
          </div>

          <div className="flex-container" >
            {filteredSubjects.map(subject =>
              renderSubjectCard(subject.grade, subject.topic, subject.subject, subject.author, subject.to)
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

const renderSubjectCard = (grade, topic, subject, author, to) => (
  <div className='subjects-container'>
    <div className="subject-card" key={to}>
      <Link className="my-subjects-text" to={`/${to}`}>
        <div className='my-subjects-text-inner'>
          <h3 style={{marginBottom:'10px'}}>{`${grade} - ${subject}`}</h3>
          <p style={{marginTop:'0px'}}>{`${topic}`}</p>
          <p style={{color:'#d1d2d3', margin:'10px 0px'}}>{`${author}`}</p>
        </div>
      </Link>  
    </div>
  </div>
);

export default TopicNotesBlock;
*/
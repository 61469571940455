import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CommunityCard from './CommunityCard';
import MenuToggle from './MenuToggle';  
import AccessDenied from './AccessDenied';
import './Communities.css'; // Import the CSS for styling

const Communities = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const communities = [
    {
      name: 'Lernacy Page',
      link: 'https://www.facebook.com/profile.php?id=61563851974584',
      imageSrc: '/styling/communities/favicon1.png',
      description: "Lernacy's official page! Stay updated with major announcements app updates, and community insights. Follow us for resources, support, and tips on how to make the most of Lernacy for your learning journey.",
    }, 
    {
      name: 'Lernacy Talks', 
      link: 'https://www.facebook.com/groups/881354716791052',
      imageSrc: '/styling/communities/community.png',
      description: 'Lernacy Private Group! This space is dedicated to discussions, feedback, and sharing experiences with the Lernacy app. Join us for in-depth conversations, user reviews, and to connect with fellow learners. Your input helps shape the future of our community!',
    }, 
    {
      name: 'Community 3',
      link: '#',
      imageSrc: '/styling/communities/community.png',
      description: 'This is a description of Community 3. Join us to engage in various activities and events...',
    },
    {
      name: 'Community 4',
      link: '#',
      imageSrc: '/styling/communities/community.png',
      description: 'This is a description of Community 4. It’s a place for networking and sharing knowledge...',
    },
    {
      name: 'Community 5',
      link: '#',
      imageSrc: '/styling/communities/community.png',
      description: 'This is a description of Community 5. Be a part of our vibrant community today...',
    },
    {
      name: 'Community 6',
      link: '#',
      imageSrc: '/styling/communities/community.png',
      description: 'This is a description of Community 6. Connect with others who share your interests...',
    },
  ];

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });
 
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        setIsLoggedIn(false);
      }
    };

    fetchUser();
  }, [navigate]);

  return (
    <div className="communities-page">
      {isLoggedIn ? (
        <div className='page-colour-manager'>

            <h1 className='activity-name-tag-h1'>Communities</h1>
            <div className="my-study-container">  
                <div className='unique-image-card-container-pad'>
                  
                  <p className='disclaimer-note-label'>
                    Clicking any link below means you accept our  
                    <Link to="/general-disclaimer" className='general-delaration-link'> disclaimer</Link>.
                  </p>


                  <div className="communities-container">
                    {communities.map((community, index) => (
                      <CommunityCard key={index} community={community} />
                    ))}
                  </div>

                </div>
            </div>

        </div>
      ) : (
        <div className='not-logged-in-alt'>
          <AccessDenied />
        </div>
      )}
    </div>
  );
};

export default Communities;

import React from 'react';

const CommunityCard = ({ community }) => {
  return (
    <div className="community-card">
      <a href={community.link} className="community-card-link">
        <img loading="lazy" src={community.imageSrc} alt={community.name} className="community-card-image" />
        <div className="community-card-content">
          <h3 className="community-card-title">{community.name}</h3>
          <p className="community-card-description">
            {community.description.length > 250
              ? `${community.description.slice(0, 250)}...`
              : community.description}
          </p>
        </div>
      </a>
    </div>
  );
};

export default CommunityCard;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AccessDenied from '../../components/AccessDenied';

const GrSevenIntegers = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [highScore, setHighScore] = useState(null);
    const [gameScore, setGameScore] = useState(0);
    const [equations, setEquations] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [scoreRating, setScoreRating] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [isGameOver, setIsGameOver] = useState(false);
    const [gameName] = useState('Grade_7:_Integers_Maths_10');

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/study', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    const user = data.user;

                    if (!user || !user.accountName) {
                        setIsAuthenticated(false);
                        // Redirect to "/login-second-stage"
                        window.location.href = "/login-second-stage";
                        return;
                    }

                    setUserDetails(user);
                    setIsAuthenticated(true);
                } else {
                    throw new Error('Error fetching user details: ' + response.statusText);
                }
            } catch (error) {
                console.error(error.message);
                setIsAuthenticated(false);
            }
        };

        fetchUserDetails();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchHighScore();
            generateEquations();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const calculatedPercentage = ((gameScore / 10) * 100).toFixed(2); // Format the percentage to two decimal places
        setPercentage(calculatedPercentage);

        if (calculatedPercentage >= 90) {
            setScoreRating('Outstanding');
        } else if (calculatedPercentage >= 75) {
            setScoreRating('Passed');
        } else if (calculatedPercentage >= 50) {
            setScoreRating('Almost');
        } else {
            setScoreRating('Failed');
        }
    }, [gameScore]);

    const fetchHighScore = async () => {
        try {
            const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setHighScore(data.highScore);
            } else {
                console.error('Error fetching high score:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching high score:', error.message);
        }
    };

    const generateEquations = () => {
        const newEquations = [];
        
        for (let i = 0; i < 10; i++) {
            let validEquation = false;
            let equationString, result;
    
            while (!validEquation) {
                // Randomly generate the starting number between -200 and 200, excluding 0
                let number = Math.floor(Math.random() * 401) - 200;
                if (number === 0) number += 1; // Ensure number is non-zero
    
                // Create an equation with the first number
                const equationArray = [number];
                let equationLength = Math.floor(Math.random() * 5) + 3; // Equation length between 3 and 7
    
                // Randomly add either positive or negative numbers
                for (let j = 1; j < equationLength; j++) {
                    // Randomly decide if next number will be positive or negative
                    const operation = Math.random() < 0.5 ? '+' : '-';
                    let nextNumber = Math.floor(Math.random() * 201); // Next number from 0 to 200
    
                    equationArray.push(operation);
                    equationArray.push(nextNumber);
                }
    
                // Combine the equation array into a string and evaluate it
                equationString = equationArray.join(' ');
                result = eval(equationString);
    
                // Check if result is within a reasonable range, e.g., -1000 to 1000
                if (result >= -1000 && result <= 1000) {
                    validEquation = true;
                }
            }
    
            newEquations.push({
                equation: equationString,
                result: result,
                userAnswer: '',
                isCorrect: null,
            });
        }
    
        setEquations(newEquations);
    };           

    const handleAnswerChange = (index, e) => {
        const updatedEquations = [...equations];
        updatedEquations[index].userAnswer = e.target.value;
        setEquations(updatedEquations);
    };

    const handleSubmit = async () => {
        let tempGameScore = 0; // Use a temporary variable to track the score
      
        const newAnswers = equations.map((eq) => {
            const userAnswer = parseInt(eq.userAnswer, 10);
            const isCorrectAnswer = userAnswer === eq.result;
            if (isCorrectAnswer) {
                tempGameScore++;
            }
            return {
                ...eq,
                userAnswer: userAnswer,
                isCorrect: isCorrectAnswer,
            };
        });
      
        setGameScore(tempGameScore); // Update the state with the computed score
        setUserAnswers(newAnswers);
        setIsGameOver(true);
      
        const data = {
            userId: userDetails._id,
            score: tempGameScore, // Use the temporary score variable here
            timeOfDay: new Date().getHours(),
            gameName: gameName,
        };
      
        try {
            // Step 1: Fetch CSRF token
            const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
                method: 'GET',
                credentials: 'include',
            });
        
            const csrfData = await csrfResponse.json();
            const csrfToken = csrfData.csrfToken;
        
            if (!csrfResponse.ok) {
                throw new Error('Failed to fetch CSRF token');
            }
      
            // Step 2: Send a POST request with CSRF token
            const response = await fetch('https://lernacy.co.za/api/record-score', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                body: JSON.stringify(data),
                credentials: 'include', // Include credentials
            });
      
            if (response.ok) {
                //console.log('Score recorded successfully!');
            } else {
                console.error('Failed to record score:', response.statusText);
            }
        } catch (error) {
          console.error('Error recording score:', error.message);
        }
    };    

    const restartGame = async () => {
        try {
            setGameScore(0);
            setUserAnswers([]);
            setIsGameOver(false);
            generateEquations();

            // Fetch high score
            const response = await fetch(`https://lernacy.co.za/api/user-score/${gameName}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setHighScore(data.highScore);
            } else {
                console.error('Error fetching high score:', response.statusText);
            }
        } catch (error) {
            console.error('Error restarting game:', error.message);
        }
    };

    return (
        <div className='tested-activity-wall-container'>
            {isAuthenticated === null ? (
                <p>Loading authentication status...</p>
            ) : !isAuthenticated ? (
                <AccessDenied />
            ) : (
                <div>
                    
                    {!isGameOver ? ( 
                        <div>
                            <h1 className='activity-name-tag-h1'>G7 - Integers</h1>
                            <div className='round-high-score'>
                                <h2 className='activity-rounds-tag-h2'>10 Questions</h2>
                                <p>
                                    <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/10</strong>
                                </p>
                            </div>
                            <h3>Answer all the questions below:</h3>
                            <div className='equations-aligner-block-cover'>
                                {equations.map((eq, index) => (
                                    <div key={index} className='equations-block-box'>
                                        <p><strong>Question {index + 1}</strong></p>
                                        <p >Solve the equation:<br/> <strong className='equation-p-tag'>{eq.equation} =</strong></p>
                                        <label>Your Answer:</label><br />
                                        <input
                                            value={eq.userAnswer}
                                            onChange={(e) => handleAnswerChange(index, e)}
                                            className='equations-block-box-input'
                                        /><br />
                                    </div>
                                ))}
                            </div>
                            <br/>
                            <button className='activity-submit-button' onClick={handleSubmit}>Submit Answers</button>
                        </div>
                    ) : ( 
                        <div>
                            <h1 className='activity-name-tag-h1'>Activity Over<br/>Your Score: {gameScore}/10</h1>
                            
                            <div className='activity-game-over-data'>
                                <h2 className='activity-rounds-tag-h2-left'>Score Rating: {scoreRating}</h2>
                                <h3>{getCommentForScoreRating(scoreRating)}</h3>
                                <p>Percentage: <strong>{percentage}%</strong></p>
                            </div>

                            <div>
                                <h3 className='activity-rounds-tag-h2'>Round Records:</h3>
                                <div className='activity-over-rounds-evidence-ul'> 
                                    {userAnswers.map((answer, index) => (
                                        <div key={index} className='activity-over-rounds-evidence'>
                                            <p key={index}>
                                                <strong>Question {index + 1}</strong> <br /><br />
                                                {answer.equation} = ☐ 
                                                <br />
                                                Your Answer: {answer.userAnswer} {answer.isCorrect ? <span style={{ color: 'green' }}>✔</span> : <span style={{ color: 'red' }}>✘</span>}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                            <hr />
                            <p>
                                <strong>High Score: {highScore !== null ? `${highScore}` : 'N/A'}/10</strong>
                            </p>

                            <div className='activity-restart-or-more'>
                                <button className='activity-restart-button' onClick={restartGame}>Play Again</button>
                                <button className='more-activity-button' onClick={() => window.location.href = "/Grade:5_Mathematics"}>Do More Activities</button>
                            </div>
                            <br/>
                        </div>
                    )}

                    <br />
                </div>
            )}
        </div>
    );
};

const getCommentForScoreRating = (scoreRating) => {
    switch (scoreRating) {
        case 'Outstanding':
            return 'Congratulations! You performed outstandingly.';
        case 'Passed':
            return 'Well done! You passed the counting game.';
        case 'Almost':
            return 'You are close to passing. Keep practicing!';
        case 'Failed':
            return 'Keep practicing. You can do better!';
        default:
            return '';
    }
};

export default GrSevenIntegers;

import nlp from 'compromise';

export const processText = (text) => {
  // Ensure proper capitalization of each sentence
  const doc = nlp(text);
  doc.sentences().toTitleCase();
  const correctedText = doc.out('text');

  // Recreate the NLP document with corrected text
  const processedDoc = nlp(correctedText);
  const sentences = processedDoc.sentences().out('array');
  const nouns = processedDoc.nouns().out('array');
  const verbs = processedDoc.verbs().out('array');
  const adjectives = processedDoc.adjectives().out('array');
  const adverbs = processedDoc.adverbs().out('array');

  return {
    correctedText,
    sentences,
    nouns,
    verbs,
    adjectives,
    adverbs,
  };
};

import React, { useState } from 'react';
import axios from 'axios';

const ImageUploadForm = () => {
  const [message, setMessage] = useState('');

  // Function to handle image upload
  const handleImageUpload = async () => {
    try {
      // Validate input data
      const userId = '65f207478d37cc9119aed70a'; // Hardcoded userId for testing purposes
      const imagePath = '../public/profiles/profile.png'; // Hardcoded image path for testing purposes
  
      if (!userId || !imagePath) {
        throw new Error('Invalid input data');
      }
  
      // Make a POST request to upload the image
      const response = await axios.post('https://lernacy.co.za/api/imageUpload/upload-profile-picture', {
        userId,
        imagePath,
      });
  
      // Display success message
      setMessage(response.data.message);
    } catch (error) {
      // Display error message if request fails
      setMessage(error.response.data.message || 'Failed to upload profile picture');
    }
  };

  return (
    <div>
      <h2>Upload Profile Picture</h2>
      <button onClick={handleImageUpload}>Upload Image</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ImageUploadForm;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ActivityTakeTour from '../components/TourGuide/ActivityTakeTour';

const ActivitiesPage = () => {
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const response = await fetch('https://lernacy.co.za/api/activities', {
                    credentials: 'include',
                });
                
                // Handle 404 response explicitly
                if (response.status === 404) {
                    throw new Error('No activities found. Please check back later.');
                }

                if (!response.ok) {
                    throw new Error('Failed to fetch activities');
                }

                const data = await response.json();
                setActivities(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchActivities();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
        return date.toLocaleDateString(undefined, options);
    };

    const getTimeRemaining = (endTime) => {
        const currentTime = new Date();
        const timeRemaining = new Date(endTime) - currentTime;

        return timeRemaining > 0 ? timeRemaining : null;
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    const nonExpiredActivities = activities.filter(activity => getTimeRemaining(activity.showFor) !== null);

    return (
        <div className='activity-page-main-border'>
            <h1 className='activity-name-tag-h1'>Activity Take</h1>
            <ActivityTakeTour />
            <div className="activities-container">
                {nonExpiredActivities.length === 0 ? (
                    <div className='activity-availabilty-check-container'>
                        <p className='activity-availabilty-check'>No available activities at the moment. Communicate with your Instructor.</p>
                    </div>
                ) : (
                    nonExpiredActivities.map(activity => {
                        const timeRemaining = getTimeRemaining(activity.showFor);
                        return (
                            <div key={activity._id} className="activity-card">
                                <h3>{activity.activityName}</h3>
                                <p>Type: {activity.activityType}</p>
                                <p>Time Limit: {activity.timeLimit} minutes</p>
                                <p>Show Until: {`${Math.floor(timeRemaining / (1000 * 60 * 60))} hours and ${Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))} minutes remaining`}</p>
                                <p>Created At: {formatDate(activity.createdAt)}</p>
                                <p>Author: {activity.authorId.accountName}</p>
                                {activity.hasAttempted ? (
                                    <p>You have already attempted this activity.</p>
                                ) : (
                                    <div className='activity-card-link-div'>
                                        <Link to={`/activity/${activity._id}`} className="activity-link">
                                            Start Activity
                                        </Link>
                                    </div>
                                )}
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default ActivitiesPage;


// useFetchUserData.js
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useFetchUserData = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://lernacy.co.za/api/study', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.user);

          if (!data.user) {
            navigate('/login');
          }

          if (!data.user.accountName) {
            navigate('/login-second-stage');
          }
        } else {
          const errorData = await response.json();
          console.error('Error fetching user data:', errorData.message);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  return user;
};

export default useFetchUserData;
